<template>
  <q-dialog v-if="isMobile" position="bottom" v-model="dialogState" maximized>
    <q-card
      class="mobileCardDialogue"
      style="min-height:150px;max-height:90vh;"
    >
      <div class="mobileDialogTitle">
        <h5 class="text-h5">Select Category</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </div>
      <div class="h-search-category-mobile">
        <div class="form-field pb-0">
          <q-input
            outlined
            v-model="searchByCategory"
            label="Search Categories"
            dense
            debounce="300"
            placeholder="Type to search..."
            clearable
            @clear="searchByCategory=''"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
        </div>
        <div class="h-search-category-mobile-list scroll-style-1">
          <a
            v-for="(cat) in filteredCategories"
            :key="cat.categoryID"
            href="javascript:void(0);"
            @click="setSearchCategory(cat.categoryID)"
            :class="{
              selected: searchCategory.categoryName == cat.categoryName,
            }"
            >{{ $options.filters.titleCase(cat.categoryName) }}</a
          >
        </div>
        <div class="col-md-6 searchCategory" v-if="filteredCategories.length == 0">
          <EmptyComponent
            :imgWidth="90"
            :image="`images/empty-search.png`"
          >
            <p>No Categories found</p>
          </EmptyComponent>
          <!-- <h6>No Contact Service</h6>
          <p>We understand the seriousness</p> -->
        </div>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  data()
  {
    return {
      searchByCategory:''
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState('selectCategory')
      },
      set(val) {
        this.changeDialogState({
          dialog: 'selectCategory',
          val,
        })
      },
    },
    allCategories() {
      let categories = [
        { categoryID: '', categoryName: 'All' },
        ...this.categories,
      ]
      return categories
    },
    filteredCategories()
    {
      return this.allCategories.filter((category)=>category.categoryName?.toLowerCase().trim().includes(this.searchByCategory?.toLowerCase().trim()));
    },
    ...mapGetters('category', ['searchCategory', 'categories']),
  },
  methods: {
    setSearchCategory(catId) {
      let selectCategory=this.allCategories.find((category)=>category.categoryID==catId)
      this.$store.commit(
        'category/SET_SELECTED_SEARCH_CATEGORY',
        selectCategory
      )

      this.changeDialogState({
        dialog: 'selectCategory',
        val: false,
      })
    },
  },
}
</script>
<style lang="scss">
.h-search-category-mobile {
  padding: 16px;
  .h-search-category-mobile-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: -5px;
    margin-left: -5px;
    max-height: calc(90dvh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
    a {
      margin: 5px;
      padding: 10px 15px;
      font-size: 14px;
      background: #fff;
      border: 1px solid rgba(0, 0, 0, 0.2);
      color: $black;
      text-decoration: none;
      border-radius: 20px;
      &.selected {
        background: $primary;
        background: var(--q-color-primary) !important;
        border-color: $primary;
        border-color: var(--q-color-primary) !important;
        border-color: var(--q-color-primary) !important;
        color: $white;
      }
    }
  }

  .form-field {
    margin-bottom: 16px;
  }
}
</style>
