<template>
  <q-popup-edit
    v-model="popup"
    ref="categorySearchPopup"
    :cover="false"
    :offset="[0, 0]"
    content-class="h-by-category scroll-style-1"
  >
    <div class="h-search-category">
      <div class="form-field pb-0">
        <q-input
          outlined
          v-model="searchByCategory"
          label="Search Categories"
          dense
          debounce="300"
          placeholder="Type to search..."
          clearable
          @clear="searchByCategory = ''"
        >
          <template v-slot:append>
            <q-icon name="search" />
          </template>
        </q-input>
      </div>
      <div class="row">
        <div class="col-12">
          <q-list
            dense
            v-for="(cat, index) in filteredCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 1 == 0"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(cat.categoryID)">
                {{ $options.filters.titleCase(cat.categoryName) }}
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="col-12">
          <q-list
            dense
            v-for="(cat, index) in filteredCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 1 != 0"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(cat.categoryID)">{{
                $options.filters.titleCase(cat.categoryName)
              }}</q-item-section>
            </q-item>
          </q-list>
        </div>
        <!-- <div class="col">
          <q-list
            dense
            v-for="(cat, index) in allCategories"
            :key="cat.categoryID"
          >
            <q-item
              clickable
              v-if="index % 3 == 2"
              :active="searchCategory.categoryName == cat.categoryName"
              active-class="text-blue"
            >
              <q-item-section @click="setSearchCategory(index)">{{
                cat.categoryName
              }}</q-item-section>
            </q-item>
          </q-list>
        </div> -->
        <!-- ToDo: v-if Temporary -->
        <div class="col-12 searchCategory" v-if="filteredCategories.length == 0">
          <EmptyComponent
            :imgWidth="90"
            :image="`images/empty-search.png`"
          >
            <p>No Categories found</p>
          </EmptyComponent>
          <!-- <h6>No Contact Service</h6>
          <p>We understand the seriousness</p> -->
        </div>
      </div>
    </div>
  </q-popup-edit>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      popup: false,
      active: true,
      searchByCategory: '',
    }
  },
  computed: {
    allCategories() {
      let categories = [
        { categoryID: '', categoryName: 'All' },
        ...this.categories,
      ]
      return categories
    },
    filteredCategories() {
      return this.allCategories.filter((category) =>
        category.categoryName
          .toLowerCase().trim()
          .includes(this.searchByCategory.toLowerCase().trim())
      )
    },
    ...mapGetters('category', ['searchCategory', 'categories']),
  },
  methods: {
    setSearchCategory(catId) {
      let selectCategory = this.allCategories.find(
        (category) => category.categoryID == catId
      )
      this.$store.commit(
        'category/SET_SELECTED_SEARCH_CATEGORY',
        selectCategory
      )
      this.$refs.categorySearchPopup.hide()
      this.changeDialogState({
        dialog: 'selectCategory',
        val: false,
      })
    },
  },
}
</script>
<style lang="scss">
.h-by-category {
  -webkit-box-shadow: 0 3px 5px rgba($textdark, 0.2);
  box-shadow: 0 3px 5px rgba($textdark, 0.2);
  -webkit-border-radius: 7px;
  border-radius: 7px;
  padding: 0;
  position: relative;
  .form-field {
    position: sticky;
    left: 0;
    right: 0;
    top: 0;
    padding: 8px;
    background: rgba(255, 255, 255, 0.9);
    z-index: 1;
  }
}
.h-search-category {
  width: 240px;
  font-size: 14px;
  font-weight: 600;
  padding-bottom: 8px;
  .q-list {
    padding: 0 12px;
  }
  .q-item {
    padding: 0 5px;
    color: #0f141a;
    .q-focus-helper {
      opacity: 0 !important;
    }
    &.text-blue,
    &:hover {
      color: $primaryOnBody !important;
      color: var(--q-color-primaryOnBody) !important;
      text-decoration: underline;
    }
  }
  a {
    font-size: 14px;
    color: #0f141a;
  }
  .searchCategory {
    h6 {
      margin: 15px 0 0;
      padding: 0;
      color: $primary;
      color: var(--q-color-primary) !important;
      font-size: 16px;
      font-family: 'Poppins', sans-serif;
      @media (min-width: 1681px) {
        margin-bottom: 5px;
        font-size: 20px;
      }
    }
    p {
      font-size: 14px;
      color: #888888;
    }
  }
  @media (max-width: 1023px) {
    width: 100%;
    padding: 10px;
  }
}
</style>
