<template>
  <Error404 v-if="!tenantFound" />
  <div
    v-else-if="!storeIsActive"
    class="tenantnotfound flex row items-center justify-center"
  >
    <div class="col-12 col-md-6 text-center q-pa-md">
      <q-img
        src="images/inactive_picture.jpg"
        style="max-width:500px; width:100%; height:auto;"
      />
    </div>
    <div class="col-12 col-md-6 q-pa-md">
      <!-- <div class="q-mt-md tenantnotfound-title" v-if="!tenantFound">
        OPPS,<br />
        Tenant not found
      </div> -->
      <div class="q-mt-md tenantnotfound-title">
        OPPS,<br />
        This site is currently under construction.
        <!-- <span class="text-primary">Your</span> Store is not active Yet -->
      </div>
      <!-- <p>
        Lorem ipsum dolor sit amet, consectetur adipisicing elit. Commodi fugit
        <br />
        voluptatem tenetur a in unde eos dicta doloribus quidem, odio autem
      </p> -->
      <!-- <q-btn color="primary" label="Go Back" @click="goBack" /> -->
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'StoreNotActive',
  components: {
    Error404: () => import('components/common/Error404'),
  },
  methods: {
    goBack() {
      if (typeof window !== 'undefined') window.history.back()
    },
  },
  computed: {
    ...mapGetters('common', ['tenantFound', 'storeIsActive']),
  },
}
</script>
<style lang="scss">
.tenantnotfound {
  min-height: 100vh;
  font-size: 16px;

  .tenantnotfound-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 1.4;
    margin-bottom: 15px;
  }
  @media (max-width: 1024px) {
    text-align: center;
    .tenantnotfound-title {
      font-size: 24px;
    }
  }
}
</style>
