<template>
  <div :class="['template-image q-mb-md']" @click="linkClick">
    <AgeRestrictedBlur :isPreview="isPreview" :component="component" />
    <template v-if="showAgeVerificationBtn && component.isAgeRestricted">
      <img
        :src="`https://placehold.co/${component.imageWidth || 612}x${
          component.imageHeight || 408
        }/F5F5F5/black?text=Age+Restricted`"
        alt=""
        class="block"
      />
    </template>
    <template v-else>
      <template v-if="redirectLink">
        <a
          v-if="component.openInNewTab"
          :href="redirectLink"
          target="_blank"
          class="inline-block"
        >
          <img
            :src="component.imageUrl || '/placeholder-image.png'"
            alt=""
            class="block"
          />
        </a>
        <router-link v-else :to="redirectLink" class="inline-block">
          <img
            :src="component.imageUrl || '/placeholder-image.png'"
            alt=""
            class="block"
          />
        </router-link>
      </template>
      <img
        v-else
        :src="component.imageUrl || '/placeholder-image.png'"
        alt=""
        class="block"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SingleImage',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters('common', ['storeHasLnsSubscription']),
    redirectLink() {
      if (this.component.linkType?.toUpperCase() === 'LNS') {
        if (this.isLoggedIn && this.storeHasLnsSubscription)
          return 'loyalty-program#/'

        return null
      }
      if (this.component.linkType && this.component.seoName)
        return `/${this.component.linkType}/${this.component.seoName}`

      return null
    },
    // canShowImageComponent() {
    //   return (
    //     !this.component.isAgeRestricted ||
    //     (this.component.isAgeRestricted &&
    //       (!this.storeAgeVerificationRequired ||
    //         (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
    //   )
    // },
  },
  methods: {
    linkClick() {
      if (
        !this.isLoggedIn &&
        this.component.linkType?.toUpperCase() === 'LNS' &&
        !(this.showAgeVerificationBtn && this.component.isAgeRestricted)
      )
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
    },
  },
}
</script>
