<template>
  <q-page class="accountPages editOrder">
    <template v-if="orderInfo.orderNumber">
      <div class="container">
        <div class="page-title hide-in-mobile">
          <h2>Edit Order</h2>
          <div class="page-breadcrumb">
            <a href="javascript:void(0)" @click="goBack()" class="no-underline">
              <SvgIcon icon="back-arrow-line" />{{
                backRoute ? 'Back' : 'Home'
              }}
            </a>
            <span v-html="`Order Number: ${orderInfo.orderNumber}`" />
          </div>
          <div class="row" v-if="!isMobile">
            <Alert
              className="col-md-6 q-mt-sm"
              v-if="editOrderAlert"
              :variant="editOrderAlert.variant"
              @dismissed="
                () => {
                  this.alertShow = 0
                }
              "
              @dismiss-count-down="
                (countDown) => {
                  this.alertShow = countDown
                }
              "
              :show="alertShow"
            >
              <span v-html="editOrderAlert.message" />
            </Alert>
          </div>
        </div>
        <div class="page-content">
          <!-- -->
          <div class="orderEditItems">
            <!-- -->
            <div class="orderItem">
              <div class="orderItemTop" v-if="!isMobile">
                <div class="row items-center">
                  <div class="col-12 col-md-5">
                    <ul class="orderItemInfo">
                      <li v-if="orderInfo.pickupStore">
                        <span>Pickup Store</span>
                        {{ orderInfo.pickupStore.name }}
                      </li>
                      <li v-if="orderInfo.shippingAddress">
                        <span>
                          {{
                            orderInfo.codeName == 'scd'
                              ? 'Deliver To'
                              : 'Ship To'
                          }}
                        </span>
                        {{
                          orderInfo.shippingAddress.firstName +
                          ' ' +
                          orderInfo.shippingAddress.lastName
                        }}
                      </li>
                      <li v-if="orderInfo.timeSlot">
                        <span>Reserved Time Slot</span>
                        {{ orderInfo.timeSlot.day.substring(0, 3) }},
                        {{ orderInfo.timeSlot.slotTime }}
                        <router-link
                          v-if="orderInfo.updateReservedSlotCount == 0"
                          :to="{
                            name: 'OrderTime',
                            params: { orderId: orderId },
                          }"
                          @click.native="
                            $store.commit('order/SET_UPDATE_TIME_SLOT', orderId)
                          "
                        >
                          Change
                        </router-link>
                      </li>
                    </ul>
                  </div>
                  <div class="col-12 col-md-7 actions"> </div>
                </div>
              </div>
              <div class="orderItemMiddle">
                <div class="row items-center">
                  <div class="col-12 col-md-12" v-if="isMobile">
                    <div class="curbBox text-center">
                      <h3
                        ><SvgIcon
                          color="primary"
                          :icon="orderInfo.codeName"
                          class="q-mr-md"
                        />{{ orderInfo.displayName }}</h3
                      >
                    </div>
                    <ul class="curbOrderInfo autoHeight" v-if="isMobile">
                      <li>
                        <span>
                          <label>ORDER NUMBER</label>
                          <strong v-html="orderInfo.orderNumber" />
                        </span>
                      </li>
                      <li v-if="orderInfo.pickupStore">
                        <span>
                          <label>Pickup Store</label>
                          <strong v-html="orderInfo.pickupStore.name" />
                        </span>
                      </li>
                      <li v-if="orderInfo.shippingAddress">
                        <span>
                          <label>
                            {{
                              orderInfo.codeName == 'scd'
                                ? 'Deliver To'
                                : 'Ship To'
                            }}
                          </label>
                          <strong>
                            {{
                              orderInfo.shippingAddress.firstName +
                              ' ' +
                              orderInfo.shippingAddress.lastName
                            }}
                          </strong>
                        </span>
                      </li>
                      <li v-if="orderInfo.timeSlot">
                        <span>
                          <label>Reserved Time Slot</label>
                          <strong>
                            {{ orderInfo.timeSlot.day.substring(0, 3) }},
                            {{ orderInfo.timeSlot.slotTime }}
                          </strong>
                          <router-link
                            v-if="orderInfo.updateReservedSlotCount == 0"
                            :to="{
                              name: 'OrderTime',
                              params: { orderId: orderId },
                            }"
                            @click.native="
                              $store.commit(
                                'order/SET_UPDATE_TIME_SLOT',
                                orderId
                              )
                            "
                          >
                            Change
                          </router-link>
                        </span>
                      </li>
                    </ul>
                  </div>

                  <div
                    v-if="orderInfo.updateReservedSlotCount == 0"
                    class="col-12 col-md-12 slotTime-change-txt"
                  >
                    <div class="row">
                      <div class="col-12 col-md-8">
                        * Time slots can be updated once per order. If you have
                        an issue with your order, please contact the store.
                      </div>
                    </div>
                  </div>

                  <div
                    v-if="
                      orderInfo.isEditTillDatetime || showSubscriptionBanner
                    "
                    class="col-12 col-md-12"
                  >
                    <div class="row">
                      <div class="col-12 col-md-12">
                        <q-banner
                          inline-actions
                          dense
                          rounded
                          class="alert-warning alert-sm q-mb-md"
                        >
                          <span v-show="orderInfo.isEditTillDatetime">
                            Modify your order until it is moved under
                            processing. Once your order status is changed to
                            processing, it can no longer be modified.
                          </span>
                          <span v-show="showSubscriptionBanner">
                            <br v-show="orderInfo.isEditTillDatetime" />
                            The subscribed product is in your order as per the
                            scheduled date. Any changes, such as increasing or
                            decreasing the product quantity, will apply to this
                            order only. To manage your subscription details for
                            future orders, please visit the '<router-link
                              class="text-underline text-primary"
                              :to="{
                                name: 'ManageSubscription',
                              }"
                              target="_blank"
                              >Manage My Subscription</router-link
                            >' menu.
                          </span>
                          <!-- Your subtotal will decrease by <strong>$0.00</strong>. -->
                          <!-- You have until
                          <strong>{{
                            orderInfo.isEditTillDatetime
                              | formatDate('h:mm A, ddd, MMM DD')
                          }}</strong>
                          to change this order. -->
                        </q-banner>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="
                      isMobile &&
                      orderInfo.appliedLnsCoupons &&
                      orderInfo.appliedLnsCoupons.length
                    "
                    class="col-12 col-md-12"
                  >
                    <div class="row">
                      <div class="col-12 col-md-8">
                        <q-banner
                          inline-actions
                          dense
                          rounded
                          class="alert-warning alert-sm q-mb-md"
                        >
                          <strong>Note: </strong>Coupons may be removed from the
                          order if the applicable items are removed either by
                          editing the order or being out of stock.
                        </q-banner>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12">
                    <div class="row editOrderTableTitle">
                      <div class="col-12 col-md-6"
                        ><h4>Items ({{ orderInfo.totalItems }})</h4></div
                      >
                      <div class="col-12 col-md-6 text-right">
                        <template v-if="orderInfo.isEditTillDatetime">
                          Add more items to this order before it moved under the
                          processing
                          <!-- Add more items to this order until:
                          {{
                            orderInfo.isEditTillDatetime
                              | formatDate('h:mm A, ddd, MMM DD')
                          }}. -->
                        </template>
                        <q-btn
                          unelevated
                          no-caps
                          :rounded="!isMobile"
                          color="secondary"
                          size="sm"
                          class="q-ml-sm"
                          @click="
                            changeDialogState({
                              dialog: 'AddOrderItems',
                              val: true,
                            })
                          "
                          label="Add item"
                        />
                      </div>
                      <div class="col-12 col-md-12">
                        <q-separator class="q-mt-md q-mb-md" />
                      </div>
                    </div>
                  </div>
                  <div class="col-12 col-md-12" v-show="!isCancelOrder">
                    <AllowSubstitutions
                      v-if="orderInfo.codeName.toLowerCase() !== 'sd'"
                      isFor="order"
                      :orderId="orderId"
                    />
                  </div>
                  <div class="col-12 col-md-12">
                    <!-- New UI for Order -->
                    <div class="cart-section">
                      <div
                        class="cart-order"
                        v-for="item of orderItems"
                        :key="item.productId"
                      >
                        <!-- Mobile View -->
                        <div class="cart-product-left">
                          <div
                            v-if="businessConfigurations.enableSubstitution"
                            :class="[
                              {
                                blacklistedproduct: item.isBlacklisted,
                              },
                              {
                                disabled: item.isDeleted,
                              },
                            ]"
                          >
                            <q-checkbox
                              color="secondaryOnBody"
                              :disable="item.isDeleted || item.isBlacklisted"
                              :value="item.allowSubstitute"
                              @input="toggleSubstitute(item)"
                              v-if="orderInfo.codeName.toLowerCase() !== 'sd'"
                            />
                          </div>

                          <template v-if="isMobile">
                            <div class="cartItemRemove" v-if="!item.isDeleted">
                              <q-btn
                                no-caps
                                unelevated
                                class="no-hover"
                                padding="0"
                                @click="deleteLineItem(item)"
                              >
                                <SvgIcon
                                  color="colorful"
                                  size="1rem"
                                  icon="trash"
                                />
                              </q-btn>
                            </div>
                            <div class="cartItemRemove" v-else>
                              <q-btn
                                no-caps
                                unelevated
                                flat
                                color="red"
                                class="no-hover"
                                padding="0"
                                @click="restoreItem(item)"
                              >
                                <q-icon
                                  size="1.2rem"
                                  color="#010101"
                                  name="restore_from_trash"
                                />
                              </q-btn>
                            </div>
                          </template>
                        </div>
                        <!-- Mobile View -->

                        <div
                          class="cart-product"
                          :class="[
                            {
                              itemDeleted: item.isDeleted,
                              'substitution-product':
                                (item.linkedProducts &&
                                  item.linkedProducts.length > 0) ||
                                item.allowSubstitute,
                            },
                          ]"
                        >
                          <!-- Cart Item -->
                          <div class="cart-produt-data">
                            <div class="produt-data flex items-start">
                              <div
                                class="product-image"
                                :class="item.isDeleted ? 'disabled' : ''"
                              >
                                <box-image
                                  :link="{
                                    name: 'Detail',
                                    params: { seoName: item.seoName },
                                  }"
                                  :imageSrc="item.image"
                                  :width="isMobile ? '80px' : '86px'"
                                />
                              </div>

                              <div class="co-product-details">
                                <div class="product--title">
                                  <h3
                                    class="ellipsis"
                                    :class="item.isDeleted ? 'disabled' : ''"
                                  >
                                    <template v-if="item.isBlacklisted">
                                      {{ item.productName }}</template
                                    >
                                    <router-link
                                      v-else
                                      :to="{
                                        name: 'Detail',
                                        params: { seoName: item.seoName },
                                      }"
                                      target="_blank"
                                    >
                                      {{ item.productName }}
                                    </router-link>
                                  </h3>

                                  <div class="product--labeles q-mt-sm">
                                    <span
                                      class="label-success"
                                      v-if="item.isAdded"
                                      v-html="`Just Added`"
                                    />
                                    <span
                                      class="label-primary"
                                      v-else-if="item.isUpdated"
                                      v-html="`Updated`"
                                    />

                                    <span
                                      v-if="item.isBlacklisted"
                                      class="text-negative text-body2 block q-mt-xs"
                                    >
                                      <q-icon name="info"></q-icon> Product No
                                      Longer on Site</span
                                    >
                                  </div>

                                  <q-banner
                                    inline-actions
                                    dense
                                    rounded
                                    class="alert-warning alert-sm text-subtitle2 inline-block"
                                    v-if="!item.isAdded && item.frequency > 0"
                                  >
                                    Recurring in every
                                    <strong>{{ item.frequency }} Days</strong>
                                  </q-banner>

                                  <OrderLevelinstruction
                                    :productId="item.productId"
                                    :product="item"
                                    :isfromEditOrderLevel="true"
                                    :orderId="orderId"
                                    v-if="
                                      !item.isDeleted && !item.isBlacklisted
                                    "
                                  />
                                  <div class="row" v-if="!isMobile">
                                    <div class="col-12 col-sm-7 col-md-6">
                                      <ProductSubscriptionOptions
                                        ref="productSubscriptionOptions"
                                        pageName="OrderEdit"
                                        :product="item"
                                        :productId="item.productId"
                                        :key="item.productId"
                                        :orderId="orderId"
                                        v-if="
                                          orderInfo.codeName == 'scd' &&
                                          item.isAdded &&
                                          item?.isSubscribeForAutoShip
                                        "
                                        class="q-mt-md"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div
                                  class="price"
                                  :class="item.isDeleted ? 'disabled' : ''"
                                >
                                  <span
                                    class="original-price"
                                    v-if="item.totalPrice != item.finalPrice"
                                  >
                                    {{ item.totalPrice | currency }}
                                  </span>
                                  <span
                                    :class="
                                      item.totalPrice != item.finalPrice
                                        ? 'discount-price'
                                        : ''
                                    "
                                  >
                                    {{ item.finalPrice | currency }}
                                  </span>
                                </div>

                                <div
                                  class="flex product-edit--options"
                                  v-if="!isMobile"
                                >
                                  <div
                                    :class="[
                                      'product-quantity',
                                      {
                                        blacklistedproduct: item.isBlacklisted,
                                      },
                                      item.isDeleted ? 'disabled' : '',
                                    ]"
                                  >
                                    <OrderCartButton
                                      :showSelectButton="false"
                                      :productId="item.productId"
                                      :product="item"
                                      :updateUsingAPI="true"
                                      :orderId="orderId"
                                      :disabled="
                                        item.isDeleted && item.isBlacklisted
                                      "
                                    />
                                    <span class="other-options q-ml-sm">
                                      x {{ item.price | currency }}
                                      <span
                                        v-if="false"
                                        class="text-strike"
                                        v-html="
                                          $options.filters.currency(
                                            item.compareAtPrice
                                          )
                                        "
                                      />
                                    </span>
                                  </div>
                                  <div class="product-delete">
                                    <div
                                      class="cartItemRemove"
                                      v-if="!item.isDeleted"
                                    >
                                      <q-btn
                                        no-caps
                                        unelevated
                                        class="no-hover"
                                        padding="0"
                                        @click="deleteLineItem(item)"
                                      >
                                        <SvgIcon
                                          color="colorful"
                                          size="1rem"
                                          icon="trash"
                                          v-if="isMobile"
                                        />
                                        <template v-else>Delete</template>
                                      </q-btn>
                                    </div>
                                    <div class="cartItemRemove" v-else>
                                      <q-btn
                                        no-caps
                                        unelevated
                                        flat
                                        color="red"
                                        class="no-hover"
                                        padding="0"
                                        @click="restoreItem(item)"
                                      >
                                        <q-icon
                                          size="1.5rem"
                                          color="#010101"
                                          name="restore_from_trash"
                                          v-if="isMobile"
                                        />
                                        <template v-else>Restore</template>
                                      </q-btn>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="row full-width" v-if="isMobile">
                                <div class="col-12 col-sm-7 col-md-6">
                                  <ProductSubscriptionOptions
                                    ref="productSubscriptionOptions"
                                    pageName="OrderEdit"
                                    :product="item"
                                    :productId="item.productId"
                                    :key="item.productId"
                                    :orderId="orderId"
                                    v-if="
                                      item.isAdded &&
                                      item.codeName != 'sp' &&
                                      item?.isSubscribeForAutoShip
                                    "
                                    class="autoship-edit-order q-mt-md"
                                  />
                                </div>
                              </div>

                              <div
                                class="flex product-edit--options"
                                v-if="isMobile"
                              >
                                <div
                                  :class="[
                                    'product-quantity',
                                    {
                                      blacklistedproduct: item.isBlacklisted,
                                    },
                                    item.isDeleted ? 'disabled' : '',
                                  ]"
                                >
                                  <OrderCartButton
                                    :showSelectButton="false"
                                    :productId="item.productId"
                                    :product="item"
                                    :updateUsingAPI="true"
                                    :orderId="orderId"
                                    :disabled="
                                      item.isDeleted && item.isBlacklisted
                                    "
                                  />
                                  <span class="other-options q-ml-sm">
                                    x {{ item.price | currency }}
                                    <span
                                      v-if="false"
                                      class="text-strike"
                                      v-html="
                                        $options.filters.currency(
                                          item.compareAtPrice
                                        )
                                      "
                                    />
                                  </span>
                                </div>
                                <div class="product-delete">
                                  <div
                                    class="cartItemRemove"
                                    v-if="!item.isDeleted"
                                  >
                                    <q-btn
                                      no-caps
                                      unelevated
                                      class="no-hover"
                                      padding="0"
                                      @click="deleteLineItem(item)"
                                    >
                                      <SvgIcon
                                        color="colorful"
                                        size="1rem"
                                        icon="trash"
                                        v-if="isMobile"
                                      />
                                      <template v-else>Delete</template>
                                    </q-btn>
                                  </div>
                                  <div class="cartItemRemove" v-else>
                                    <q-btn
                                      no-caps
                                      unelevated
                                      flat
                                      color="red"
                                      class="no-hover"
                                      padding="0"
                                      @click="restoreItem(item)"
                                    >
                                      <q-icon
                                        size="1.5rem"
                                        color="#010101"
                                        name="restore_from_trash"
                                        v-if="isMobile"
                                      />
                                      <template v-else>Restore</template>
                                    </q-btn>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Cart Item -->

                          <!-- Cart Linked -->
                          <div
                            class="substitution-product--block"
                            v-if="
                              item.linkedProducts &&
                              item.linkedProducts.length > 0
                            "
                          >
                            <div
                              v-for="(
                                linkedProduct, index
                              ) in item.linkedProducts"
                              :key="`${linkedProduct.orderLineItemID}${index}`"
                              :class="[
                                'substitution-product produt-data flex items-center',
                                {
                                  blacklistedproduct:
                                    linkedProduct.isBlacklisted,
                                },
                              ]"
                            >
                              <div class="product-image">
                                <q-avatar square>
                                  <box-image
                                    :link="
                                      linkedProduct.isSellable
                                        ? {
                                            name: 'Detail',
                                            params: {
                                              seoName: linkedProduct.seoName,
                                            },
                                          }
                                        : ''
                                    "
                                    :imageSrc="linkedProduct.image"
                                    width="40px"
                                  />
                                </q-avatar>
                                <q-avatar flat>
                                  <SvgIcon icon="linked" />
                                </q-avatar>
                              </div>
                              <div class="co-product-details">
                                <div class="product--title">
                                  <h4>
                                    <router-link
                                      v-if="linkedProduct.isSellable"
                                      :to="{
                                        name: 'Detail',
                                        params: {
                                          seoName: linkedProduct.seoName,
                                        },
                                      }"
                                      v-html="linkedProduct.productName"
                                    />
                                    <template v-else>
                                      {{ linkedProduct.productName }}
                                    </template>
                                    <q-tooltip
                                      max-width="180px"
                                      content-class="bg-dark"
                                    >
                                      {{ linkedProduct.productName }}
                                    </q-tooltip>
                                  </h4>
                                </div>
                                <div class="price">
                                  <span
                                    class="original-price"
                                    v-html="
                                      $options.filters.currency(
                                        linkedProduct.totalPrice
                                      )
                                    "
                                    v-if="
                                      linkedProduct.finalPrice &&
                                      linkedProduct.totalPrice !=
                                        linkedProduct.finalPrice
                                    "
                                  />
                                  <span
                                    :class="
                                      linkedProduct.finalPrice &&
                                      linkedProduct.totalPrice !=
                                        linkedProduct.finalPrice
                                        ? 'discount-price'
                                        : ''
                                    "
                                    v-html="
                                      $options.filters.currency(
                                        linkedProduct.finalPrice ||
                                          linkedProduct.totalPrice
                                      )
                                    "
                                  />
                                </div>
                                <div class="flex product-edit--options">
                                  <div class="product-quantity">
                                    <span
                                      v-html="
                                        `${
                                          linkedProduct.qty
                                        } X ${$options.filters.currency(
                                          linkedProduct.price
                                        )}`
                                      "
                                  /></div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Cart Linked -->

                          <!-- Cart Substitute -->
                          <div
                            v-if="
                              item.allowSubstitute &&
                              !item.isDeleted &&
                              item.substituteProductListData &&
                              item.substituteProductListData.length > 0 &&
                              item.substituteProductListData[0]
                                .substituteProductDetails.length > 0
                            "
                            class="substitution-product--sibling"
                          >
                            <div class="division">
                              <span>Choose your preferred Substitution</span>
                            </div>
                            <div
                              class="row q-col-gutter-sm substitution-product--items"
                            >
                              <div
                                v-for="(substituteProduct, index) in item
                                  .substituteProductListData[0]
                                  .substituteProductDetails"
                                :key="`${item.substituteProductListData[0].orderLineItemID}${index}`"
                                class="col-12"
                                :class="
                                  substituteProduct.productName ==
                                  'No Preference'
                                    ? 'col-md-12 order-first'
                                    : 'col-md-6'
                                "
                              >
                                <div class="produt-data flex items-start">
                                  <template>
                                    <q-radio
                                      dense
                                      color="secondaryOnBody"
                                      :val="
                                        selectedChildSubstituteProduct(
                                          item.orderLineItemID
                                        ) ||
                                        item.selectedSubstituteProductId ||
                                        (substituteProduct.substituteProductID ==
                                        '00000000-0000-0000-0000-000000000000'
                                          ? substituteProduct.substituteProductID
                                          : '')
                                      "
                                      :value="
                                        substituteProduct.substituteProductID
                                      "
                                      @input="
                                        () => {
                                          toggleSubstituteChildProduct({
                                            orderLineItemID:
                                              item.orderLineItemID,
                                            allowSubstitute:
                                              item.allowSubstitute,
                                            substituteProductID:
                                              substituteProduct.substituteProductID,
                                          })
                                        }
                                      "
                                    />
                                  </template>
                                  <div
                                    class="product-image"
                                    v-if="
                                      substituteProduct.productName !=
                                      'No Preference'
                                    "
                                  >
                                    <q-avatar square>
                                      <box-image
                                        :link="{
                                          name: 'Detail',
                                          params: {
                                            seoName: substituteProduct.seoName,
                                          },
                                        }"
                                        :imageSrc="
                                          substituteProduct.image
                                            | defaultProductImage
                                        "
                                        width="45px"
                                      />
                                    </q-avatar>
                                  </div>
                                  <div
                                    class="co-product-details"
                                    v-if="
                                      substituteProduct.productName !=
                                      'No Preference'
                                    "
                                  >
                                    <div class="product--title">
                                      <h5>
                                        <router-link
                                          :to="{
                                            name: 'Detail',
                                            params: {
                                              seoName:
                                                substituteProduct.seoName,
                                            },
                                          }"
                                          v-html="substituteProduct.productName"
                                        />
                                      </h5>
                                    </div>
                                    <div
                                      class="price"
                                      v-html="
                                        $options.filters.currency(
                                          substituteProduct.price
                                        )
                                      "
                                    />
                                  </div>
                                  <div class="co-product-details" v-else>
                                    <div class="product--title">
                                      <h5>
                                        {{ substituteProduct.productName }}
                                      </h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Cart Substitute -->
                        </div>
                      </div>
                    </div>
                    <!-- New UI for Order -->
                  </div>

                  <div class="col-12 col-md-12">
                    <div class="orderSummaryInfos">
                      <div class="row q-col-gutter-md">
                        <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 o-1">
                          <ul class="orderSummaryETotal">
                            <li>
                              Sub Total
                              <!-- {{ orderInfo.displayName }} Total -->
                              <span>
                                {{ orderInfo.subTotal | currency }}
                                <template v-if="orderInfo.additionalSubTotal">
                                  {{
                                    orderInfo.additionalSubTotal > 0
                                      ? '+ '
                                      : '- '
                                  }}{{
                                    Math.abs(orderInfo.additionalSubTotal)
                                      | currency
                                  }}
                                </template>
                              </span>
                            </li>
                            <!-- <li
                              v-if="
                                orderInfo.codeName == 'sd' &&
                                orderInfo.shippingRate !== null
                              "
                              >Shipping Fee
                              <span>
                                {{
                                  orderInfo.shippingRate == 0
                                    ? 'Free'
                                    : $options.filters.currency(
                                        orderInfo.shippingRate
                                      )
                                }}
                              </span>
                            </li> -->
                            <template v-if="orderInfo.tipAmount">
                              <li>
                                Tip
                                <span>{{
                                  orderInfo.tipAmount | currency(false, true)
                                }}</span>
                              </li>
                            </template>
                            <template
                              v-if="
                                orderInfo.additionalCharges &&
                                orderInfo.additionalCharges.length
                              "
                            >
                              <li
                                v-for="additionalCharge of orderInfo.additionalCharges"
                                :key="additionalCharge.chargeName"
                              >
                                {{ additionalCharge.chargeName }}
                                <span>{{
                                  additionalCharge.chargeAmount
                                    | currency(false, true)
                                }}</span>
                              </li>
                            </template>

                            <template
                              v-if="
                                orderInfo &&
                                orderInfo.codeName &&
                                orderInfo.codeName.toLowerCase() === 'scd' &&
                                orderInfo.shippingRate
                              "
                            >
                              <li>
                                Delivery Fee
                                <span>
                                  <template>
                                    {{
                                      orderInfo.shippingRate
                                        | currency(false, true)
                                    }}
                                  </template>
                                </span>
                              </li>
                            </template>
                            <!-- <li
                              v-if="
                                orderInfo.totalTax ||
                                  orderInfo.additionalTotalTax
                              "
                            >
                              Tax
                              <span>
                                {{ orderInfo.totalTax | currency(false, true) }}
                                <template v-if="orderInfo.additionalTotalTax">
                                  {{
                                    orderInfo.additionalTotalTax > 0
                                      ? '+ '
                                      : '- '
                                  }}{{
                                    Math.abs(orderInfo.additionalTotalTax)
                                      | currency
                                  }}
                                </template>
                              </span>
                            </li> -->
                            <template v-if="orderInfo.discount">
                              <li>
                                Discount Applied
                                <span
                                  v-html="
                                    $options.filters.currency(
                                      orderInfo.discount,
                                      true
                                    )
                                  "
                                />
                              </li>
                            </template>
                            <li v-if="orderInfo.buydown">
                              Other Discount
                              <span>
                                -
                                {{ orderInfo.buydown | currency }}
                              </span>
                            </li>
                            <!-- redeem section -->
                            <li v-if="orderInfo.appliedLnsDollars">
                              Loyalty Dollars Applied
                              <q-btn
                                flat
                                unelevated
                                dense
                                class="redeem-remove"
                                @click="removePoints"
                                :loading="removingPoints"
                              >
                                <SvgIcon
                                  class="text-negative"
                                  icon="close"
                                  size="12px"
                                />
                              </q-btn>

                              <span>
                                -{{
                                  orderInfo.appliedLnsDollars | currency(true)
                                }}
                              </span>
                            </li>

                            <!-- <li>
                              Sub Total
                              <span>
                                <strong>
                                  {{
                                    (orderInfo.totalOrderAmount -
                                      (orderInfo.appliedLnsDollars || 0) -
                                      (orderInfo.totalTax || 0))
                                      | currency
                                  }}
                                </strong>
                              </span>
                            </li> -->
                            <template
                              v-if="
                                orderInfo.otherTax ||
                                orderInfo.additionalTotalTax
                              "
                            >
                              <li>
                                Tax
                                <span>
                                  {{
                                    orderInfo.otherTax | currency(false, true)
                                  }}
                                  <template v-if="orderInfo.additionalTotalTax">
                                    {{
                                      orderInfo.additionalTotalTax > 0
                                        ? '+ '
                                        : '- '
                                    }}{{
                                      Math.abs(orderInfo.additionalTotalTax)
                                        | currency
                                    }}
                                  </template>
                                </span>
                              </li>
                            </template>
                            <template v-if="orderInfo.occupationalTax">
                              <li>
                                Occupational Tax
                                <span>
                                  {{
                                    orderInfo.occupationalTax
                                      | currency(false, true)
                                  }}
                                </span>
                              </li>
                            </template>
                            <template
                              v-if="
                                orderInfo.appliedLnsCoupons &&
                                orderInfo.appliedLnsCoupons.length
                              "
                            >
                              <strong
                                ><p class="q-mt-sm applied-coupons"
                                  >Applied Loyalty Coupon(s)</p
                                ></strong
                              >
                              <q-separator />
                              <li
                                v-for="appliedLnsCoupon in orderInfo.appliedLnsCoupons"
                                :key="appliedLnsCoupon.id"
                                style="padding-right: 0px !important"
                              >
                                <!-- <div class="text-left"> -->
                                <div>
                                  <span>
                                    {{
                                      appliedCouponName(appliedLnsCoupon.name)
                                    }}
                                    <q-tooltip
                                      max-width="180px"
                                      content-class="bg-dark"
                                    >
                                      {{ appliedLnsCoupon.name }}
                                    </q-tooltip>
                                  </span>
                                </div>
                                <!-- <span class="float-right"> -->
                                <span style="width: unset !important">
                                  <q-btn
                                    unelevated
                                    flat
                                    dense
                                    round
                                    size="sm"
                                    color="red"
                                    class="coupon-remove-btn no-hover"
                                    @click="
                                      removeCoupons(appliedLnsCoupon.couponID)
                                    "
                                    v-if="isLnsSubscribed"
                                  >
                                    <SvgIcon size="1rem" icon="close-circle" />
                                  </q-btn>
                                  - {{ appliedLnsCoupon.amount | currency }}
                                </span>
                                <!-- </span> -->
                                <!-- </div> -->
                              </li>
                            </template>

                            <template
                              v-if="
                                orderInfo.appliedDiscountCoupons &&
                                orderInfo.appliedDiscountCoupons.length
                              "
                            >
                              <strong
                                ><p class="q-mt-sm applied-coupons"
                                  >Applied Discount Coupons</p
                                ></strong
                              >
                              <q-separator />
                              <li
                                v-for="appliedDiscountCoupon in orderInfo.appliedDiscountCoupons"
                                :key="appliedDiscountCoupon.id"
                                style="padding-right: 0px !important"
                              >
                                <!-- <div class="text-left"> -->
                                <div>
                                  <span>
                                    {{ appliedDiscountCoupon.couponCode }}
                                    <q-tooltip
                                      max-width="180px"
                                      content-class="bg-dark"
                                    >
                                      {{ appliedDiscountCoupon.couponCode }}
                                    </q-tooltip>
                                  </span>
                                </div>
                                <span style="width: unset !important">
                                  <q-btn
                                    unelevated
                                    flat
                                    dense
                                    round
                                    size="sm"
                                    color="red"
                                    class="coupon-remove-btn no-hover"
                                    @click="
                                      removeDiscountCoupons(
                                        appliedDiscountCoupon.id
                                      )
                                    "
                                  >
                                    <SvgIcon size="1rem" icon="close-circle" />
                                  </q-btn>
                                  {{ appliedDiscountCoupon.amount | currency }}
                                </span>
                              </li>
                            </template>

                            <template>
                              <div class="q-mt-sm" />
                              <li class="eredeem q-mt-md">
                                <div class="orderSummaryPromocode redeem-code">
                                  <q-form @submit="applyDiscountCoupons">
                                    <h4 class="lda-point">
                                      <span class="lda-point-title"
                                        >Redeem Coupon</span
                                      >
                                    </h4>
                                    <div class="promocodeForm">
                                      <q-input
                                        outlined
                                        class="promo-input"
                                        v-model="couponCode"
                                        placeholder="Enter Coupon Code"
                                      />
                                      <q-btn
                                        class="promo-btn text-capitalize"
                                        type="submit"
                                        label="Apply"
                                        :disabled="submitCoupon"
                                        :loading="submitCoupon"
                                      />
                                    </div>
                                  </q-form>
                                </div>
                              </li>
                            </template>
                            <div class="q-mt-xs" v-if="isLnsSubscribed" />

                            <!-- <li class="ecouponcode">
                              <div class="orderSummaryPromocode">
                                <a
                                  href="javascript:void(0);"
                                  class="promo-link"
                                  @click="couponsDialog"
                                  v-if="businessConfigurations.isLnsEnabled"
                                >
                                  Check For Loyal-N-Save Coupons
                                </a>
                              </div>
                            </li> -->

                            <transition
                              appear
                              enter-active-class="animated fadeIn"
                              leave-active-class="animated fadeOut"
                            >
                              <Alert
                                show
                                variant="warning"
                                v-show="
                                  !orderInfo.finalPayAmount &&
                                  orderInfo.appliedLnsDollars
                                "
                                className="q-mt-sm"
                              >
                                We will not charge your payment method for any
                                orders totaling out to $0.00. However, all
                                orders must have a temporary authorization hold
                                of at least $0.01 to verify the payment method
                                in case additional items are added
                              </Alert>
                            </transition>
                            <q-separator />
                            <li class="total">
                              Order Total
                              <span>
                                {{ orderInfo.finalPayAmount | currency }}
                              </span>
                              <div
                                class="orderSummaryCouponcode"
                                v-if="isLnsSubscribed"
                              >
                                <a
                                  href="javascript:void(0);"
                                  class="promo-link float-right"
                                  @click="couponsDialog"
                                >
                                  Browse Loyalty Coupons
                                </a>
                              </div>
                            </li>

                            <template v-if="isLnsSubscribed">
                              <li class="eredeem q-mt-md">
                                <!-- <div
                                class="text-left q-mb-sm"
                                v-if="orderInfo.appliedLnsDollars"
                              >
                                <span><strong>Loyalty Dollars Applied</strong></span>
                                <strong class="float-right ">
                                  <span class="vertical-middle	">
                                    -
                                    {{ orderInfo.appliedLnsDollars | currency }}
                                  </span>
                                  <q-btn
                                    flat
                                    unelevated
                                    dense
                                    class="q-ml-sm redeem-remove"
                                    @click="removePoints"
                                    :loading="removingPoints"
                                  >
                                    <SvgIcon
                                      class="text-negative"
                                      icon="close"
                                      size="12px"
                                    />
                                  </q-btn>
                                </strong>
                              </div> -->
                                <div class="orderSummaryPromocode redeem-code">
                                  <q-form @submit="applyPoints">
                                    <h4 class="lda-point">
                                      <strong class="lda-point-title">
                                        Redeem Dollars
                                        <!-- <q-btn
                                        flat
                                        size="sm"
                                        padding="5px"
                                        type="button"
                                      >
                                        <SvgIcon
                                          icon="info-outline"
                                          size="13px"
                                          class=""
                                        />
                                      </q-btn> -->
                                        <span class="float-right">
                                          <SvgIcon
                                            icon="token"
                                            size="18px"
                                            class="q-mr-xs"
                                          />
                                          <span class="verticl-middle">
                                            <strong>
                                              {{
                                                totalRedeemedDollars | currency
                                              }}
                                            </strong>
                                          </span>
                                          <span
                                            v-if="orderInfo.additionalLnsDollar"
                                          >
                                            <strong
                                              v-if="
                                                orderInfo.additionalLnsDollar &&
                                                !orderInfo.appliedLnsDollars
                                              "
                                            >
                                              +
                                              {{
                                                orderInfo.additionalLnsDollar
                                                  | currency(true)
                                              }}
                                            </strong>
                                            <strong v-else>
                                              -
                                              {{
                                                orderInfo.appliedLnsDollars
                                                  | currency(true)
                                              }}
                                            </strong>
                                          </span>
                                        </span>
                                      </strong>
                                    </h4>
                                    <div
                                      class="promocodeForm"
                                      v-if="totalRedeemedDollars > 0"
                                    >
                                      <!-- <q-input
                                      outlined
                                      dense
                                      class="promo-input"
                                    /> -->
                                      <q-currency-input
                                        class="promo-input"
                                        v-model="redeemDolors"
                                        :options="{
                                          distractionFree: false,
                                          currency: 'USD',
                                          locale: 'en',
                                        }"
                                        placeholder="Redeem Loyalty Dollars"
                                      />
                                      <q-btn
                                        class="promo-btn text-capitalize"
                                        type="submit"
                                        label="Redeem"
                                        size="md"
                                        :disabled="submitredeem"
                                        :loading="submitredeem"
                                      />
                                    </div>
                                  </q-form>
                                </div>
                              </li>
                            </template>
                          </ul>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 o-3">
                          <div class="row q-col-gutter-x-lg">
                            <div class="col-xs-12 col-sm-12 col-md-10">
                              <!-- <div class="stepsTimeSlot"> -->
                              <h5 :class="isMobile ? 'text-primary' : ''">
                                Write Order Instructions
                              </h5>
                              <div class="pb-0">
                                <q-input
                                  outlined
                                  :value="orderInfo.deliveryInstruction"
                                  type="textarea"
                                  color="grey"
                                  label="Enter Instruction"
                                  maxlength="255"
                                  @input="updateDeliveryInstruction"
                                  autogrow
                                >
                                </q-input
                                ><span class="float-right text-caption"
                                  >{{
                                    orderInfo.deliveryInstruction?.length || 0
                                  }}/255</span
                                >
                              </div>
                              <!-- </div> -->
                            </div>
                          </div>
                        </div>
                        <div
                          class="col-xs-12 col-sm-6 col-md-3 col-lg-3 o-2"
                          v-if="
                            orderInfo.isPayAtStore ||
                            orderInfo.orderPaymentDetail
                          "
                        >
                          <h5>Payment Method</h5>
                          <p v-if="orderInfo.isPayAtStore">
                            <SvgIcon
                              size="44px"
                              icon="pay-at-store"
                              class="q-mr-sm"
                            />
                            Pay at store
                          </p>
                          <p v-else-if="orderInfo.orderPaymentDetail">
                            Credit Card<br />
                            <span :class="`paymentIcon paymentIcon-left`">
                              <img
                                :src="`images/payment/${
                                  getCardType(
                                    orderInfo.orderPaymentDetail.cardType
                                  ).logo
                                }`"
                                :title="
                                  getCardType(
                                    orderInfo.orderPaymentDetail.cardType
                                  ).title
                                "
                              />
                            </span>
                            <!-- <SvgIcon size="24px" icon="card" class="q-mr-sm" /> -->
                            xxxx-xxxx-xxxx-{{
                              orderInfo.orderPaymentDetail.cardLast4Digits
                            }}
                            <span
                              class="text-caption acknowledge-acceptance-msg"
                              v-html="acknowledgeAcceptanceMessage"
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 col-md-12"
                    v-if="orderInfo.temporaryHoldAmount"
                  >
                    <div class="orderHoldAmount">
                      <span
                        v-html="
                          `${
                            orderInfo.temporaryHoldAmount > 0
                              ? 'Additional'
                              : ''
                          } Temporary hold: ${
                            orderInfo.temporaryHoldAmount > 0 ? '+' : '-'
                          }${$options.filters.currency(
                            Math.abs(orderInfo.temporaryHoldAmount)
                          )}`
                        "
                      />
                      <p>
                        The temporary hold is the amount we authorize to ensure
                        there are funds to complete your purchase. This isn't a
                        charge. <br />Your order total may vary to account for
                        weighted items, like meat produce, and any bag fees in
                        your state. <br />You will only be charged for the final
                        order total once your order is picked up or delivered.
                        Your bank should remove the authorization hold on your
                        card within 3-5 business days.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- -->
          </div>
          <!-- -->
          <div class="orderEditActions">
            <div class="row">
              <div class="col-12 col-sm-12 col-md-6"></div>
              <div class="col-12 col-sm-12 col-md-6 q-pt-lg">
                <div class="row">
                  <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                    <div class="form-action">
                      <q-btn
                        unelevated
                        no-caps
                        :rounded="!isMobile"
                        color="tertiary"
                        :label="backRoute ? 'Go Back' : 'Go to Home'"
                        class="full-width q-btn-lg"
                        size="1rem"
                        @click="cancelOrderEdit()"
                      />
                    </div>
                  </div>
                  <div class="col-6 col-sm-6 col-md-6 q-pr-sm q-pl-sm">
                    <div class="form-action">
                      <q-btn
                        @click="updateOrder"
                        unelevated
                        no-caps
                        :rounded="!isMobile"
                        color="secondaryOnBody"
                        :label="
                          orderInfo.ageRequired > 0
                            ? 'Verify Age and Update'
                            : 'Update'
                        "
                        class="full-width q-btn-lg"
                        size="1rem"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- -->
        </div>
      </div>

      <!-- -->
      <AddOrderItemDialog
        :displayName="orderInfo.displayName"
        :codeName="orderInfo.codeName"
        :orderId="orderId"
      />
      <TriggerListDialog />
    </template>
    <ItemInstructionMobile v-if="isMobile" />
    <AlreadySubscribedProducts />
  </q-page>
</template>

<script>
import OrderCartButton from 'components/OrderCartButton'
import AddOrderItemDialog from 'components/order/AddOrderItemDialog'
import { tempCacheStorage } from 'src/boot/axios'
import OrderLevelinstruction from 'src/components/OrderLevelinstruction'
import AlreadySubscribedProducts from 'src/components/cart/AlreadySubscribedProducts.vue'
import { appliedCouponName, getCardType, hideShowFullSpinner } from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'PageOrderEdit',
  mixins: [IdentityMixin],
  /* preFetch({ store, currentRoute, redirect }) {
    let { orderId } = currentRoute.params,
      { sessionId } = currentRoute.query
    let promise = store
      .dispatch('order/editOrderDetail', {
        OrderID: orderId,
        ClearEditedItems: sessionId
          ? false
          : !store.getters['order/getUpdateTimeSlotByOrderId'](orderId),
      })
      .then((response) => {
        if (!response || !response.orderNumber || !response.allowModification) {
          redirect({
            name: 'Orders',
          })
        }
      })

    if (process.env.SERVER) return Promise.all(promise)
  }, */
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  meta() {
    return {
      title: 'Order Edit',
      ...this.commonMeta,
    }
  },
  components: {
    OrderCartButton,
    AllowSubstitutions: () => import('components/checkout/AllowSubstitutions'),
    TriggerListDialog: () => import('components/discount/TriggerListDialog'),
    AddOrderItemDialog,
    OrderLevelinstruction,
    ItemInstructionMobile: () =>
      import('components/dialog/ItemInstructionMobile.vue'),
    ProductSubscriptionOptions: () =>
      import('components/common/ProductSubscriptionOptions'),
    AlreadySubscribedProducts,
  },
  data() {
    return {
      alertShow: 0,
      subscription: false,
      orderItem1: false,
      qty: '',
      addItems: false,
      maximizedToggle: true,
      group: 'cate1',
      cartQty: '',
      model: null,
      submitredeem: false,
      removingPoints: false,
      redeemDolors: null,
      isSubstituteChange: false,
      couponCode: '',
      submitCoupon: false,
    }
  },
  watch: {
    editOrderAlert() {
      this.alertShow = 10
    },
  },
  destroyed() {
    this.$store.commit('order/SET_EDIT_ORDER_INFO', { [this.orderId]: null })
    if (this.editOrderAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'editOrderAlert',
        alertData: null,
      })
  },
  computed: {
    ...mapGetters('customer', ['totalRedeemedDollars', 'customer']),
    ...mapGetters('order', [
      'editOrderInfoById',
      'isSubstitutionAllowedByOrderId',
      'categories',
      'alertData',
      'editOrderDetails',
      'selectedRecentSubstituteProduct',
      'recentItems',
    ]),
    ...mapGetters('cart', [
      'selectedChildSubstituteProduct',
      'recentSubstituteDetail',
    ]),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),
    ...mapGetters('common', ['backRoute']),
    ...mapGetters('discounts', ['currentDiscount']),
    ...mapGetters('auth', ['currentUser']),
    ...mapGetters('guest', ['guestUser']),
    editOrderAlert() {
      return this.alertData('editOrderAlert')
    },
    orderInfo() {
      return this.editOrderInfoById(this.orderId)
    },
    isSubstitutionAllowed() {
      return this.isSubstitutionAllowedByOrderId(this.orderId)
    },
    orderItems() {
      let items = [...this.orderInfo.trackOrderLineItems]

      return items
    },
    isCancelOrder() {
      let cancelOrder = true

      for (let i = 0; i < this.orderItems.length; i++) {
        if (!this.orderItems[i].isDeleted) {
          cancelOrder = false
          break
        }
      }
      return cancelOrder
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    isLnsSubscribed() {
      return (
        this.businessConfigurations.lnsSubscriptionType == 2 ||
        (this.businessConfigurations.lnsSubscriptionType == 1 &&
          this.customer?.isLoyalty === true)
      )
    },
    currentUserName() {
      let name = ''
      if (this.isLoggedIn)
        name = this.currentUser.firstName + ' ' + this.currentUser.lastName
      else if (this.guestUser)
        name = this.guestUser.firstName + ' ' + this.guestUser.lastName
      return name
    },
    acknowledgeAcceptanceMessage() {
      let msg = ''
      if (this.orderInfo.isAcknowledgeAccepted) {
        msg += `<br />Digitally Authorized by <strong>${
          this.currentUserName
        }</strong> on ${this.$options.filters.formatDate(
          this.orderInfo.paymentAcknowledgementDate,
          'HH:mm MMM DD, YYYY'
        )} [${this.orderInfo.paymentAcknowledgementAcceptedFromIP}]`
      }
      return msg
    },
    showSubscriptionBanner() {
      let result = false
      for (let item of this.orderItems) {
        if (item.isSubscribeForAutoShip && item.frequency > 0) {
          result = true
          break
        }
      }
      return result
    },
    isSubscribedProductDeleted() {
      let result = false
      for (let item of this.orderItems) {
        if (
          item.isSubscribeForAutoShip &&
          item.frequency > 0 &&
          item.isDeleted
        ) {
          result = true
          break
        }
      }
      return result
    },
    getAlReadySubscribedProducts() {
      let alreadySubscribedProducts = []
      for (const trackOrderLineItem of this.orderInfo.trackOrderLineItems) {
        if (
          trackOrderLineItem.isAdded &&
          !trackOrderLineItem.isDeleted &&
          trackOrderLineItem.subscriptionID !=
            '00000000-0000-0000-0000-000000000000' &&
          trackOrderLineItem.orderLineItemOrderType == 1 &&
          trackOrderLineItem.productSubscriptions
        ) {
          alreadySubscribedProducts = [
            ...alreadySubscribedProducts,
            ...trackOrderLineItem.productSubscriptions,
          ]
        }
      }
      return alreadySubscribedProducts
    },
  },
  mounted() {
    this.setHeaderHeight()

    // prefetch code
    let { orderId } = this.$route.params,
      { sessionId } = this.$route.query
    this.$store
      .dispatch('order/editOrderDetail', {
        OrderID: orderId,
        ClearEditedItems: sessionId
          ? false
          : !this.$store.getters['order/getUpdateTimeSlotByOrderId'](orderId),
      })
      .then((response) => {
        if (!response || !response.orderNumber || !response.allowModification) {
          this.$router.replace({
            name: 'Orders',
          })
        }
      })
    // prefetch code
  },
  methods: {
    ...mapActions('cart', ['toggleSubstituteChildProduct']),
    getCardType: getCardType,
    applyPoints() {
      if (this.redeemDolors) {
        // check if redeem doller greater then final payment amount
        if (this.redeemDolors > this.orderInfo.finalPayAmount) {
          this.showError('You can not redeem dollars more than order amount')
          return
        }

        let redeemDolors = this.redeemDolors
        // parseFloat(String(redeemDolors).replace(/\$|,/g, ''))
        this.submitredeem = true
        this.$store
          .dispatch('order/updateLNSDollars', {
            dollars: redeemDolors,
            orderID: this.orderId,
          })
          .then((res) => {
            if (!res.success && res.message) this.showError(res.message)
          })
          .finally(() => {
            this.submitredeem = false
            this.redeemDolors = null
          })
      }
    },
    removePoints() {
      this.removingPoints = true
      this.$store
        .dispatch('order/updateLNSDollars', {
          orderID: this.orderId,
          dollars: 0,
        })
        .then((res) => {
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          this.removingPoints = false
        })
    },
    toggleSubstitute(item) {
      let params = {
        orderID: this.orderId,
        editOrderLineItems: [
          {
            productID: item.productId,
            allowSubstitute: !item.allowSubstitute,
          },
        ],
      }
      this.$store.commit('cart/REMOVE_RECENT_SUBSTITUTE', item.orderLineItemID) // need to remove recent substitute
      this.$store.dispatch('order/updateOrder', params)
    },
    cancelOrderEdit() {
      this.$store
        .dispatch('order/clearRecentItems', this.orderId)
        .then((response) => {
          if (response) {
            this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
            this.applyBack()
          }
        })
    },
    deleteLineItem(product) {
      let productLineItem = {
        productID: product.productId,
        isDeleted: true,
        instruction: product.instruction,
      }
      if (product.isSubscribeForAutoShip) {
        productLineItem = {
          ...productLineItem,
          orderLineItemOrderType: product.orderLineItemOrderType || 0,
          frequency: product.frequency || 0,
          subscriptionID: product.subscriptionID || undefined,
        }
      }

      let params = {
        orderID: this.orderId,
        editOrderLineItems: [productLineItem],
      }

      this.$store.dispatch('order/updateOrder', params)
    },
    restoreItem(item) {
      let params = {
        orderID: this.orderId,
        editOrderLineItems: [
          {
            productID: item.productId,
            isDeleted: false,
            instruction: item.instruction,
            orderLineItemOrderType: item.orderLineItemOrderType || 0,
            frequency: item.frequency || 0,
          },
        ],
      }

      this.$store.dispatch('order/updateOrder', params)
    },
    async updateOrder(value = true) {
      let noOfProducts = this.orderItems.filter(
        (item) => !item.isDeleted
      ).length

      if (this.getAlReadySubscribedProducts.length && value) {
        this.changeDialogState({
          dialog: 'AlreadySubscribedProducts',
          properties: {
            alreadySubscribedProducts: this.getAlReadySubscribedProducts,
            updateOrder: this.updateOrder,
          },
          val: true,
        })
        return
      }

      // Ask confirmation when order has more than one item and subscription product deleted
      if (noOfProducts > 0 && this.isSubscribedProductDeleted) {
        this.ftxConfirm(
          '<div class="text-bold text-h6 pd-none">Are you sure you want to remove the subscribed product?</div>',
          '<p>If you remove the subscribed product from the order, it will be considered as canceled order while existing products will be unchanged for this order.</p>'
        ).onOk(() => {
          this.applyOrderChanges()
        })
      } else if (this.isCancelOrder) {
        let dialogTitle, dialogDesc
        // Ask confirmation when order has only one subscription product and deleted
        if (this.orderItems?.length == 1 && this.isSubscribedProductDeleted) {
          dialogTitle =
            '<div class="text-bold text-h6 pd-none">Are you sure you want to cancel the order by removing it?</div>'
          dialogDesc =
            '<p>You are removing the subscribed product from this order. So, the subscribed product will be considered a canceled order.</p>'
        } else {
          dialogTitle = 'Are you sure you want to Cancel orders?'
        }

        if (dialogTitle)
          this.ftxConfirm(dialogTitle, dialogDesc).onOk(async () => {
            this.applyOrderChanges(true)
          })
      } else if (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.orderInfo?.ageRequired > 0 &&
        this.businessConfigurations?.enableAgeRestrictedCheckout
      ) {
        this.triggerEAIVFlow()
      } else {
        this.applyOrderChanges()
      }
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
    triggerEAIVFlow() {
      hideShowFullSpinner(true)
      let reqData = {
        redirectUri: this.getRedirectUri,
        verificationDuring: 2,
        customerID: null,
      }

      if (this.customer?.isVerifiedOnline) {
        reqData.collectPersonalInfo = false
        reqData.personalInfo = this.personalInfoObj()
      } else {
        reqData.collectPersonalInfo = true
        reqData.preFillInformation = this.personalInfoObj()
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => hideShowFullSpinner(false))
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (!isSuccess) this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'OrderEdit') {
        if (!eaivSessionId || !routeName) return
        if (isPopup) tempCacheStorage.clear()
        this.$store.commit('order/SET_UPDATE_TIME_SLOT', this.orderId)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
          this.applyOrderChanges(false, eaivSessionId)
        }, 500)
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'OrderEdit') {
        if (!eaivSessionId || !routeName) return
        if (isPopup) tempCacheStorage.clear()
        if (!isPopup) this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {
              redirectUri: this.getRedirectUri,
              verificationDuring: 2,
              customerID: null,
              routeName,
            },
          })
        }, 500)
      }
    },

    async applyOrderChanges(cancelOrder = false, eaivSessionId = null) {
      let ordTrackItemsSubstitutes = this.orderInfo.trackOrderLineItems.map(
        (x) => ({
          allowSubstitute: x.allowSubstitute,
          orderLineItemID: x.orderLineItemID,
        })
      )
      let body = {
        deliveryInstruction: this.orderInfo?.deliveryInstruction || '',
        orderID: this.orderId,
        cancelOrder,
        isSubstituteLineItemsCheckBoxChange: this.isSubstituteChange,
      }
      if (eaivSessionId) {
        body.avSessionID = eaivSessionId
      }
      await this.$store
        .dispatch('order/editOrderUpdateSubstitute', ordTrackItemsSubstitutes)
        .then((res) => {
          body['orderLineItemRequestViewModel'] = res
          this.$store
            .dispatch('order/applyOrderChanges', body)
            .then((response) => {
              this.$store.dispatch('customer/getTotalRedeemableDollars')
              this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
              if (eaivSessionId) {
                this.removeQueryParams(eaivSessionId, true)
              }

              if (response) {
                if (cancelOrder)
                  this.$router
                    .push({
                      name: 'Orders',
                    })
                    .catch(() => {})
                else
                  this.$router
                    .push({
                      name: 'TrackOrder',
                      params: {
                        orderId: this.orderId,
                      },
                    })
                    .catch(() => {})
              } else {
                this.$root.$emit('scroll-to-position')
              }
            })
        })
      return
    },
    appliedCouponName: appliedCouponName,
    couponsDialog() {
      this.changeDialogState({
        dialog: 'LNSCouponsDialog',
        val: true,
        properties: {
          loading: true,
        },
      })
      this.$store.dispatch('order/GetCoupons', this.orderId).then((res) => {
        if (res.success)
          this.$store.commit('showcase/SET_DIALOG_PROPERTIES', {
            dialog: 'LNSCouponsDialog',
            properties: {
              loading: false,
              coupons: res.data,
              applyCoupons: this.applyCoupons,
            },
            refresh: false,
          })
        if (!res.success && res.message) this.showError(res.message)
      })
    },
    async applyCoupons(selectedCoupons) {
      try {
        hideShowFullSpinner(true)
        let couponsPayload = selectedCoupons.map((coupon) => ({
          lnsCustomerCouponId: coupon.id,
          barcode: coupon.barcode,
        }))
        let res = await this.$store.dispatch('order/applyCoupons', {
          orderId: this.orderId,
          payload: couponsPayload,
        })
        if (
          res.success &&
          res.data.errorMessage &&
          res.data.errorMessage.length
        ) {
          this.showMultipleCouponsError(
            this.formatErrorMessage(res.data.errorMessage)
          )
          this.changeDialogState({
            dialog: 'LNSCouponsDialog',
            val: false,
          })
        } else if (
          res.success &&
          res.data.errorMessage &&
          !res.data.errorMessage.length
        ) {
          this.changeDialogState({
            dialog: 'LNSCouponsDialog',
            val: false,
          })
          this.showSuccess('Coupon Applied Successfully.')
        } else if (!res.success && res.message) this.showError(res.message)
        hideShowFullSpinner(false)
      } catch (error) {
        console.log(error)
        if (error && error.message) this.showError(error.message)
      }
    },
    removeCoupons(couponId) {
      hideShowFullSpinner(true)
      this.$store
        .dispatch('order/removeCoupons', { couponId, orderId: this.orderId })
        .then((res) => {
          if (res.success) {
            this.showSuccess('Coupon Removed Successfully.')
          }
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    async applyDiscountCoupons() {
      if (this.couponCode) {
        try {
          hideShowFullSpinner(true)
          this.submitCoupon = true
          let discountCouponCode = { discountCouponCode: this.couponCode }
          let res = await this.$store.dispatch('order/applyDiscountCoupons', {
            orderId: this.orderId,
            payload: discountCouponCode,
          })
          if (
            res.success &&
            res.data.errorMessage &&
            res.data.errorMessage.length
          ) {
            this.showMultipleCouponsError(
              this.formatErrorMessage(res.data.errorMessage)
            )
          } else if (
            res.success &&
            res.data.errorMessage &&
            !res.data.errorMessage.length
          ) {
            this.ftxConfirmCoupon(
              '',
              'Yay! Coupon is applied',
              'Cancel',
              'Ok',
              true,
              false,
              '',
              '',
              false,
              true,
              false,
              false
            )
          } else if (!res.success && res.message) {
            if (res.errors[0].errorCode === 'TRIGGERLIST_ITEM') {
              let seoname = res.message
              await this.$store
                .dispatch('discounts/getDiscountDetail', seoname)
                .then((data) => {
                  if (data && this.currentDiscount) {
                  }
                })
                .catch((error) => {
                  console.log('error', error)
                })

              this.ftxConfirmCoupon(
                '',
                'To receive this discount, you must purchase at least ' +
                  this.currentDiscount?.triggerQty +
                  ' item from the list',
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                true,
                true
              ).onOk(() => {
                this.changeDialogState({
                  dialog: 'TriggerListDialog',
                  val: true,
                  properties: {
                    discount: this.currentDiscount,
                    applyCoupon: true,
                    addToCartButton: 'editorder',
                    orderId: this.orderId,
                  },
                })
              })
            } else
              this.ftxConfirmCoupon(
                '',
                res.message,
                'Cancel',
                'Ok',
                true,
                false,
                'info-filled',
                '3em',
                true,
                false,
                false,
                true
              )
          }
          hideShowFullSpinner(false)
        } catch (error) {
          console.log(error)
          if (error && error.message) this.showError(error.message)
        }
        this.couponCode = ''
        this.submitCoupon = false
      }
    },
    removeDiscountCoupons(couponId) {
      hideShowFullSpinner(true)
      this.$store
        .dispatch('order/removeDiscountCoupons', {
          couponId,
          orderId: this.orderId,
        })
        .then((res) => {
          if (res.success) {
            this.showSuccess('Coupon Removed Successfully.')
          }
          if (!res.success && res.message) this.showError(res.message)
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    formatErrorMessage(messages) {
      let result = `<span>`
      for (const message of messages) {
        result += `<i aria-hidden="true" role="presentation" class="material-icons q-icon notranslate text-negative q-ma-xs">error</i>${message}<br />`
      }
      result += `</span>`
      return result
    },
    goBack() {
      this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
      this.applyBack()
    },
    updateDeliveryInstruction(val) {
      this.$store.commit('order/SET_EDIT_ORDER_INFO', {
        [this.orderId]: {
          ...this.orderInfo,
          deliveryInstruction: val || '',
        },
      })
    },
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)
    this.$store.dispatch('category/getOrderCategory', {
      orderId: this.orderId,
      locationId: this.currentLocationId,
    })
  },
}
</script>

<style lang="scss">
.cartsubstitute-section {
  min-width: 100%;
  margin-left: 0 !important;
}
.orderEditItems {
  .cartItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px 0;
    @media (max-width: 1199px) {
      align-items: flex-start;
    }
    .cartItemCheck {
      width: 40px;
    }
    .cartItemDet {
      flex: 10000 0 0%;
      display: flex;
      align-items: center;
      .cartItemDesc {
        flex: 10000 0 0%;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        .cartItemThumb {
          flex: 0 0 70px;
          padding-right: 15px;
          img {
            padding: 5px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          }
        }
        .cartItemTitle {
          font-size: 16px;
          color: #333b3e;
          a {
            color: #333b3e;
            text-decoration: none;
            &:hover {
              color: $secondary;
              color: var(--q-color-secondaryOnBody) !important;
              text-decoration: underline;
            }
          }
        }
      }
      .cartItemSelect {
        flex: 1 0 30%;
        padding-right: 15px;
        .q-item {
          .q-item__section {
            padding: 6px 0px;
          }
        }
      }
      .cartItemQty {
        flex: 1 0 100px;
        padding-right: 15px;
        display: flex;
        flex-direction: row;
        text-align: left;
        @media (max-width: 1023px) {
          flex-wrap: wrap;
          text-align: left;
        }
        .q-field {
          flex: 10000 0 0%;
          display: inline-block;
          .q-field__native {
            text-align: center;
            font-size: 16px;
            font-weight: bold;
          }
          @media (max-width: 1023px) {
            flex: 1 0 100%;
            padding-bottom: 15px;
          }
        }
        .price {
          flex: 10000 0 0%;
          font-size: 16px;
          color: #888888;
          @media (max-width: 1023px) {
            flex: 1 0 100%;
            .discount {
              margin-bottom: 0;
            }
          }
        }
      }
      @media (max-width: 1199px) {
        flex-wrap: wrap;
        .cartItemDesc {
          flex: 1 0 100%;
          padding-bottom: 10px;
        }
        .cartItemSelect {
          flex: 0 0 30%;
        }
        .cartItemQty {
          flex: 0 0 50%;
        }
      }
      @media (max-width: 1199px) {
        .cartItemSelect {
          flex: 1 0 100%;
          padding-bottom: 15px;
        }
        .cartItemQty {
          flex: 1 0 100%;
        }
      }
      @media (max-width: 599px) {
        .cartItemDesc,
        .cartItemSelect,
        .cartItemQty {
          padding-right: 0;
        }
      }
    }
    .cartItemRemove {
      width: 50px;
      text-align: center;
      svg {
        color: #e26e6e;
      }
      @media (max-width: 1439px) {
        button {
          padding: 18px 0;
          display: inline-block;
        }
      }
    }
    .cartItemPrice {
      width: 170px;
      text-align: right;
      font-size: 20px;
      font-weight: bold;
      color: #333b3e;
    }
    @media (max-width: 1024px) {
      position: relative;
      border-top: 1px solid rgba(0, 0, 0, 0.08);
      &:first-child {
        border-top: none;
      }
      .cartItemDet {
        min-height: 120px;
        padding-right: 45px;
        padding-bottom: 45px;
        .cartItemDesc {
          align-items: flex-start;
          .cartItemThumb {
            flex: 0 0 70px !important;
          }
          .cartItemTitle {
            font-size: 14px;
            line-height: 1;
          }
        }
      }

      .cartItemRemove {
        position: absolute;
        left: auto;
        bottom: 0;
        right: 0;
        top: 0;
      }
      .cartItemPrice {
        position: absolute;
        right: 0;
        bottom: 70px;
        font-size: 16px;
        left: 114px;
        text-align: left;
        .strike-price {
          display: inline-block;
          width: auto;
          margin-right: 5px;
        }
      }
      .cartItemSelect {
        position: absolute;
        left: 110px;
        bottom: 0;
        width: 126px;
      }
      .cartItemQty {
        position: absolute;
        bottom: 25px;
        left: 245px;
      }
    }
    @media (max-width: 480px) {
      .cartItemSelect {
        left: 40px;
      }
      .cartItemQty {
        left: 175px;
      }
    }
    &.link-products {
      padding: 5px;
      @media (max-width: 1024px) {
        .cartItemSelect {
          bottom: 80px !important;
        }
        .cartItemQty {
          bottom: 105px !important;
        }
      }
    }
  }

  .cartItemLi {
    /* box-shadow: 0px 4px 6px rgba($black, 0.1); */
    margin-bottom: 15px;
    @media (min-width: 1025px) {
      padding-right: 10px;
    }
  }

  .cartsubstitute {
    position: relative;
    margin-left: 22px;
    padding-top: 15px;
    border: 1px dashed $primary;
    border-radius: 7px;
    margin-top: 15px;
    .cartsubstitute-title {
      position: absolute;
      top: -10px;
      left: 5px;
      padding: 4px 15px;
      background: #ebebeb;
      border-radius: 7px;
      line-height: 1;
      font-size: 14px;
    }
    .cartItem {
      padding: 10px 0;
      @media (max-width: 1024px) {
        border-top: none;
        .cartItemDet {
          padding-right: 15px;
          padding-bottom: 0px;
          min-height: auto;
        }
        .cartItemDesc {
          padding-bottom: 0;
        }
      }
      .cartItemPrice {
        text-align: left;
        margin-top: 5px;
        font-size: 16px;
        position: relative;
        left: 0;
        top: 0;
      }
    }
  }
  .orderItem {
    margin-top: 15px;
    background: #fff;
    border: 1px solid #cccccc;
    border-radius: 5px;
    &:first-child {
      margin-top: 0;
    }
    .orderItemTop {
      background: #fafafa;
      padding: 15px;
      border-radius: 5px 5px 0 0;
      .actions {
        text-align: right;
      }
    }
    .orderItemMiddle {
      padding: 15px;
      .actions {
        text-align: right;
        .q-btn {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        }
        .q-btn__wrapper {
          padding: 9px 18px;
        }
        @media (max-width: 767px) {
          text-align: center;
          .q-btn {
            margin: 5px;
            width: 100%;
            max-width: 300px;
          }
        }
      }
      .q-list {
        @media (max-width: 767px) {
          padding-bottom: 15px;
        }
      }
      .q-item {
        padding: 15px 5px;
        .disabled {
          filter: grayscale(100%);
          opacity: 0.7;
        }
      }
      .q-list:not(.singleItem) {
        display: flex;
        flex-wrap: wrap;
        .q-item__section--avatar {
          min-width: 50px;
          padding-right: 0;
        }
      }
      .q-item__section--avatar {
        min-width: 70px;
      }
      .last {
        .q-avatar a {
          height: 50px;
          background: $primary;
          background: var(--q-color-primary) !important;
          border-color: $primary;
          border-color: var(--q-color-primary) !important;
          border-color: var(--q-color-primary) !important;
          color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            strong {
              font-size: 18px;
            }
          }
        }
      }
      .q-item__label a {
        color: #333b3e;
        font-size: 16px;
        text-decoration: none;
        padding: 0 0 5px;
        display: inline-block;
        &:hover {
          text-decoration: underline;
        }
      }
      .label-success,
      .label-primary {
        display: inline-block;
        padding: 2px 12px;
        font-size: 11px;
        font-weight: bold;
        border-radius: 16px;
      }
      .label-success {
        background: #f6fcf1;
        color: #008a03;
      }
      .label-primary {
        background: #e8f6fc;
        color: $primary;
        color: var(--q-color-primary) !important;
      }
      .text-caption:not(.acknowledge-acceptance-msg) {
        font-size: 13px;
        strong {
          font-size: 15px;
          font-weight: normal;
        }
      }
      .quantityBox {
        @media (min-width: 768px) {
          width: 100px !important;
        }
      }
      .orderItemPrice {
        font-size: 18px;
        font-weight: bold;
        color: $tertiary;
        text-align: right;
        @media (min-width: 768px) {
          width: 110px;
          text-align: right;
          padding-right: 15px;
        }
        @media (max-width: 599px) {
          text-align: left;
          .strike-price {
            text-align: left;
          }
        }
      }
      .qtyGroup {
        .q-field__native {
          text-align: center;
        }
      }
      .itemRemove {
        @media (min-width: 768px) {
          width: 50px;
        }
        a {
          color: #e26e6e;
          font-size: 12px;
        }
        .q-focus-helper,
        .q-ripple {
          display: none;
        }
      }
      .editOrderTableTitle {
        font-size: 12px;
        line-height: normal;
        color: #707070;
        h4 {
          margin: 0;
          padding: 0;
          font-size: 20px;
          line-height: normal;
          color: #0f141a;
        }
      }
      .orderHoldAmount {
        padding: 0px 15px;
        font-size: 12px;
        line-height: 20px;
        color: #888;
        span {
          display: inline-block;
          font-size: 13px;
          font-weight: bold;
          color: #0f141a;
          padding-right: 20px;
          position: relative;
          cursor: pointer;
          &:before {
            content: '';
            display: block;
            width: 6px;
            height: 6px;
            border-left: 2px solid #888;
            border-bottom: 2px solid #888;
            transform: rotate(-45deg);
            position: absolute;
            right: 6px;
            top: 6px;
          }
        }
        p {
          padding: 5px 0;
          visibility: hidden;
        }
        &:hover {
          p {
            visibility: visible;
          }
        }
        @media (max-width: 1023px) {
          span:before {
            display: none !important;
          }
          p {
            margin-bottom: 15px !important;
            display: block !important;
            visibility: visible !important;
          }
        }
      }
      .slotTime-change-txt {
        margin-bottom: 10px;
        font-size: 12px;
        font-weight: normal;
        color: #e3a62c;
      }
    }
    .orderItemDeliveryOpt {
      position: relative;
      padding-left: 30px;
      padding-bottom: 15px;
      i {
        position: absolute;
        top: 0;
        left: 5px;
        font-size: 18px;
      }
      h5 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: bold;
        line-height: normal;
        color: $primary;
        color: var(--q-color-primary) !important;
      }
      p {
        margin: 0;
        padding: 3px 0;
        font-size: 12px;
        color: #888888;
        a {
          color: $primary;
          color: var(--q-color-primary) !important;
        }
      }
    }
    .orderPickDesc {
      font-size: 12px;
      @media (min-width: 1024px) {
        padding-left: 15px;
      }
      .orderPickDescTitle {
        padding: 3px;
        color: $tertiary;
      }
      .orderPickDescDate {
        padding: 3px;
        font-size: 18px;
        font-weight: bold;
        color: #7cb24d;
      }
      .orderPickDescTime {
        padding: 3px;
        color: #888888;
      }
    }
    .orderItemInfo {
      margin: 0;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      font-size: 14px;
      font-weight: bold;
      color: #0f141a;

      li {
        padding: 0 5px;
        flex: 1 0 0;
        @media (max-width: 767px) {
          padding-bottom: 10px;
          flex: 0 0 50%;
        }
      }
      span {
        padding-bottom: 5px;
        display: block;
        font-size: 11px;
        font-weight: normal;
        color: #888888;
        text-transform: uppercase;
      }
      a {
        font-size: 11px;
        color: $primary;
        color: var(--q-color-primary) !important;
      }
    }
    .btn-cancel {
      font-size: 14px;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: bottom;
        margin-right: 8px;
        background: #f9d7d7;
        color: #ec7a7a;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-cancelled {
      font-size: 14px;
      font-weight: bold;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #ec7a7a;
        color: #fff;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-completed,
    .btn-refunded {
      font-size: 14px;
      font-weight: bold;
      color: #008a03;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #008a03;
        color: #fff;
        svg {
          width: 8px;
          height: 7px;
        }
      }
    }
  }
}
.editorder-complete-qty {
  min-width: 110px;
  max-width: 110px;
}
.orderEditItems .linked-product .edit-order-sprice {
  max-width: 86px;
  min-width: 86px;
}
/* Order Summary Info */
.orderSummaryInfos {
  margin-top: 20px;
  border-top: 1px solid #cccccc;
  padding: 20px;
  @media (max-width: 599px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .o-1 {
    order: 1;
  }
  .o-2 {
    order: 2;
  }
  .o-3 {
    order: 3;
  }
  .o-4 {
    order: 4;
  }
  @media (min-width: 600px) {
    .o-2 {
      order: 1;
    }
    .o-1 {
      order: 2;
    }
  }
  @media (min-width: 1024px) {
    .o-1 {
      order: 5;
      margin-left: auto;
    }
  }
  h5 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
}
ul.orderSummaryETotal {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 6px 0;
    padding-right: 140px;
    font-size: 16px;
    font-weight: normal;
    color: #888888;
    position: relative;
    > span {
      font-weight: bold;
      width: 70%;
      position: absolute;
      right: 0;
      top: 6px;
      text-align: right;
    }
    &.total {
      padding: 10px 0 5px;
      font-weight: 700;
      color: #314154;
      span {
        top: 10px;
        font-weight: 700;
      }
    }
    &.ecouponcode {
      padding-right: 0;
      .orderSummaryPromocode {
        padding-bottom: 20px;
        // margin-bottom: 10px;
        h4 {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: normal;
          color: #0f141a;
          line-height: 1.2;
          margin-bottom: 10px;
        }
        .promocodeForm {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          .q-field {
            width: 65%;
          }
          .q-btn {
            width: 30%;
          }
        }

        .promo-input .q-field__control {
          height: 34px;
        }
        .promo-link {
          color: #2a9dcc;
          font-size: 12px;
          text-decoration: none;
          border-bottom: 1px solid #2a9dcc;
          padding-bottom: 2px;
          margin-top: 5px;
          display: inline-block;
        }
      }
    }
    &.eredeem {
      padding-right: 0;
      color: #0f141a;
      .redeem-remove {
        .q-btn__wrapper {
          min-height: auto;
          // padding: 0;
        }
      }
      h4 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: normal;
        color: #0f141a;
        line-height: 1.2;
        margin-bottom: 10px;
      }
      .promo-btn {
        background-color: $primaryOnBody;
        color: $primaryText;
        .q-btn__wrapper {
          min-height: 34px;
        }
      }
      .promocodeForm {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .q-field {
          width: 65%;
        }
        .q-btn {
          width: 30%;
        }
      }
      .promo-input .q-field__control {
        height: 34px;
      }
    }
  }
}
/* Order Summary Info */
.editOrder {
  .orderSubscription {
    padding-bottom: 15px;
    margin: 0;
    border: 0;
    background: none;
    p {
      margin: 0;
      font-size: 12px;
      font-weight: normal;
      color: #e3a62c;
    }
    .q-item {
      padding: 0 5px !important;
      background: #fff;
      border: none;
      @media (max-width: 1023px) {
        padding: 0 !important;
      }
      .q-item__section--avatar {
        min-width: 50px !important;
        padding-right: 0;
      }
      .q-item__section--main {
        padding-top: 10px;
        padding-bottom: 5px;
      }
      .q-item__label {
        font-size: 16px;
        font-weight: bold;
        color: #0f141a;
        padding-bottom: 5px;
        &.text-caption {
          font-size: 13px;
          font-weight: normal;
          color: #888888;
        }
      }
    }
  }
}
/* Add Items */
.addItemsDialogue {
  height: calc(100dvh - 100px) !important;
  @media (max-width: 1023px) {
    height: 100dvh !important;
    .addItemsHeader {
      .q-field__control,
      .q-field__marginal {
        height: 52px;
      }
    }
  }
}
.addItemsContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  .addItemsLeft,
  .addItemsRight {
    padding: 15px 15px;
    position: relative;
  }
  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: stretch;
    .addItemsLeft {
      background: #fafafa;
      flex: 0 0 320px;
      padding: 25px 30px;
      padding-right: 5px;
    }
    .addItemsRight {
      flex: 10000 0 0%;
      padding: 15px 30px;
    }
  }
  @media (max-width: 1023px) {
    .addItemsLeft {
      display: none;
    }
  }

  .addItemsLeft {
    .addItemsLeftBack {
      font-family: 'Poppins';
      color: $tertiary;
      .q-btn {
        font-size: 18px;
        font-weight: 500;
        .q-btn__wrapper {
          padding: 0;
        }
        i {
          font-size: 16px;
        }
        .q-focus-helper {
          display: none;
        }
      }
    }
  }

  .filterCategory {
    .q-item {
      padding: 0;
      min-height: 31px;
      .q-item__section--avatar {
        min-width: 30px;
        padding-right: 10px;
      }
    }
    .q-tree__arrow {
      order: 2;
    }
    .q-focus-helper {
      display: none;
    }
    .q-tree__node-header {
      padding-left: 0;
    }
  }

  .addItemsHeader {
    padding: 10px 5px;
    padding-right: 17px;
    padding-bottom: 20px;
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-weight: 500;
      color: #0f141a;
    }
    .close {
      display: none;
      position: absolute;
      top: 30px;
      right: 5px;
    }
  }

  .btnadd {
    span.txt {
      display: flex;
      flex-direction: row;
      width: 100%;
      font-size: 10px;
      line-height: 15px;
      text-transform: none;
      justify-content: center;
    }
  }

  .addItemsHeightLeft {
    padding-right: 20px;
    height: calc(100dvh - 196px);
    @media (min-width: 1024px) {
      height: calc(100dvh - 196px);
    }
    .q-radio__label {
      font-size: 14px;
      color: #314154;
    }
  }

  .addItemsHeight {
    padding-right: 20px;
    height: calc(100dvh - 140px);
    .q-card {
      .q-field__native {
        text-align: center;
      }
    }
    @media (min-width: 1024px) {
      height: calc(100dvh - 220px);
    }
  }

  .product-items {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    padding-top: 1px;
    padding-left: 1px;
    .product-items--card {
      width: 50%;
      @media (min-width: 600px) {
        width: 33.33%;
      }
      @media (min-width: 1024px) {
        width: 33.33%;
      }
      @media (min-width: 1200px) {
        width: 25%;
      }
      @media (min-width: 1440px) {
        width: 20%;
      }
      @media (min-width: 1600px) {
        width: 20%;
      }
      @media (min-width: 1920px) {
        width: 16.66%;
      }
    }
  }
}
.addItemsHeader {
  /*& > .q-btn:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 15px;
    margin-top: -2px;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #fff;
  }*/
  @media (max-width: 1024px) {
    .arrowItemDropdown.active .addItemsOrders {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
  @media (min-width: 1024px) {
    .arrowItemDropdown:hover .addItemsOrders {
      display: block;
      visibility: visible;
      opacity: 1;
    }
  }
}

.arrowItemDropdown {
  > span {
    position: absolute;
    right: 0;
    top: 8px;
    width: 50px;
    height: 55px;
    display: block;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.05);
    border-left: 1px solid rgba(255, 255, 255, 0.2);
    &:before {
      content: '';
      position: absolute;
      top: 50%;
      right: 18px;
      margin-top: -2px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 5px solid #fff;
      transition: transform 0.3s ease-in-out;
    }
  }
  &.active {
    > span {
      &:before {
        transform: rotate(-180deg);
      }
    }
  }
}

.addItemsOrders {
  display: none;
  visibility: hidden;
  opacity: 0;
  width: 370px;
  height: calc(100dvh - 210px);
  position: absolute;
  top: calc(100% + 0px);
  right: 0;
  z-index: 99999;
  &.sidebarCartAccordion {
    padding: 15px;
    background: #fff;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  }
  .cartList {
    h3 {
      margin: 0;
      padding: 0 10px 10px;
      font-size: 16px;
      line-height: 30px;
      font-weight: bold;
      color: $primary;
      color: var(--q-color-primary) !important;
      border-bottom: 1px solid #cccccc;
    }
    overflow: auto;
    max-height: calc(100dvh - 280px);
    &::-webkit-scrollbar {
      width: 9px;
    }
    &::-webkit-scrollbar-track {
      background: rgb(229, 229, 229);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(183, 183, 183);
      border: 2px solid rgb(229, 229, 229);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #b30000;
    }
  }
  .q-item {
    color: $primary;
    color: var(--q-color-primary) !important;
    border-bottom: 1px solid #707070;
    svg {
      fill: currentColor;
    }
    .toggleIcon {
      min-width: 40px;
    }
    .toggleTitle {
      font-size: 14px;
      font-weight: bold;
      @media (min-width: 1681px) {
        font-size: 16px;
      }
    }
    .product-title a {
      color: #333b3e;
      text-decoration: none;
      &:hover {
        color: #2a9dcc;
        text-decoration: underline;
      }
    }
    .quantityBox {
      margin-top: 12px;
    }
    .product-qty {
      margin-top: 12px;
    }
    .cartProductSide {
      justify-content: center !important;
      .cartItemRemove a {
        color: #e26e6e;
        width: 32px;
        height: 32px;
        display: inline-block;
        text-align: center;
        line-height: 32px;
      }
    }
  }
  .addItemsOrdersBottom {
    text-align: center;
    padding: 15px 0 0;
    border-top: 1px solid #cccccc;
  }
}
.q-aifilter-xs {
  .q-badge {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 8px;
    height: 8px;
    padding: 0;
  }
}
/* Add Items */
/* Mobile View */
@media (max-width: 767px) {
  .editOrder {
    padding-bottom: 70px;
    .orderEditItems {
      .editOrderTableTitle {
        position: relative;
        h4 {
          margin-bottom: 16px !important;
        }
        .text-right {
          text-align: left;
        }
        .q-btn {
          position: absolute;
          top: -8px;
          right: 0;
          .q-btn__wrapper {
            padding: 12px 20px;
            font-size: 13px;
          }
        }
      }
      .orderItemMiddle {
        padding: 10px;
        .q-item {
          padding: 10px 0;
          flex-wrap: wrap;
          .q-item__section--side {
            padding-right: 0;
            &.itemActions {
              flex: 0 0 100%;
              margin-top: 10px;
              padding-left: 0;
              & > .row {
                width: calc(100% - 40px);
              }
            }
          }
        }
        .orderHoldAmount {
          padding: 0 0 10px;
          span {
            font-size: 15px;
          }
          p {
            visibility: visible;
            display: none;
            margin: 0;
          }
          &:hover p {
            display: block;
          }
        }
      }
    }
    .orderSubscription {
      padding-bottom: 0;
      .q-item__section--main {
        padding-top: 3px !important;
      }
    }
    .orderItem {
      border: none;
      .orderItemMiddle {
        padding: 15px 0 0;
        .q-item__label a {
          font-size: 14px;
        }
      }
      .orderItemInfo li {
        &.oItemPlaced {
          order: 2;
        }
        &.oItemNo {
          order: 1;
        }
        &.oItemTotal {
          order: 3;
        }
      }
    }
  }
  .addItemsDialogue {
    .addItemsRight {
      padding: 0 10px;
      .addItemsHeader {
        padding: 0;
        .close {
          display: block;
          top: 5px;
        }
      }
      .addItemsHeight {
        margin-top: 10px;
        padding-right: 0;
        height: calc(100dvh - 190px);
        .product-items {
          padding-right: 10px;
        }
        @media (min-width: 600px) and (max-width: 1023px) {
          height: calc(100dvh - 140px);
        }
        @media (max-width: 374px) {
          .product-price {
            font-size: 11px;
          }
        }
      }
    }
  }
  .addItemsOrders {
    min-width: 300px;
    width: calc(100% - 10px);
    padding-left: 0 !important;
    padding-right: 0 !important;
    .cartList {
      .q-item {
        .q-item__section--avatar {
          min-width: 50px;
        }
      }
    }
  }
  .orderEditActions {
    padding: 0 5px;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    border-radius: 10px 10px 0 0;
    z-index: 9;
    .q-pt-lg {
      padding-top: 12px;
    }
    .form-action {
      padding-bottom: 12px;
      .q-btn__wrapper {
        padding: 0 10px;
        min-height: 44px;
        font-size: 15px;
      }
    }
  }
}
/* Mobile View */

.lda-point {
  display: flex;
  align-items: center;
  .lda-point-title {
    flex: 1;
    padding-right: 0px;
  }
}

.orderSummaryCouponcode {
  text-align: left;
  display: block;
  padding-bottom: 10px;
  // border-bottom: 1px solid rgba(112, 112, 112, 0.25);
  .promo-link {
    color: grey;
    font-size: 11px;
    // text-decoration: none;
    // border-bottom: 1px solid grey;
    padding-bottom: 2px;
    margin-top: 5px;
    display: inline-block;
  }
}

.editOrder .cart-section .q-checkbox__inner {
  color: rgba(0, 0, 0, 0.54) !important;
  &.q-checkbox__inner--truthy {
    color: $secondaryOnBody !important;
  }
}

.pd-none {
  @at-root div.inner.q-card__section {
    padding-top: 0px;
  }
}
</style>
