export function SET_PWA_POPUP_DENIED(state, val) {
  state.pwaPopupDenied = val
}

export function SET_CART_PERSISTED_INFO(state, { cartNumber, data }) {
  if (cartNumber) {
    if (data) {
      let currentInfo = state.cartPersistedInfo || {}
      state.cartPersistedInfo = {
        ...currentInfo,
        [cartNumber]: {
          ...(currentInfo[cartNumber] || {}),
          ...data,
        },
      }
    } else if (state.cartPersistedInfo) {
      delete state.cartPersistedInfo[cartNumber]
    }
  }
}

export function SET_PINED_CART_SECTION(state, pined) {
  state.isCartSectionPined = pined
}

export function SET_FILTER_BY_PAGE(state, { filter, page }) {
  if (filter && page)
    state.filters = {
      ...state.filters,
      [page]: Object.assign({}, filter),
    }
}

export function SET_ORDER_FILTER_OPTIONS(state, filterOptions) {
  state.orderFilters.filterOptions = filterOptions
}

export function SET_ORDER_FILTER(state, filter) {
  state.orderFilters.selectedFilter = filter
}

export function SHOW_APP_DOWNLOAD_NOTIFICATION(state, val) {
  state.showAppDownloadNotification = val
}

export function SET_DISCLAIMER_UPDATE(state, disclaimerList) {
  state.disclaimerUpdate = {
    ...state.disclaimerUpdate,
    ...disclaimerList,
  }
}

export function SET_ANNOUNCEMENT_UPDATE(state, announcementList) {
  state.announcementUpdate = {
    ...state.announcementUpdate,
    ...announcementList,
  }
}

export function SET_STATE(state, { field, value }) {
  if (field) state[field] = value
}

export function SET_SELECTED_ADDRESS(state, { address, fulfillmentCode }) {
  state.selectedAddress = Object.assign({}, state.selectedAddress, {
    [fulfillmentCode]: address,
  })
}

export function SET_SUBSCRIBE_TO_LNS(state, subscribeToLNS = true) {
  state.subscribeToLNS = subscribeToLNS
}

export function REMOVE_SUBSCRIBE_TO_LNS(state, subscribeToLNS = true) {
  delete state.subscribeToLNS
}

export function SET_CONTACTLESS_DELIVERY(state, isContactlessdelivery) {
  state.isContactlessdelivery = isContactlessdelivery
}

export function REMOVE_CONTACTLESS_DELIVERY(state, isContactlessdelivery) {
  state.isContactlessdelivery = isContactlessdelivery
}

export function REMOVE_ITEM_SUBSCRIPTION(state, { cartNumber, orderItemId }) {
  let currentInfo = state.cartPersistedInfo[cartNumber] || {}
  if (
    currentInfo &&
    currentInfo.subscriptionDetails &&
    currentInfo.subscriptionDetails[orderItemId]
  ) {
    delete currentInfo.subscriptionDetails[orderItemId]
  }
}
