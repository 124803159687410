<template>
  <q-page class="accountPages trackOrder">
    <q-pull-to-refresh no-mouse @refresh="refresh">
      <div class="container">
        <template v-if="orderDetail && orderDetail.orderNumber">
          <div class="page-title hide-in-mobile">
            <h2>Order Status</h2>
            <div class="page-breadcrumb">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
              <span>Order Number: {{ orderDetail.orderNumber }}</span>
              <a
                href="javascript:void(0)"
                @click="downloadOrderInvoice(orderDetail)"
                class="btn-print"
                v-if="!isMobile"
              >
                <q-icon name="file_download" class="q-mr-sm" /> Download Invoice
                <!-- <SvgIcon icon="print" class="q-mr-sm" /> Print order -->
              </a>
            </div>
            <div class="row" v-if="!isMobile">
              <Alert
                v-if="trackOrderAlert"
                className="col-12 col-md-6 q-mt-sm"
                :variant="trackOrderAlert.variant"
                :show="10"
              >
                <span v-html="trackOrderAlert.message" />
              </Alert>
            </div>
          </div>

          <div class="page-content">
            <!-- -->
            <div class="orderItems">
              <!-- -->
              <div class="orderItem">
                <!-- top bar -->
                <div class="orderItemTop" v-if="!isMobile">
                  <div class="row items-center">
                    <!--  -->
                    <div
                      :class="[
                        {
                          'col-12 col-sm-12 col-md-10':
                            !orderDetail.allowModification &&
                            !orderDetail.allowCancelOrder &&
                            isLoggedIn,
                        },
                        {
                          'col-8 col-sm-8 col-md-8': isGuestOrderCancelled,
                        },
                        {
                          'col-12 col-sm-7 col-md-8':
                            orderDetail.allowCancelOrder ||
                            orderDetail.allowModification,
                        },
                      ]"
                    >
                      <ul
                        :class="
                          !!(
                            orderDetail.allowCancelOrder ||
                            orderDetail.allowModification
                          )
                            ? 'orderItemInfo-flex'
                            : 'orderItemInfo'
                        "
                      >
                        <li class="oItemPlaced">
                          <span>Order Placed</span>
                          {{ orderDetail.orderDate | formatDate }}
                        </li>
                        <li class="oItemNo">
                          <span>Order Number</span>
                          {{ orderDetail.orderNumber }}
                        </li>
                        <li class="oItemTotal">
                          <span>Total</span>
                          {{ orderDetail.finalPayAmount | currency }}
                        </li>
                        <li
                          v-if="
                            orderDetail.codeName === 'sd' &&
                            orderDetail &&
                            orderDetail.customerEmail
                          "
                          class="oItemTotal"
                        >
                          <span>Contact Info</span>
                          <div class="trackorder-contact-address-">
                            {{ orderDetail?.customerEmail || '' }}
                            <q-tooltip>
                              {{
                                orderDetail.customerDetailsPickupInfo
                                  ?.customerName || ''
                              }}
                            </q-tooltip>
                          </div>
                        </li>
                        <li
                          v-if="
                            orderDetail &&
                            orderDetail.pickupPerson &&
                            orderDetail.pickupPerson.pickUpByEmail1
                          "
                          class="oItemTotal"
                        >
                          <span>Primary Person</span>
                          <div class="trackorder-contact-address">
                            {{
                              orderDetail &&
                              orderDetail.pickupPerson?.primaryUserFirstName
                                ? orderDetail.pickupPerson
                                    .primaryUserFirstName +
                                  ' ' +
                                  orderDetail.pickupPerson.primaryUserLastName
                                : ''
                            }}
                            <q-tooltip>
                              {{ orderDetail.pickupPerson.pickUpByEmail1 }}
                            </q-tooltip>
                          </div>
                        </li>
                        <li
                          v-if="
                            orderDetail &&
                            orderDetail.pickupPerson &&
                            orderDetail.pickupPerson.pickUpByEmail2
                          "
                          class="oItemTotal"
                        >
                          <span>Alternate Person</span>
                          <div class="trackorder-contact-address">
                            {{
                              orderDetail &&
                              orderDetail.pickupPerson?.alternateUserFirstName
                                ? orderDetail.pickupPerson
                                    .alternateUserFirstName +
                                  ' ' +
                                  orderDetail.pickupPerson.alternateUserLastName
                                : ''
                            }}
                            <q-tooltip>
                              {{ orderDetail.pickupPerson.pickUpByEmail2 }}
                            </q-tooltip>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div
                      class="col-12 col-sm-5 col-md-4 actions"
                      v-if="
                        orderDetail.allowModification ||
                        (orderDetail.allowCancelOrder &&
                          !orderDetail.isCancelled)
                      "
                    >
                      <q-btn
                        v-if="
                          orderDetail.allowModification &&
                          orderDetail.codeName !== 'sd'
                        "
                        :to="{
                          name: 'OrderEdit',
                          params: { orderId: orderId },
                        }"
                        unelevated
                        no-caps
                        rounded
                        outline
                        color="tertiary"
                        label="Edit Order"
                        class="q-ma-sm q-pr-md q-pl-md"
                      />
                      <q-btn
                        v-if="
                          orderDetail.allowModification ||
                          (orderDetail.allowCancelOrder &&
                            !orderDetail.orderCompleted &&
                            !orderDetail.isCancelled)
                        "
                        unelevated
                        no-caps
                        rounded
                        color="colorful"
                        label="Cancel Order"
                        class="q-ma-sm q-pr-md q-pl-md"
                        @click="cancelOrder"
                        :loading="cancelingdOrder"
                      />
                    </div>
                    <div
                      class="col-12 col-sm-4 col-md-4 actions"
                      v-else-if="isGuestOrderCancelled"
                    >
                      <div class="btn-cancel q-mr-sm"> Order Cancelled </div>
                    </div>
                  </div>
                </div>
                <div class="orderItemMiddle">
                  <div class="row items-center">
                    <div class="col-12 col-md-12" v-if="isMobile">
                      <div class="curbBox text-center">
                        <h3 v-text="orderDetail.displayName" />
                        <div class="curbSteps" v-if="!isGuestOrderCancelled">
                          <ul>
                            <li
                              v-for="(
                                tracking, index
                              ) of orderDetail.orderTrackings"
                              :key="index"
                              :class="[
                                tracking.isReached
                                  ? 'completed'
                                  : !orderDetail.orderTrackings[index - 1] ||
                                    orderDetail.orderTrackings[index - 1]
                                      .isReached
                                  ? 'current'
                                  : '',
                              ]"
                            >
                              <span class="dot" />
                              <span class="text" v-html="tracking.title" />
                            </li>
                          </ul>
                        </div>
                        <!-- Reserved Time Slot -->
                        <p
                          v-if="
                            orderDetail.codeName == 'scd' &&
                            orderDetail.timeSlot
                          "
                        >
                          <strong>Reserved Time Slot</strong><br />
                          <strong class="text-h6 text-green">
                            {{ orderDetail.timeSlot.bookingDate | formatDate }}
                          </strong>
                          <br />
                          {{ orderDetail.timeSlot.slotTime }}
                        </p>
                        <!-- Reserved Time Slot -->
                        <!-- <p v-if="orderDetail.codeName == 'sp'" && orderDetail.pickupType == 2>
                            (>30 Minutes)
                            <a href="#" class="text-tertiary">Change Location</a>
                          </p> -->
                        <!-- <div class="curbBarcode" v-if="orderDetail.codeName == 'sp'" && orderDetail.pickupType == 2>
                          <p>Please present barcode to employee</p>
                          <p>
                            <q-img
                              src="http://placehold.it/260x50"
                              width="260px"
                              height="50px"
                            />
                          </p> </div>-->
                        <div
                          class="form-action"
                          v-if="
                            orderDetail.codeName == 'sp' &&
                            (orderDetail.showIamonMyway ||
                              orderDetail.showIamHere)
                          "
                        >
                          <q-btn
                            unelevated
                            color="green"
                            :label="
                              orderDetail.showIamonMyway
                                ? `I'm on my Way`
                                : `I AM HERE`
                            "
                            v-if="
                              orderDetail.showIamonMyway ||
                              orderDetail.showIamHere
                            "
                            @click="changeArrivalStatus"
                            :loading="
                              submitting &&
                              orderDetail.codeName == 'sp' &&
                              orderDetail.pickupType == 1
                            "
                          />
                        </div>
                      </div>
                      <!-- Order Info | Mobile -->
                      <!-- <ul
                        class="curbOrderInfo autoHeight"
                        v-if="
                          orderDetail.codeName == 'sp'
                        "
                      > -->
                      <ul
                        class="curbOrderInfo autoHeight"
                        v-if="orderDetail.codeName == 'sp'"
                      >
                        <li class="full-width" v-show="isGuestOrderCancelled">
                          <span>
                            <div class="btn-cancel q-mr-sm">
                              Order Canceled
                            </div>
                          </span>
                        </li>
                        <li class="full-width">
                          <span>
                            <label>ORDER NUMBER</label>
                            <strong v-html="orderDetail.orderNumber" />
                          </span>
                        </li>
                        <li>
                          <span>
                            <SvgIcon :icon="orderDetail.codeName" />
                            <strong
                              v-html="
                                orderDetail.pickupStore &&
                                orderDetail.pickupStore.name
                              "
                            />
                          </span>
                        </li>
                        <li v-if="orderDetail.timeSlot">
                          <span>
                            <SvgIcon icon="clock" />
                            <strong>
                              {{
                                orderDetail.timeSlot.bookingDate | formatDate
                              }}
                              <div> {{ orderDetail.timeSlot.slotTime }}</div>
                            </strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            <SvgIcon icon="phone" />
                            <strong
                              v-html="
                                orderDetail.pickupStore &&
                                orderDetail.pickupStore.phone
                              "
                            />
                          </span>
                        </li>
                        <li v-if="orderDetail.pickupType">
                          <span>
                            <label>Pickup Method</label>
                            <strong>
                              {{
                                orderDetail.pickupType == 1
                                  ? 'In Store'
                                  : 'Curbside'
                              }}
                            </strong>
                          </span>
                        </li>
                        <li v-if="orderDetail.orderPaymentDetail">
                          <span>
                            <SvgIcon icon="card" />
                            <strong>
                              Ending in
                              {{
                                orderDetail.orderPaymentDetail.cardLast4Digits
                              }}
                            </strong>
                          </span>
                        </li>
                      </ul>

                      <ul class="curbOrderInfo autoHeight" v-else>
                        <li class="full-width" v-show="isGuestOrderCancelled">
                          <span>
                            <div class="btn-cancel q-mr-sm">
                              Order Canceled
                            </div>
                          </span>
                        </li>
                        <li>
                          <span>
                            <label>ORDER PLACED</label>
                            <strong>{{
                              orderDetail.orderDate | formatDate
                            }}</strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            <label>ORDER NUMBER</label>
                            <strong>{{ orderDetail.orderNumber }}</strong>
                          </span>
                        </li>
                        <li>
                          <span>
                            <label>ORDER TOTAL</label>
                            <strong>
                              {{ orderDetail.finalPayAmount | currency }}
                            </strong>
                          </span>
                        </li>
                        <li v-if="orderDetail.orderPaymentDetail">
                          <span>
                            <label>PAYMENT METHOD</label>
                            <strong>
                              Ending in
                              {{
                                orderDetail.orderPaymentDetail.cardLast4Digits
                              }}
                            </strong>
                          </span>
                        </li>
                      </ul>
                      <!-- Curbside I'm Here | Mobile -->
                      <div
                        class="curbPickInfo"
                        v-if="
                          orderDetail.iAmHereInfo &&
                          orderDetail.codeName == 'sp' &&
                          orderDetail.pickupType == 2
                        "
                      >
                        <ul class="curbPickUpCar">
                          <li v-if="orderDetail.iAmHereInfo.carColor">
                            <SvgIcon icon="car" />
                            <span>Car color</span>
                            {{ orderDetail.iAmHereInfo.carColor }}
                          </li>
                          <li v-if="orderDetail.iAmHereInfo.parkingSpot">
                            <SvgIcon icon="parking" />
                            <span>Parking Spot</span>
                            {{ orderDetail.iAmHereInfo.parkingSpot }}
                          </li>
                        </ul>

                        <template
                          v-if="
                            orderDetail.iAmHereInfo &&
                            orderDetail.iAmHereInfo.customerName
                          "
                        >
                          <template
                            v-if="
                              orderDetail.codeName == 'sp' &&
                              orderDetail.pickupType == 2
                            "
                          >
                            <h4>Hold on!</h4>
                            <p>
                              Our associates are coming with your order soon.
                            </p>
                          </template>
                          <ul class="curbPickUpi" @click="curbsideInfo = true">
                            <li>
                              <span>Full Name</span>
                              {{ orderDetail.iAmHereInfo.customerName }}
                            </li>
                            <li>
                              <span>Confirmation Code</span>
                              <strong
                                v-html="
                                  orderDetail.iAmHereInfo.confirmationCode
                                "
                              />
                            </li>
                          </ul>
                        </template>
                        <p class="text-red" v-if="false">
                          *You have items which requires Age Verification so
                          kindly keep your verification document handy with you.
                        </p>
                      </div>

                      <!-- display Tracking Number when fulfillment method is standard shipping | mobile -->
                      <div
                        class="contactInfo"
                        v-else-if="orderDetail.codeName == 'sd'"
                      >
                        <ul class="contactInfoU">
                          <li
                            v-if="
                              !orderDetail.trackingNumber ||
                              !orderDetail.trackingUrl
                            "
                          >
                            <span class="text-weight-bold"
                              >Order tracking details will be shown here once
                              the order is shipped</span
                            >
                          </li>
                          <template v-else>
                            <li>
                              <span>Tracking Number</span>
                              {{ orderDetail.trackingNumber }}
                            </li>
                            <li>
                              <span
                                >Shipped with:
                                {{ orderDetail.shippingCarrier }}</span
                              >
                              <q-btn
                                unelevated
                                no-caps
                                label="Track Order"
                                class="track-btn text-white"
                                color="primary"
                                @click="
                                  openTrackingURL(orderDetail.trackingUrl)
                                "
                              />
                            </li>
                          </template>
                        </ul>
                      </div>
                      <div
                        class="contactInfo"
                        v-else-if="
                          orderDetail.customerDetailsPickupInfo &&
                          orderDetail.codeName !== 'sd' &&
                          orderDetail.deliveryMethod == 1
                        "
                      >
                        <template v-if="!isOutofDelivery">
                          <ul class="contactInfoU">
                            <li>
                              <span class="text-weight-bold"
                                >Tracking link will be shown when your order is
                                out of delivery</span
                              >
                            </li>
                          </ul>
                        </template>
                        <template v-else>
                          <ul class="contactInfoU">
                            <li>
                              <span class="text-weight-bold"
                                >Your order is out of delivery and reaching at
                                you soon.
                              </span>
                              <q-btn
                                unelevated
                                no-caps
                                flat
                                color="transparent"
                                class="track-btn text-red"
                                @click="
                                  openTrackingURL(orderDetail.trackingUrl)
                                "
                                >Click current locaiton
                                <q-icon name="map" color="red"
                              /></q-btn>
                            </li>
                          </ul>
                        </template>
                      </div>
                      <div class="contactInfo" v-else>
                        <ul @click="contactInfo = true" class="contactInfoU">
                          <li>
                            <span>Full Name</span>
                            {{
                              orderDetail.customerDetailsPickupInfo.customerName
                            }}
                          </li>
                          <li>
                            <span>Confirmation Code</span>
                            <strong
                              v-html="
                                orderDetail.customerDetailsPickupInfo
                                  .confirmationCode
                              "
                            />
                          </li>
                          <li
                            class="verification-instructions"
                            v-if="
                              orderDetail.isAgeRestricted &&
                              businessConfigurations.isStoreAgeRestricted &&
                              businessConfigurations.enableAgeRestrictedFulfillment &&
                              ((orderDetail.codeName == 'sp' &&
                                isOrderStatusReached('Ready_For_Pickup')) ||
                                (orderDetail.codeName == 'scd' &&
                                  orderDetail.deliveryMethod == 0 &&
                                  isOrderStatusReached('Ready_for_Delivery')))
                            "
                          >
                            <span class="text-red text-justify">
                              The person fulfilling your order will ask for the
                              <strong>
                                Driver's License/Age-Verified QR Code
                              </strong>
                              to verify your age and identity, as the order
                              contains
                              <strong>age-restricted</strong> products.
                              <template
                                v-if="
                                  orderDetail.pickupPerson
                                    ?.alternateUserFirstName
                                "
                              >
                                If you added an alternate person, in that case
                                as well, the
                                <strong>
                                  Driver's License/Age-Verified QR Code
                                </strong>
                                will be asked to verify the alternate person's
                                age.
                              </template>
                              <template
                                v-if="orderDetail.isConfirmationCodeRequired"
                              >
                                Also, please note that the
                                <strong>Confirmation Code</strong> is mandatory
                                for
                                {{
                                  orderDetail.codeName == 'sp'
                                    ? 'picking up'
                                    : 'receiving'
                                }}
                                your order.
                              </template>
                            </span>
                          </li>
                        </ul>
                      </div>

                      <div
                        class="row q-col-gutter-md justify-center"
                        v-if="
                          orderDetail.allowModification ||
                          orderDetail.allowCancelOrder
                        "
                      >
                        <div
                          class="col-6 q-mb-md"
                          v-if="orderDetail.allowModification"
                        >
                          <q-btn
                            v-if="
                              orderDetail.allowModification &&
                              orderDetail.codeName !== 'sd'
                            "
                            :to="'/order-edit/' + orderId"
                            unelevated
                            no-caps
                            :rounded="!isMobile"
                            outline
                            color="tertiary"
                            label="Edit Order"
                            class="full-width"
                          />
                        </div>
                        <div
                          class="col-6 q-mb-md"
                          v-if="
                            orderDetail.allowCancelOrder &&
                            !orderDetail.orderCompleted &&
                            !orderDetail.isCancelled
                          "
                        >
                          <q-btn
                            unelevated
                            no-caps
                            :rounded="!isMobile"
                            color="colorful"
                            label="Cancel Order"
                            class="full-width"
                            @click="cancelOrder"
                            :loading="cancelingdOrder"
                          />
                        </div>
                      </div>

                      <p
                        class="curbPickLink"
                        v-if="
                          orderDetail.pickupInfo && orderDetail.codeName == 'sp'
                        "
                      >
                        <q-btn
                          unelevated
                          flat
                          no-caps
                          color="tertiary"
                          class="text-underline"
                          @click="
                            changeDialogState({
                              dialog: 'CMSDialog',
                              val: true,
                              properties: {
                                title: 'Pickup Instruction',
                                content: orderDetail.pickupInfo,
                              },
                            })
                          "
                        >
                          <SvgIcon
                            size="1.5em"
                            :icon="orderDetail.codeName"
                            class="q-mr-md"
                          />
                          Pickup Instruction
                        </q-btn>
                      </p>

                      <q-dialog
                        v-model="curbsideInfo"
                        maximized
                        v-if="
                          orderDetail.iAmHereInfo &&
                          orderDetail.iAmHereInfo.customerName
                        "
                      >
                        <q-card style="width: 100%" class="curbsideInfo">
                          <q-card-section>
                            <q-btn
                              unelevated
                              flat
                              round
                              icon="close"
                              v-close-popup
                              class="close"
                            />
                            <ul class="curbPickUpi">
                              <li>
                                <span>Full Name</span>
                                {{ orderDetail.iAmHereInfo.customerName }}
                              </li>
                              <li
                                v-if="orderDetail.iAmHereInfo.confirmationCode"
                              >
                                <span>Confirmation Code</span>
                                <strong
                                  v-html="
                                    orderDetail.iAmHereInfo.confirmationCode
                                  "
                                ></strong>
                              </li>
                            </ul>
                          </q-card-section>
                        </q-card>
                      </q-dialog>
                      <q-dialog
                        v-model="contactInfo"
                        maximized
                        v-if="orderDetail.customerDetailsPickupInfo"
                      >
                        <q-card style="width: 100%" class="contactInfoCard">
                          <q-card-section>
                            <q-btn
                              unelevated
                              flat
                              round
                              icon="close"
                              v-close-popup
                              class="close"
                            />
                            <ul class="contactInfoCardU">
                              <li>
                                <span>Full Name</span>
                                {{
                                  orderDetail.customerDetailsPickupInfo
                                    .customerName
                                }}
                              </li>
                              <li>
                                <span>Confirmation Code</span>
                                <strong
                                  v-html="
                                    orderDetail.customerDetailsPickupInfo
                                      .confirmationCode
                                  "
                                />
                              </li>
                            </ul>
                          </q-card-section>
                        </q-card>
                      </q-dialog>

                      <q-dialog v-model="curbsideCarInfo" position="bottom">
                        <q-card style="width: 100%" class="curbsideCarInfo">
                          <q-form
                            @submit="setParkingSpot"
                            ref="parkingSpotForm"
                          >
                            <q-card-section
                              class="q-pb-none"
                              style="min-height: 380px"
                            >
                              <InnerLoadingSpinner
                                :showing="loadingParkingSlot"
                              />
                              <div
                                class="row q-col-gutter-md"
                                v-show="!loadingParkingSlot"
                              >
                                <div class="col-12">
                                  <h4>Car Color</h4>
                                  <q-scroll-area
                                    horizontal
                                    :visible="true"
                                    style="height: 110px; width: 100%"
                                  >
                                    <q-list class="row no-wrap curbColor">
                                      <q-item
                                        v-for="(carColor, index) in carColors"
                                        :key="index"
                                        :class="
                                          selectedColor &&
                                          selectedColor.id == carColor.id
                                            ? 'active'
                                            : ''
                                        "
                                      >
                                        <q-item-section
                                          @click="selectedColor = carColor"
                                        >
                                          <q-item-label>
                                            <q-avatar
                                              :color="carColor.id"
                                            ></q-avatar>
                                          </q-item-label>
                                          <q-item-label
                                            v-html="carColor.value"
                                          />
                                        </q-item-section>
                                      </q-item>
                                    </q-list>
                                  </q-scroll-area>
                                </div>
                                <div
                                  class="col-12 q-py-none"
                                  v-if="parkingSpots && parkingSpots.length"
                                >
                                  <h4>Parking Spot</h4>
                                  <q-scroll-area
                                    horizontal
                                    :visible="true"
                                    style="height: 75px; width: 100%"
                                  >
                                    <q-list class="row no-wrap curbParking">
                                      <q-item
                                        v-for="(
                                          parkingSpot, index
                                        ) in parkingSpots"
                                        :key="`${parkingSpot.pickupSpotID}-${index}`"
                                      >
                                        <q-item-section
                                          @click="
                                            selectedParkingslot = parkingSpot
                                          "
                                          :class="
                                            selectedParkingslot &&
                                            selectedParkingslot.pickupSpotID ==
                                              parkingSpot.pickupSpotID
                                              ? 'active'
                                              : ''
                                          "
                                        >
                                          <q-item-label
                                            v-html="parkingSpot.title"
                                          />
                                        </q-item-section>
                                      </q-item>
                                    </q-list>
                                  </q-scroll-area>
                                </div>
                                <div class="col-12 col-sm-6">
                                  <!-- <label for="Car Maker" class=""
                                        >Car Maker</label
                                      > -->
                                  <q-select
                                    label="Car Maker"
                                    ref="qCarMaker"
                                    outlined
                                    v-model="carMaker"
                                    use-input
                                    input-debounce="0"
                                    new-value-mode="add"
                                    :options="carMakerOptions"
                                    @filter="carMakerFilterFn"
                                    @keyup.enter="addNewMaker"
                                    lazy-rules
                                    :rules="[(val) => validateRequired(val)]"
                                    option-label="carMakerName"
                                    emit-value
                                    @input="(obj) => onChangeCarMaker(obj)"
                                  >
                                    <template v-slot:no-option>
                                      <q-item>
                                        <q-item-section class="text-grey">
                                          No results
                                        </q-item-section>
                                      </q-item>
                                    </template>
                                  </q-select>
                                </div>
                                <div class="col-12 col-sm-6">
                                  <!-- <label for="Car Model" class=""
                                        >Car Model</label
                                      > -->
                                  <q-select
                                    label="Car Model"
                                    ref="qCarModel"
                                    outlined
                                    v-model="carModel"
                                    use-input
                                    input-debounce="0"
                                    new-value-mode="add"
                                    :options="carModelOptions"
                                    @filter="carModelFilterFn"
                                    @keyup.enter="addNewModel"
                                    lazy-rules
                                    :rules="[(val) => validateRequired(val)]"
                                    option-value="carModelName"
                                    option-label="carModelName"
                                    emit-value
                                  >
                                    <template v-slot:no-option>
                                      <q-item>
                                        <q-item-section class="text-grey">
                                          No results
                                        </q-item-section>
                                      </q-item>
                                    </template>
                                  </q-select>
                                </div>
                                <div class="col-12">
                                  <div class="form-action">
                                    <q-btn
                                      unelevated
                                      color="primaryOnBody"
                                      label="Confirm"
                                      class="q-btn-lg full-width"
                                      :loading="submitting"
                                      type="submit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </q-card-section>
                          </q-form>
                        </q-card>
                      </q-dialog>
                    </div>
                    <OrderDetailTrack :orderDetail="orderDetail" v-else />
                    <ShopedItems :orderDetail="orderDetail" />
                    <Substitutions
                      :orderDetail="orderDetail"
                      :showDeclineSubstitutionLink="true"
                    />
                    <OrderSummary :orderDetail="orderDetail" />
                  </div>
                </div>
              </div>
              <!-- -->
            </div>
            <!-- -->
          </div>
        </template>
      </div>
    </q-pull-to-refresh>
  </q-page>
</template>

<script>
import CustomAlert from 'components/common/CustomAlert'
import OrderSummary from 'components/TrackOrder/OrderSummary'
import Substitutions from 'components/TrackOrder/Substitutions'
import ShopedItems from 'components/TrackOrder/ShopedItems'
import OrderDetailTrack from 'components/TrackOrder/OrderDetailTrack'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'PageTrackOrder',
  data() {
    return {
      cancelingdOrder: false,
      submitting: false,
      loadingParkingSlot: false,
      declineItem: false,
      curbsideCarInfo: false,
      curbsideInfo: false,
      contactInfo: false,
      carColors: [
        {
          value: 'Black',
          id: 'black',
        },
        {
          value: 'White',
          id: 'white',
        },
        {
          value: 'Grey',
          id: 'grey-9',
        },
        {
          value: 'Blue',
          id: 'blue',
        },
        {
          value: 'Red',
          id: 'red',
        },
        {
          value: 'Green',
          id: 'green',
        },
      ],
      selectedColor: {
        value: 'Black',
        id: 'black',
      },
      selectedParkingslot: null,
      carMaker: null,
      carModel: null,
      carMakerOptions: [],
      carMakerFilterOptions: null,
      carModelOptions: [],
    }
  },
  components: { OrderSummary, OrderDetailTrack, ShopedItems, Substitutions },
  meta() {
    return {
      title: 'View Order',
      ...this.commonMeta,
    }
  },
  props: {
    orderId: {
      type: String,
      default: '',
    },
  },
  /* preFetch({ store, currentRoute, redirect }) {
    let { orderId } = currentRoute.params,
      { orderNumber } = currentRoute.query,
      isLoggedIn = store.getters['auth/isLoggedIn'],
      promise

    if (!isLoggedIn) {
      if (!orderId || !orderNumber) {
        return redirect({
          name: 'Home',
        })
      }

      promise = store
        .dispatch('order/trackGuestOrder', {
          orderId: orderId,
          orderNumber: orderNumber,
        })
        .then(async (response) => {
          if (!response)
            redirect({
              name: 'Home',
            })

          if (response.isGuestCheckout && response.customerID)
            await store.dispatch('cart/fetchGuestUserInfo', response.customerID)
        })
    } else {
      if (currentRoute.name == 'TrackGuestOrder')
        redirect({
          name: 'Orders',
        })

      if (
        (orderId && !store.getters['order/recentUpdatedOrder']) ||
        (store.getters['order/recentUpdatedOrder'] &&
          store.getters['order/recentUpdatedOrder'].orderId != orderId)
      )
        promise = store
          .dispatch('order/trackOrder', {
            orderId: orderId,
          })
          .then((response) => {
            if (!response)
              redirect({
                name: 'Orders',
              })
          })
    }

    if (process.env.SERVER) return promise
  }, */
  computed: {
    ...mapGetters('order', [
      'trackOrderDetailById',
      'recentUpdatedOrder',
      'alertData',
      'parkingSpots',
    ]),
    trackOrderAlert() {
      return this.alertData('trackOrderAlert')
    },
    orderDetail() {
      return this.trackOrderDetailById(this.orderId)
    },
    objMakeModelRequest() {
      let reqObj = {
        Filters: '',
        Columns: '',
        Sorts: '',
        Page: 0,
        DisablePaging: false,
        PageSize: 100,
      }

      return reqObj
    },
    isGuestOrderCancelled() {
      return this.orderDetail.allowCancelOrder && this.orderDetail.isCancelled
    },
    isOutofDelivery() {
      let flag = false
      this.orderDetail.orderTrackings.forEach((element) => {
        if (element.isReached && element.status == 'Out_of_Delivery') {
          flag = true
          return
        }
      })
      return flag
    },
  },
  methods: {
    ...mapActions('order', ['generateInvoice']),
    cancelOrder() {
      if (this.orderId) {
        this.ftxConfirm('Are you sure you want to cancel your order?')
          .onOk(() => {
            this.cancelingdOrder = true

            let reqData = {
              orderId: this.orderId,
            }

            if (!this.isLoggedIn)
              reqData.customerID = this.orderDetail.customerID
            this.$store
              .dispatch('order/deleteOrder', reqData)
              .then((response) => {
                if (response && response.success) {
                  let routeName = this.isLoggedIn ? 'Orders' : 'Home'
                  this.$router
                    .push({
                      name: routeName,
                    })
                    .catch(() => {})
                } else if (response.errors && response.errors.length) {
                  this.showError(response.errors[0].descreption)
                }
              })
              .finally(() => {
                this.cancelingdOrder = false
              })
          })
          .onCancel(() => {})
          .onDismiss(() => {})
      }
    },
    downloadOrderInvoice(orderDetail) {
      this.generateInvoice({
        orderId: orderDetail.orderID,
        showLoader: true,
        invoiceErrorAlertKey: 'trackOrderAlert',
      })
        .then((response) => {
          if (response?.data && response.data instanceof Blob) {
            const file = new Blob([response.data], {
              type: 'application/pdf',
            })
            const fileURL = window.URL.createObjectURL(file)
            const link = document.createElement('a')
            link.href = fileURL
            link.download = 'Order-' + orderDetail.orderNumber + '.pdf'
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    changeArrivalStatus() {
      if (this.orderDetail && this.orderDetail.showIamonMyway) {
        this.$q
          .dialog({
            component: CustomAlert,
            parent: this,
          })
          .onOk(() => {
            let reqData = {
              orderID: this.orderId,
            }

            if (!this.isLoggedIn)
              reqData.customerID = this.orderDetail.customerID
            this.$store
              .dispatch('order/changeArrivalStatus', reqData)
              .then((response) => {})
          })
      } else if (
        this.orderDetail &&
        this.orderDetail.showIamHere &&
        this.orderDetail.pickupStore &&
        this.orderDetail.pickupStore.locationId
      ) {
        if (
          this.orderDetail.codeName == 'sp' &&
          this.orderDetail.pickupType == 2
        ) {
          this.curbsideCarInfo = true
          this.loadingParkingSlot = true
          let reqData = {
            LocationID: this.orderDetail.pickupStore.locationId,
          }

          if (!this.isLoggedIn) reqData.customerID = this.orderDetail.customerID
          this.$store
            .dispatch('order/getParkingSpot', reqData)
            .then((data) => {
              if (data && data.data && data.data.length)
                this.selectedParkingslot = data.data[0]
            })
            .finally(() => {
              this.loadingParkingSlot = false
            })
        } else if (
          this.orderDetail.codeName == 'sp' &&
          this.orderDetail.pickupType == 1
        ) {
          this.submitting = true
          let reqData = {
            orderID: this.orderId,
          }

          if (!this.isLoggedIn) reqData.customerID = this.orderDetail.customerID
          this.$store
            .dispatch('order/setParkingSpot', reqData)
            .then((response) => {
              if (response && response.success) {
                this.orderDetail.iAmHereInfo = response.data
                this.showSuccess(
                  'Please reach at nearest counter to collect your order in store.'
                )
              }
            })
            .finally(() => {
              this.submitting = false
            })
        }
      }
    },
    setParkingSpot() {
      if (!this.selectedColor)
        return this.showSuccess('Please Selected Car Color.')
      if (
        this.parkingSpots &&
        !!this.parkingSpots.length &&
        !this.selectedParkingslot
      )
        return this.showSuccess('Please Selected Parking Slot.')

      this.submitting = true
      let reqData = {
        orderID: this.orderId,
        carColor: this.selectedColor.value,
        curbSidePickupSpotID: this.selectedParkingslot
          ? this.selectedParkingslot.pickupSpotID
          : null,
        carMaker: this.carMaker.hasOwnProperty('carMakerName')
          ? this.carMaker.carMakerName
          : this.carMaker.trim(),
        carModel: this.carModel.trim(),
      }

      if (!this.isLoggedIn) reqData.customerID = this.orderDetail.customerID
      this.$store
        .dispatch('order/setParkingSpot', reqData)
        .then((response) => {
          if (response && response.success) {
            this.orderDetail.iAmHereInfo = response.data
            this.showSuccess(
              'Hold On! Our associates are coming with your order soon!'
            )
          }
        })
        .finally(() => {
          this.submitting = false
          this.curbsideCarInfo = false
        })
    },
    refresh(done) {
      this.$store
        .dispatch('order/trackOrder', {
          orderId: this.orderId,
          showLoader: false,
        })
        .then((response) => {
          if (!response)
            redirect({
              name: 'Orders',
            })
        })
        .finally(() => {
          done()
        })
    },
    carMakerFilterFn(val, update, abort) {
      let params = {
        ...this.objMakeModelRequest,
      }
      if (this.carMakerFilterOptions != null) {
        update(() => {
          const needle = val.toLocaleLowerCase()
          this.carMakerOptions = this.carMakerFilterOptions.filter(
            (v) => v.carMakerName.toLocaleLowerCase().indexOf(needle) > -1
          )
        })
        return
      }
      this.$store.dispatch('order/getCarMakeModel', params).then((response) => {
        if (response && response.data && response.data.items) {
          this.carMakerFilterOptions = JSON.parse(
            JSON.stringify(response.data.items)
          )
          this.carMakerOptions = JSON.parse(JSON.stringify(response.data.items))
        }
      })
      setTimeout(() => {
        update()
      }, 1000)
    },
    addNewMaker(val) {
      const inputValue = this.$refs.qCarMaker.inputValue
      if (inputValue) this.$refs.qCarMaker.add(inputValue, 'add-unique')
    },
    onChangeCarMaker(selectedModel) {
      if (
        selectedModel &&
        selectedModel.hasOwnProperty('carModelViewModelList')
      ) {
        this.carModelOptions = JSON.parse(
          JSON.stringify(this.carMaker.carModelViewModelList)
        )
        this.carModel = null
        this.$refs.parkingSpotForm.reset()
      } else if (
        selectedModel &&
        !selectedModel.hasOwnProperty('carModelViewModelList')
      ) {
        this.carModelOptions = []
        this.carModel = null
        this.$refs.parkingSpotForm.reset()
      }
    },
    carModelFilterFn(val, update, abort) {
      if (
        this.carMaker &&
        this.carMaker.hasOwnProperty('carModelViewModelList')
      ) {
        update(() => {
          const needle = val.toLocaleLowerCase()
          this.carModelOptions = this.carMaker.carModelViewModelList.filter(
            (v) => v.carModelName.toLocaleLowerCase().indexOf(needle) > -1
          )
        })
        return
      } else {
        update()
        return
      }
    },
    addNewModel(val) {
      const inputValue = this.$refs.qCarModel.inputValue
      if (inputValue) this.$refs.qCarModel.add(inputValue, 'add-unique')
    },
    openTrackingURL(url) {
      window.open(url, '_blank')
    },
    isOrderStatusReached(status) {
      let flag = false
      this.orderDetail.orderTrackings.forEach((element, index, arr) => {
        if (
          element.isReached &&
          element.status == status &&
          arr[index + 1]?.isReached === false
        ) {
          flag = true
          return
        }
      })
      return flag
    },
  },
  destroyed() {
    if (this.recentUpdatedOrder)
      this.$store.commit('order/SET_RECENT_UPDATED_ORDER', null)
    if (this.trackOrderAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'trackOrderAlert',
        alertData: null,
      })
  },
  mounted() {
    // prefetch code
    let { orderId } = this.$route.params,
      { orderNumber } = this.$route.query,
      isLoggedIn = this.$store.getters['auth/isLoggedIn']

    if (!isLoggedIn) {
      if (!orderId || !orderNumber) {
        return this.$router.replace({
          name: 'Home',
        })
      }

      this.$store
        .dispatch('order/trackGuestOrder', {
          orderId: orderId,
          orderNumber: orderNumber,
        })
        .then((response) => {
          if (!response)
            this.$router.replace({
              name: 'Home',
            })
        })
    } else {
      if (this.$route.name == 'TrackGuestOrder')
        this.$router.replace({
          name: 'Orders',
        })

      if (
        (orderId && !this.$store.getters['order/recentUpdatedOrder']) ||
        (this.$store.getters['order/recentUpdatedOrder'] &&
          this.$store.getters['order/recentUpdatedOrder'].orderId != orderId)
      )
        this.$store
          .dispatch('order/trackOrder', {
            orderId: orderId,
          })
          .then((response) => {
            if (!response)
              this.$router.replace({
                name: 'Orders',
              })
          })
    }
    // prefetch code
  },
}
</script>
<style lang="scss">
.trackorder-contact-address {
  max-width: 220px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
}
.accountPages {
  .orderItems {
    .orderItem {
      display: block;
      padding: 0;
      margin-top: 15px;
      background: #fff;
      border: 1px solid #cccccc;
      border-radius: 5px;
      &:first-child {
        margin-top: 0;
      }
      .orderItemTop {
        background: #fafafa;
        padding: 15px;
        border-radius: 5px 5px 0 0;
        .actions {
          text-align: right;
          @media (max-width: 599px) {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            a,
            button {
              padding-left: 10px;
              padding-right: 10px;
              flex: 1 0 0;
              .q-btn__wrapper {
                padding: 0;
              }
            }
          }
        }
      }
      .orderItemMiddle {
        padding: 15px;
        .actions {
          text-align: right;
          .q-btn {
            box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
          }
          .q-btn__wrapper {
            padding: 9px 18px;
          }
          @media (max-width: 767px) {
            text-align: center;
            .q-btn {
              margin: 5px;
              width: 100%;
              max-width: 300px;
            }
          }
        }
        .orderSingleLabel {
          padding: 10px 0;
          margin-bottom: 12px;
          font-size: 16px;
          font-weight: bold;
          color: #0f141a;
          border-bottom: 1px dashed #cccccc;
        }
      }
      .orderItemDeliveryOptXs {
        @media (max-width: 1023px) {
          background: #f5f5f5;
          border: 1px solid #f0f0f0;
          padding: 10px 15px;
          border-radius: 5px;
        }
      }
      .orderItemDeliveryOpt {
        position: relative;
        padding-left: 30px;
        padding-bottom: 15px;
        i {
          position: absolute;
          top: 0;
          left: 5px;
          font-size: 18px;
        }
        @media (max-width: 1023px) {
          padding-left: 0;
          padding-top: 25px;
          padding-bottom: 0;
          i {
            top: 0;
          }
        }
        h5 {
          margin: 0;
          padding: 0;
          font-size: 16px;
          font-weight: bold;
          line-height: normal;
          color: $primary;
          color: var(--q-color-primaryOnBody) !important;
          a {
            color: $primary;
            color: var(--q-color-primaryOnBody) !important;
            text-decoration: none;
          }
        }
        p {
          margin: 0;
          padding: 3px 0;
          font-size: 12px;
          color: #888888;
          a {
            color: $primary;
            color: var(--q-color-primary) !important;
          }
        }
      }
      .orderpickmethod {
        font-size: 12px;
        margin-bottom: 5px;
        padding-left: 18px;
      }
      .orderPickDesc {
        font-size: 12px;
        text-align: right;
        @media (min-width: 1024px) {
          padding-left: 15px;
          text-align: left;
        }
        .orderPickDescTitle {
          padding: 3px;
          color: $tertiary;
        }
        .orderPickDescDate {
          padding: 3px;
          font-size: 18px;
          font-weight: bold;
          color: #7cb24d;
        }
        .orderPickDescTime {
          padding: 3px;
          color: #888888;
        }
      }
      .orderItemInfo-flex {
        margin: 0 -10px;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        font-size: 16px;
        font-weight: bold;
        color: #0f141a;

        li {
          margin-bottom: 10px;
          padding: 0 10px;
          flex: 1 0 0;
          @media (max-width: 767px) {
            padding-bottom: 10px;
            flex: 0 0 50%;
          }
        }
        span {
          padding-bottom: 5px;
          display: block;
          font-size: 11px;
          font-weight: normal;
          color: #888888;
          text-transform: uppercase;
        }
      }
      .orderItemInfo {
        margin: 0 -10px;
        padding: 0;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        list-style: none;
        font-size: 16px;
        font-weight: bold;
        color: #0f141a;

        li {
          // margin-bottom: 10px;
          padding: 0 10px;
          // flex: 1 0 0;
          @media (max-width: 767px) {
            padding-bottom: 10px;
            flex: 0 0 50%;
          }
        }
        span {
          padding-bottom: 5px;
          display: block;
          font-size: 11px;
          font-weight: normal;
          color: #888888;
          text-transform: uppercase;
        }
      }
      .btn-cancel {
        font-size: 14px;
        color: #ec7a7a;
        text-transform: uppercase;
        text-decoration: none;
        i {
          width: 15px;
          height: 15px;
          border-radius: 3px;
          vertical-align: bottom;
          margin-right: 8px;
          background: #f9d7d7;
          color: #ec7a7a;
          svg {
            width: 7px;
            height: 7px;
          }
        }
      }
      .btn-cancelled {
        font-size: 14px;
        font-weight: bold;
        color: #ec7a7a;
        text-transform: uppercase;
        text-decoration: none;
        i {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          vertical-align: bottom;
          margin-right: 8px;
          background: #ec7a7a;
          color: #fff;
          svg {
            width: 7px;
            height: 7px;
          }
        }
      }
      .btn-completed,
      .btn-refunded {
        font-size: 14px;
        font-weight: bold;
        color: #008a03;
        text-transform: uppercase;
        text-decoration: none;
        i {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          vertical-align: bottom;
          margin-right: 8px;
          background: #008a03;
          color: #fff;
          svg {
            width: 8px;
            height: 7px;
          }
        }
      }
    }
  }
}

/* Order Summary Info */
.orderSummaryInfos {
  margin-top: 20px;
  border-top: 1px solid #cccccc;
  padding: 20px;
  @media (max-width: 599px) {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }
  .o-1 {
    order: 1;
  }
  .o-2 {
    order: 2;
  }
  .o-3 {
    order: 3;
  }
  .o-4 {
    order: 4;
  }
  @media (min-width: 600px) {
    .o-2 {
      order: 1;
    }
    .o-1 {
      order: 2;
    }
  }
  @media (min-width: 1024px) {
    .o-1 {
      order: 5;
      margin-left: auto;
    }
  }
  h5 {
    margin: 0 0 10px;
    padding: 0;
    font-size: 18px;
    font-weight: bold;
    line-height: normal;
    color: $tertiary;
  }
  p {
    margin: 0;
    font-size: 16px;
    line-height: 24px;
    color: #888888;
    .default {
      background: #91bf5b;
      color: #fff;
      font-size: 12px;
      line-height: 12px;
      display: inline-block;
      padding: 3px 10px;
      border-radius: 12px;
    }
  }
}
ul.orderSummaryITotal {
  margin: 0;
  padding: 0;
  list-style: none;
  li {
    padding: 6px 0;
    padding-right: 140px;
    font-size: 16px;
    font-weight: normal;
    color: #888888;
    position: relative;
    > span {
      font-weight: bold;
      width: 70%;
      position: absolute;
      right: 0;
      top: 6px;
      text-align: right;
    }
    &.total {
      padding: 10px 0 5px;
      font-weight: 700;
      color: #314154;
      span {
        top: 10px;
        font-weight: 700;
      }
    }
  }
}
/* Order Summary Info */
/* Order Steps */
.accountPages {
  @media (max-width: 599px) {
    .orderSteps {
      padding: 25px 10px 0;
      margin-bottom: 10px;
      border-bottom: 1px dashed #dddddd;
      ul {
        margin: 0;
        flex-direction: column;
        li {
          flex: 1 0 0;
          padding: 0 0 15px;
          padding-left: 35px;
          height: 100%;
          min-height: auto;
          text-align: left;
          .dot {
            width: 21px;
            height: 21px;
            top: 0;
            left: 5px;
            transform: translate(0, 0);
          }
          .text {
            width: 200px;
            display: block;
            padding-bottom: 10px;
            position: relative;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            font-size: 16px;
          }
          &::after {
            width: 4px;
            height: 100%;
            top: 0;
            left: 13px;
            bottom: 0;
            transform: translate(0, -100%);
          }
          &::before {
            width: 4px;
            height: 100%;
            top: 0;
            left: 13px;
            bottom: 0;
            transform: translate(0, 0);
          }
          &.current {
            .dot {
              width: 21px;
              height: 21px;
              left: 5px;
              &:before {
                font-size: 10px;
                left: 4px;
                top: 3px;
              }
            }
            .text {
              font-size: 16px;
            }
            .tooltip {
              width: 100%;
              position: relative;
              left: 0;
              top: 0;
              transform: translate(0, 0);
              &:before,
              &:after {
                content: none;
              }
            }
          }
          &.completed {
            .dot {
              width: 21px;
              height: 21px;
              left: 5px;
              &:before {
                width: 9px;
                height: 5px;
                left: 5px;
                top: 5px;
              }
            }
          }
        }
      }
    }
  }
}
/* Order Steps */
/* Mobile View */
@media (max-width: 767px) {
  .trackOrder {
    .orderItems {
      .orderItem {
        border: none;
        .orderItemMiddle {
          padding: 15px 0 0;
        }
        .orderItemInfo li {
          &.oItemPlaced {
            order: 2;
          }
          &.oItemNo {
            order: 1;
          }
          &.oItemTotal {
            order: 3;
          }
        }
      }
    }
  }
}
/* Mobile View */

// Curbside Mobile
.curbsidePage {
  padding: 16px 0;
}
.curbPickLink {
  text-align: center;
  .q-btn {
    font-size: 15px;
  }
}
.curbPickInfo,
.contactInfo {
  text-align: center;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 24px;
    color: $tertiary;
  }
  p {
    font-size: 14px;
    &.text-red {
      font-size: 12px;
      line-height: 16px;
    }
  }
  .curbPickUpCar {
    margin: 16px 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    li {
      padding: 4px 16px 0;
      padding-left: 45px;
      position: relative;
      font-size: 14px;
      color: #333333;
      text-align: left;
      span {
        display: block;
        font-size: 10px;
        text-transform: uppercase;
        color: #666666;
      }
      i {
        position: absolute;
        left: 0;
        top: 0;
        padding: 10px;
        width: 16px;
        height: 16px;
        background: #e5f7ff;
        color: $primary;
        color: var(--q-color-primary) !important;
        border-radius: 3px;
      }
    }
  }
  .curbPickUpi,
  .contactInfoU {
    margin: 16px 0;
    padding: 16px;
    list-style: none;
    background: #fff;
    border: 1px dashed $primary;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: wrap;
    li {
      padding: 0 16px;
      display: inline-block;
      font-size: 20px;
      color: $tertiary;
      span {
        padding-bottom: 8px;
        display: block;
        font-size: 12px;
        color: #888888;
      }
      button.track-btn {
        line-height: 1;
        flex-direction: row;
        span.q-btn__wrapper {
          padding: 0;
          min-height: 1px;
          span {
            padding: 0;
          }
          span.block {
            color: #fff;
            padding: 10px 15px;
            text-align: center;
          }
        }
        &.text-red {
          span.q-btn__wrapper {
            span,
            span.block {
              padding: 0;
              color: #f00;
            }
          }
        }
      }
    }
    .verification-instructions {
      width: 100%;
      display: block;
      padding: 10px 0px 0px 0px;
    }
  }
}
.curbSteps {
  padding: 0;
  padding-bottom: 30px;
  margin: 0;
  ul {
    margin: 0 0 8px;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    li {
      margin: 0;
      padding: 0;
      height: 30px;
      flex: 1 0 0;
      text-align: center;
      position: relative;
      .dot {
        content: '';
        background: #888888;
        border: 2px solid #fff;
        border-radius: 50%;
        width: 13px;
        height: 13px;
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }
      .text {
        // display: none;
        font-size: 9px;
        color: #888888;
        width: 50px;
        position: absolute;
        top: 30px;
        left: 50%;
        transform: translate(-50%, 0);
        z-index: 1;
        @media (max-width: 374px) {
          width: 50px;
        }
      }
      &:after {
        content: '';
        width: 100%;
        height: 6px;
        background: #d7d5ce;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
      }

      &:before {
        content: '';
        width: 100%;
        height: 6px;
        background: #d7d5ce;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(0, -50%);
      }

      &:last-child {
        &:before {
          content: none;
        }
      }

      &:first-child,
      &:last-child {
        &:after {
          content: none;
        }
      }
      &.active {
        .text {
          display: block;
        }
      }
      &.completed {
        &:after {
          background: #a3cc80;
        }
        .dot {
          width: 16px;
          height: 16px;
          background: #7cb24d;
          border-color: #7cb24d;

          &:before {
            content: '';
            display: block;
            width: 8px;
            height: 4px;
            border-left: 2px solid #fff;
            border-bottom: 2px solid #fff;
            transform: rotate(-45deg);
            position: absolute;
            left: 2px;
            top: 3px;
          }
        }
        + .completed {
          &:after {
            left: -50%;
            width: 100%;
          }
        }

        &:last-child {
          &:after {
            content: '';
          }
        }
      }
    }
  }
}
.curbsOrders {
  .orderSingleLabel {
    padding: 16px 0 16px;
    margin-bottom: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #0f141a;
    border-bottom: 1px dashed #cccccc;
  }
  .q-list {
    margin-top: 10px;
    margin-bottom: 10px;
    &.substitution {
      border-left: 4px solid rgba(42, 157, 204, 0.5);
      .q-item {
        //padding-left: 15px;
        /*.orderItemPrice {
          color: #008a03;
        }*/
        &.disable {
          opacity: 0.7;
          pointer-events: none;
          img {
            filter: grayscale(100%);
          }
          .orderItemPrice {
            color: #888888;
          }
        }
      }
    }
  }
  .q-item {
    padding: 15px 5px;
  }
  .q-list:not(.singleItem) {
    display: flex;
    flex-wrap: wrap;
    .q-item__section--avatar {
      min-width: 50px;
      padding-right: 0;
    }
  }
  .q-item__section--avatar {
    min-width: 70px;
  }
  .q-item__section--side {
    align-items: center;
    padding-right: 10px;
    .caption {
      font-size: 14px;
      color: #888888;
      &.decline {
        color: #ec7a7a;
      }
      a {
        font-size: 14px;
        color: #ec7a7a;
        text-decoration: underline;
      }
    }
  }
  .q-avatar {
    height: 1.3em;
    width: 1.3em;
    .q-avatar__content {
      box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
    }
    a {
      padding: 5px;

      width: 52px;
      text-align: center;
      text-decoration: none;
    }
  }
  .last {
    .q-avatar a {
      height: 52px;
      background: $primary;
      background: var(--q-color-primary) !important;
      border-color: $primary;
      border-color: var(--q-color-primary) !important;
      border-color: var(--q-color-primary) !important;
      color: $white;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      span {
        font-size: 14px;
        font-weight: bold;
        line-height: 16px;
        strong {
          font-size: 18px;
        }
      }
    }
  }
  .q-item__label a {
    color: #333b3e;
    font-size: 16px;
    text-decoration: none;
    padding: 0 0 5px;
    display: inline-block;
    &:hover {
      text-decoration: underline;
    }
  }
  .text-caption {
    font-size: 15px;
    span.discount {
      margin-bottom: 5px;
      display: block;
      font-size: 12px;
      color: #008a03;
    }
    strong {
      font-size: 15px;
      font-weight: normal;
    }
  }
  .orderItemPrice {
    font-size: 18px;
    font-weight: bold;
    color: $tertiary;
  }
  .trackPrice {
    flex: 0 0 15%;
  }
  @media (min-width: 1024px) {
    .trackPrice {
      flex: 0 0 10%;
    }
  }
  @media (max-width: 767px) {
    .q-item {
      flex-wrap: wrap;
    }
    .q-item__section--avatar {
      flex: 1 1 56px;
    }
    .q-item__section--main {
      flex: 10000 1 calc(100% - 70px);
    }
    .trackQty {
      flex: 0 0 65%;
      padding-left: 70px;
    }
    .trackPrice {
      flex: 0 0 35%;
      text-align: right;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }
}
/* Dialog */
.curbsideCarInfo {
  border-radius: 16px 16px 0 0 !important;
  h4 {
    margin: 0;
    padding: 0;
    font-size: 16px;
    font-weight: bold;
    color: $tertiary;
  }
  .q-scrollarea {
    margin-bottom: 16px;
  }
  .curbColor {
    font-size: 14px;
    text-align: center;
    .q-item {
      padding: 8px;
    }
    .q-avatar {
      // border: 4px solid #fff;
      box-shadow: 0 0px 10px #dddddd;
      position: relative;
    }
    .active {
      .q-avatar {
        &::before {
          width: 20px;
          height: 20px;
          content: '';
          position: absolute;
          border-radius: 100%;
          background-color: #fff;
          top: -8px;
          left: 0;
          right: 0;
          margin: auto;
          border: 1px solid $primary;
        }
        &::after {
          content: '';
          width: 12px;
          height: 7px;
          position: absolute;
          top: -2px;
          border-left: 3px solid $primary;
          border-bottom: 3px solid $primary;
          transform: rotate(-45deg);
          left: 0;
          right: 0;
          margin: auto;
        }
        // border-color: $primary;
        // border-color: var(--q-color-primary) !important;
        // border-color: var(--q-color-primary) !important;
      }
    }
  }
  .curbParking {
    font-size: 16px;
    text-align: center;
    .q-item {
      padding: 8px;
    }
    .q-item__section {
      height: 60px;
      width: 60px;
      border: 2px solid #888888;
      &.active {
        font-weight: bold;
        color: $primary;
        color: var(--q-color-primary) !important;
        border-color: $primary;
        border-color: var(--q-color-primary) !important;
        border-color: var(--q-color-primary) !important;
      }
    }
  }
}
/* Dialog */
.curbsideInfo,
.contactInfoCard {
  .close {
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .q-card__section {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: center;
    height: 100%;
  }
  .curbPickUpi,
  .contactInfoCardU {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    li {
      padding: 16px 8px;
      display: block;
      font-size: 50px;
      font-weight: bold;
      color: $tertiary;
      span {
        padding-bottom: 8px;
        display: block;
        font-size: 24px;
        color: #888888;
      }
    }
  }
}
/* Dialog */
</style>
