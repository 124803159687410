<template>
  <SplitSection v-if="component.type == 'section'" :section="component" />
  <div v-else :style="componentCommonStyle">
    <component
      v-bind:is="componentMapper[component.type]"
      :component="component"
      :isPreview="isPreview"
    />
  </div>
</template>

<script>
export default {
  name: 'ColumnComponent',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
    isPreview: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    componentCommonStyle() {
      const cViewId = this.isMobile ? 'mobile' : 'desktop',
        padding = this.component.padding?.[cViewId],
        margin = this.component.margin?.[cViewId],
        style = {}

      style['padding'] = `${padding?.top || 0}px ${padding?.right || 0}px ${
        padding?.bottom || 0
      }px ${padding?.left || 0}px`

      style['margin'] = `${margin?.top || 0}px ${margin?.right || 0}px ${
        margin?.bottom || 0
      }px ${margin?.left || 0}px`

      return style
    },
    componentMapper() {
      return {
        text: 'TextComponent',
        textEditor: 'TextComponent',
        imageSlider: 'ImageSlider',
        collection:
          this.isPreview ||
          (this.showAgeVerificationBtn && this.component.isAgeRestricted)
            ? 'CollectionPreview'
            : 'Collection',
        image: 'SingleImage',
      }
    },
  },
}
</script>
