import { render, staticRenderFns } from "./SubscriptionDetail.vue?vue&type=template&id=25bd306a"
import script from "./SubscriptionDetail.vue?vue&type=script&lang=js"
export * from "./SubscriptionDetail.vue?vue&type=script&lang=js"
import style0 from "./SubscriptionDetail.vue?vue&type=style&index=0&id=25bd306a&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QChip from 'quasar/src/components/chip/QChip.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QTable from 'quasar/src/components/table/QTable.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QAvatar,QBtn,QChip,QItem,QItemSection,QImg,QItemLabel,QTooltip,QTable,QTr,QTd});
