import { hideShowFullSpinner } from 'src/utils'
import { Platform } from 'quasar'
import { NotifyMethods } from 'src/boot/notify'

export async function getOrders({}, params) {
  try {
    let response = await this.$axiosInstance.get('/Order', { params })

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}

export async function deleteOrder({ commit }, { orderId, customerID = null }) {
  try {
    let params = {}
    if (customerID) params.customerID = customerID
    let response = await this.$axiosInstance.delete(`/Order/${orderId}`, {
      params,
    })

    if (response?.data?.success) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showSuccess(
          '<strong>Success!</strong> We have canceled your order.'
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'orderAlert',
          alertData: {
            variant: 'success',
            message: '<strong>Success!</strong> We have canceled your order.',
          },
        })
      }
      return response.data
    } else {
      return false
    }
  } catch (error) {
    return error
  }
}

export async function trackOrder(
  { commit },
  { orderId, showLoader = true, invoiceError = false }
) {
  try {
    hideShowFullSpinner(showLoader)
    let response = await this.$axiosInstance.get(`/Order/Detail/${orderId}`, {
      params: {
        WithBifurcation: true,
      },
    })

    if (response?.data?.success) {
      let order = response.data.data
      if (order && order.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      commit('SET_ORDER_TRACK_INFO', { [orderId]: order })
      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    if (invoiceError) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(
          '<strong>Error!</strong> Sorry! PDF is not available.'
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'trackOrderAlert',
          alertData: {
            variant: 'danger',
            message: '<strong>Error!</strong> Sorry! PDF is not available.',
          },
        })
      }
    }
    hideShowFullSpinner(false)
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function editOrderDetail({ commit }, params) {
  try {
    hideShowFullSpinner(true)

    let response = await this.$axiosInstance.get('/Order/Edit/Detail', {
      params,
    })

    commit('CLEAR_UPDATE_TIME_SLOT', params.OrderID)

    if (response && response.data && response.data.success) {
      let order = response.data.data
      if (order && order.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      order.trackOrderLineItems.forEach((item) => {
        item.substituteProductListData.forEach((x) => {
          if (
            x.substituteProductDetails &&
            x.substituteProductDetails.length > 0
          ) {
            x.substituteProductDetails.push({
              image: '',
              price: 0,
              productID: '00000000-0000-0000-0000-000000000000',
              productName: 'No Preference',
              qtyOnHand: 0,
              seoName: '',
              substituteProductID: '00000000-0000-0000-0000-000000000000',
            })
          }
        })
      })

      commit('SET_EDIT_ORDER_INFO', { [params.OrderID]: order })
      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function viewSubscriptionDetail(
  { commit },
  { subscriptionId, showLoader = true }
) {
  hideShowFullSpinner(showLoader)
  try {
    let response = await this.$axiosInstance.get(
      `/AutoShipOrder/Detail/${subscriptionId}`
    )
    let subscriptionDetails = response.data.data
    commit('SET_SUBSCRIPTION_INFO', { [subscriptionId]: subscriptionDetails })
    if (Platform?.is?.mobile && subscriptionDetails.productName) {
      commit(
        'common/SET_HEADER_TITLE',
        { title: subscriptionDetails.productName },
        { root: true }
      )
    }
    return response.data.data
  } catch (err) {
    console.log('err', err)
    hideShowFullSpinner(false)
  } finally {
    hideShowFullSpinner(false)
  }
}
export async function getUpcomingOrders({}, subscriptionId) {
  hideShowFullSpinner(true)
  try {
    let response = await this.$axiosInstance.get(
      `/AutoShipOrder/UpcomingOrders/${subscriptionId}`
    )

    return response.data.data
  } catch (err) {
    console.log('err', err)
  } finally {
    hideShowFullSpinner(false)
  }
}
export async function getPreviousOrders({}, { subscriptionId, params }) {
  try {
    let { data } = await this.$axiosInstance.get(
      `/AutoShipOrder/PreviousOrders/${subscriptionId}`,
      { params }
    )

    return data
  } catch (err) {
    console.log('err', err)
  }
}
export async function viewOrderDetail({ commit }, orderId) {
  try {
    let response = await this.$axiosInstance.get(`/Order/ViewDetail/${orderId}`)

    if (response?.data?.success) {
      let order = response.data.data

      if (order && order.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      if (order && order.returnOrderLineItems) {
        let breakIndex
        for (let i = order.returnOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.returnOrderLineItems[i].isLinkedProduct) break
          else {
            order.returnOrderLineItems.every((returnOrderLineItem) => {
              if (
                returnOrderLineItem.orderLineItemID ==
                order.returnOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!returnOrderLineItem.linkedProducts)
                  returnOrderLineItem.linkedProducts = []

                returnOrderLineItem.linkedProducts.push(
                  order.returnOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.returnOrderLineItems = order.returnOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }
      if (order && order.notDeliveredOrderLineItems) {
        let breakIndex
        for (let i = order.notDeliveredOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.notDeliveredOrderLineItems[i].isLinkedProduct) break
          else {
            order.notDeliveredOrderLineItems.every(
              (notDeliveredOrderLineItem) => {
                if (
                  notDeliveredOrderLineItem.orderLineItemID ==
                  order.notDeliveredOrderLineItems[i].parentLinkedLineItemId
                ) {
                  if (!notDeliveredOrderLineItem.linkedProducts)
                    notDeliveredOrderLineItem.linkedProducts = []

                  notDeliveredOrderLineItem.linkedProducts.push(
                    order.notDeliveredOrderLineItems[i]
                  )
                  return false
                }

                return true
              }
            )

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.notDeliveredOrderLineItems =
            order.notDeliveredOrderLineItems.splice(0, breakIndex)
        }
      }

      commit('SET_ORDER_INFO', {
        [orderId]: order,
      })

      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    return false
  }
}

export async function toggleSubstitutionByOrderId(
  { commit, getters, dispatch },
  { orderId, isSubstitutionAllowed }
) {
  try {
    let orderInfo = getters.editOrderInfoById(orderId),
      objRequest = {}

    isSubstitutionAllowed = isSubstitutionAllowed || false

    if (orderInfo && orderInfo.orderNumber) {
      objRequest.orderID = orderId
      objRequest.editOrderLineItems = []

      for (let item of orderInfo.trackOrderLineItems) {
        if (item.allowSubstitute == isSubstitutionAllowed && !item.isDeleted) {
          objRequest.editOrderLineItems.push({
            productID: item.productId,
            allowSubstitute: !isSubstitutionAllowed,
          })
        }
      }
    }

    await dispatch('updateOrder', objRequest)
  } catch (error) {
    return error
  }
}

export async function updateOrder({ commit }, params) {
  try {
    hideShowFullSpinner(true)
    let response = await this.$axiosInstance.post('Order/Edit', params)

    if (response.data?.success) {
      let order = response.data.data
      if (order && order.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      order.trackOrderLineItems.forEach((item) => {
        item.substituteProductListData.forEach((x) => {
          if (
            x.substituteProductDetails &&
            x.substituteProductDetails.length > 0
          ) {
            x.substituteProductDetails.push({
              image: '',
              price: 0,
              productID: '00000000-0000-0000-0000-000000000000',
              productName: 'No Preference',
              qtyOnHand: 0,
              seoName: '',
              substituteProductID: '00000000-0000-0000-0000-000000000000',
            })
          }
        })
      })

      commit('SET_EDIT_ORDER_INFO', { [params.orderID]: order })
      return true
    }
  } catch (error) {
    if (error.errors && error.errors.length) {
      error.errors.forEach((error) => {
        if (error.errorCode == 'CRT_0114') {
          throw error.descreption
        }
      })
    } else return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function getProducts({ rootGetters }, params) {
  try {
    let response = await this.$axiosInstance.get('Order/Edit/Products', {
      params: {
        ...params,
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      },
    })

    if (response?.data?.success) {
      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    return error
  }
}

export async function clearRecentItems({}, orderId) {
  try {
    let response = await this.$axiosInstance.delete(`Order/Edit/${orderId}`)

    if (response?.data?.success) return true
    else return false
  } catch (error) {
    return error
  }
}

export async function applyOrderChanges({ commit }, params) {
  try {
    hideShowFullSpinner(true)
    let cancelOrder = params.cancelOrder
    delete params.cancelOrder
    let response = await this.$axiosInstance.post('Order/Update', params)

    if (response) {
      if (response.data?.success) {
        let order = response.data.data
        if (order && order.trackOrderLineItems) {
          let breakIndex
          for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
            if (!order.trackOrderLineItems[i].isLinkedProduct) break
            else {
              order.trackOrderLineItems.every((trackOrderLineItem) => {
                if (
                  trackOrderLineItem.orderLineItemID ==
                  order.trackOrderLineItems[i].parentLinkedLineItemId
                ) {
                  if (!trackOrderLineItem.linkedProducts)
                    trackOrderLineItem.linkedProducts = []

                  trackOrderLineItem.linkedProducts.push(
                    order.trackOrderLineItems[i]
                  )
                  return false
                }

                return true
              })

              breakIndex = i
            }
          }

          if (breakIndex) {
            order.trackOrderLineItems = order.trackOrderLineItems.splice(
              0,
              breakIndex
            )
          }
        }

        if (Platform?.is?.mobile) {
          if (cancelOrder) {
            NotifyMethods.showSuccess(
              '<strong>Success!</strong> We have canceled your order.'
            )
          } else {
            NotifyMethods.showSuccess(
              '<strong>Success!</strong> We Updated your order.'
            )
          }
        } else {
          if (cancelOrder) {
            commit('SET_ORDER_ALERT', {
              alertKey: 'orderAlert',
              alertData: {
                variant: 'success',
                message:
                  '<strong>Success!</strong> We have canceled your order.',
              },
            })
          } else {
            commit('SET_ORDER_ALERT', {
              alertKey: 'trackOrderAlert',
              alertData: {
                variant: 'success',
                message: '<strong>Success!</strong> We Updated your order.',
              },
            })
          }
        }

        if (!cancelOrder) commit('SET_RECENT_UPDATED_ORDER', order)
      }
      return true
    } else {
      return false
    }
  } catch (error) {
    if (error.errors && error.errors.length > 0) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(
          `<strong>Error!</strong> ${error.errors[0].descreption}`
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'editOrderAlert',
          alertData: {
            variant: 'danger',
            message: `<strong>Error!</strong> ${error.errors[0].descreption}`,
          },
        })
      }
    } else if (error && error.message) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(`<strong>Error!</strong> ${error.message}`)
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'editOrderAlert',
          alertData: {
            variant: 'danger',
            message: `<strong>Error!</strong> ${error.message}`,
          },
        })
      }
    }
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function changeArrivalStatus({ commit }, body) {
  try {
    hideShowFullSpinner(true)

    let { orderID } = body,
      { data } = await this.$axiosInstance.put(
        '/Order/StorePickup/ArrivalStatus',
        body
      )

    if (data?.success)
      commit('UPDATE_TRACK_ORDER_DETAIL', {
        orderId: orderID,
        showIamonMyway: false,
        showIamHere: true,
      })

    return data
  } catch (error) {
    return error
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function allowDeclineSubstitution({ commit }, params) {
  try {
    hideShowFullSpinner(true)
    let { data } = await this.$axiosInstance.put(
      'Order/AllowDeclineSubstitution',
      params
    )

    if (data?.success && data.data?.orderID) {
      let order = data.data
      if (order?.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      commit('SET_ORDER_TRACK_INFO', { [data.data.orderID]: order })
      return true
    }
  } catch (error) {
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function getParkingSpot({ commit }, params) {
  try {
    // if (!getters.parkingSpots) {
    let { data } = await this.$axiosInstance.get(
      '/Order/StorePickup/PickupSpot',
      { params }
    )
    commit('SET_PARKING_SPOTS', data.data)
    return data
    // }
  } catch (error) {
    return error
  }
}

export async function availableSlots({ commit, getters }, params) {
  try {
    commit('SET_SLOT_LOADING', true)
    let { data } = await this.$axiosInstance.get('Order/Edit/Slots', { params })
    if (data.success) {
      commit('SET_SLOT_INFO', { [params.orderId]: data.data })
      return data
    }
  } catch (error) {
    return error
  } finally {
    commit('SET_SLOT_LOADING', false)
  }
}

export async function updateReservedSlot({ commit, getters }, params) {
  try {
    let { data } = await this.$axiosInstance.post(
      'Order/UpdateReservedSlot',
      params
    )

    if (data.success) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showSuccess(
          '<strong>Success!</strong> We Updated your order time slot.'
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'editOrderAlert',
          alertData: {
            variant: 'success',
            message: '<strong>Success!</strong> We Updated your order.',
          },
        })
      }
      return data
    } else if (response.errors && response.errors.length > 0) {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(
          `<strong>Error!</strong>${response.errors[0].descreption}.`
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'orderTimeAlert',
          alertData: {
            variant: 'danger',
            message: `<strong>Error!</strong>${response.errors[0].descreption}.`,
          },
        })
      }
    }
  } catch (error) {
    if (Platform?.is?.mobile) {
      NotifyMethods.showError(`<strong>Error!</strong> ${error.message}.`)
    } else {
      commit('SET_ORDER_ALERT', {
        alertKey: 'orderTimeAlert',
        alertData: {
          variant: 'danger',
          message: `<strong>Error!</strong> ${error.message}.`,
        },
      })
    }
    return error
  }
}

export async function setParkingSpot({ commit }, body) {
  try {
    let { orderID } = body,
      { data } = await this.$axiosInstance.put(
        '/Order/StorePickup/PickupSpot',
        body
      )

    if (data && data.success) {
      commit('UPDATE_TRACK_ORDER_DETAIL', {
        orderId: orderID,
        showIamonMyway: false,
        showIamHere: false,
        iAmHereInfo: {
          ...data.data,
        },
      })
    }
    return data
  } catch (error) {
    return error
  }
}

export async function generateInvoice(
  { commit },
  { orderId, showLoader = true, invoiceErrorAlertKey = '' }
) {
  try {
    hideShowFullSpinner(showLoader)
    let response = await this.$axiosInstance.get(
      `/Order/PrintInvoice?orderId=${orderId}`,
      {
        responseType: 'blob',
      }
    )

    hideShowFullSpinner(false)
    return response
  } catch (error) {
    hideShowFullSpinner(false)
    if (Platform?.is?.mobile) {
      NotifyMethods.showError(
        '<strong>Error!</strong> Sorry! PDF is not available.'
      )
    } else {
      commit('SET_ORDER_ALERT', {
        alertKey: invoiceErrorAlertKey,
        alertData: {
          variant: 'danger',
          message: '<strong>Error!</strong> Sorry! PDF is not available.',
        },
      })
    }
  }
}

export async function updateLNSDollars({ commit }, params) {
  try {
    let response = await this.$axiosInstance.post(
      'Order/Edit/UpdateLNSDollars',
      params
    )

    if (response.data?.success) {
      let order = response.data.data
      if (order?.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }
      commit('SET_EDIT_ORDER_INFO', { [params.orderID]: order })
      return response.data
    }
  } catch (error) {
    return error
  }
}

export async function getLinkedProducts({ commit }, { productID, locationID }) {
  try {
    let response = await this.$axiosInstance.get(
      `Product/LinkedProduct?productID=${productID}&LocationID=${locationID}`
    )

    return response
  } catch (error) {
    return error
  }
}

export async function GetCoupons({ commit, rootGetters }, orderId) {
  try {
    let { data } = await this.$axiosInstance.get(
      `/Order/Edit/Coupons/${orderId}`
    )

    // if (data.success && data.data)
    //   commit('SET_COUPONS', data.data)

    return data
  } catch (error) {
    return error
  }
}

export async function applyCoupons({ commit }, { orderId, payload }) {
  try {
    let { data } = await this.$axiosInstance.post(
      `/Order/Edit/Coupons/${orderId}`,
      payload
    )

    if (data.success && data.data)
      commit('SET_EDIT_ORDER_INFO', { [orderId]: data.data })

    return data
  } catch (error) {
    return error
  }
}

export async function removeCoupons({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `/Order/Edit/Coupons/${body.couponId}?OrderID=${body.orderId}`
    )

    if (data.success && data.data)
      commit('SET_EDIT_ORDER_INFO', { [body.orderId]: data.data })

    return data
  } catch (error) {
    return error
  }
}

export async function applyReorder({ commit }, { orderId, body }) {
  try {
    let response = await this.$axiosInstance.post(
      `Order/reorder/${orderId}`,
      body
    )

    if (
      response.data?.success &&
      response.data.data?.shoppingCartDetailViewModel
    ) {
      let cartDetails = response.data.data.shoppingCartDetailViewModel
      commit('cart/SET_CURRENT_CART_NUMBER', cartDetails.orderNumber, {
        root: true,
      })
      commit(
        'cart/PUSH_CART_DETAIL',
        {
          cartId: cartDetails.orderNumber,
          cartDetail: cartDetails,
        },
        { root: true }
      )
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function getCarMakeModel({ commit, getters }, params) {
  try {
    let response = await this.$axiosInstance.get('/Order/CarMakeModel', params)

    return response.data
  } catch (error) {
    return error
  }
}

export async function editOrderUpdateSubstitute(
  { commit, dispatch, rootGetters },
  params
) {
  let recentSubstitutions = rootGetters['cart/recentSubstituteDetail']
  if (
    Object.keys(recentSubstitutions) &&
    Object.keys(recentSubstitutions).length > 0
  ) {
    commit('cart/RESET_RECENT_SUBSTITUTE', null, { root: true })
    let substitute = {}
    let substituteParams = []

    params.forEach((x) => {
      if (recentSubstitutions[x.orderLineItemID]) {
        substitute[x.orderLineItemID] = {
          allowSubstitute:
            recentSubstitutions[x.orderLineItemID].allowSubstitute,
          selectedSubstituteProduct:
            recentSubstitutions[x.orderLineItemID].selectedSubstituteProduct ==
            '00000000-0000-0000-0000-000000000000'
              ? null
              : recentSubstitutions[x.orderLineItemID]
                  .selectedSubstituteProduct,
          orderLineItemID:
            recentSubstitutions[x.orderLineItemID].orderLineItemID,
          substituteProducts:
            recentSubstitutions[x.orderLineItemID].substituteProducts,
        }

        substituteParams = [
          ...substituteParams,
          {
            allowSubstitute:
              recentSubstitutions[x.orderLineItemID].allowSubstitute,
            orderLineItemID: x.orderLineItemID,
            substituteProductID:
              recentSubstitutions[x.orderLineItemID]
                .selectedSubstituteProduct ==
              '00000000-0000-0000-0000-000000000000'
                ? null
                : recentSubstitutions[x.orderLineItemID]
                    .selectedSubstituteProduct,
          },
        ]
      }
    })

    commit('cart/SET_RECENT_SUBSTITUTE', substitute, { root: true })
    return substituteParams
  }
}

export async function trackGuestOrder(
  { commit },
  { orderId, orderNumber, showLoader = true, invoiceError = false }
) {
  try {
    hideShowFullSpinner(showLoader)
    let response = await this.$axiosInstance.get(`/Order/Guest/${orderId}`, {
      params: {
        orderNumber,
      },
    })

    if (response?.data?.success) {
      let order = response.data.data
      if (order && order.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }

      commit('SET_ORDER_TRACK_INFO', { [orderId]: order })
      return response.data.data
    } else {
      return false
    }
  } catch (error) {
    if (invoiceError) {
      if (Platform && Platform.is && Platform.is.mobile) {
        NotifyMethods.showError(
          '<strong>Error!</strong> Sorry! PDF is not available.'
        )
      } else {
        commit('SET_ORDER_ALERT', {
          alertKey: 'trackOrderAlert',
          alertData: {
            variant: 'danger',
            message: '<strong>Error!</strong> Sorry! PDF is not available.',
          },
        })
      }
    }
    hideShowFullSpinner(false)
    return false
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function applyDiscountCoupons({ commit }, { orderId, payload }) {
  try {
    let { data } = await this.$axiosInstance.post(
      `/Order/Edit/DiscountCoupons/${orderId}`,
      payload
    )

    if (data.success && data.data) {
      let order = data.data
      if (order?.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }
      commit('SET_EDIT_ORDER_INFO', { [orderId]: order })
      return data
    }
  } catch (error) {
    return error
  }
}
export async function removeDiscountCoupons({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `/Order/Edit/DiscountCoupons/${body.couponId}?orderId=${body.orderId}`
    )

    if (data.success && data.data) {
      let order = data.data
      if (order?.trackOrderLineItems) {
        let breakIndex
        for (let i = order.trackOrderLineItems.length - 1; i >= 0; i--) {
          if (!order.trackOrderLineItems[i].isLinkedProduct) break
          else {
            order.trackOrderLineItems.every((trackOrderLineItem) => {
              if (
                trackOrderLineItem.orderLineItemID ==
                order.trackOrderLineItems[i].parentLinkedLineItemId
              ) {
                if (!trackOrderLineItem.linkedProducts)
                  trackOrderLineItem.linkedProducts = []

                trackOrderLineItem.linkedProducts.push(
                  order.trackOrderLineItems[i]
                )
                return false
              }

              return true
            })

            breakIndex = i
          }
        }

        if (breakIndex) {
          order.trackOrderLineItems = order.trackOrderLineItems.splice(
            0,
            breakIndex
          )
        }
      }
      commit('SET_EDIT_ORDER_INFO', { [body.orderId]: order })
      return data
    }
  } catch (error) {
    return error
  }
}

export async function getSubscriptions({}, params) {
  try {
    let response = await this.$axiosInstance.get('/AutoShipOrder', { params })

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}
export async function changeSubscriptionOption({}, { id, params = {} }) {
  try {
    let response = await this.$axiosInstance.put(
      `/AutoShipOrder/SubscriptionPauseOrCancel/${id}`,
      params
    )

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}
export async function updateSubscriptionAction({}, { id, params = {} }) {
  try {
    let response = await this.$axiosInstance.put(
      `/AutoShipOrder/Subscription/${id}`,
      params
    )

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}

export async function upcomingOrderAction({}, { id, params = {} }) {
  try {
    let response = await this.$axiosInstance.put(
      `/AutoShipOrder/UpcomingorderAction/${id}`,
      params
    )

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}

export async function updateSubscriptionAddressPayment(
  {},
  { id, params = {} }
) {
  try {
    let response = await this.$axiosInstance.put(
      `/AutoShipOrder/ManageAddressOrPayment/${id}`,
      params
    )

    if (response?.data?.success) return response.data
    else return false
  } catch (error) {
    return error
  }
}

export async function getSubscriptionDiscounts({}, params = {}) {
  try {
    let { data } = await this.$axiosInstance.get(
      '/AutoShipOrder/OrderDiscount/Details',
      { params }
    )

    return data
  } catch (error) {
    return error
  }
}
