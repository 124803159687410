<template>
  <div class="row q-col-gutter-sm" :class="isMobile ? 'q-mt-sm' : ''">
    <div
      :class="isMobile ? 'col-12' : 'col-12 col-sm-4'"
      v-show="filterKey == 'order'"
    >
      <div class="form-field pb-0">
        <q-select
          dense
          options-dense
          outlined
          label="Filter By"
          :value="orderFilters.selectedFilter"
          :options="orderFilters.filterOptions"
          @input="(val) => applyFilters('selectedFilter', val)"
        />
      </div>
    </div>
    <div
      :class="isMobile ? 'col-6' : 'col-10 col-sm-2'"
      v-show="filterKey == 'auto-ship-order'"
    >
      <div class="form-field pb-0">
        <q-select
          dense
          options-dense
          outlined
          label="Filter by Status"
          :value="orderFilters.filterByStatus"
          :options="statusOptions"
          @input="(val) => applyFilters('filterByStatus', val)"
          emit-value
          map-options
        />
      </div>
    </div>
    <div
      :class="isMobile ? 'col-6' : 'col-12 col-sm-2'"
      v-show="filterKey == 'auto-ship-order'"
    >
      <div class="form-field pb-0">
        <q-select
          dense
          options-dense
          outlined
          label="Filter by Upcoming"
          :value="orderFilters.filterByUpcoming"
          :options="upcomingOptions"
          @input="onChangeUpcomingFilter"
          emit-value
          map-options
        />
      </div>
    </div>
    <div
      :class="['col-12', { 'col-sm-3': !isMobile }]"
      v-if="filterKey == 'auto-ship-order'"
    >
      <div class="form-field pb-0">
        <q-input
          outlined
          dense
          @click="openDateRangePicker"
          v-model="showDates"
          :readonly="orderFilters?.filterByUpcoming == 3"
          :disable="orderFilters?.filterByUpcoming != 3"
        >
          <template v-slot:append>
            <q-icon name="event" :class="{ 'cursor-pointer': showDatePicker }">
              <q-popup-proxy
                ref="qDateProxy"
                transition-show="scale"
                transition-hide="scale"
                v-if="showDatePicker"
              >
                <q-date
                  v-model="selectedCustomRange"
                  range
                  single
                  mask="MM/DD/YYYY"
                >
                  <div class="row items-center justify-end">
                    <q-btn label="close" flat v-close-popup />
                    <q-btn label="ok" @click="onOk" flat v-close-popup />
                  </div>
                </q-date>
              </q-popup-proxy>
            </q-icon>
          </template>
        </q-input>
      </div>
    </div>
    <div
      :class="[
        'col-12',
        filterKey == 'auto-ship-order' ? 'col-sm-5' : 'col-sm-8',
      ]"
    >
      <div class="form-field pb-0">
        <q-input
          outlined
          v-model="localFilterSearchBy"
          color="grey"
          :label="searchInputPlaceholder"
          @clear="clearFilters"
          clearable
        >
          <template v-slot:append>
            <q-btn
              unelevated
              no-caps
              color="secondaryOnBody"
              @click="inputFilters"
              label="Search"
              dense
              class="q-px-sm r-4"
              :disable="!localFilterSearchBy"
            />
          </template>
        </q-input>
      </div>
    </div>
  </div>
</template>

<script>
import { isObject, isString } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'OrderSearch',
  props: {
    filterKey: {
      type: String,
      default: 'order',
    },
    searchInputPlaceholder: {
      type: String,
      default: 'Enter Product name or Order number here',
    },
  },
  data() {
    return {
      orders: {},
      orderfilter: 'Last 3 Months',
      orderfilterInput: '',
      startDate: '',
      endDate: '',
      selectedCustomRange: {
        from: null,
        to: null,
      },
      showDatePicker: false,
      statusOptions: [
        { label: 'All', value: '' },
        { label: 'Active', value: 1 },
        { label: 'Paused', value: 2 },
        { label: 'Canceled', value: 3 },
      ],
      upcomingOptions: [
        { label: 'Default', value: '' },
        { label: 'Next Week', value: 1 },
        { label: 'Next Month', value: 2 },
        { label: 'Custom Range', value: 3 },
      ],
      // upcomingOptions: ['Default', 'Next Week', 'Next Month', 'Custom Range'],
    }
  },
  computed: {
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    ...mapGetters('order', ['filterSearchBy']),
    orderFilters() {
      return this.getFilterByPage(this.filterKey)
    },
    showDates: {
      get() {
        let result = ''
        if (
          this.selectedCustomRange?.from &&
          this.selectedCustomRange?.to &&
          this.orderFilters?.filterByUpcoming > 0
        ) {
          result =
            this.$options.filters.formatDate(this.selectedCustomRange.from) +
            ' - ' +
            this.$options.filters.formatDate(this.selectedCustomRange.to)
        } else if (this.selectedCustomRange && this.showDatePicker) {
          result = this.$options.filters.formatDate(this.selectedCustomRange)
        }
        return result
      },
      set(val) {},
    },
    localFilterSearchBy: {
      get() {
        return this.filterSearchBy
      },
      set(val) {
        this.$store.commit('order/SET_FILTER_SEARCH', val)
      },
    },
    filterOptions() {
      let options = [
        'All Orders',
        'Last 30 Days',
        'Last 3 Months',
        'Last 6 Months',
      ]
      let currentYear = new Date().getFullYear()
      for (let year = currentYear; year > currentYear - 5; year--) {
        options.push(`${year}`)
      }
      return options
    },
  },
  methods: {
    clearFilters() {
      if (this.$route.query?.sku) this.$router.replace({ query: null })
      this.inputFilters()
    },
    openDateRangePicker() {
      if (this.$refs.qDateProxy) this.$refs.qDateProxy.show()
    },
    onChangeUpcomingFilter(val) {
      if (val == 1 || val == 2) {
        let { startDate, endDate } = this.getDateRange(val)
        if (startDate && endDate) {
          this.selectedCustomRange = {
            from: startDate,
            to: endDate,
          }
        }
      } else if (val == 3) {
        let { startDate, endDate } = this.getDateRange(val)
        // When custom range is selected and start date and end date not available then default this month date will be considered
        if (!startDate && !endDate) {
          let currentDate = new Date()
          startDate = new Date(
            `${currentDate.getFullYear()}-${currentDate.getMonth() + 1}-1`
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            0
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          this.showDatePicker = true
          this.selectedCustomRange = {
            from: startDate,
            to: endDate,
          }
          this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
            page: this.filterKey,
            filter: {
              ...this.orderFilters,
              StartDate: startDate,
              EndDate: endDate,
            },
          })
        } else if (startDate && endDate) {
          this.showDatePicker = true
          this.selectedCustomRange = {
            from: startDate,
            to: endDate,
          }
        }
      }
      this.applyFilters('filterByUpcoming', val)
    },
    calculateOrderDate() {
      let currentDate = new Date()
      let startDate, endDate
      if (this.filterKey == 'order') {
        let orderfilter = this.orderFilters.selectedFilter
        switch (orderfilter) {
          case 'All Orders':
            break
          case 'Last 30 Days':
            startDate = new Date(
              new Date().setDate(currentDate.getDate() - 30)
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            endDate = currentDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            break
          case 'Last 3 Months':
            startDate = new Date(
              new Date().setDate(currentDate.getDate() - 90)
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            endDate = currentDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            break
          case 'Last 6 Months':
            startDate = new Date(
              new Date().setDate(currentDate.getDate() - 180)
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            endDate = currentDate.toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            break
          default:
            startDate = new Date(orderfilter, 0, 1).toLocaleDateString(
              'en-US',
              {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              }
            )
            endDate = new Date(orderfilter, 12, 0).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            break
        }
      } else if (this.filterKey == 'auto-ship-order') {
        let upcomingOrderFilter = this.orderFilters.filterByUpcoming
        switch (upcomingOrderFilter) {
          case 'Default':
            break
          case 1:
            let today = currentDate.getDay()
            let diffToMon = today == 0 ? 1 : 8 - today
            startDate = new Date(
              new Date().setDate(currentDate.getDate() + diffToMon)
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })

            endDate = new Date(
              new Date().setDate(currentDate.getDate() + 6 + diffToMon)
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })

            break
          case 2:
            startDate = new Date(
              `${currentDate.getFullYear()}-${currentDate.getMonth() + 2}-1`
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            endDate = new Date(
              currentDate.getFullYear(),
              currentDate.getMonth() + 2,
              0
            ).toLocaleDateString('en-US', {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            })
            break
          case 3:
            /* if (isString(this.selectedCustomRange)) {
              startDate = new Date(this.selectedCustomRange).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )
              endDate = new Date(this.selectedCustomRange).toLocaleDateString(
                'en-US',
                {
                  year: 'numeric',
                  month: '2-digit',
                  day: '2-digit',
                }
              )
            } else if (isObject(this.selectedCustomRange)) {
              startDate = new Date(
                this.selectedCustomRange?.from
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
              endDate = new Date(
                this.selectedCustomRange?.to
              ).toLocaleDateString('en-US', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })
            } */
            if (this.orderFilters?.StartDate && this.orderFilters?.EndDate) {
              startDate = this.orderFilters.StartDate
              endDate = this.orderFilters.EndDate
            }
            break
        }
      }
      this.startDate = startDate
      this.endDate = endDate
    },
    applyFilters(key, val) {
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: this.filterKey,
        filter: {
          ...this.orderFilters,
          [key]: val,
        },
      })

      let filterOptions = {
        searchby: this.localFilterSearchBy,
      }
      if (this.filterKey == 'order') {
        this.calculateOrderDate()
        filterOptions = {
          ...filterOptions,
          StartDate: this.startDate,
          EndDate: this.endDate,
          filterBy: this.orderFilters.selectedFilter,
        }
      } else if (this.filterKey == 'auto-ship-order') {
        this.calculateOrderDate()
        filterOptions = {
          ...filterOptions,
          StartDate: this.startDate,
          EndDate: this.endDate,
          filterByStatus: this.orderFilters.filterByStatus,
          filterByUpcoming: this.orderFilters.filterByUpcoming,
        }
      }

      if (filterOptions.filterByUpcoming == 3) {
        this.showDatePicker = true
      } else {
        this.showDatePicker = false
      }

      if (key == 'filterByUpcoming') {
        if (val != 3) this.$emit('filterModified', filterOptions)
        else if (
          val == 3 &&
          ((this.selectedCustomRange?.from && this.selectedCustomRange?.to) ||
            isString(this.selectedCustomRange))
        )
          this.$emit('filterModified', filterOptions)
      } else this.$emit('filterModified', filterOptions)
    },
    closeDatePicker() {
      this.showDatePicker = false
    },
    onOk() {
      if (isString(this.selectedCustomRange)) {
        this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
          page: this.filterKey,
          filter: {
            ...this.orderFilters,
            StartDate: this.selectedCustomRange,
            EndDate: this.selectedCustomRange,
          },
        })
      } else if (
        isObject(this.selectedCustomRange) &&
        this.selectedCustomRange.from &&
        this.selectedCustomRange.to
      )
        this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
          page: this.filterKey,
          filter: {
            ...this.orderFilters,
            StartDate: this.selectedCustomRange.from,
            EndDate: this.selectedCustomRange.to,
          },
        })
      this.applyFilters('filterByUpcoming', 3)
    },
    inputFilters() {
      this.$store.commit('order/SET_FILTER_SEARCH', this.localFilterSearchBy)
      let filterOptions = {
        searchby: this.localFilterSearchBy,
      }
      if (this.filterKey == 'order') {
        this.calculateOrderDate()
        filterOptions = {
          ...filterOptions,
          StartDate: this.startDate,
          EndDate: this.endDate,
          filterBy: this.orderFilters.selectedFilter,
        }
      } else if (this.filterKey == 'auto-ship-order') {
        filterOptions = {
          ...filterOptions,
          StartDate: this.startDate,
          EndDate: this.endDate,
          filterByStatus: this.orderFilters.filterByStatus,
          filterByUpcoming: this.orderFilters.filterByUpcoming,
        }
      }

      this.$emit('filterModified', filterOptions)
    },
    getDateRange(filterByUpcoming) {
      let currentDate = new Date()
      let startDate, endDate
      switch (filterByUpcoming) {
        case 'Default':
          break
        case 1:
          let today = currentDate.getDay()
          let diffToMon = today == 0 ? 1 : 8 - today
          startDate = new Date(
            new Date().setDate(currentDate.getDate() + diffToMon)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })

          endDate = new Date(
            new Date().setDate(currentDate.getDate() + 6 + diffToMon)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })

          break
        case 2:
          startDate = new Date(
            `${currentDate.getFullYear()}-${currentDate.getMonth() + 2}-1`
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 2,
            0
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        case 3:
          if (this.orderFilters?.StartDate && this.orderFilters?.EndDate) {
            startDate = this.orderFilters.StartDate
            endDate = this.orderFilters.EndDate
          }
          break
      }

      return { startDate, endDate }
    },
  },
  created() {
    if (this.filterKey == 'order') {
      let options = [
        'All Orders',
        'Last 30 Days',
        'Last 3 Months',
        'Last 6 Months',
      ]
      let currentYear = new Date().getFullYear()
      for (let year = currentYear; year > currentYear - 5; year--) {
        options.push(`${year}`)
      }
      // this.$store.commit('persistedLocal/SET_ORDER_FILTER_OPTIONS', options)
      this.$store.commit('persistedLocal/SET_FILTER_BY_PAGE', {
        page: 'order',
        filter: {
          ...this.orderFilters,
          filterOptions: options,
        },
      })
    } else if (this.filterKey == 'auto-ship-order') {
      let filterByUpcoming = this.orderFilters.filterByUpcoming
      if (filterByUpcoming == 1 || filterByUpcoming == 2) {
        let { startDate, endDate } = this.getDateRange(filterByUpcoming)
        if (startDate && endDate) {
          this.selectedCustomRange = {
            from: startDate,
            to: endDate,
          }
        }
      } else if (filterByUpcoming == 3) {
        let { startDate, endDate } = this.getDateRange(filterByUpcoming)
        if (startDate && endDate) {
          let sTime = new Date(startDate).getTime(),
            eTime = new Date(endDate).getTime(),
            isSameDate = sTime == eTime

          this.showDatePicker = true
          if (isSameDate) this.selectedCustomRange = startDate
          else {
            this.selectedCustomRange = {
              from: startDate,
              to: endDate,
            }
          }
        }
      }
    }
  },
}
</script>

<style lang="scss"></style>
