<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-md"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                Paying Cancellation Charge
              </h4>
              <div class="text-subtitle1 line-height-15 text-black">
                The following payment method is linked with your subscription
              </div>
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center q-pa-none q-pt-lg"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
        >
          <q-form @submit="onSubmit">
            <div class="row">
              <div class="col-12 col-md-8 offset-md-2">
                <div class="b-1 b-cc bg-grey-1 r-5 q-pa-md text-left q-mb-md">
                  <div class="row q-col-gutter-md items-center">
                    <div class="col-12">
                      <div
                        class="text-subtitle1 text-tertiary line-height-normal text-weight-bold"
                      >
                        Payment Method
                      </div>
                    </div>
                    <div class="col-12" v-if="properties.cardDetails">
                      <div
                        class="text-caption text-uppercase line-height-normal"
                        >Credit or Debit Card</div
                      >
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal"
                      >
                        <img
                          :src="`images/payment/${
                            getCardType(properties.cardDetails.cardType).logo
                          }`"
                          :title="
                            getCardType(properties.cardDetails.cardType).title
                          "
                          class="q-my-sm q-mr-xs vm"
                        />
                        {{ properties.cardDetails?.cardHolderName }}<br />
                      </div>
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal q-mb-sm"
                      >
                        Ending in {{ properties.cardDetails.cardLast4Digits }}
                      </div>
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal"
                      >
                        Expires on {{ properties.cardDetails.cardExpiry }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <p class="text-left">
                  Subscription Cancellation Charge:
                  <span class="text-negative text-weight-bold">{{
                    Math.abs(properties.discountTotal) | currency
                  }}</span>
                </p>
                <div class="bg-grey-2 q-pa-sm r-6 text-left text-14 q-mb-md">
                  <q-checkbox
                    dense
                    v-model="isAccepted"
                    label="I acknowledge and agree to charge my card for the subscription cancellation charges."
                  />
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-action">
                  <div
                    class="row q-col-gutter-md justify-center"
                    :class="isMobile ? '' : 'q-pb-lg'"
                  >
                    <div class="col-auto col-md-4">
                      <q-btn
                        unelevated
                        no-caps
                        outline
                        class="q-btn-lg full-width"
                        v-close-popup
                      >
                        Cancel
                      </q-btn>
                    </div>
                    <div class="col-auto col-md-4">
                      <q-btn
                        unelevated
                        no-caps
                        color="primary"
                        class="q-btn-lg full-width"
                        type="submit"
                        :loading="loading"
                        :disable="!isAccepted"
                      >
                        Proceed
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { getCardType } from 'src/utils'

function initialState() {
  return {
    dialog: 'CancellationPaymentDialog',
    isAccepted: false,
    loading: false,
  }
}

export default {
  name: 'CancellationPaymentDialog',
  data: function () {
    return initialState()
  },
  computed: {
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    getCardType: getCardType,
    onHide() {
      Object.assign(this.$data, initialState())
    },
    onSubmit() {
      this.loading = true
      let reqObj = {
        id: this.properties.subscriptionID,
        params: {
          actionType: this.properties.actionType,
        },
      }
      this.$store
        .dispatch('order/changeSubscriptionOption', reqObj)
        .then((response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing(response.data.subscriptionId)
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.changeDialogState({
              dialog: 'CancelSubscriptionDialog',
              val: false,
            })
            this.showSuccess(
              'Your subscription has been successfully canceled.'
            )
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
