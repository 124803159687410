import { render, staticRenderFns } from "./AddOrderItemDialog.vue?vue&type=template&id=118e0afe"
import script from "./AddOrderItemDialog.vue?vue&type=script&lang=js"
export * from "./AddOrderItemDialog.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QTree from 'quasar/src/components/tree/QTree.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QRadio from 'quasar/src/components/radio/QRadio.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QList from 'quasar/src/components/item/QList.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QDialog,QCard,QBtn,QScrollArea,QTree,QItem,QItemSection,QRadio,QItemLabel,QBadge,QInput,QIcon,QList,QImg,QInfiniteScroll});qInstall(component, 'directives', {ClosePopup,Ripple});
