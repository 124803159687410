<template>
  <div class="text-layer-container" v-html="rHtml" />
</template>

<script>
export default {
  name: 'TextComponent',
  props: {
    component: {
      type: Object,
      default: new Object(),
    },
  },
  computed: {
    domId() {
      return `text-editor-${this.component.id}`
    },
    rHtml() {
      const cViewId = this.isMobile ? 'mobile' : 'desktop',
        currentAlignment = this.component.alignment?.[cViewId],
        htmlTag = this.component.htmlTag,
        typography =
          this.component.typography || this.component.defaultTypography || {},
        fontSize = typography.fontSize?.[cViewId] || null,
        lineHeight = typography.lineHeight?.[cViewId] || null,
        letterSpacing = typography.letterSpacing?.[cViewId] || null

      let styleString = ''

      if (currentAlignment) styleString += `text-align:${currentAlignment};`

      const textColor =
        this.component.textColor || this.component.defaultTextColor

      if (textColor)
        styleString += `color:${
          textColor.custom
            ? textColor.color
            : `var(--q-color-${textColor.color});`
        };`

      if (typography.fontWeight)
        styleString += `font-weight:${typography.fontWeight};`

      if (typography.textTransform)
        styleString += `text-transform:${typography.textTransform};`

      if (typography.fontStyle)
        styleString += `font-style:${typography.fontStyle};`

      if (typography.textDecoration)
        styleString += `text-decoration:${typography.textDecoration};`

      if (fontSize) styleString += `font-size:${fontSize}px;`

      if (lineHeight) styleString += `line-height:${lineHeight};`

      if (letterSpacing) styleString += `letter-spacing:${letterSpacing}px;`

      return `<${htmlTag} style="${styleString}" id="${this.domId}" class="editable layer-inline-editor q-pa-none q-ma-none">${this.component.text}</${htmlTag}>`
    },
    // redirectLink() {
    //   if (this.component.linkType && this.component.seoName)
    //     return `/${this.component.linkType}/${this.component.seoName}`

    //   return null
    // },
    // canShowImageComponent() {
    //   return (
    //     !this.component.isAgeRestricted ||
    //     (this.component.isAgeRestricted &&
    //       (!this.storeAgeVerificationRequired ||
    //         (this.storeAgeVerificationRequired && this.isUserAgeVerified)))
    //   )
    // },
  },
}
</script>
