<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    :className="properties.isMinRecurringCompleted ? 'dialog-md' : 'dialog-lg'"
    :before-show="onShow"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                Cancel Subscription
              </h4>
              <div
                class="text-subtitle1 line-height-15 text-black"
                v-if="properties.isMinRecurringCompleted"
              >
                You're about to cancel your subscription for this product. This
                means you will no longer receive scheduled auto-ship orders.
              </div>
              <div class="text-subtitle1 line-height-15 text-black" v-else>
                You are attempting to cancel your subscription before completing
                the required
                <strong
                  >{{ properties.minOrderRecurring }} recurring orders</strong
                >.<br />The subscription discount is offered exclusively when
                customers subscribe to a product through our e-commerce
                platform.
                <span v-show="discountDetails">
                  <br />So far, you have completed
                  <strong
                    >{{ totalOrderCount }}
                    {{ totalOrderCount > 1 ? 'orders' : 'order' }}
                  </strong>
                  with the subscription discount.
                </span>
              </div>
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center q-pa-none q-pt-lg"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
          v-if="properties.isMinRecurringCompleted"
        >
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="text-18 text-weight-bold text-black"
                >Are you sure you want to proceed?</div
              >
              <div class="form-action q-pt-md">
                <div
                  class="row q-col-gutter-md"
                  :class="isMobile ? '' : 'q-pb-lg'"
                >
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      outline
                      class="q-btn-lg full-width"
                      v-close-popup
                    >
                      No
                    </q-btn>
                  </div>
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      color="primary"
                      class="q-btn-lg full-width"
                      @click="cancelSubscription"
                      :loading="loading"
                    >
                      Yes
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <template v-else>
          <LoadMoreSpinner v-show="loading" />
          <q-card-section
            class="text-center q-pa-none"
            :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            v-show="!loading && discountDetails"
          >
            <q-table
              :data="discountData"
              :columns="columns"
              row-key="orderID"
              separator="horizontal"
              bordered
              flat
              hide-bottom
              class="table-subsctiption q-my-md"
              table-header-class="bg-grey-1 text-uppercase"
            >
              <template v-slot:body="props">
                <q-tr :props="props">
                  <q-td key="orderNumber" :props="props">
                    <router-link
                      :to="{
                        name: 'OrderDetail',
                        params: { orderId: props.row.orderID },
                      }"
                      class="text-underline text-weight-bold"
                      >{{ props.row.orderNumber }}</router-link
                    >
                    <br />
                    <small>{{ props.row.orderDate | formatDate }}</small>
                  </q-td>
                  <q-td key="discountedAmount" :props="props">
                    <span class="text-positive">{{
                      Math.abs(props.row.discountedAmount) | currency
                    }}</span>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
            <div class="text-subtitle1 line-height-15 text-black">
              If you proceed, the discount you've received will be reversed as
              <strong>subscription cancellation charges</strong>.<br />If you
              want to cancel the subscription without paying cancellation
              charges, hit the <strong>Cancel</strong> button and contact
              customer support.
              <a
                :href="`tel:${businessConfigurations.customerServicePhone}`"
                class="full-width justify-center text-primary no-underline"
                v-show="businessConfigurations.customerServicePhone"
                ><span class="text-body1">{{
                  businessConfigurations.customerServicePhone
                }}</span></a
              >
            </div>
          </q-card-section>

          <q-card-section
            class="text-center q-pa-none q-pt-lg"
            :class="isMobile ? 'q-px-md' : 'q-px-xl'"
          >
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="text-18 text-weight-bold text-black"
                  >Are you sure you want to proceed?</div
                >
                <div class="form-action q-pt-md">
                  <div
                    class="row q-col-gutter-md"
                    :class="isMobile ? '' : 'q-pb-lg'"
                  >
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        outline
                        class="q-btn-lg full-width"
                        v-close-popup
                      >
                        Cancel
                      </q-btn>
                    </div>
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        color="primary"
                        class="q-btn-lg full-width"
                        @click="cancellationPaymentConfirm"
                        :disable="loading"
                      >
                        Confirm Cancellation
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-card-section>
        </template>
      </q-card>
      <!-- With Cancelling Charge -->
      <CancellationPaymentDialog />
    </template>
  </ftx-dialog>
</template>
<script>
function initialState() {
  return {
    dialog: 'CancelSubscriptionDialog',
    loading: false,
    columns: [
      {
        name: 'orderNumber',
        required: true,
        label: 'Order Info',
        align: 'left',
        field: 'orderNumber',
      },
      {
        name: 'discountedAmount',
        align: 'center',
        label: '$ Discount Applied',
        field: 'discountedAmount',
      },
    ],
    discountDetails: null,
  }
}

export default {
  name: 'CancelSubscriptionDialog',
  data: function () {
    return initialState()
  },
  components: {
    CancellationPaymentDialog: () =>
      import('components/subscription/CancellationPaymentDialog.vue'),
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    discountData() {
      return this.discountDetails ? this.discountDetails.discountDetail : []
    },
    totalOrderCount() {
      return this.discountDetails ? this.discountDetails?.orderCount : 0
    },
  },
  methods: {
    onShow() {
      if (this.properties?.isMinRecurringCompleted === false) {
        // hideShowFullSpinner(true)
        this.loading = true
        let reqObj = {
          subscriptionId: this.properties?.subscriptionID,
        }
        this.$store
          .dispatch('order/getSubscriptionDiscounts', reqObj)
          .then((response) => {
            if (response.success) {
              this.discountDetails = response.data
            }
          })
          .finally(() => {
            // hideShowFullSpinner(false)
            this.loading = false
          })
      }
    },
    onHide() {
      Object.assign(this.$data, initialState())
    },
    cancelSubscription() {
      this.loading = true
      let reqObj = {
        id: this.properties?.subscriptionID,
        params: {
          actionType: 5,
        },
      }
      this.$store
        .dispatch('order/changeSubscriptionOption', reqObj)
        .then((response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing(response.data.subscriptionId)
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.showSuccess(
              'Your subscription has been successfully canceled.'
            )
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
    cancellationPaymentConfirm() {
      this.changeDialogState({
        dialog: 'CancellationPaymentDialog',
        parentDialog: 'CancelSubscriptionDialog',
        val: true,
        properties: {
          subscriptionID: this.properties.subscriptionID,
          actionType: 5,
          cardDetails: this.properties.cardDetails,
          refreshListing: this.properties.refreshListing,
          discountTotal: this.discountDetails.discountTotal,
        },
      })
    },
  },
}
</script>
