<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-md"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                Pause Subscription
              </h4>
              <div class="text-subtitle1 line-height-15 text-black">
                If you pause your subscription, all scheduled orders will be
                halted until you manually resume it. When you choose to resume,
                you will have the option to reset the subscription schedule.
              </div>
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center q-pa-none q-pt-lg"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
        >
          <div class="row">
            <div class="col-12 col-md-12">
              <div class="text-18 text-weight-bold text-black"
                >Are you sure you want to pause your subscription now?</div
              >
              <div class="form-action q-pt-md">
                <div
                  class="row q-col-gutter-md"
                  :class="isMobile ? '' : 'q-pb-lg'"
                >
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      outline
                      class="q-btn-lg full-width"
                      v-close-popup
                    >
                      Cancel
                    </q-btn>
                  </div>
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      color="primary"
                      class="q-btn-lg full-width"
                      @click="pauseSubscription"
                      :loading="loading"
                    >
                      Pause Subscription
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
export default {
  name: 'PauseSubscriptionDialog',
  data() {
    return {
      dialog: 'PauseSubscriptionDialog',
      loading: false,
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    async pauseSubscription() {
      this.loading = true
      let reqObj = {
        id: this.properties?.subscriptionID,
        params: {
          actionType: 4,
        },
      }
      await this.$store
        .dispatch('order/changeSubscriptionOption', reqObj)
        .then(async (response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing(response.data.subscriptionId)
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.showSuccess('Your subscription is paused for now.')
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
