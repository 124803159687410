export function orderDetailById(state) {
  return (orderNumber) => {
    return state.orders[orderNumber] ? state.orders[orderNumber] : {}
  }
}

export const recentOrderNumber = (state) => state.recentOrderNumber

export function orderInfoById(state) {
  return (orderId) => {
    return state.detail[orderId] ? state.detail[orderId] : {}
  }
}

export function trackOrderDetailById(state) {
  return (orderId) => {
    if (state.recentUpdatedOrder) return state.recentUpdatedOrder

    return state.trackOrderDetail[orderId]
      ? state.trackOrderDetail[orderId]
      : {}
  }
}
export function subscriptionInfoById(state) {
  return (subscriptionId) => {
    return state.subscriptionDetail[subscriptionId]
      ? state.subscriptionDetail[subscriptionId]
      : ''
  }
}
export function editOrderInfoById(state) {
  return (orderId) => {
    return state.editDetail[orderId] ? state.editDetail[orderId] : {}
  }
}

export const selectedRecentSubstituteProduct = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return (orderLineItemId, preferredSubstituteProductID) => {
    if (
      rootGetters['cart/recentSubstituteDetail'] &&
      rootGetters['cart/recentSubstituteDetail'].hasOwnProperty(orderLineItemId)
    )
      return rootGetters['cart/recentSubstituteDetail'][orderLineItemId]
        .selectedSubstituteProduct

    let data = Object.values(rootGetters['cart/recentSubstituteDetail']).find(
      (x) =>
        x.orderLineItemID == orderLineItemId ||
        x.previousOrderLineItemID == orderLineItemId
    )
    if (data) return data.selectedSubstituteProduct
    else if (preferredSubstituteProductID) return preferredSubstituteProductID

    return '00000000-0000-0000-0000-000000000000'
  }
}

export function slotInfoById(state) {
  return (orderId) => {
    return state.slotInfo[orderId] || null
  }
}

export function isSubstitutionAllowedByOrderId(state, getters) {
  return (orderId) => {
    let isTrueFound = false,
      isFalseFound = false,
      orderInfo = getters.editOrderInfoById(orderId)

    if (
      orderInfo &&
      orderInfo.trackOrderLineItems &&
      orderInfo.codeName != 'sd'
    ) {
      for (let item of orderInfo.trackOrderLineItems) {
        if (item.isDeleted) {
          continue
        }
        if (item.allowSubstitute) {
          isTrueFound = true
        } else {
          isFalseFound = true
        }
      }

      if (isTrueFound && isFalseFound) {
        return null
      }
    }
    return isTrueFound
  }
}

export const categories = (state) => state.categories

export const recentItems = (state) => state.recentItems

export const recentUpdatedOrder = (state) => state.recentUpdatedOrder

export const parkingSpots = (state) => state.parkingSpots

export const loadingSlot = (state) => state.loadingSlot

export const alertData = (state) => (alertKey) =>
  state.alertData[alertKey] || null

export function getUpdateTimeSlotByOrderId(state) {
  return (orderId) => {
    return !!state.updateTimeSlot[orderId]
  }
}

export function getPaymentDetailsForFulfillment(state, getters) {
  return (fulfillmentCode) => {
    let orderDetail = getters.orderDetailById(getters.recentOrderNumber)
    let paymentType = 'CARD'

    if (orderDetail && orderDetail.orderFulfillments) {
      orderDetail.orderFulfillments.every(function (fulfillment) {
        if (
          fulfillment.codeName == fulfillmentCode &&
          fulfillment.isPayAtStore
        ) {
          paymentType = 'PAY_AT_STORE'
          return false
        }
        return true
      })
    }
    return paymentType
  }
}

export const filterSearchBy = (state) => state.filterSearchBy
