<template>
  <q-page class="cartPage" :style-fn="cartHeight">
    <div class="page-content">
      <div class="container">
        <div class="row" v-if="!isMobile">
          <Alert
            className="col-md-6 q-mt-sm"
            dismissible
            v-if="alertShow"
            :variant="editCartAlert.variant"
            @dismissed="
              () => {
                this.alertShow = 0
              }
            "
            @dismiss-count-down="
              (countDown) => {
                this.alertShow = 5
              }
            "
            :show="alertShow"
          >
            <span v-html="editCartAlert.message" />
          </Alert>
        </div>
        <template v-if="currentCartDetails?.fulfillments">
          <ShowAlert v-if="!isMobile" />
          <div class="sectionInner">
            <div class="sectionLeft cart-section">
              <div
                v-for="(
                  fulFillment, key, index
                ) in currentCartDetails.fulfillments"
                :key="`${fulFillment.fulfillmentMethodID}-${key}-${index}`"
              >
                <!-- Cart Page Header -->
                <div class="cart-header flex justify-between items-end">
                  <div class="cart-title flex items-center">
                    <h2>
                      Your Cart ({{ currentCartDetails.totalItems }} items)
                      <span>
                        Your
                        {{
                          selectedFulfillmentCodeName == 'sp'
                            ? 'pickup'
                            : selectedFulfillmentCodeName == 'scd'
                            ? 'delivery'
                            : 'shipping'
                        }}
                        choices
                      </span>
                    </h2>
                  </div>
                  <div class="cart-time row items-center">
                    <q-avatar flat>
                      <SvgIcon
                        color="primaryOnBody"
                        :icon="fulFillment.codeName"
                      />
                    </q-avatar>
                    <div class="flex column">
                      <template v-if="formatBookedSlot(fulFillment.codeName)">
                        <strong>
                          Time Slot
                          <a
                            href="javascript:void(0)"
                            @click="
                              (event) => {
                                openReserSlot(
                                  event,
                                  fulFillment.codeName.toUpperCase()
                                )
                              }
                            "
                            class="text-secondary"
                          >
                            Edit
                          </a>
                        </strong>
                        <span class="caption">
                          {{ formatBookedSlot(fulFillment.codeName) }}
                        </span>
                      </template>
                      <div
                        v-else
                        class="text-subtitle1 text-weight-bold text-dark"
                        >{{ fulFillment.displayName }}</div
                      >
                    </div>
                  </div>
                </div>
                <!-- Cart Page Header -->

                <!-- Allowed Substitution -->
                <AllowSubstitutions v-if="showSubstitution" />
                <!-- Allowed Substitution -->

                <div class="cart-order-list">
                  <CartLineItemNew
                    v-for="(
                      shoppingCartItem, index
                    ) in fulFillment.shoppingCartItems"
                    :key="`${shoppingCartItem.orderLineItemID}-${index}`"
                    :shoppingCartItem="shoppingCartItem"
                    :fulFillmentCodeName="fulFillment.codeName"
                    :fulFillmentId="fulFillment.fulfillmentMethodID"
                    :toggleSubstitute="toggleSubstitute"
                    :substitute="substitute"
                  />
                </div>

                <MinimumCheckoutError
                  :errorId="`${fulFillment.fulfillmentMethodID}-error`"
                  :fulFillment="fulFillment"
                  :orderSummary="currentCartDetails.orderSummary"
                />
              </div>
            </div>
            <OrderSummary
              show-lns-option
              :currentCartDetails="currentCartDetails"
              :showEmptyCart="true"
              :emptyCartAction="emptyCart"
              :currentCardId="currentCartNumber"
              :proceedCheckout="proceedCheckout"
              :checkMinCheckoutAmount="checkMinCheckoutAmount"
            />
          </div>
        </template>
        <EmptyComponent
          v-else-if="!loading"
          image="images/empty-cart.png"
          btnText="Continue Shopping"
          to="/"
        >
          <p>Your cart is currently empty.</p>
        </EmptyComponent>
        <template v-else>
          <div style="height: calc(100vh - 150px)"></div>
        </template>
      </div>
    </div>
    <ItemInstructionMobile v-if="isMobile" />
    <TriggerListDialog />
    <AlreadySubscribedProducts />
  </q-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import {
  validateMinCheckoutAmount,
  cartHasFulfillment,
  hideShowFullSpinner,
} from 'src/utils'
import OrderSummary from 'components/cart/OrderSummary'
import AllowSubstitutions from 'components/checkout/AllowSubstitutions'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'
import IdentityMixin from 'src/utils/IdentityMixin'

export default {
  name: 'PageCart',
  mixins: [IdentityMixin],
  /* preFetch({ store, currentRoute, redirect }) {
    let { redirectFrom, orderNumber } = currentRoute.query,
      isLoggedIn = store.getters['auth/isLoggedIn'],
      cartDetails = store.getters['cart/currentCartDetails']

    let promise = []

    if (redirectFrom === 'email' && !isLoggedIn && !cartDetails) {
      promise.push(
        store
          .dispatch('auth/signIn')
          .then((user) => {
            if (this.isCordova && user) {
              this.$router.push({ name: 'Callback' })
            }
          })
          .catch((error) => console.log(error))
      )
    } else if (!isLoggedIn && !cartDetails) {
      if (orderNumber) store.commit('guest/SET_GUEST_CART_NUMBER', orderNumber)
    }
  }, */
  computed: {
    ...mapGetters('cart', [
      'currentCartDetails',
      'substituteDetails',
      'recentSubstituteDetail',
      'currentCartNumber',
      'substitute',
      'showSubstitution',
      'loading',
      'cartHasInStore',
      'cartHasCurbside',
      'getSubscribedProducts',
      'isConfirmSubscription',
    ]),
    ...mapGetters('address', [
      'defaultAddress',
      'getAddressByCustomerAddressID',
    ]),
    ...mapGetters('common', ['isSlotBookingRequired']),
    ...mapGetters('reserveSlot', [
      'formatBookedSlot',
      'customerSPSlotId',
      'customerSCDSlotId',
    ]),
    ...mapGetters('locationDetail', ['location']),
    ...mapGetters('persisted', [
      'eaivPersistedSessionId',
      'eaivPersistedVerificationSuccess',
    ]),
    ...mapGetters('ageVerification', ['isVerificationFail', 'isOrderVerified']),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    checkoutBtnName() {
      return this.businessConfigurations?.isStoreAgeRestricted &&
        this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.currentCartDetails?.ageRequired > 0 &&
        !this.isOrderVerified
        ? 'Verify Age and Checkout'
        : 'Proceed to Checkout'
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
  },
  mounted() {
    /* Start: prefetch code */
    let { redirectFrom, orderNumber } = this.$route.query
    if (
      redirectFrom === 'email' &&
      !this.isLoggedIn &&
      !this.currentCartDetails
    ) {
      this.$store
        .dispatch('auth/signIn')
        .then((user) => {
          if (this.isCordova && user) {
            this.$router.push({ name: 'Callback' })
          }
        })
        .catch((error) => console.log(error))
    } else if (!this.isLoggedIn && !this.currentCartDetails) {
      if (orderNumber)
        this.$store.commit('guest/SET_GUEST_CART_NUMBER', orderNumber)
      this.$store.dispatch('reserveSlot/getCustomerBlockedSlots')
    }

    this.$store.dispatch('cart/getCartDetail', {
      showFullLoader: !this.currentCartDetails || true,
    })
    /* End: prefetch code */

    this.setHeaderHeight()

    if (!this.isLoggedIn && !this.businessConfigurations?.enableGuestCheckout) {
      this.showError(
        'Guest Checkout is temporarily off. You need to Sign up/Sign in to place the order.',
        5000
      )
    }
  },
  data() {
    return {
      promoText: '',
      editCartAlert: {},
      alertShow: 0,
      checkboxTrue: true,
      checkboxTrue1: true,
      checkboxTrue2: true,
      checkboxTrue3: true,
      checkboxTrue4: true,
      radioTrue: true,
      radioTrue1: true,
      radioTrue2: true,
      radioTrue3: true,
      radioTrue4: true,
    }
  },
  components: {
    OrderSummary,
    AllowSubstitutions,
    // lazy load component
    ShowAlert: () => import('components/cart/ShowAlert'),
    CartLineItemNew: () => import('components/cart/CartLineItemNew'),
    MinimumCheckoutError: () => import('components/cart/MinimumCheckoutError'),
    ItemInstructionMobile: () =>
      import('components/dialog/ItemInstructionMobile.vue'),
    TriggerListDialog: () => import('components/discount/TriggerListDialog'),
    AlreadySubscribedProducts: () =>
      import('components/cart/AlreadySubscribedProducts'),
  },
  meta() {
    return {
      title: 'Cart',
      ...this.commonMeta,
    }
  },
  created() {
    this.$root.$on('eaivVerificationSuccess', this.onEaivSuccess)
    this.$root.$on('eaivVerificationFailed', this.onEaivFail)
  },
  methods: {
    ...mapActions('cart', ['toggleSubstitute']),
    openReserSlot(event, bookingType) {
      event.stopPropagation()

      this.changeDialogState({
        dialog: 'reserveTimeslot',
        val: true,
        properties: {
          activeTab: bookingType,
        },
      })
    },
    emptyCart() {
      this.ftxConfirm('Are you sure you want to empty your cart?').onOk(() => {
        this.$store.dispatch('cart/emptyCart').then(() => {
          this.$router
            .push({
              name: 'Home',
            })
            .catch(() => {})
        })
      })
    },
    checkMinCheckoutAmount() {
      let isValidCheckoutAmount = validateMinCheckoutAmount(
        this.currentCartDetails
      )

      if (!isValidCheckoutAmount.valid) {
        var errorDomElement = document.getElementById(
          `${isValidCheckoutAmount.fulfillmentMethodID}-error`
        )
        if (errorDomElement) {
          this.$root.$emit(
            'scroll-to-position',
            errorDomElement.offsetTop - document.body.offsetHeight / 2
          )

          errorDomElement.classList.add('zoomFocus')

          setTimeout(() => {
            errorDomElement.classList.remove('zoomFocus')
          }, 2000)
        }

        return false
      }

      return true
    },
    verifyAgeAndCheckout() {
      if (
        this.businessConfigurations?.isStoreAgeRestricted &&
        this.businessConfigurations?.enableAgeRestrictedCheckout &&
        this.currentCartDetails?.ageRequired > 0 &&
        !this.isOrderVerified
      ) {
        this.triggerEAIVFlow()
      } else this.proceedCheckout()
    },
    async proceedCheckout() {
      if (this.loading) return
      let params = Object.values(this.recentSubstituteDetail),
        resetSubstitution = false
      params = params.map((item) => {
        let { orderLineItemID, allowSubstitute } = item
        return allowSubstitute &&
          item.selectedSubstituteProduct &&
          item.selectedSubstituteProduct !=
            '00000000-0000-0000-0000-000000000000'
          ? {
              orderLineItemID,
              allowSubstitute,
              substituteProductID: item.selectedSubstituteProduct || null,
            }
          : { orderLineItemID, allowSubstitute, substituteProductID: null }
      })
      if (params.length)
        resetSubstitution = await this.$store.dispatch(
          'cart/updateSubstitution',
          params,
          { resetSubstitution: false }
        )

      if (!this.checkMinCheckoutAmount()) return false

      let slotBookingRequiredType

      if (
        this.isSlotBookingRequired &&
        !this.customerSPSlotId &&
        cartHasFulfillment(this.currentCartDetails, 'sp')
      ) {
        slotBookingRequiredType = 'SP'
      }

      if (
        !slotBookingRequiredType &&
        this.isSlotBookingRequired &&
        !this.customerSCDSlotId &&
        cartHasFulfillment(this.currentCartDetails, 'scd')
      ) {
        slotBookingRequiredType = 'SCD'
      }

      if (slotBookingRequiredType) {
        this.changeDialogState({
          dialog: 'reserveTimeslot',
          val: true,
          properties: {
            activeTab: slotBookingRequiredType,
            bookType: 'checkout',
          },
        })

        return false
      }

      // Fetch active subscription and show in popup
      try {
        if (
          this.getSubscribedProducts.length &&
          this.selectedFulfillmentCodeName != 'sp'
        ) {
          if (!this.isLoggedIn) {
            this.changeDialogState({
              dialog: 'accountDrawer',
              val: true,
            })
            return false
          } else if (!this.isConfirmSubscription) {
            hideShowFullSpinner(true)
            const response = await this.$store.dispatch(
              'cart/getAlreadySubscribeProducts',
              {
                orderNumber: this.currentCartNumber,
                locationId: this.currentLocationId,
              }
            )

            if (response?.data?.length) {
              this.changeDialogState({
                dialog: 'AlreadySubscribedProducts',
                properties: {
                  alreadySubscribedProducts: { ...response.data },
                  proceedCheckout: this.proceedCheckout,
                },
                val: true,
              })
              return
            }
          }
        }
      } catch (err) {
        console.log('err', err)
      } finally {
        hideShowFullSpinner(false)
      }

      this.$router
        .push({
          name: 'Checkout',
        })
        .then(() => {
          if (resetSubstitution)
            this.$store.commit('cart/RESET_RECENT_SUBSTITUTE')
          if (this.isConfirmSubscription)
            this.changeDialogState({
              dialog: 'AlreadySubscribedProducts',
              val: false,
            })
        })
        .catch(() => {})
    },
    showCartSuccessAlert(alertData) {
      this.editCartAlert = alertData
      this.alertShow = 1
      this.$root.$emit('scroll-to-position')
    },
    cartHeight(offset) {
      //return { minHeight: offset ? `calc(100vh - ${offset}px)` : '100vh' }
      return { minHeight: offset ? `calc(100vh - 187px)` : '100vh' }
    },
    triggerEAIVFlow() {
      if (!this.isLoggedIn) {
        // Fetch personalInfo and Prefill inputs
        if (
          this.eaivPersistedVerificationSuccess &&
          this.eaivPersistedSessionId
        ) {
          hideShowFullSpinner(true)
          this.$store
            .dispatch(
              'ageVerification/checkStatus',
              this.eaivPersistedSessionId
            )
            .then((response) => {
              if (response.success && response.data) {
                if (
                  response.data?.isVerified === true &&
                  response.data?.personalInfo
                ) {
                  const personalInfo = response.data.personalInfo
                  let dob = personalInfo.dob
                    ? this.$options.filters.formatDate(
                        personalInfo.dob.split('T')[0],
                        'YYYY-MM-DD'
                      )
                    : ''

                  let reqData = {
                    collectPersonalInfo: false,
                    personalInfo: { ...personalInfo, dob },
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                } else {
                  let reqData = {
                    collectPersonalInfo: true,
                    redirectUri: this.getRedirectUri,
                    verificationDuring: 1,
                    customerID: null,
                  }

                  this.generateSession(reqData, !!!this.isCordova)
                }
              }
            })
            .catch(() => hideShowFullSpinner(false))
        } else {
          let reqData = {
            collectPersonalInfo: true,
            redirectUri: this.getRedirectUri,
            verificationDuring: 1,
            customerID: null,
          }

          this.generateSession(reqData, !!!this.isCordova)
        }
      } else {
        let reqData = {
          redirectUri: this.getRedirectUri,
          verificationDuring: 1,
          customerID: null,
        }
        if (this.customer?.isVerifiedOnline) {
          reqData.collectPersonalInfo = false
          reqData.personalInfo = this.personalInfoObj()
        } else {
          reqData.collectPersonalInfo = true
          reqData.preFillInformation = this.personalInfoObj()
        }

        this.generateSession(reqData, !!!this.isCordova)
      }
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      // else {
      //   this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      //   removeAVSessionID()
      // }

      if (!isSuccess) this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params })
    },
    async onEaivSuccess({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Cart') {
        if (!eaivSessionId || !routeName) return
        if (!this.currentCartNumber)
          await this.$store.dispatch('cart/getCartDetail', {
            showFullLoader: true,
          })
        this.$store.commit(
          'ageVerification/SET_CURRENT_CART_NUMBER',
          this.currentCartNumber
        )
        if (isPopup) tempCacheStorage.clear()
        else this.removeQueryParams(eaivSessionId, true)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
          this.proceedCheckout()
        }, 500)
      }
    },
    onEaivFail({ eaivSessionId, routeName, isPopup = false }) {
      if (routeName == 'Cart') {
        if (!eaivSessionId || !routeName) return
        if (isPopup) tempCacheStorage.clear()
        else this.removeQueryParams(eaivSessionId, false)

        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {
              redirectUri: this.getRedirectUri,
              verificationDuring: 1,
              customerID: null,
              routeName,
            },
          })
        }, 500)
      }
    },
    generateSession(reqData, isPopup = false) {
      hideShowFullSpinner(true)
      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (isPopup || (!this.isCordova && 'FTXIdentityLite' in window)) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }
              this.initEAIVPopup(payloadObj)
            } else if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    triggerEAIVPopup() {
      let reqData = {
        collectPersonalInfo: !!!this.isLoggedIn,
        redirectUri: this.getRedirectUri,
        verificationDuring: 1,
        customerID: null,
      }

      this.generateSession(reqData, true)
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
  },
}
</script>
<style lang="scss">
.cartPage {
  .page-title {
    margin-bottom: 10px;

    h1 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      line-height: normal;
      color: #0f141a;

      span {
        margin-left: 5px;
        font-size: 14px;
        font-family: 'Lato', sans-serif;
        color: #888888;
      }

      @media (min-width: 1440px) {
        font-size: 20px;

        span {
          font-size: 15px;
        }
      }

      @media (min-width: 1681px) {
        font-size: 24px;

        span {
          font-size: 18px;
        }
      }

      @media (max-width: 599px) {
        span {
          display: block;
        }
      }
    }
  }

  /* Content */
  .page-content {
    padding: 20px 0;

    @media (min-width: 1024px) {
      padding: 40px 0;
    }
  }

  .sectionInner {
    display: flex;
    flex-direction: column;
    margin-left: -15px;
    margin-right: -15px;

    @media (min-width: 1024px) {
      flex-direction: row;
    }

    .sectionLeft {
      flex: 10000 0 0%;
      padding: 0 15px;

      @media (max-width: 767px) {
        padding-bottom: 90px;

        .orderSubscription + div {
          .cartToggle {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

.product-cart {
  &.no-rounder {
    &:hover {
      .added-cart,
      .add-cart.addDelivery {
        border-radius: 0px 0px 5px 5px;

        .icon {
          border-top-left-radius: 0px;
          border-bottom-left-radius: 4px;
        }
      }
    }
  }

  .dropdown {
    top: auto;
    bottom: calc(100% - 1px);
    border-radius: 3px 3px 0px 0px;
  }
}
/* New Design CSS start */
*,
*:before,
*:after {
  box-sizing: border-box;
}
@mixin font($fontsize, $lineheight) {
  font-size: $fontsize;
  line-height: $lineheight;
}
@mixin flex($flex, $flexwrap, $justifycontent, $alignitems, $flexdirection) {
  display: $flex;
  flex-wrap: $flexwrap;
  justify-content: $justifycontent;
  align-items: $alignitems;
  flex-direction: $flexdirection;
}

.cart-section {
  .cart-header {
    border-bottom: 1px solid $light-grey;
    padding-bottom: 20px;
    margin-bottom: 20px;
    @media (max-width: 767px) {
      padding-bottom: 10px;
      margin-bottom: 10px;
    }
    * {
      font-family: 'Lato', sans-serif;
    }
  }
  .cart-title {
    h2 {
      @include font(28px, 34px);
      font-weight: 600;
      color: $dark-light;
      margin: 0;
      @media (max-width: 991px) {
        @include font(24px, 29px);
      }
    }
    span {
      @include font(14px, 17px);
      font-weight: 400;
      color: $dark-grey;
      padding-left: 15px;
    }
    @media (max-width: 767px) {
      span {
        display: block;
        padding-left: 0;
        padding-top: 5px;
      }
    }
  }
  .cart-time {
    @media (max-width: 767px) {
      margin: 10px 0 0 0;
    }
    strong {
      @include font(13px, 16px);
      font-weight: 700;
      color: $raisin-black;
      a {
        text-decoration: none;
        padding-left: 5px;
        &:hover {
          color: $primary;
          text-decoration: underline;
        }
      }
    }
    span.caption {
      @include font(14px, 17px);
      font-weight: 400;
      color: $dark-grey;
      margin-top: 5px;
    }
  }
  .subsitution-title {
    position: relative;
    > div {
      display: flex !important;
      flex-wrap: wrap;
      @media (max-width: 991px) {
        align-items: flex-start;
      }
    }
    span {
      @include font(13px, 16px);
      font-weight: 400;
      color: $raisin-black;
      width: calc(100% - 35px);
    }
    strong {
      font-size: 16px;
    }
    .q-badge {
      @include font(13px, 16px);
      font-weight: 500;
      color: $raisin-black;
      background: #fff3c2 !important;
      display: inline-flex !important;
      padding: 7px 12px;
      @media (max-width: 991px) {
        margin: 15px 0;
      }
      @media (max-width: 767px) {
        white-space: inherit !important;
      }
    }
    /* .q-checkbox__inner {
      .q-checkbox__bg {
        top: 0;
        left: 0;
      }
    } */
  }
  .text-textdark {
    color: $textdark;
  }
  .text-textlight {
    color: #cfcece;
  }
}
/* New Design CSS End */
</style>
