<template>
  <div class="quantityBox full-width" v-if="isinstock">
    <transition
      appear
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
    >
      <span
        :class="['error text-center', showMaxErrorOnTop ? 'top' : '']"
        v-if="product"
        v-show="cartQty >= qtyLimit && (showMaxError || focus)"
      >
        Max {{ qtyLimit }}
      </span>
    </transition>
    <template v-if="cartQty > 0 || focus">
      <q-btn
        round
        dense
        @click="addItemInCart(-1)"
        flat
        :icon="cartQty == 1 ? 'delete' : 'remove'"
        size="9px"
        type="number"
      />
      <q-input
        ref="cartInput"
        @blur="onBlur"
        class="text-center qtyGroup"
        outlined
        v-mask="'####'"
        v-model="cartQty"
        dense
        @focus="focus = true"
        @keyup.enter="$event.target.blur()"
      />
      <q-btn
        round
        dense
        @click="addItemInCart(+1)"
        flat
        icon="add"
        size="0.6rem"
        :class="cartQty >= qtyLimit ? 'disable' : ''"
      />
    </template>
    <q-btn
      v-else-if="showSelectButton"
      color="secondaryOnBody"
      @click="addItemInCart(+1)"
      outline
      label="Select"
      class="full-width"
    />
  </div>
  <div class="relative-position product-cart product-outstock" v-else>
    <span class="btn-outstock">Out Of Stock</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'OrderCartButton',
  props: {
    isinstock: {
      type: Boolean,
      default: true,
    },
    showSelectButton: {
      type: Boolean,
      default: true,
    },
    productId: {
      type: String,
      default: () => {
        return ''
      },
    },
    product: {
      type: Object,
      default: () => {
        return {}
      },
    },
    updateState: {
      type: Boolean,
      default: false,
    },
    updateUsingAPI: {
      type: Boolean,
      default: false,
    },
    orderId: {
      type: String,
      default: '',
    },
    showMaxErrorOnTop: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      quantity: null,
      showMaxError: false,
      showMaxErrorTimer: null,
      focus: false,
      timer: null,
    }
  },
  computed: {
    ...mapGetters('order', ['recentItems']),
    itemInfo() {
      return this.recentItems[this.productId] || this.product
    },
    qtyLimit() {
      if (this.product.qtyOnHand) {
        return (
          (this.product &&
            Math.min(this.product.qtyLimit, this.product.qtyOnHand)) ||
          9999
        )
      }
      return (this.product && this.product.qtyLimit) || 9999
    },
    cartQty: {
      get() {
        if (this.quantity !== null) {
          return this.quantity
        }
        let info = this.recentItems[this.productId] || this.product
        return parseInt(info.qty || 0)
      },
      set(val) {
        this.quantity = val || 0
      },
    },
  },
  watch: {
    'itemInfo.qty': function (newVal, oldVal) {
      if (newVal != oldVal) {
        this.cartQty = newVal
      }
    },
  },
  methods: {
    onBlur() {
      this.focus = false
      if (this.cartQty != this.product.qty) {
        this.updateCart()
      }
    },
    checkMaxLimit() {
      if (this.cartQty >= this.qtyLimit) {
        this.showMaxError = true

        if (this.showMaxErrorTimer) {
          clearTimeout(this.showMaxErrorTimer)
          this.showMaxErrorTimer = null
        }

        this.showMaxErrorTimer = setTimeout(() => {
          this.showMaxError = false
        }, 2000)

        this.cartQty = this.qtyLimit
      }
    },
    addItemInCart(incrementQty) {
      let cartQty = parseInt(this.cartQty),
        timeout = cartQty > 1 ? 300 : 0

      incrementQty = parseInt(incrementQty)

      this.cartQty = cartQty + incrementQty > 0 ? cartQty + incrementQty : 0

      this.checkMaxLimit()

      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = null
      }

      this.timer = setTimeout(() => {
        this.updateCart()
      }, timeout)
    },
    updateCart(quantity) {
      this.checkMaxLimit()

      if (!quantity && quantity !== 0) {
        quantity = this.cartQty
      }
      let objItem = {
        [this.productId]: {
          ...this.itemInfo,
          qty: quantity,
        },
      }

      if (this.updateState) {
        if (!quantity) {
          this.$store.commit('order/DELETE_RECENT_ITEM', this.productId)
        } else {
          if (
            this.showSelectButton &&
            objItem[this.productId].linkedProductID
          ) {
            this.$store
              .dispatch('order/getLinkedProducts', {
                productID: objItem[this.productId].productID,
                locationID: this.currentLocationId,
              })
              .then((response) => {
                if (response && response.data.data && response.data.success) {
                  let linkedProduct = Object.assign({}, response.data.data)
                  linkedProduct.qty = quantity
                  objItem[this.productId].linkedProduct = linkedProduct
                  this.$store.commit('order/SET_RECENT_ITEMS', objItem)
                } else {
                  this.$store.commit('order/SET_RECENT_ITEMS', objItem)
                }
              })
          } else {
            this.$store.commit('order/SET_RECENT_ITEMS', objItem)
          }
        }
      }

      if (this.updateUsingAPI && this.orderId) {
        let productLineItem = {
          productID: this.productId,
          qty: this.cartQty,
          instruction: this.product.instruction,
        }
        if (this.product.isSubscribeForAutoShip) {
          productLineItem = {
            ...productLineItem,
            frequency: this.product.frequency || 0,
            orderLineItemOrderType: this.product.orderLineItemOrderType || 0,
            subscriptionID: this.product.subscriptionID || undefined,
          }
        }
        let params = {
          orderID: this.orderId,
          editOrderLineItems: [productLineItem],
        }

        this.$store.dispatch('order/updateOrder', params).catch((error) => {
          let info = this.recentItems[this.productId] || this.product
          this.cartQty = parseInt(info.qty || 0)
          this.ftxConfirmCoupon(
            '',
            error.toString(),
            false,
            'Ok',
            true,
            false,
            'info-filled',
            '3em',
            true,
            false,
            false,
            true
          )
        })
      }
    },
  },
}
</script>
<style lang="scss">
.quantityBox {
  .error {
    left: 0;
    right: 0;
  }
}
</style>
