<template>
  <ftx-dialog
    dialog="WishlistDialog"
    :beforeShow="beforeShow"
    :onHide="reset"
    position="right"
    maximized
  >
    <template v-slot:DialogContent>
      <q-card class="whishlistDialog">
        <q-card-section>
          <div class="topHeader">
            <div class="row items-center no-wrap">
              <div class="col">
                <h3>Add to Wishlist</h3>
              </div>
              <div class="col-auto"
                ><q-btn
                  unelevated
                  no-caps
                  outline
                  rounded
                  color="secondaryOnBody"
                  @click="addNewWishlistDialog"
                  label=""
                  class="text-weight-regular"
                  ><q-icon name="add" class="q-mr-xs" />Create</q-btn
                ></div
              >
              <div class="col-auto q-px-md"></div>
            </div>
            <q-btn
              unelevated
              flat
              round
              icon="close"
              class="close"
              v-close-popup
            />
          </div>
          <div class="middle">
            <q-scroll-area
              class="scrollHeight"
              :thumb-style="scrollAreaThumbStyle"
              :bar-style="scrollAreaBarStyle"
              :visible="scrollAreaVisible"
              style="max-width: 100%"
            >
              <template v-if="allWishlistName.length">
                <ul>
                  <li
                    v-for="wishlist in allWishlistName"
                    :key="wishlist.wishListID"
                  >
                    <q-checkbox
                      dense
                      size="2.4rem"
                      :value="isSelected(wishlist.wishListID)"
                      :label="wishlist.wishListName"
                      @input="(val) => setSelected(wishlist.wishListID, val)"
                      class="text-capitalize"
                      color="secondaryOnBody"
                    >
                      <q-icon name="done" />
                    </q-checkbox>
                  </li>
                </ul>
              </template>
              <template v-else>
                <EmptyComponent
                  image="images/empty-wishlist.png"
                  v-show="allWishlistName && allWishlistName.length == 0"
                >
                  <p>You have not create any wishlist yet.</p>
                </EmptyComponent>
              </template>
            </q-scroll-area>
          </div>
          <div class="bottom">
            <q-btn
              unelevated
              no-caps
              :rounded="!isMobile"
              outline
              color="tertiary"
              label="Cancel"
              class="q-ml-sm q-mr-sm q-px-md q-py-xs"
              v-close-popup
            />
            <q-btn
              unelevated
              no-caps
              :rounded="!isMobile"
              color="secondaryOnBody"
              label="Save"
              class="q-ml-sm q-mr-sm q-px-md q-py-xs"
              @click="addToWishlist"
              :disable="!isWishlistSelected"
            />
          </div>
        </q-card-section>
      </q-card>
      <CreateWishListDialog />
    </template>
  </ftx-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { hideShowFullSpinner } from 'src/utils'
import CreateWishListDialog from 'src/components/common/CreateWishlistDialog.vue'
export default {
  name: 'WishlistDialog',

  data() {
    return {
      wishlistNames: [],
    }
  },
  components: {
    CreateWishListDialog,
  },
  computed: {
    ...mapGetters('wishlist', ['allWishlistName']),
    properties() {
      return this.getDialogProperties('WishlistDialog')
    },
    isWishlistSelected() {
      let selected = false
      for (let wishlistName of this.wishlistNames) {
        if (wishlistName.selected == true) {
          selected = true
          break
        }
      }
      return selected
    },
  },
  watch: {
    allWishlistName: function (newVal, oldVal) {
      for (let wishlist of this.allWishlistName) {
        this.wishlistNames.push({ id: wishlist.wishListID, selected: false })
      }
    },
  },
  mounted() {
    this.$root.$on('udpateWishlist', (wishlistObj) => {
      this.wishlistNames.push({
        id: wishlistObj[wishlistObj.length - 1].wishListID,
        selected: false,
      })
    })
  },
  methods: {
    beforeShow() {
      for (let wishlist of this.allWishlistName) {
        this.wishlistNames.push({ id: wishlist.wishListID, selected: false })
      }
    },
    isSelected(wishlistId) {
      let selected = false
      for (let wishlistName of this.wishlistNames) {
        if (wishlistName.id == wishlistId) {
          selected = wishlistName.selected
          break
        }
      }
      return selected
    },
    setSelected(wishlistId, val) {
      let wishlistNames = [...this.wishlistNames]
      for (let wishlistName of wishlistNames) {
        if (wishlistName.id == wishlistId) {
          wishlistName.selected = val
          break
        }
      }
      this.wishlistNames = wishlistNames
    },
    async addToWishlist() {
      let wishlistIds = []
      for (let wishlistName of this.wishlistNames) {
        if (wishlistName.selected) {
          wishlistIds.push({ wishListID: wishlistName.id })
        }
      }
      if (wishlistIds.length) {
        hideShowFullSpinner(true)
        let obj = {
          productIds: this.properties.productID,
          wishListIds: wishlistIds,
        }
        await this.$store
          .dispatch('wishlist/toggleProductIsWishlist', obj)
          .then((response) => {
            if (response.data.success) {
              this.changeDialogState({
                dialog: 'WishlistDialog',
                val: false,
              })
              this.showSuccess('Product added to Wishlist.')
            }
          })

          .catch((e) => {
            this.showError(e.message)
          })
          .finally(() => {
            hideShowFullSpinner(false)
          })
      }
    },
    addNewWishlistDialog() {
      this.changeDialogState({
        dialog: 'CreateWishListDialog',
        val: true,
        parentDialog: 'WishlistDialog',
        properties: {
          action: 'add',
        },
      })
    },
    reset() {
      this.wishlistNames = []
    },
  },
}
</script>
<style lang="scss">
.whishlistDialog {
  width: 100vw !important;
  @media (min-width: 481px) {
    width: 440px !important;
  }
  @media (min-width: 1024px) {
    width: 640px !important;
  }

  .q-card__section {
    padding: 0;
  }
  .topHeader {
    padding: 15px 30px;
    border-bottom: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 767px) {
      padding: 20px 15px;
    }
    h3 {
      margin: 0;
      padding: 0;
      font-size: 18px;
      line-height: normal;
      color: #333333;
      @media (min-width: 1681px) {
        font-size: 20px;
      }
    }
    .back {
      .q-focus-helper {
        display: none;
      }
      .q-btn__wrapper {
        padding: 0 0 4px;
      }
    }
    .close {
      position: absolute;
      right: 10px;
      top: 10px;
    }
  }
  .middle {
    padding: 20px 15px;
    @media (max-width: 767px) {
      padding: 15px 0px;
    }
    .scrollHeight {
      padding-left: 15px;
      padding-right: 15px;
      height: calc(100dvh - 191px);
      @media (max-width: 1023px) {
        height: calc(100dvh - 185px) !important;
      }
    }

    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      .q-checkbox {
        display: flex;
        margin: 8px 0;
        padding: 12px 12px;
        padding-right: 45px;
        font-size: 16px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        position: relative;
        border-radius: 25px;
        transition: all 0.3s ease-in-out;
        &[aria-checked='true'] {
          font-weight: 600;
          color: $primary;
          color: var(--q-color-primary) !important;
          border-color: $primary;
          border-color: var(--q-color-primary) !important;

          .no-outline {
            background: $primary;
            background: var(--q-color-primary) !important;
            border-color: $primary;
            border-color: var(--q-color-primary) !important;
          }
        }
        .no-outline {
          position: absolute;
          width: 24px;
          height: 24px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          right: 16px;
          border-radius: 25px;
          transition: all 0.1s ease-in-out;
        }
        .q-icon {
          color: #fff;
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translate3d(0, -50%, 0);
        }
      }
      .q-checkbox__inner {
        display: none;
      }
      .q-checkbox__label {
        width: 100%;
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .bottom {
    padding: 10px 30px;
    border-top: 1px solid rgba(112, 112, 112, 0.2);
    position: relative;
    @media (max-width: 1023px) {
      padding: 5px 15px;
    }
    .q-btn {
      width: 180px;
      margin: 7px 0;
      padding: 5px 0;
      @media (max-width: 479px) {
        width: auto;
        padding: 5px 8px;
      }
    }
    .q-btn:last-child {
      float: right;
    }
  }
}
</style>
