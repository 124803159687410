<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'right'"
    maximized
    className="dialog-xs"
    :before-show="onShow"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <h4
            class="text-subtitle1 q-ma-none q-pa-md text-weight-bold text-black"
          >
            Update Address
          </h4>
        </div>

        <q-card-section class="q-pa-none">
          <q-scroll-area
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            style="height: calc(100dvh - 80px)"
          >
            <div class="addressList">
              <q-list>
                <q-item
                  tag="label"
                  v-for="(address, key) in addressList"
                  :key="`${key}`"
                  :class="address.addressId == addressId ? 'active' : ''"
                >
                  <q-item-section
                    class="addAddress"
                    v-if="key == 'add-address'"
                    @click="editAddress"
                  >
                    <q-item-label>
                      <span>+</span>
                      Add new address
                    </q-item-label>
                  </q-item-section>
                  <q-item-section v-else>
                    <div
                      :style="
                        !isAddressDisable(address) && key !== 'add-address'
                          ? 'opacity: 0.3; cursor: not-allowed !important;'
                          : ''
                      "
                    >
                      <q-radio
                        size="sm"
                        :value="addressId"
                        :val="address.addressId"
                        :label="
                          addressId == address.addressId ? 'Selected' : 'Select'
                        "
                        @input="updateAddress(address)"
                        :disable="!isAddressDisable(address)"
                      />
                      <q-item-label>
                        <div class="row items-center">
                          <div class="col text-weight-bold">
                            {{ address.firstName }} {{ address.lastName }}
                            <span v-if="address.isDefault" class="default">
                              Default
                            </span>
                          </div>
                        </div>
                      </q-item-label>
                      <q-item-label
                        class="address q-mb-sm"
                        v-html="$options.filters.formatAddress(address)"
                      >
                      </q-item-label>
                      <q-item-label
                        v-if="
                          properties.fulfillmentCode == 'scd' &&
                          address.isDeliver === false
                        "
                        class="text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"
                      >
                        *Unable to deliver to this location for Delivery
                      </q-item-label>
                      <q-item-label
                        v-else-if="
                          properties.fulfillmentCode == 'sd' &&
                          address.isShippingPossible === false
                        "
                        class="text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"
                      >
                        *Unable to ship to this location for Standard Shipping
                      </q-item-label>
                      <q-btn
                        :disable="!isAddressDisable(address)"
                        unelevated
                        flat
                        class="editAddress q-mr-sm q-mt-sm"
                        @click="editAddress(address)"
                        ><q-icon name="edit" size="1rem"
                      /></q-btn>
                    </div>
                  </q-item-section>
                </q-item>
              </q-list>
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-inner-loading
          :showing="loading"
          label="Please wait..."
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { convertArrayToObject, mergeObjects } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'UpdateAddressDialog',
  data() {
    return {
      dialog: 'UpdateAddressDialog',
      addressId: null,
    }
  },
  computed: {
    ...mapGetters('address', ['addresses', 'loading']),
    addressList() {
      let addressList = { ...this.addresses }
      let key =
        this.properties.fulfillmentCode == 'scd'
          ? 'isDeliver'
          : 'isShippingPossible'
      let sorted = Object.values(addressList).sort(function (a, b) {
        return b[key] - a[key]
      })
      addressList = convertArrayToObject(sorted, 'addressId')

      if (this.loading) return {}
      return mergeObjects({ 'add-address': {} }, addressList)
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    isAddressDisable(address) {
      let key =
        this.properties.fulfillmentCode == 'scd'
          ? 'isDeliver'
          : 'isShippingPossible'
      return address.hasOwnProperty(key) && address[key] ? address[key] : false
    },
    async onShow() {
      if (!Object.keys(this.addresses).length)
        await this.$store.dispatch('address/getCustomerAddresses')
      this.addressId = this.properties.addressId
    },
    editAddress(address = {}) {
      this.changeDialogState({
        dialog: 'addShippingAddress',
        val: true,
        properties: {
          address,
          showNotification: false,
        },
        parentDialog: 'UpdateAddressDialog',
      })
    },
    updateAddress(address) {
      if (this.properties.addressId === address.addressId) {
        this.addressId = address.addressId
        return
      } else if (
        (this.properties.fulfillmentCode === 'scd' && !address.isDeliver) ||
        (this.properties.fulfillmentCode === 'sd' &&
          !address.isShippingPossible)
      ) {
        this.ftxConfirmCoupon(
          '',
          'Your subscribed product does not apply to the selected address. Keep your previous address as it is or try another address details.',
          false,
          'Ok',
          true,
          false,
          'info-filled',
          '3em',
          true,
          false,
          false,
          true
        )
        return
      } else {
        let reqObj = {
          id: this.properties.subscriptionID,
          params: {
            actionType: this.properties.actionType,
            addressId: address.addressId,
          },
        }
        this.$store.commit('address/SET_LOADING', true)
        this.$store
          .dispatch('order/updateSubscriptionAddressPayment', reqObj)
          .then(async (response) => {
            if (
              this.properties?.refreshListing &&
              response?.data?.subscriptionId
            ) {
              this.addressId = address.addressId
              this.properties.refreshListing()
              this.changeDialogState({
                dialog: this.dialog,
                val: false,
              })
            }
          })
          .catch((error) => {
            console.log(error)
          })
          .finally(() => this.$store.commit('address/SET_LOADING', false))
      }
    },
  },
  created() {
    this.$root.$on('address-added-or-modified', async (data) => {
      if (data?.address?.addressId && data.isNewAddress) {
        this.updateAddress(data.address)
      }
    })
  },
}
</script>

<style lang="scss" scoped>
.editAddress {
  background: #acacac;
  color: #fff;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-border-radius: 50%;
  border-radius: 50%;
  position: absolute;
  right: 8px;
  top: 8px;
  text-decoration: none;
}
</style>
