import { convertArrayToObject, mergeObjects } from 'src/utils'

export async function getBillingAddresses({ commit }, params = {}) {
  try {
    let { data } = await this.$axiosInstance.get('/Customer/Address/', {
      params: {
        global: true,
        DisablePaging: true,
        addressType: 1,
        ...params,
      },
    })

    commit(
      'SET_CUSTOMER_BILLING_ADDRESSES',
      convertArrayToObject(data.data.items, 'customerAddressesID')
    )
  } catch (error) {}
}

export async function getCustomerAddresses({ commit }, params = {}) {
  try {
    commit('SET_LOADING', true)
    let { data } = await this.$axiosInstance.get('/Customer/Address/', {
      params: {
        DisablePaging: true,
        addressType: 0,
        global: true,
        ...params,
      },
    })

    if (data?.success && data?.data) {
      commit(
        'SET_CUSTOMER_ADDRESSES',
        convertArrayToObject(data.data.items, 'addressId')
      )
      commit('SET_LOADING', false)
    }
  } catch (error) {}
}

export async function addOrUpdateAddress({ commit }, address) {
  try {
    let response
    if (address.customerAddressesID) {
      response = await this.$axiosInstance.put(
        `/Customer/Address/${address.customerAddressesID}`,
        address
      )
    } else {
      response = await this.$axiosInstance.post('/Customer/Address/', address)
    }

    if (response.data.success && response.data.data) {
      if (address.type == 1)
        commit(
          'ADD_OR_UPDATE_BILLING_ADDRESS',
          response.data.data.address || response.data.data
        )
      else {
        commit(
          'ADD_OR_UPDATE_ADDRESS',
          response.data.data.address || response.data.data
        )

        if (response.data.data.deletedAddressId) {
          commit('REMOVE_ADDRESS', response.data.data.deletedAddressId)
          commit('SET_ADDRESS_MAPPING', {
            [response.data.data.deletedAddressId]:
              response.data.data.address.addressId,
          })
        }
      }
    }

    return response.data.data
  } catch (error) {
    return false
  }
}

export async function deleteAddress({ commit }, address) {
  if (address && address.customerAddressesID) {
    try {
      let { data } = await this.$axiosInstance.delete(
        `/Customer/Address/${address.customerAddressesID}`
      )

      if (data && data.data) {
        commit('REMOVE_ADDRESS', address.addressId)
      }
      return data
    } catch (error) {
      return error
    }
  }
}

export async function UpdateDefaultAddress({ commit, rootGetters }, address) {
  try {
    let { data } = await this.$axiosInstance.put(
      '/Customer/Address/UpdateDefaultAddress',
      {
        customerAddressId: address.customerAddressesID,
      }
    )

    if (data?.data) {
      commit(
        'ADD_OR_UPDATE_ADDRESS',
        mergeObjects(address, { isDefault: true })
      )
      return true
    }
    return false
  } catch (error) {
    return false
  }
}
