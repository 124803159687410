export const currentCartDetails = (state, getters) =>
  state.cartDetail[getters['currentCartNumber']]

export function getSubCartTotal(state, getters) {
  if (getters.currentCartDetails?.subTotalDisplay)
    return getters.currentCartDetails.subTotalDisplay

  return '0.0'
}

export function cartTotalItems(state, getters) {
  if (getters.currentCartDetails) return getters.currentCartDetails.totalItems

  return 0
}

export const currentCartNumber = (state) => state.cartNumber

export const substituteDetails = (state) => state.substituteDetail

export const recentSubstituteDetail = (state) => state.recentSubstituteDetail

export function pickupInfoByFulfillmentId(state) {
  return (fulfillmentId) => {
    for (let fulfillment of state.currentOrderParameters.orderFulfillments) {
      if (fulfillmentId == fulfillment.fulfillmentMethodID) {
        return fulfillment.pickupPerson
      }
    }
    return null
  }
}

export function addressByFulfillmentId(state, getters, rootState, rootGetters) {
  return (fulfillmentId) => {
    for (let fulfillment of state.currentOrderParameters.orderFulfillments) {
      if (
        fulfillmentId == fulfillment.fulfillmentMethodID &&
        fulfillment.addressID
      ) {
        let addressId =
          rootGetters['address/newAddressId'](fulfillment.addressID) ||
          fulfillment.addressID

        return rootState.address.addresses[addressId]
      }
    }
    return null
  }
}

export function selectedPaymentMethod(state, getters, rootState, rootGetters) {
  if (state.currentOrderParameters.paymentMethodID) {
    return rootGetters['payment/paymentMethods'][
      state.currentOrderParameters.paymentMethodID
    ]
    // rootState.payment.availableMethods[
    //   state.currentOrderParameters.paymentMethodID
    // ]
  }
  return null
}

export const selectedPaymentMethodId = (state) =>
  state.currentOrderParameters.paymentMethodID

export const checkoutInReviewMode = (state) => state.checkoutInReviewMode

export const currentOrderParameters = (state) => state.currentOrderParameters

export const substitute = (state, getters) => {
  return (orderLineItemId, allowSubstitute) => {
    if (getters.recentSubstituteDetail.hasOwnProperty(orderLineItemId))
      return getters.recentSubstituteDetail[orderLineItemId].allowSubstitute

    if (getters.substituteDetails.hasOwnProperty(orderLineItemId))
      return getters.substituteDetails[orderLineItemId].allowSubstitute

    return allowSubstitute
  }
}

export function isSubstitutionAllowed(state, getters) {
  let isTrueFound = false,
    isFalseFound = false

  if (getters.currentCartDetails && getters.currentCartDetails.fulfillments) {
    for (let fId in getters.currentCartDetails.fulfillments) {
      const fulfillment = getters.currentCartDetails.fulfillments[fId]
      if (fulfillment.codeName !== 'sd') {
        for (let olId in fulfillment.shoppingCartItems) {
          const item = fulfillment.shoppingCartItems[olId]
          if (getters.substitute(item.orderLineItemID, item.allowSubstitute)) {
            isTrueFound = true
          } else {
            isFalseFound = true
          }

          if (isTrueFound && isFalseFound) {
            return null
          }
        }
      }
    }
  }

  return isTrueFound
}

export function showSubstitution(state, getters) {
  let showSubstitution = false

  if (getters.currentCartDetails?.fulfillments) {
    let fulfillments = Object.values(getters.currentCartDetails.fulfillments)
    if (fulfillments.length > 1 || fulfillments[0].codeName !== 'sd') {
      showSubstitution = true
    }
  }

  return showSubstitution
}

export const loading = (state) => state.loading

export const getAlertErrors = (state) => state.alertDetail

export const cartHasInStore = (state, getters) => {
  let currentCartDetails = getters.currentCartDetails,
    hasFulfillments = false

  if (currentCartDetails?.fulfillments) {
    for (const fId in currentCartDetails.fulfillments) {
      const fulfillment = currentCartDetails.fulfillments[fId]
      if (fulfillment.codeName == 'is') {
        hasFulfillments = true
        break
      }
    }
  }
  return hasFulfillments
}

export const cartHasCurbside = (state, getters) => {
  let currentCartDetails = getters.currentCartDetails,
    hasFulfillments = false

  if (currentCartDetails?.fulfillments) {
    for (const fId in currentCartDetails.fulfillments) {
      const fulfillment = currentCartDetails.fulfillments[fId]
      if (fulfillment.codeName == 'cs') {
        hasFulfillments = true
        break
      }
    }
  }
  return hasFulfillments
}

export const outOfStockProducts = (state, getters) => {
  return state.outOfStockProducts[getters['currentCartNumber']] || []
}

export function cartHasAllOutOfStockProducts(state, getters) {
  let currentCartDetails = getters['currentCartDetails']
  let outOfStockProductsItemIDs = getters['outOfStockProducts'].map(
    (product) => product.orderLineItemID
  )
  let cartItemIDs = []
  for (const fId in currentCartDetails.fulfillments) {
    const fulfillment = currentCartDetails.fulfillments[fId]
    for (const shoppingCartItem in fulfillment.shoppingCartItems) {
      const olId = fulfillment.shoppingCartItems[olId]
      cartItemIDs.push(shoppingCartItem.orderLineItemID)
    }
  }
  return (
    cartItemIDs.sort().toString() == outOfStockProductsItemIDs.sort().toString()
  )
}

export const substituteChildProductDetails = (state, getters) => {
  return (orderLineItemId, allowSubstitute) => {
    if (getters.recentSubstituteDetail.hasOwnProperty(orderLineItemId))
      return getters.recentSubstituteDetail[orderLineItemId].substituteProducts
    return []
  }
}

export const selectedChildSubstituteProduct = (state, getters) => {
  return (orderLineItemId, allowSubstitute) => {
    if (getters.recentSubstituteDetail.hasOwnProperty(orderLineItemId))
      return getters.recentSubstituteDetail[orderLineItemId]
        .selectedSubstituteProduct
    return ''
  }
}

export const isPayAtStoreAvailable = (state, getters) => {
  return (fulfillmentCode) => {
    return state.isPayAtStoreAvailable[fulfillmentCode]
  }
}

export const shippingRates = (state) => state.shippingRates || {}

export const shippingRatesById = (state) => (rateID) =>
  state.shippingRates[rateID] || null

export function getProductById(state, getters, rootState, rootGetters) {
  return (productId) => {
    let productInfo = null
    if (getters.currentCartDetails?.fulfillments) {
      for (let fId in getters.currentCartDetails.fulfillments) {
        const fulfillment = getters.currentCartDetails.fulfillments[fId]
        if (
          fulfillment.codeName ==
          rootGetters['persisted/selectedFulfillmentCodeName']
        ) {
          for (let olId in fulfillment.shoppingCartItems) {
            const item = fulfillment.shoppingCartItems[olId]
            if (item.productId == productId) {
              productInfo = item
              break
            }
          }
        }
        if (productInfo) break
      }
    }

    return productInfo
  }
}

export const alertData = (state) => (alertKey) =>
  state.alertData[alertKey] || null

export const allowPlaceOrder = (state) => !!state.allowPlaceOrder
export const isTipConfirmed = (state) => state.isTipConfirmed

export const checkoutSelection = (state) => state.checkoutSelection

export const isAcknowledgeAccepted = (state) =>
  state.currentOrderParameters.isAcknowledgeAccepted

export function getSubscribedProducts(state, getters, rootState, rootGetters) {
  let orderSubscriptions = [],
    currentCartDetails = getters.currentCartDetails,
    cartPersistedInfo = rootGetters['persistedLocal/getCartPersistedInfo'](
      currentCartDetails.orderNumber
    )
  if (
    currentCartDetails?.fulfillments &&
    cartPersistedInfo?.subscriptionDetails
  ) {
    for (const fId in currentCartDetails.fulfillments) {
      const fulfillment = currentCartDetails.fulfillments[fId]
      if (
        fulfillment.codeName ==
        rootGetters['persisted/selectedFulfillmentCodeName']
      ) {
        for (let olId in fulfillment.shoppingCartItems) {
          const item = fulfillment.shoppingCartItems[olId],
            subscriptionDetail =
              cartPersistedInfo.subscriptionDetails[item.orderLineItemID]
          if (
            item.isSubscribeForAutoShip &&
            subscriptionDetail.subscriptionOption == 2 &&
            subscriptionDetail.productID &&
            subscriptionDetail.frequency
          )
            orderSubscriptions.push({
              productID: subscriptionDetail.productID,
              frequency: subscriptionDetail.frequency,
            })
        }
      }
    }
  }
  return orderSubscriptions
}

export const isConfirmSubscription = (state) => state.isConfirmSubscription
