<template>
  <div
    v-if="
      orderDetail.substitutedOrderLineItems &&
      orderDetail.substitutedOrderLineItems.length > 0
    "
    class="col-12 col-md-12 subtitute_linked"
  >
    <div class="row">
      <div class="col-12 col-md-12 orderSingleLabel">
        Substitutions
      </div>
      <div class="col-12 col-md-12">
        <!-- New UI for Order -->
        <div class="cart-section">
          <div
            class="cart-order"
            v-for="(
              substitutedOrderLineItem, index
            ) of orderDetail.substitutedOrderLineItems"
            :key="`${substitutedOrderLineItem.orderLineItemID}-${index}`"
          >
            <div
              :class="[
                'cart-product sd-spacing q-ml-none',
                substitutedOrderLineItem.linkedProducts && substitutedOrderLineItem.linkedProducts.length > 0
                  ? 'substitution-product'
                  : substitutedOrderLineItem.allowSubstitute
                  ? 'substitution-product'
                  : '',
                  substitutedOrderLineItem.isFulfilled && substitutedOrderLineItem.qty === 0
                  ? 'product-not-available'
                  : '',
              ]"
            >
              <div class="cart-produt-data">
                <div class="produt-data flex items-start">
                  <div
                    class="product-image"
                    :class="{
                      blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                    }"
                  >
                    <box-image
                      :link="{
                        name: 'Detail',
                        params: { seoName: substitutedOrderLineItem.seoName },
                      }"
                      :imageSrc="substitutedOrderLineItem.image"
                      :width="isMobile ? '80px' : '86px'"
                    />
                  </div>

                  <div class="co-product-details">
                    <div class="product--title">
                      <h3
                        class="ellipsis"
                        :class="{
                          blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                        }"
                      >
                        <router-link
                          :to="{
                            name: 'Detail',
                            params: { seoName: substitutedOrderLineItem.seoName },
                          }"
                        >
                          {{ substitutedOrderLineItem.productName }}
                        </router-link>
                      </h3>

                      <div class="cart--labels q-mt-sm">
                        <span
                          class="label-success q-mr-xs"
                          v-if="substitutedOrderLineItem.isAdded"
                          v-html="`Just Added`"
                        />
                        <span
                          class="label-primary q-mr-xs"
                          v-else-if="substitutedOrderLineItem.isUpdated"
                          v-html="`Updated`"
                        />
                        <span v-show="substitutedOrderLineItem.allowSubstitute" class="allowed">
                          Substitution Allowed
                          <SvgIcon icon="info-outline" class="q-ml-sm" />
                        </span>
                        <span
                          v-if="substitutedOrderLineItem.isBlacklisted"
                          class="text-negative text-body2 block q-mt-xs"
                        >
                          <q-icon name="info"></q-icon> Product No Longer on
                          Site
                        </span>
                      </div>

                      <div
                        class="cart--instruction text-body2 word-break-word q-mt-sm"
                        v-if="substitutedOrderLineItem.instruction"
                      >
                        <strong class="text-grey-6">Item instruction:</strong>
                        <br />
                        {{ substitutedOrderLineItem.instruction || '(not set)' }}
                      </div>

                      <div class="cart--quantity q-mt-sm"
                        >{{ substitutedOrderLineItem.qty }} x
                        <span v-html="$options.filters.currency(substitutedOrderLineItem.price)"
                      /></div>
                    </div>

                    <div class="price">
                      <span
                        class="original-price"
                        v-html="$options.filters.currency(substitutedOrderLineItem.totalPrice)"
                        v-if="substitutedOrderLineItem.totalPrice != substitutedOrderLineItem.finalPrice"
                      />
                      <span
                        :class="
                          substitutedOrderLineItem.totalPrice != substitutedOrderLineItem.finalPrice
                            ? 'discount-price'
                            : ''
                        "
                        v-html="$options.filters.currency(substitutedOrderLineItem.finalPrice)"
                      />
                    </div>

                    <div
                      class="product-not-available-label text-negative text-body2"
                      v-if="substitutedOrderLineItem.isFulfilled && substitutedOrderLineItem.qty === 0"
                    >
                      <span>Product Not Available</span>
                    </div>
                  </div>
                </div>
              </div>

              <!-- Linked Item -->
              <div
                class="substitution-product--block"
                v-if="substitutedOrderLineItem.linkedProductLineItem"
              >
                <div class="substitution-product produt-data flex items-center">
                  <div class="product-image">
                    <q-avatar square>
                      <box-image
                        :link="
                          substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                            ? ''
                            : {
                                name: 'Detail',
                                params: {
                                  seoName:
                                    substitutedOrderLineItem.linkedProductLineItem
                                      .seoName,
                                },
                              }
                        "
                        :imageSrc="substitutedOrderLineItem.linkedProductLineItem.image"
                        width="40px"
                      />
                    </q-avatar>
                    <q-avatar flat>
                      <SvgIcon icon="linked" />
                    </q-avatar>
                  </div>
                  <div class="co-product-details">
                    <div class="product--title">
                      <h4>
                        <router-link
                          :to="{
                            name: 'Detail',
                            params: {
                              seoName:
                                substitutedOrderLineItem.linkedProductLineItem.seoName,
                            },
                          }"
                          v-html="substitutedOrderLineItem.linkedProductLineItem.productName"
                        />
                        <q-tooltip max-width="180px" content-class="bg-dark">
                          {{ substitutedOrderLineItem.linkedProductLineItem.productName }}
                        </q-tooltip>
                      </h4>
                      <span
                          v-if="
                            substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                          "
                          class="text-negative text-body2 block q-mt-xs"
                        >
                          <q-icon name="info"></q-icon> Product No Longer on Site</span
                        >
                    </div>
                    <div class="price">
                      <span
                        class="original-price"
                        v-html="
                          $options.filters.currency(
                            substitutedOrderLineItem.linkedProductLineItem.totalPrice
                          )
                        "
                        v-if="
                          substitutedOrderLineItem.linkedProductLineItem.totalPrice !=
                            substitutedOrderLineItem.linkedProductLineItem.finalPrice &&
                          substitutedOrderLineItem.linkedProductLineItem.totalPrice > 0
                        "
                      />
                      <span
                        :class="
                          substitutedOrderLineItem.linkedProductLineItem.qty == 0
                            ? 'discount-price'
                            : ''
                        "
                      >
                        {{
                          substitutedOrderLineItem.linkedProductLineItem.finalPrice
                            | currency
                        }}</span>
                    </div>
                    <div class="flex product-edit--options">
                      <div class="product-quantity">
                        <span>
                          {{ substitutedOrderLineItem.linkedProductLineItem.qty }} x
                          {{ substitutedOrderLineItem.linkedProductLineItem.price | currency }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Linked Item -->

              <!-- Substitute Item -->

              <div v-if="substitutedOrderLineItem.newOrderLineItem.length > 0" class="q-my-md">
                <div class="text-secondary text-weight-bold q-py-sm">Substitution Item</div>
                <q-separator />
              </div>

              <div class="cart-product-data relative-position"
              v-for="(
                newOrderLineItem, index
              ) of substitutedOrderLineItem.newOrderLineItem"
              :key="`${substitutedOrderLineItem.newOrderLineItem}-${index}`"
              :class="!newOrderLineItem.isSubtitutionAccepted ? 'decline' : ''"
              >
                <div class="produt-data flex items-start">
                  <div
                    class="product-image"
                    :class="{
                      blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                    }"
                  >
                    <box-image
                      :link="
                        newOrderLineItem.isBlacklisted
                          ? ''
                          : {
                              name: 'Detail',
                              params: {
                                seoName: newOrderLineItem.seoName,
                              },
                            }
                      "
                      :imageSrc="newOrderLineItem.image"
                      :width="isMobile ? '80px' : '86px'"
                    />
                  </div>

                  <div class="co-product-details">
                    <div class="product--title">
                      <h3
                        class="ellipsis"
                        :class="{
                          blacklistedproduct: newOrderLineItem.isBlacklisted,
                        }"
                      >
                        <router-link
                          :to="{
                            name: 'Detail',
                            params: {
                              seoName: newOrderLineItem.seoName,
                            },
                          }"
                        >
                          <span
                            :class="
                              !newOrderLineItem.isSubtitutionAccepted
                                ? 'block text-strike'
                                : ''
                            "
                            >{{ newOrderLineItem.productName }}</span
                          >
                        </router-link>
                      </h3>

                      <div class="cart--quantity q-mt-sm">
                        {{ newOrderLineItem.qty }} x {{ newOrderLineItem.price | currency }}
                      </div>
                    </div>

                    <div class="price">
                      <span
                        class="original-price"
                        v-html="$options.filters.currency(newOrderLineItem.totalPrice)"
                        v-if="
                          newOrderLineItem.totalPrice != newOrderLineItem.finalPrice &&
                          newOrderLineItem.totalPrice > 0
                        "
                      />
                      <span
                        :class="
                          !newOrderLineItem.isSubtitutionAccepted
                            ? 'discount-price'
                            : ''
                        "
                      >
                        {{ newOrderLineItem.finalPrice | currency }}
                      </span>
                    </div>

                    <div
                      class="product-not-available-label text-negative text-body2 q-mt-sm"
                      v-if="newOrderLineItem.isBlacklisted"
                    >
                      <span>Product Not Available</span>
                    </div>

                    <template
                      v-if="
                        newOrderLineItem.isSubtitutionAccepted &&
                        !orderDetail.orderCompleted &&
                        showDeclineSubstitutionLink
                      "
                    >
                      <div
                        class="caption decline q-mt-sm"
                        :class="{
                          invisible:
                            $route.name == 'TrackGuestOrder' ||
                            (isLoggedIn && orderDetail.codeName != 'sp'),
                        }"
                      >
                        <q-btn unelevated no-caps dense color="red-1" text-color="red" padding="xs md" label="Decline Substitution" @click="declineItem(newOrderLineItem)" />
                      </div>
                    </template>
                    <template v-else-if="!newOrderLineItem.isSubtitutionAccepted">
                      <div class="caption decline text-red q-mt-sm"> Declined</div>
                    </template>
                  </div>
                </div>

                <!-- Substitute Linked Item -->
                <!-- <q-item
                  v-if="newOrderLineItem && newOrderLineItem.linkedProductLineItem"
                  :class="[
                    'linked-product',
                    {
                      blacklistedproduct:
                        newOrderLineItem.linkedProductLineItem.isBlacklisted,
                    },
                    !newOrderLineItem.isSubtitutionAccepted ? 'disable' : '',
                  ]"
                >
                  <q-item-section avatar>
                    <q-avatar square color="white">
                      <box-image
                        :link="
                          newOrderLineItem.linkedProductLineItem.isBlacklisted
                            ? ''
                            : {
                                name: 'Detail',
                                params: {
                                  seoName:
                                    newOrderLineItem.linkedProductLineItem.seoName,
                                },
                              }
                        "
                        :imageSrc="newOrderLineItem.linkedProductLineItem.image"
                      />
                    </q-avatar>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>
                      <router-link
                        :to="{
                          name: 'Detail',
                          params: {
                            seoName: newOrderLineItem.linkedProductLineItem.seoName,
                          },
                        }"
                      >
                        <span
                          :class="
                            !newOrderLineItem.linkedProductLineItem
                              .isSubtitutionAccepted
                              ? 'text-strike'
                              : ''
                          "
                          >{{
                            newOrderLineItem.linkedProductLineItem.productName
                          }}</span
                        >
                      </router-link>
                      <br v-show="isMobile" />
                      <span
                        v-show="newOrderLineItem.linkedProductLineItem.isLinkedProduct"
                        class="allowed"
                      >
                        Linked Product
                        <SvgIcon icon="info-outline" class="q-ml-sm" />
                      </span>
                    </q-item-label>
                    <q-item-label caption lines="2">
                      {{ newOrderLineItem.linkedProductLineItem.qty }}
                      x
                      <strong class="q-mr-sm">
                        {{ newOrderLineItem.linkedProductLineItem.price | currency }}
                      </strong>
                    </q-item-label>
                    <q-item-label caption>
                      <span
                        v-if="newOrderLineItem.linkedProductLineItem.isBlacklisted"
                        class="text-negative text-body2 block q-mt-xs"
                      >
                        <q-icon name="info"></q-icon> Product No Longer on Site</span
                      >
                    </q-item-label>
                  </q-item-section>
                  <q-item-section class="text-right linked-product__price" side>
                    <q-item-label class="orderItemPrice">
                      <div
                        class="text-strike strike-price text-grey text-weight-regular"
                        v-html="
                          $options.filters.currency(
                            newOrderLineItem.linkedProductLineItem.totalPrice
                          )
                        "
                        v-if="
                          newOrderLineItem.linkedProductLineItem.totalPrice !=
                            newOrderLineItem.linkedProductLineItem.finalPrice &&
                          newOrderLineItem.linkedProductLineItem.totalPrice > 0
                        "
                      />
                      <div
                        :class="
                          !newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
                            ? 'text-strike'
                            : ''
                        "
                      >
                        {{
                          newOrderLineItem.linkedProductLineItem.finalPrice | currency
                        }}
                      </div>
                    </q-item-label>
                    <q-item-label
                      class="caption product-not-available-label"
                      v-if="newOrderLineItem.linkedProductLineItem.qty == 0"
                    >
                      Product not available
                    </q-item-label>
                    <template
                      v-if="
                        !newOrderLineItem.linkedProductLineItem.isSubtitutionAccepted
                      "
                    >
                      <q-item-label class="caption decline"> Declined </q-item-label>
                    </template>
                  </q-item-section>
                </q-item> -->
                <!-- Substitute Linked Item -->
              </div>
              <!-- Substitute Item -->
            </div>
          </div>
        </div>
        <!-- New UI for Order -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Substitutions',
  props: {
    orderDetail: {
      type: Object,
      default: new Object(),
    },
    showDeclineSubstitutionLink: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    declineItem(substitutedOrderLineItem) {
      this.ftxConfirm(
        'Are you sure you want to decline substituted order line item?'
      ).onOk(async () => {
        this.$store
          .dispatch('order/allowDeclineSubstitution', {
            orderLineItemID: substitutedOrderLineItem.orderLineItemID,
            allowSubstitution: false,
          })
          .then((response) => {
            if (response) {
              this.showSuccess(
                'order line item substitution declined successfully.'
              )
            }
          })
      })
    },
  },
}
</script>
