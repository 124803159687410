<template>
  <div class="relative-position product-cart">
    <q-btn
      v-if="!showUpdateQuantity"
      unelevated
      no-caps
      flat
      color="primary"
      label="Update Quantity"
      @click="toggleUpdateQuantity"
      padding="0"
      class="no-hover text-underline btn-update"
    />

    <div class="row q-col-gutter-sm items-center" v-if="showUpdateQuantity">
      <div class="col col-md-6 relative-position">
        <div class="added-cart">
          <div class="btn">
            <q-btn
              round
              dense
              flat
              :icon="'remove'"
              :disable="subscriptionQty <= 1"
              size="0.6rem"
              @click="subscriptionQty--"
            />
            <q-input
              @focus="focus = true"
              class="text-center col-2 qtyGroup"
              rounded
              outlined
              v-mask="'####'"
              v-model="subscriptionQty"
              @blur="
                () => {
                  this.focus = false
                }
              "
              dense
            />
            <q-btn
              :class="[subscriptionQty >= 999 ? 'disable' : '']"
              @click="subscriptionQty++"
              round
              dense
              flat
              icon="add"
              size="0.6rem"
            />
          </div>
        </div>
        <span
        :class="['error text-center']"
        v-if="showUpdateQuantity"
        v-show="subscriptionQty >= 999 && focus"
      >
        Max 999
      </span>
      </div>
      <div class="col-auto col-md-auto">
        <q-btn
        :dense="isMobile"
          round
          unelevated
          no-caps
          color="green-1"
          text-color="green"
          icon="check"
          :loading="loading"
          :class="[subscriptionQty >= 999 ? 'disable' : '']"
          @click="updateQuantity"
        />
      </div>
      <div class="col-auto col-md-auto">
        <q-btn
        :dense="isMobile"
          round
          unelevated
          no-caps
          color="red-1"
          text-color="red"
          icon="close"
          @click="toggleUpdateQuantity"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'UpdateSubscriptionQuantity',
  props: {
    subscriptionId: {
      type: String,
      Required: true,
    },
    subscriptionQuantity: {
      type: Number,
      Required: true,
    },
    getSubscriptionDetails: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      focus: false,
      showUpdateQuantity: false,
      noOfItems: null,
      loading: false,
    }
  },
  computed: {
    subscriptionQty: {
      get() {
        return this.noOfItems !== null
          ? this.noOfItems
          : this.subscriptionQuantity
      },
      set(value) {
        if (value == 0) {
          this.noOfItems = 1
        } else this.noOfItems = value ? value : 1
      },
    },
  },
  methods: {
    toggleUpdateQuantity() {
      if (this.showUpdateQuantity) {
        this.showUpdateQuantity = false
      } else {
        this.showUpdateQuantity = true
      }
    },
    async updateQuantity() {
      this.loading = true
      this.subscriptionQty = parseInt(this.subscriptionQty)
      let reqObj = {
        id: this.subscriptionId,
        params: { qty: this.subscriptionQty, actionType: 7 },
      }
      await this.$store
        .dispatch('order/updateSubscriptionAction', reqObj)
        .then((response) => {
          if (response && response.success) {
            this.loading = false
            this.getSubscriptionDetails(this.subscriptionId)
            this.showUpdateQuantity = false
          }
        })
        .catch((err) => {
          this.loading = false
          console.log('error', err)
        })
    },
  },
}
</script>
