const routes = [
  {
    path: '/',
    component: () => import('layouts/MainLayout.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('pages/Index.vue'),
        meta: {
          requiresLocation: true,
          clearHistory: true,
        },
      },
      {
        path: '/collection/:seoName',
        name: 'Collection',
        component: () => import('pages/Collection.vue'),
        props: true,
        meta: {
          requiresLocation: true,
          emptyTitle: true,
        },
      },
      {
        path: '/search',
        name: 'Search',
        component: () => import('pages/Search.vue'),
        props: (route) => ({
          query: route.query.q,
          selectedIndex: route.query.si,
        }),
        meta: {
          requiresLocation: true,
          headerTitle: 'Search',
        },
      },
      {
        path: '/category/:seoName',
        name: 'Category',
        component: () => import('pages/Category.vue'),
        meta: {
          requiresLocation: true,
          emptyTitle: true,
        },
      },
      {
        path: '/discount/:seoName?',
        name: 'DiscountDetail',
        component: () => import('pages/DiscountDetail.vue'),
        meta: {
          requiresLocation: true,
          emptyTitle: true,
        },
      },
      {
        path: '/detail/:seoName',
        name: 'Detail',
        component: () => import('pages/Detail.vue'),
        props: true,
        meta: {
          requiresLocation: true,
          emptyTitle: true,
        },
      },
      {
        path: '/location/:locationId',
        name: 'Location',
        component: () => import('pages/Location.vue'),
        meta: {
          emptyTitle: true,
          skipAgeVerification: true,
        },
      },
      {
        path: '/find-store',
        name: 'FindStore',
        component: () => import('pages/SearchLocation.vue'),
      },
      {
        path: '/favorites',
        name: 'Favorites',
        component: () => import('pages/Favorites.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Favorites',
          requiresCustomer: true,
        },
      },
      {
        path: '/account',
        name: 'Account',
        component: () => import('pages/Account.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Account',
        },
      },
      {
        path: '/cart',
        name: 'Cart',
        component: () => import('pages/Cart.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Your Cart',
          requiresCustomer: true,
        },
      },
      {
        path: '/checkout',
        name: 'Checkout',
        component: () => import('pages/Checkout.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Checkout',
          requiresCustomer: true,
        },
      },
      {
        path: '/order-complete/:orderNumber',
        name: 'OrderComplete',
        props: true,
        component: () => import('pages/OrderComplete.vue'),
        meta: {
          requiresLocation: true,
          disableHistory: true,
          clearHistory: true,
          requiresCustomer: true,
        },
      },
      {
        path: '/orders',
        name: 'Orders',
        component: () => import('pages/Orders.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Orders',
          requiresCustomer: true,
        },
      },
      {
        path: '/order/:orderId',
        name: 'OrderDetail',
        component: () => import('pages/OrderDetail.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          headerTitle: 'Order',
          requiresCustomer: true,
        },
      },
      {
        path: '/order-start-return/:orderId',
        name: 'OrderStartReturn',
        component: () => import('pages/OrderStartReturn.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          headerTitle: 'Start Return',
          requiresCustomer: true,
        },
      },
      {
        path: '/reviews',
        name: 'Reviews',
        component: () => import('pages/Reviews.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Reviews',
          requiresCustomer: true,
        },
      },
      {
        path: '/account/address',
        name: 'Address',
        component: () => import('pages/Address.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Address',
          requiresCustomer: true,
        },
      },
      {
        path: '/track-order/:orderId',
        name: 'TrackOrder',
        component: () => import('pages/TrackOrder.vue'),
        props: true,
        meta: {
          headerTitle: 'Track Order',
          requiresCustomer: true,
        },
      },
      {
        path: '/track-guest-order/:orderId',
        name: 'TrackGuestOrder',
        component: () => import('pages/TrackOrder.vue'),
        props: true,
        meta: {
          headerTitle: 'Track Order',
          requiresCustomer: true,
        },
      },
      {
        path: '/order-edit/:orderId',
        name: 'OrderEdit',
        component: () => import('pages/OrderEdit.vue'),
        props: true,
        meta: {
          requiresAuth: true,
          headerTitle: 'Edit Order',
          requiresCustomer: true,
        },
      },
      {
        path: '/order-time/:orderId',
        name: 'OrderTime',
        props: true,
        component: () => import('pages/OrderTime.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Change Time Slot',
          requiresCustomer: true,
        },
      },
      {
        path: '/account/payment',
        name: 'PaymentMethod',
        component: () => import('pages/Payment.vue'),
        meta: {
          requiresAuth: true,
          headerTitle: 'Payment Methods',
          requiresCustomer: true,
        },
      },
      {
        path: '/pages/legal/:slugId',
        name: 'LegalPages',
        component: () => import('pages/LegalPages.vue'),
        meta: {},
      },
      {
        path: '/pages/:slugId',
        name: 'CMSPages',
        component: () => import('pages/CMSPages.vue'),
        meta: {},
        props: true,
      },
      {
        path: '/footer-links/:slugId',
        name: 'FooterLinks',
        component: () => import('pages/FooterLinks.vue'),
        meta: {},
        props: true,
      },
      {
        path: '/manage-wishlist',
        name: 'ManageWishlist',
        component: () => import('pages/Wishlists.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Manage Wishlist',
          requiresCustomer: true,
        },
      },
      {
        path: '/wishlist/:wishListID',
        name: 'Wishlist',
        component: () => import('pages/Wishlist.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Wishlist',
          requiresCustomer: true,
        },
      },
      {
        path: '/loyalty-program',
        name: 'LoyaltyProgram',
        component: () => import('pages/EmptyPage.vue'),
      },
      {
        path: '/customer-service',
        name: 'CustomerSupport',
        component: () => import('pages/CustomerSupport.vue'),
        meta: {
          headerTitle: 'Customer Support',
        },
      },
      {
        path: '/templates-preview',
        name: 'PreviewTemplate',
        component: () => import('pages/PreviewTemplate.vue'),
        props: true,
        meta: {
          pageTitle: 'Preview Template',
          skipAgeVerification: true,
        },
      },
      {
        path: '/manage-subscription',
        name: 'ManageSubscription',
        component: () => import('pages/ManageSubscription.vue'),
        meta: {
          requiresLocation: true,
          headerTitle: 'Manage My Subscription',
          requiresCustomer: true,
        },
      },
      {
        path: '/manage-subscription/:subscriptionId',
        name: 'SubscriptionDetail',
        component: () => import('pages/SubscriptionDetail.vue'),
        meta: {
          emptyTitle: true,
          requiresCustomer: true,
        },
        props: true,
      },
    ],
  },
  // {
  //   path: '/signup',
  //   name: 'SignUp',
  //   component: () => import('pages/SignUp.vue'),
  // },
  // {
  //   path: '/upload-apk',
  //   name: 'UploadAPK',
  //   component: () => import('pages/UploadAPK.vue'),
  // },
  {
    path: '/silent-renew',
    name: 'SilentRenew',
    component: () => import('pages/SilentRenew.vue'),
  },
  {
    path: '/callback',
    name: 'Callback',
    component: () => import('pages/OidcCallback.vue'),
  },

  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    name: 'Error404',
    component: () => import('pages/Error404.vue'),
  },
]

export default routes
