<template>
  <q-expansion-item v-if="isMobile">
    <template v-slot:header>
      <q-item-section avatar>
        <q-avatar size="md" rounded color="tertiary" text-color="white">
          <SvgIcon size="1rem" :icon="subscription.codeName" />
        </q-avatar>
      </q-item-section>
      <q-item-section>
        <div class="row items-center">
          <div class="col">
            <q-item-label
              caption
              v-html="subscription.displayName"
              style="font-size: 9px"
            />
            <q-item-label v-html="subscription.subscriptionNumber" />
            <q-item-label caption style="font-size: 10px">
              {{ getNextOrderDate(subscription) }}
            </q-item-label>
          </div>
          <div class="col-auto q-pl-md">
            <q-chip
              outline
              :color="getSubscriptionStatus(subscription).color"
              :class="getSubscriptionStatus(subscription).class"
            >
              {{ getSubscriptionStatus(subscription).name }}
            </q-chip>
          </div>
        </div>
      </q-item-section>
    </template>

    <q-card>
      <q-card-section class="orderItemMiddle">
        <ul class="orderItemInfo">
          <li>
            <span>Fulfillment</span>
            {{ subscription.displayName }}
          </li>
          <li>
            <span>Subscription ID</span>
            {{ subscription.subscriptionNumber }}
          </li>
          <li>
            <span>Frequency</span>
            Every {{ subscription.frequency }} Days
          </li>
          <li>
            <span>Next Order</span>
            {{ getNextOrderDate(subscription) }}
          </li>
          <li>
            <span>Order Total</span>
            {{ subscription.orderTotal | currency }}
          </li>
        </ul>
        <q-list class="singleItem">
          <q-item>
            <q-item-section avatar>
              <q-avatar square>
                <router-link
                  :to="{
                    name: 'SubscriptionDetail',
                    params: {
                      subscriptionId: subscription.autoShipSubscriptionID,
                    },
                  }"
                >
                  <img :src="subscription.image | defaultProductImage" />
                </router-link>
              </q-avatar>
            </q-item-section>
            <q-item-section>
              <q-item-label>
                <router-link
                  :to="{
                    name: 'Detail',
                    params: {
                      seoName: subscription.seoName,
                    },
                  }"
                >
                  {{ subscription.title }}
                </router-link>
              </q-item-label>
              <q-item-label caption lines="2">
                {{ subscription.qty }} x
                <strong class="q-mr-sm">
                  {{ subscription.price | currency }}
                </strong>
              </q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-card-section>
      <q-card-actions class="q-pa-none">
        <q-btn
          :to="{
            name: 'SubscriptionDetail',
            params: { subscriptionId: subscription.autoShipSubscriptionID },
          }"
          unelevated
          flat
          icon-right="keyboard_arrow_right"
          color="tertiary"
          label="View Details"
          size="13px"
          class="q-px-sm full-width"
        />
      </q-card-actions>
    </q-card>
  </q-expansion-item>
  <div class="full-width" v-else>
    <div class="orderItemTop">
      <div class="row items-center">
        <div class="col-12 col-md-8">
          <ul class="orderItemInfo">
            <li class="oItemIcon content-center" style="flex: 0 0 40px">
              <q-avatar size="md" rounded color="tertiary" text-color="white">
                <SvgIcon size="1rem" :icon="subscription.codeName" />
              </q-avatar>
            </li>
            <li class="oItemStore">
              <span>Fulfillment</span>
              {{ subscription.displayName }}
            </li>
            <li>
              <span>Subscription ID</span>
              {{ subscription.subscriptionNumber }}
            </li>
            <li class="oItemStore">
              <span>Frequency</span>
              Every {{ subscription.frequency }} Days
            </li>
            <li class="oItemDate">
              <span>Next Order</span>
              {{ getNextOrderDate(subscription) }}
            </li>
            <li class="oItemTotal">
              <span>Order Total</span>
              {{ subscription.orderTotal | currency }}
            </li>
          </ul>
        </div>

        <div class="col-12 col-md-4 actions">
          <q-chip
            outline
            :color="getSubscriptionStatus(subscription).color"
            :class="getSubscriptionStatus(subscription).class"
          >
            {{ getSubscriptionStatus(subscription).name }}
          </q-chip>
        </div>
      </div>
    </div>
    <div class="orderItemMiddle">
      <div class="row items-center">
        <div class="col-12 col-md-6">
          <q-list class="singleItem">
            <q-item>
              <q-item-section avatar>
                <q-avatar square>
                  <router-link
                    :to="{
                      name: 'SubscriptionDetail',
                      params: {
                        subscriptionId: subscription.autoShipSubscriptionID,
                      },
                    }"
                    class="bg-white"
                  >
                    <q-img
                      :src="subscription.image | defaultProductImage"
                      contain
                      style="width: 40px; height: 40px"
                    />
                  </router-link>
                </q-avatar>
              </q-item-section>
              <q-item-section>
                <q-item-label>
                  <router-link
                    :to="{
                      name: 'Detail',
                      params: {
                        seoName: subscription.seoName,
                      },
                    }"
                  >
                    {{ subscription.title }}
                  </router-link>
                </q-item-label>
                <q-item-label caption lines="2">
                  {{ subscription.qty }} x
                  <strong class="q-mr-sm">
                    {{ subscription.price | currency }}
                  </strong>
                </q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
        </div>
        <div class="col-12 col-md-6 actions">
          <q-btn
            :to="{
              name: 'SubscriptionDetail',
              params: { subscriptionId: subscription.autoShipSubscriptionID },
            }"
            unelevated
            :rounded="!isMobile"
            color="secondaryOnBody"
            label="View Details"
            size="12px"
            class="q-px-sm q-ml-sm q-mr-sm"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SubscriptionOrderItem',
  props: {
    subscription: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  methods: {
    getSubscriptionStatus(subscription) {
      const statusMap = {
        1: { color: 'green-7', class: 'bg-light-green-1' },
        2: { color: 'amber-8', class: 'bg-amber-1' },
        3: { color: 'deep-orange-6', class: 'bg-deep-orange-1' },
      }

      const defaultStatus = {
        name: subscription.statusName,
        color: '',
        class: '',
      }

      return statusMap[subscription.status]
        ? { name: subscription.statusName, ...statusMap[subscription.status] }
        : defaultStatus
    },
    getNextOrderDate(subscription) {
      let nextOrderDate = '-'
      if (subscription.status == 1) {
        nextOrderDate = this.$options.filters.formatDate(
          subscription.nextOrderDate
        )
      } else if (subscription.status == 2) {
        nextOrderDate = 'Until I Resume'
      }
      return nextOrderDate
    },
  },
}
</script>
