<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-md"
    :before-show="onShow"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                {{ dialogTitle }}
              </h4>
              <div
                class="text-subtitle1 line-height-15 text-black"
                v-show="isSkipAction"
              >
                if you 'skip' a scheduled date, you will not get the order on
                that specific occurence and the next order will be considered.
              </div>
            </div>
          </div>
        </div>
        <q-card-section
          class="text-center q-pa-none"
          :class="[
            isMobile ? 'q-px-md' : 'q-px-xl',
            { 'q-pt-lg': isSkipAction },
          ]"
        >
          <div class="row">
            <div class="col-12 col-md-12">
              <div
                class="text-18 text-weight-bold text-black"
                v-if="properties.order?.scheduleDate"
              >
                Are you sure you want to
                {{ isSkipAction ? 'skip' : 'resume' }} the schedule for
                {{ properties.order.scheduleDate | formatDate }} ?
              </div>
              <div class="form-action q-pt-md">
                <div
                  class="row q-col-gutter-md"
                  :class="isMobile ? '' : 'q-pb-lg'"
                >
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      outline
                      class="q-btn-lg full-width"
                      v-close-popup
                    >
                      Cancel
                    </q-btn>
                  </div>
                  <div class="col">
                    <q-btn
                      unelevated
                      no-caps
                      color="primary"
                      class="q-btn-lg full-width"
                      :loading="loading"
                      @click="onSubmit"
                    >
                      Yes, {{ isSkipAction ? 'Skip' : 'Resume' }}
                    </q-btn>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
function initialState() {
  return {
    dialog: 'SkipResumeScheduleDialog',
    loading: false,
    isSkipAction: false,
  }
}

export default {
  name: 'SkipResumeScheduleDialog',
  data: function () {
    return initialState()
  },
  computed: {
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    dialogTitle() {
      return (this.isSkipAction ? 'Skip' : 'Resume') + ' Schedule'
    },
  },
  methods: {
    onShow() {
      this.isSkipAction =
        this.properties.actionType == 'SKIP_ORDER' ? true : false
    },
    onHide() {
      Object.assign(this.$data, initialState())
    },
    onSubmit() {
      let reqObj = {
        id: this.properties.order.upcomingOrdersID,
        params: {
          actionType: this.isSkipAction ? 1 : 3,
        },
      }
      this.loading = true
      this.$store
        .dispatch('order/upcomingOrderAction', reqObj)
        .then(async (response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing(response.data.subscriptionId)
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.showSuccess(
              `Order ${this.isSkipAction ? 'skipped' : 'resumed'} successfully.`
            )
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
