var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.orderDetail.substitutedOrderLineItems &&
    _vm.orderDetail.substitutedOrderLineItems.length > 0
  )?_c('div',{staticClass:"col-12 col-md-12 subtitute_linked"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 col-md-12 orderSingleLabel"},[_vm._v("\n      Substitutions\n    ")]),_c('div',{staticClass:"col-12 col-md-12"},[_c('div',{staticClass:"cart-section"},_vm._l((_vm.orderDetail.substitutedOrderLineItems),function(substitutedOrderLineItem,index){return _c('div',{key:`${substitutedOrderLineItem.orderLineItemID}-${index}`,staticClass:"cart-order"},[_c('div',{class:[
              'cart-product sd-spacing q-ml-none',
              substitutedOrderLineItem.linkedProducts && substitutedOrderLineItem.linkedProducts.length > 0
                ? 'substitution-product'
                : substitutedOrderLineItem.allowSubstitute
                ? 'substitution-product'
                : '',
                substitutedOrderLineItem.isFulfilled && substitutedOrderLineItem.qty === 0
                ? 'product-not-available'
                : '',
            ]},[_c('div',{staticClass:"cart-produt-data"},[_c('div',{staticClass:"produt-data flex items-start"},[_c('div',{staticClass:"product-image",class:{
                    blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                  }},[_c('box-image',{attrs:{"link":{
                      name: 'Detail',
                      params: { seoName: substitutedOrderLineItem.seoName },
                    },"imageSrc":substitutedOrderLineItem.image,"width":_vm.isMobile ? '80px' : '86px'}})],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h3',{staticClass:"ellipsis",class:{
                        blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                      }},[_c('router-link',{attrs:{"to":{
                          name: 'Detail',
                          params: { seoName: substitutedOrderLineItem.seoName },
                        }}},[_vm._v("\n                        "+_vm._s(substitutedOrderLineItem.productName)+"\n                      ")])],1),_c('div',{staticClass:"cart--labels q-mt-sm"},[(substitutedOrderLineItem.isAdded)?_c('span',{staticClass:"label-success q-mr-xs",domProps:{"innerHTML":_vm._s(`Just Added`)}}):(substitutedOrderLineItem.isUpdated)?_c('span',{staticClass:"label-primary q-mr-xs",domProps:{"innerHTML":_vm._s(`Updated`)}}):_vm._e(),_c('span',{directives:[{name:"show",rawName:"v-show",value:(substitutedOrderLineItem.allowSubstitute),expression:"substitutedOrderLineItem.allowSubstitute"}],staticClass:"allowed"},[_vm._v("\n                        Substitution Allowed\n                        "),_c('SvgIcon',{staticClass:"q-ml-sm",attrs:{"icon":"info-outline"}})],1),(substitutedOrderLineItem.isBlacklisted)?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on\n                        Site\n                      ")],1):_vm._e()]),(substitutedOrderLineItem.instruction)?_c('div',{staticClass:"cart--instruction text-body2 word-break-word q-mt-sm"},[_c('strong',{staticClass:"text-grey-6"},[_vm._v("Item instruction:")]),_c('br'),_vm._v("\n                      "+_vm._s(substitutedOrderLineItem.instruction || '(not set)')+"\n                    ")]):_vm._e(),_c('div',{staticClass:"cart--quantity q-mt-sm"},[_vm._v(_vm._s(substitutedOrderLineItem.qty)+" x\n                      "),_c('span',{domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(substitutedOrderLineItem.price))}})])]),_c('div',{staticClass:"price"},[(substitutedOrderLineItem.totalPrice != substitutedOrderLineItem.finalPrice)?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(substitutedOrderLineItem.totalPrice))}}):_vm._e(),_c('span',{class:substitutedOrderLineItem.totalPrice != substitutedOrderLineItem.finalPrice
                          ? 'discount-price'
                          : '',domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(substitutedOrderLineItem.finalPrice))}})]),(substitutedOrderLineItem.isFulfilled && substitutedOrderLineItem.qty === 0)?_c('div',{staticClass:"product-not-available-label text-negative text-body2"},[_c('span',[_vm._v("Product Not Available")])]):_vm._e()])])]),(substitutedOrderLineItem.linkedProductLineItem)?_c('div',{staticClass:"substitution-product--block"},[_c('div',{staticClass:"substitution-product produt-data flex items-center"},[_c('div',{staticClass:"product-image"},[_c('q-avatar',{attrs:{"square":""}},[_c('box-image',{attrs:{"link":substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                          ? ''
                          : {
                              name: 'Detail',
                              params: {
                                seoName:
                                  substitutedOrderLineItem.linkedProductLineItem
                                    .seoName,
                              },
                            },"imageSrc":substitutedOrderLineItem.linkedProductLineItem.image,"width":"40px"}})],1),_c('q-avatar',{attrs:{"flat":""}},[_c('SvgIcon',{attrs:{"icon":"linked"}})],1)],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h4',[_c('router-link',{attrs:{"to":{
                          name: 'Detail',
                          params: {
                            seoName:
                              substitutedOrderLineItem.linkedProductLineItem.seoName,
                          },
                        }},domProps:{"innerHTML":_vm._s(substitutedOrderLineItem.linkedProductLineItem.productName)}}),_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                        "+_vm._s(substitutedOrderLineItem.linkedProductLineItem.productName)+"\n                      ")])],1),(
                          substitutedOrderLineItem.linkedProductLineItem.isBlacklisted
                        )?_c('span',{staticClass:"text-negative text-body2 block q-mt-xs"},[_c('q-icon',{attrs:{"name":"info"}}),_vm._v(" Product No Longer on Site")],1):_vm._e()]),_c('div',{staticClass:"price"},[(
                        substitutedOrderLineItem.linkedProductLineItem.totalPrice !=
                          substitutedOrderLineItem.linkedProductLineItem.finalPrice &&
                        substitutedOrderLineItem.linkedProductLineItem.totalPrice > 0
                      )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(
                        _vm.$options.filters.currency(
                          substitutedOrderLineItem.linkedProductLineItem.totalPrice
                        )
                      )}}):_vm._e(),_c('span',{class:substitutedOrderLineItem.linkedProductLineItem.qty == 0
                          ? 'discount-price'
                          : ''},[_vm._v("\n                      "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.linkedProductLineItem.finalPrice)))])]),_c('div',{staticClass:"flex product-edit--options"},[_c('div',{staticClass:"product-quantity"},[_c('span',[_vm._v("\n                        "+_vm._s(substitutedOrderLineItem.linkedProductLineItem.qty)+" x\n                        "+_vm._s(_vm._f("currency")(substitutedOrderLineItem.linkedProductLineItem.price))+"\n                      ")])])])])])]):_vm._e(),(substitutedOrderLineItem.newOrderLineItem.length > 0)?_c('div',{staticClass:"q-my-md"},[_c('div',{staticClass:"text-secondary text-weight-bold q-py-sm"},[_vm._v("Substitution Item")]),_c('q-separator')],1):_vm._e(),_vm._l((substitutedOrderLineItem.newOrderLineItem),function(newOrderLineItem,index){return _c('div',{key:`${substitutedOrderLineItem.newOrderLineItem}-${index}`,staticClass:"cart-product-data relative-position",class:!newOrderLineItem.isSubtitutionAccepted ? 'decline' : ''},[_c('div',{staticClass:"produt-data flex items-start"},[_c('div',{staticClass:"product-image",class:{
                    blacklistedproduct: substitutedOrderLineItem.isBlacklisted,
                  }},[_c('box-image',{attrs:{"link":newOrderLineItem.isBlacklisted
                        ? ''
                        : {
                            name: 'Detail',
                            params: {
                              seoName: newOrderLineItem.seoName,
                            },
                          },"imageSrc":newOrderLineItem.image,"width":_vm.isMobile ? '80px' : '86px'}})],1),_c('div',{staticClass:"co-product-details"},[_c('div',{staticClass:"product--title"},[_c('h3',{staticClass:"ellipsis",class:{
                        blacklistedproduct: newOrderLineItem.isBlacklisted,
                      }},[_c('router-link',{attrs:{"to":{
                          name: 'Detail',
                          params: {
                            seoName: newOrderLineItem.seoName,
                          },
                        }}},[_c('span',{class:!newOrderLineItem.isSubtitutionAccepted
                              ? 'block text-strike'
                              : ''},[_vm._v(_vm._s(newOrderLineItem.productName))])])],1),_c('div',{staticClass:"cart--quantity q-mt-sm"},[_vm._v("\n                      "+_vm._s(newOrderLineItem.qty)+" x "+_vm._s(_vm._f("currency")(newOrderLineItem.price))+"\n                    ")])]),_c('div',{staticClass:"price"},[(
                        newOrderLineItem.totalPrice != newOrderLineItem.finalPrice &&
                        newOrderLineItem.totalPrice > 0
                      )?_c('span',{staticClass:"original-price",domProps:{"innerHTML":_vm._s(_vm.$options.filters.currency(newOrderLineItem.totalPrice))}}):_vm._e(),_c('span',{class:!newOrderLineItem.isSubtitutionAccepted
                          ? 'discount-price'
                          : ''},[_vm._v("\n                      "+_vm._s(_vm._f("currency")(newOrderLineItem.finalPrice))+"\n                    ")])]),(newOrderLineItem.isBlacklisted)?_c('div',{staticClass:"product-not-available-label text-negative text-body2 q-mt-sm"},[_c('span',[_vm._v("Product Not Available")])]):_vm._e(),(
                      newOrderLineItem.isSubtitutionAccepted &&
                      !_vm.orderDetail.orderCompleted &&
                      _vm.showDeclineSubstitutionLink
                    )?[_c('div',{staticClass:"caption decline q-mt-sm",class:{
                        invisible:
                          _vm.$route.name == 'TrackGuestOrder' ||
                          (_vm.isLoggedIn && _vm.orderDetail.codeName != 'sp'),
                      }},[_c('q-btn',{attrs:{"unelevated":"","no-caps":"","dense":"","color":"red-1","text-color":"red","padding":"xs md","label":"Decline Substitution"},on:{"click":function($event){return _vm.declineItem(newOrderLineItem)}}})],1)]:(!newOrderLineItem.isSubtitutionAccepted)?[_c('div',{staticClass:"caption decline text-red q-mt-sm"},[_vm._v(" Declined")])]:_vm._e()],2)])])})],2)])}),0)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }