import { render, staticRenderFns } from "./Wishlist.vue?vue&type=template&id=9609a108"
import script from "./Wishlist.vue?vue&type=script&lang=js"
export * from "./Wishlist.vue?vue&type=script&lang=js"
import style0 from "./Wishlist.vue?vue&type=style&index=0&id=9609a108&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QSkeleton from 'quasar/src/components/skeleton/QSkeleton.js';
import QBreadcrumbs from 'quasar/src/components/breadcrumbs/QBreadcrumbs.js';
import QBreadcrumbsEl from 'quasar/src/components/breadcrumbs/QBreadcrumbsEl.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QField from 'quasar/src/components/field/QField.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QSkeleton,QBreadcrumbs,QBreadcrumbsEl,QBtn,QCheckbox,QSelect,QDialog,QCard,QItemLabel,QIcon,QOptionGroup,QInfiniteScroll,QColor,QField});qInstall(component, 'directives', {ClosePopup});
