<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-md"
    :before-show="onShow"
    :onHide="onHide"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                Reschedule Order Date
              </h4>
              <div class="text-subtitle1 line-height-15 text-black">
                To change the order date, please reschedule it. The store will
                process your order on the new date you select.
              </div>
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center q-pa-none q-pt-lg"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
        >
          <q-form @submit="onSubmit">
            <div class="row">
              <div class="col-12 col-md-12" v-show="properties.order">
                <div class="form-field">
                  <q-input
                    outlined
                    dense
                    :value="selectedRescheduleDate"
                    label="Select Date"
                    placeholder="YYYY-MM-DD"
                    :rules="[(val) => validateRequired(val)]"
                    :lazy-rules="lazyValidationRules"
                    @click="openDatePicker"
                    :readonly="true"
                  >
                    <template v-slot:append>
                      <q-icon name="event" class="cursor-pointer">
                        <q-popup-proxy
                          cover
                          ref="qDateProxy"
                          transition-show="scale"
                          transition-hide="scale"
                        >
                          <q-date
                            v-model="calendarDate"
                            mask="YYYY-MM-DD"
                            :options="minTodayDate"
                          >
                            <div
                              class="row items-center justify-end q-gutter-sm"
                            >
                              <q-btn
                                label="Cancel"
                                color="primary"
                                flat
                                @click="cancelDateSelection"
                                v-close-popup
                              />
                              <q-btn
                                label="OK"
                                color="primary"
                                flat
                                v-close-popup
                                @click="selectDate"
                              />
                            </div>
                          </q-date>
                        </q-popup-proxy>
                      </q-icon>
                    </template>
                  </q-input>
                </div>
              </div>
              <div class="col-12 col-md-12">
                <div class="form-action">
                  <div
                    class="row q-col-gutter-md"
                    :class="isMobile ? '' : 'q-pb-lg'"
                  >
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        outline
                        class="q-btn-lg full-width"
                        v-close-popup
                      >
                        Cancel
                      </q-btn>
                    </div>
                    <div class="col">
                      <q-btn
                        unelevated
                        no-caps
                        color="primary"
                        class="q-btn-lg full-width"
                        :loading="loading"
                        type="submit"
                      >
                        Reschedule
                      </q-btn>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </q-form>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { throttle } from 'quasar'

function initialState() {
  return {
    dialog: 'RescheduleOrderDialog',
    rescheduleDate: null,
    calendarDate: null,
    rescheduleDateBetween: {
      from: '',
      to: '',
    },
    loading: false,
    disableDate: '',
  }
}

export default {
  name: 'RescheduleOrderDialog',
  data: function () {
    return initialState()
  },
  computed: {
    properties() {
      return this.getDialogProperties(this.dialog)
    },
    selectedRescheduleDate() {
      let selectedDate = ''
      if (this.rescheduleDate) {
        selectedDate = this.$options.filters.formatDate(
          this.rescheduleDate,
          'MMM DD, YYYY'
        )
      }
      return selectedDate
    },
  },
  methods: {
    openDatePicker: throttle(function (event) {
      if (this.$refs.qDateProxy) this.$refs.qDateProxy.show()
    }, 1000),
    onShow() {
      let scheduleDate = this.$options.filters.formatDate(
        this.properties.order.scheduleDate,
        'YYYY-MM-DD'
      )
      this.disableDate = scheduleDate
    },
    /* onShow() {
      let oScheduleDate,
        scheduleDate = this.properties.order.scheduleDate,
        initialScheduleDate = this.properties.order.previousScheduleDate,
        isScheduleDateBigger =
          new Date(scheduleDate).getTime() >
          new Date(initialScheduleDate).getTime()
      if (isScheduleDateBigger) {
        oScheduleDate = initialScheduleDate
      } else {
        oScheduleDate = scheduleDate
      }

      let sDate = new Date(oScheduleDate),
        eDate = new Date(oScheduleDate)
      sDate.setDate(eDate.getDate() + 1)
      eDate.setDate(eDate.getDate() + this.properties.frequency - 1)
      let sTime = new Date(sDate).getTime(),
        eTime = new Date(eDate).getTime()
      if (eTime > sTime) {
        this.rescheduleDateBetween = {
          from: this.$options.filters.formatDate(sDate, 'YYYY-MM-DD'),
          to: this.$options.filters.formatDate(eDate, 'YYYY-MM-DD'),
        }
      }
      let selectedDate = isScheduleDateBigger ? scheduleDate : sDate
      this.calendarDate = this.$options.filters.formatDate(
        selectedDate,
        'YYYY-MM-DD'
      )
      this.selectDate()
    }, */
    onHide() {
      Object.assign(this.$data, initialState())
    },
    minTodayDate(d) {
      /* let todayDate = new Date().toISOString().slice(0, 10)
      d = d.split('/').join('-')
      if (this.rescheduleDateBetween?.from && this.rescheduleDateBetween?.to) {
        let sTime = new Date(this.rescheduleDateBetween.from).getTime(),
          eTime = new Date(this.rescheduleDateBetween.to).getTime(),
          todayTime = new Date(d).getTime()
        return todayTime >= sTime && todayTime <= eTime
      } else {
        return todayDate <= d
      } */
      let todayDate = new Date()
      todayDate.setDate(todayDate.getDate() + 1)
      d = d.split('/').join('-')
      return (
        todayDate.toISOString().slice(0, 10).split('/').join('-') <= d &&
        this.disableDate != d
      )
    },
    selectDate() {
      this.rescheduleDate = this.calendarDate
    },
    cancelDateSelection() {
      this.calendarDate = this.rescheduleDate
    },
    onSubmit() {
      let reqObj = {
        id: this.properties.order.upcomingOrdersID,
        params: {
          actionType: 2,
          rescheduleDate: this.$options.filters.formatDate(
            this.rescheduleDate,
            'YYYY-MM-DD'
          ),
        },
      }
      this.loading = true
      this.$store
        .dispatch('order/upcomingOrderAction', reqObj)
        .then(async (response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.loading = false
            this.properties.refreshListing(response.data.subscriptionId)
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
            this.showSuccess('Order is rescheduled successfully.')
          } else this.loading = false
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
        })
    },
  },
}
</script>
