import { isObject } from 'src/utils'

export const isPWAPopupDenied = (state) => state.pwaPopupDenied

export const getCartPersistedInfo = (state) => (cartNumber) =>
  state.cartPersistedInfo && state.cartPersistedInfo[cartNumber]
    ? state.cartPersistedInfo[cartNumber]
    : null

export const isCartSectionPined = (state) => state.isCartSectionPined

export const getFilterByPage = (state) => (page) => {
  if (state.filters[page] && isObject(state.filters[page]))
    return state.filters[page]

  if (page === 'favorite') {
    return {
      sortBy: 0,
      category: '',
      isListView: false,
    }
  } else if (page === 'wishlist') {
    return {
      sortBy: 'Price Low to High',
      isListView: false,
    }
  } else if (page == 'order') {
    return {
      selectedFilter: 'All Orders',
      filterOptions: [],
    }
  } else if (page == 'auto-ship-order') {
    return {
      filterByStatus: '',
      filterByUpcoming: '',
      StartDate: '',
      EndDate: '',
    }
  }

  return {
    sortBy: 'Price Low to High',
    pageSize: '10',
    isListView: false,
  }
}

export const orderFilters = (state) => state.orderFilters

export const showAppDownloadNotification = (state) =>
  state.showAppDownloadNotification

export const disclaimerUpdate = (state) => state.disclaimerUpdate

export const announcementUpdate = (state) => state.announcementUpdate

export const selectedDeliveryAddress = (state) => state.deliveryAddress

export const selectedAddressForFulfillmentCode = (
  state,
  getters,
  rootState,
  rootGetters
) => {
  return (fulfillmentCode) => {
    let cartDetail = rootGetters['cart/currentCartDetails']
    let selectedAddress
    // let selectedAddress = state.selectedAddress[fulfillmentCode]
    // if (selectedAddress) {
    //   let addressAvailable = rootGetters['address/getAddressByAddressID'](
    //     selectedAddress.addressId
    //   )
    //   return addressAvailable
    //     ? addressAvailable
    //     : rootGetters['address/defaultAddress']
    // }

    if (cartDetail?.fulfillments) {
      for (let fId in cartDetail.fulfillments) {
        let fulfillment = cartDetail.fulfillments[fId]
        if (fulfillment.codeName == fulfillmentCode && fulfillment.addressID) {
          for (let key in rootGetters['address/addresses']) {
            if (key == fulfillment.addressID) {
              selectedAddress = rootGetters['address/addresses'][key]
            }
          }
        }
      }
    }

    // return default address
    if (!selectedAddress)
      selectedAddress = rootGetters['address/defaultAddress']
    return selectedAddress
  }
}

export const subscribeToLNS = (state) => !!state.subscribeToLNS

export const isContactlessdelivery = (state) => state.isContactlessdelivery
