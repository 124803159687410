<template>
  <!-- Sub Header Start -->
  <div class="subHeader">
    <div class="sh-center">
      <CategorySlider />
    </div>
    <div class="sh-right">
      <q-btn
        :to="{ name: 'DiscountDetail' }"
        unelevated
        no-caps
        flat
        class="full-width"
      >
        Discounts & Offers
      </q-btn>
    </div>
  </div>
  <!-- Sub Header End -->
</template>

<script>
import CategorySlider from 'components/CategorySlider.vue';

export default {
  name: 'Header',

  components: {
    CategorySlider,
  },
  data() {
    return {}
  },
}
</script>

<style lang="scss">
.subHeader {
  padding: 0;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  /* background: darken($primary, 10%); */
  overflow: hidden;
  &:before {
    content: '';
    background: $primary;
    background: var(--q-color-primary) !important;
    filter: brightness(80%);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
  }
  .sh-left {
    flex: 0 0 auto;
    width: 50px;
    max-width: 100%;
  }
  .sh-center {
    flex: 1 0 0%;
    width: 100%;
    max-width: calc(100% - 300px);
    padding: 0 12px;
    .q-category-slider {
      padding: 0;
      .swiper-slide {
        a {
          padding: 5px 10px;
          font-size: 14px;
          font-weight: normal;
          background: transparent;
          color: #fff;
          border-color: transparent;
          &:hover {
            text-decoration: underline;
            background: transparent !important;
            color: #fff !important;
            border-color: transparent !important;
          }
        }
      }
      .swiper-container {
        padding: 0 35px;
        &:after,
        &:before {
          content: none;
        }
      }
      .swiper-button-prev,
      .swiper-button-next {
        width: 28px;
        height: 28px;
        margin-top: -14px;
        background: $secondary;
        background: var(--q-color-secondary) !important;
        border: 1px solid $secondary;
        border: 1px solid var(--q-color-secondary) !important;
        color: #fff !important;
        &:after {
          //font-size: 10px;
          content: '';
          width: 8px;
          height: 8px;
          display: block;
          border-right: 1px solid #fff;
          border-top: 1px solid #fff;
          border-right-color: var(--q-color-secondaryText) !important;
          border-top-color: var(--q-color-secondaryText) !important;
          transform: rotate(45deg);
          position: absolute;
          left: 50%;
          top: 50%;
          margin-top: -4px;
          margin-left: -6px;
        }
      }
      .swiper-button-prev:after {
        transform: rotate(-135deg);
        margin-left: -3px;
      }
    }
  }
  .sh-right {
    flex: 0 0 auto;
    width: 300px;
    max-width: 100%;
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 50%;
      border-top: 30px solid transparent;
      border-bottom: 30px solid transparent;
      border-left: 20px solid $primary;
      border-left: 20px solid var(--q-color-primary) !important;
      filter: brightness(80%);
      margin-top: -30px;
      z-index: 1;
    }
    .q-btn {
      padding: 8px 0px;
      padding-left: 30px;
      background: url(/images/loyalty.svg) center top no-repeat;
      border: none;
      @include border-radius(0);
      .q-btn__content {
        justify-content: flex-start;
        font-size: 18px;
        font-weight: bold;
        color: #2e50ae;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    display: none;
  }
}
</style>
