import Vue from 'vue'
import store from '..'

export function SET_RECENT_ORDER(state, orderNumber) {
  state.recentOrderNumber = orderNumber
}

export function SET_ORDER_DETAIL(state, orderDetail) {
  for (const orderNum in orderDetail) {
    orderDetail[orderNum].orderFulfillments.forEach((fulfillment) => {
      let breakIndex

      for (
        let i = fulfillment.orderLineItemsModelsList.length - 1;
        i >= 0;
        i--
      ) {
        if (!fulfillment.orderLineItemsModelsList[i].isLinkedProduct) break
        else {
          fulfillment.orderLineItemsModelsList.every(function (
            orderLineItemsModel
          ) {
            if (
              orderLineItemsModel.orderLineItemID ==
              fulfillment.orderLineItemsModelsList[i].parentLinkedLineItemId
            ) {
              if (!orderLineItemsModel.linkedProducts)
                orderLineItemsModel.linkedProducts = []

              orderLineItemsModel.linkedProducts.push(
                fulfillment.orderLineItemsModelsList[i]
              )

              return false
            }
            return true
          })
          breakIndex = i
        }
      }

      if (breakIndex) {
        fulfillment.orderLineItemsModelsList =
          fulfillment.orderLineItemsModelsList.splice(0, breakIndex)
      }
    })
  }

  state.orders = Object.assign({}, state.orders, orderDetail)
}

export function SET_ORDER_TRACK_INFO(state, trackOrderDetail) {
  state.trackOrderDetail = Object.assign(
    {},
    state.trackOrderDetail,
    trackOrderDetail
  )
}

export function SET_ORDER_INFO(state, detail) {
  state.detail = Object.assign({}, state.detail, detail)
}

export function DELETE_ORDER_INFO(state, orderId) {
  Vue.delete(state.detail, orderId)
}

export function SET_EDIT_ORDER_INFO(state, detail) {
  try {
    this.commit('cart/UPDATE_RECENT_SUBSTITUTE_ON_EDITORDER', detail, {
      root: true,
    })
  } catch {}
  state.editDetail = Object.assign({}, state.editDetail, detail)
}

export function DELETE_ORDER_DETAIL(state, orderNumber) {
  Vue.delete(state.orders, orderNumber)
}

export function SET_ORDER_CATEGORIES(state, categories) {
  state.categories = [
    {
      categoryID: '',
      categoryName: 'All categories',
      hasChild: false,
    },
    ...categories,
  ]
}

export function SET_RECENT_ITEMS(state, item) {
  state.recentItems = Object.assign({}, state.recentItems, item)
}

export function DELETE_RECENT_ITEM(state, productId) {
  Vue.delete(state.recentItems, productId)
}

export function RESET_RECENT_ITEM(state) {
  state.recentItems = Object.assign({})
}

export function SET_RECENT_UPDATED_ORDER(state, orderDetail) {
  if (orderDetail) state.recentUpdatedOrder = Object.assign({}, orderDetail)
  else state.recentUpdatedOrder = null
}

export function SET_PARKING_SPOTS(state, parkingSpots) {
  state.parkingSpots = parkingSpots
}
export function SET_SUBSCRIPTION_INFO(state, subscriptionDetail) {
  state.subscriptionDetail = Object.assign(
    {},
    state.subscriptionDetail,
    subscriptionDetail
  )
}
export function SET_SLOT_INFO(state, info) {
  state.slotInfo = Object.assign({}, state.slotInfo, info)
}

export function UPDATE_TRACK_ORDER_DETAIL(state, updatedDetail) {
  let { orderId } = updatedDetail

  if (orderId && state.trackOrderDetail[orderId])
    state.trackOrderDetail[orderId] = Object.assign(
      {},
      state.trackOrderDetail[orderId],
      updatedDetail
    )
}

export function CLEAR_TRACK_ORDER_DETAIL(state, orderId) {
  Vue.delete(state.slotInfo, orderId)
}

export function SET_SLOT_LOADING(state, loading) {
  state.loadingSlot = loading
}

export function SET_ORDER_ALERT(state, { alertKey, alertData }) {
  if (typeof alertData === 'object' && alertData !== null)
    Vue.set(state.alertData, alertKey, alertData)
  else Vue.set(state.alertData, alertKey, null)
}

export function SET_UPDATE_TIME_SLOT(state, orderId) {
  Vue.set(state.updateTimeSlot, orderId, true)
}

export function CLEAR_UPDATE_TIME_SLOT(state, orderId) {
  Vue.delete(state.updateTimeSlot, orderId)
}

export function SET_FILTER_SEARCH(state, searchBy) {
  state.filterSearchBy = searchBy
}
