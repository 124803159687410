<template>
  <div
    ref="addToCartButton"
    :class="['product-cart', showLoader ? 'product-cart-loading' : '']"
  >
    <div :class="['add-cart ', 'addDelivery']">
      <!-- Loader -->
      <q-inner-loading
        :showing="showLoader"
        style="background: rgb(0 0 0 / 32%); border-radius: 4px"
      >
        <q-spinner color="white" size="20px" :thickness="3" />
      </q-inner-loading>
      <!-- Loader -->
      <!-- Need to add class when user added this item: addDelivery  -->
      <div
        class="full-width flex items-center justify-center"
        @click="addItemsInCart(1)"
      >
        <div class="icon hover" v-if="cartDelivery">
          <SvgIcon size="1rem" :icon="cartDelivery" />
        </div>
        <div class="btn"> Add to Cart </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { convertArrayToObject } from 'src/utils'
import { testModePaymentMessage } from 'src/constants'

export default {
  name: 'AddAllToCartBtn',
  props: {
    productFulFillments: {
      type: Array,
      default: () => {
        return new Array()
      },
    },
    companionProducts: {
      type: Object,
      default: () => {
        return {}
      },
    },
    selectedProducts: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      cartDelivery: '',
      showLoader: false,
    }
  },
  computed: {
    products() {
      if (this.companionProducts.hasOwnProperty('productID')) {
        return convertArrayToObject([this.companionProducts], 'productID')
      } else if (Array.isArray(this.companionProducts)) {
        return convertArrayToObject([this.companionProducts], 'productID')
      }
      return Object.keys(this.companionProducts)
    },
    isItemHasSelectedFulFillmentOption() {
      if (this.cartDelivery) {
        for (let fulFillment of this.productFulFillments) {
          if (
            fulFillment.codeName == this.cartDelivery &&
            fulFillment.available
          ) {
            return true
          }
        }
      }
      return false
    },
    fulFillmentId() {
      return this.selectedFulfillment.fulfillmentId
    },
    cartItemInfo() {
      if (!this.products.length) {
        return {}
      }

      let cartDetails = {},
        cartInfo = {}

      cartDetails = this.currentCartDetails

      for (let productId of this.products) {
        let isItemInCart = false
        let cartItemInfo = {
          cartQty: 0,
          orderLineItemID: '',
          cartDelivery: '',
        }

        if (cartDetails && cartDetails.orderID) {
          for (let fId in cartDetails.fulfillments) {
            const fulFillment = cartDetails.fulfillments[fId]
            for (let olItemID in fulFillment.shoppingCartItems) {
              const item = fulFillment.shoppingCartItems[olItemID]

              if (item.productId == productId) {
                isItemInCart = true
                cartItemInfo.cartDelivery = fulFillment.codeName
                cartItemInfo.cartQty = item.qty
                cartItemInfo.orderLineItemID = item.orderLineItemID

                break
              }

              if (isItemInCart) break
            }
          }
        }
        cartInfo[productId] = cartItemInfo
      }

      return cartInfo
    },
    ...mapGetters('cart', ['currentCartDetails']),
  },
  methods: {
    requestObject(incrementQty) {
      let objRequest = {
        fulfillmentMethodId: this.fulFillmentId,
      }

      let products = []

      for (let productId of this.products) {
        if (this.selectedProducts.includes(productId)) {
          products.push({
            productId,
            qty: 1,
            // parseInt(this.cartItem(productId).cartQty) +
            // parseInt(incrementQty),
          })
        }
      }

      objRequest.products = products

      return objRequest
    },
    cartItem(productId) {
      return this.cartItemInfo && this.cartItemInfo[productId]
        ? this.cartItemInfo[productId]
        : {}
    },
    addItemsInCart(incrementQty) {
      if (
        this.businessConfigurations.mode === 2 &&
        this.businessConfigurations.currentPaymentGateway == null
      ) {
        this.showError(testModePaymentMessage)
      } else if (
        this.isLoggedIn ||
        (!this.isLoggedIn && this.businessConfigurations?.enableGuestCheckout)
      ) {
        this.setLoading(true)
        let objRequest = this.requestObject(incrementQty)

        this.$store
          .dispatch('cart/addUpdateCartDetail', objRequest)
          .finally(() => this.setLoading(false))
      } else if (!this.isLoggedIn)
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
    },
    notify(message) {
      this.showSuccess(
        message,
        this.$route.name != 'Cart'
          ? [
              {
                color: 'dark',
                label: 'Go To Cart',
                color: 'orange-5',
                handler: () => {
                  if (this.isMobile)
                    this.$router
                      .push({
                        name: 'Cart',
                      })
                      .catch(() => {})
                  else
                    this.changeDialogState({
                      dialog: 'cartDrawer',
                      val: true,
                    })
                },
              },
            ]
          : []
      )
    },
    setLoading(loading = false) {
      this.showLoader = loading
    },
  },
  created() {
    this.cartDelivery = this.selectedFulfillmentCodeName
  },
}
</script>
<style lang="scss">
.product-cart {
  position: relative;
  width: 100%;
  .q-btn {
    font-family: 'Lato', Arial, sans-serif;
    font-weight: normal;
  }
  .dropdown {
    display: none;
    position: absolute;
    left: 0;
    right: 0;
    top: calc(100% - 1px);
    color: $tertiary;
    background: #fff;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    border-radius: 0px 0px 3px 3px;
    z-index: 3;
    transition: all 0.3s ease-in-out;
    .q-item {
      min-height: 30px;
      padding: 2px 10px;
    }
    .q-item__section--avatar {
      min-width: 24px;
      padding-right: 5px;
      justify-content: center;
    }
    .q-item__section--main {
      font-size: 12px !important;
      color: #314154;
    }
    .q-radio__inner {
      width: 0.4em;
      min-width: 0.4em;
      height: 0.4em;
    }
    .q-hoverable:hover .q-focus-helper {
      background: $secondary;
      background: var(--q-color-secondary) !important;
    }
  }
  .q-btn__wrapper {
    width: auto;
  }
  &:hover .q-btn--rectangle {
    border-radius: 3px 3px 0px 0px;
  }
  &:hover .q-btn--outline .q-btn__wrapper:before {
    border-color: $secondary;
    border-color: var(--q-color-secondaryOnBody) !important;
  }
  .hover:hover .dropdown {
    display: block;
  }
  .add-cart {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    background: $white;
    color: $tertiary;
    border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    border-radius: 5px;
    cursor: pointer;
    .icon,
    .btn,
    .arrow {
      padding: 10px 15px;
      min-height: 17px;
    }
    .icon {
      padding: 10px;
      line-height: 16px;
      display: none;
      i {
        font-size: 14px;
      }
    }
    &.addDelivery .icon {
      display: flex;
    }
    .btn {
      font-size: 14px;
      padding: 10px 15px;
    }
    .arrow {
      border-left: 1px solid $secondary;
      border-left: 1px solid var(--q-color-secondary) !important;
      .ar {
        line-height: 13px;
      }
      i {
        font-size: 12px;
      }
    }
    .dropdown {
      left: 0px;
      right: 0px;
    }
    &.addDelivery {
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $white;
      color: var(--q-color-secondaryText) !important;
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondaryOnBody) !important;
      cursor: pointer;
      .arrow {
        border-left: 1px solid #60c4ec;
      }
    }
  }
  .added-cart {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: stretch;
    justify-content: center;
    color: $tertiary;
    // border: 1px solid $secondary;
    border: 1px solid var(--q-color-secondaryOnBody) !important;
    // outline: 1px solid var(--q-color-secondaryOnBody);
    border-radius: 5px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    .icon,
    .btn {
      //padding: 10px 15px;
      min-height: 17px;
    }
    .icon {
      padding: 9px 10px;
      background: $secondary;
      background: var(--q-color-secondary) !important;
      color: $white;
      color: var(--q-color-secondaryText) !important;
      line-height: 16px;
      cursor: pointer;
      border-radius: 0;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      i {
        font-size: 14px;
      }
    }
    .btn {
      /* flex: 10000 0 0%; */
      font-size: 14px;
      padding: 0;
      .q-focus-helper,
      .q-ripple {
        display: none;
      }
    }
    .q-field__control,
    .q-field__marginal {
      height: 35px;
    }
    .q-field__native {
      text-align: center;
      @media (max-width: 1023px) {
        line-height: 18px !important;
      }
    }
    .q-field--outlined .q-field__control:before,
    .q-field--outlined .q-field__control:after {
      border: none;
      outline: none;
      box-shadow: none;
    }
  }
  /* Bottom */
  &:hover .add-cart {
    border-radius: 5px 5px 0px 0px;
  }
  &:hover .addDelivery {
    border-radius: 5px 5px 5px 5px;
  }
  &.no-rounder:hover {
    .add-cart.addDelivery,
    .added-cart {
      border-radius: 5px 5px 0 0;
      .icon {
        border-top-left-radius: 0;
      }
    }
    .added-cart {
      .dropdown {
        left: -1px;
        right: -1px;
        bottom: calc(100% - 1px);
        top: auto;
      }
    }
    .addDelivery {
      border-radius: 5px;
    }
  }
  /* Bottom */
  /* Top */
  &.top {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondary) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
  /* Top */
}
.q-product-slider {
  .product-cart {
    .dropdown {
      border: 1px solid $secondary;
      border: 1px solid var(--q-color-secondary) !important;
      top: auto;
      bottom: calc(100% - 1px);
      border-radius: 3px 3px 0px 0px;
    }
    &:hover .add-cart {
      border-radius: 0px 0px 5px 5px;
    }
    &:hover .addDelivery {
      border-radius: 5px 5px 5px 5px;
    }
    &.no-rounder:hover {
      .add-cart.addDelivery,
      .added-cart {
        border-radius: 0px 0px 5px 5px;
      }
    }
  }
}
</style>
