<template>
  <q-toolbar class="betaMode bg-secondary text-center justify-center q-pa-xs">
    <p class="q-ma-none flex justify-center no-wrap items-center full-width">
      <span
        class="text-weight-bold q-mr-xs"
        v-text="mode == 2 ? 'TEST:' : 'BETA:'"
      ></span>

      <span v-if="!shortDescription">
        {{ mode == 2 ? 'Test' : 'Beta' }} mode enabled
      </span>
      <span
        :class="[{ ellipsis: isMobile }]"
        v-else-if="modeDescription.length > 125"
      >
        {{ shortDescription }}
      </span>
      <span :class="[{ ellipsis: isMobile }]" v-else>{{
        modeDescription
      }}</span>
      <q-btn
        dense
        unelevated
        no-caps
        no-wrap
        label="See details"
        class="text-underline"
        v-if="modeDescription.length > 125 || isMobile"
        @click="betaMode = true"
      />
    </p>
    <q-dialog
      v-model="betaMode"
      maximized
      :seamless="!isMobile"
      content-class="betaMode__dialog"
      :position="isMobile ? 'bottom' : 'top'"
    >
      <q-card flat class="bg-secondary text-white full-width">
        <q-card-section class="row items-center justify-center no-wrap">
          <div class="col-8">
            <div class="container q-pt-md q-pt-md-none">
              <div
                class="row items-start"
                :class="$q.screen.lt.md ? 'q-col-gutter-xs' : 'q-col-gutter-lg'"
              >
                <div
                  class="col-12 col-md-3"
                  :class="[{ 'text-right': $q.screen.gt.sm }]"
                >
                  <SvgIcon
                    size="5rem"
                    :icon="mode == 2 ? 'test-mode' : 'beta-mode'"
                    class="text-secondary"
                  />
                </div>
                <div class="col col-md word-break-word">
                  <span
                    class="text-weight-bold q-mr-xs"
                    v-text="mode == 2 ? 'TEST:' : 'BETA:'"
                  ></span>
                  {{
                    modeDescription
                      ? modeDescription
                      : mode == 2
                      ? 'Test mode enabled'
                      : 'Beta mode enabled'
                  }}
                  <br />
                  <q-btn
                    dense
                    unelevated
                    no-caps
                    label="Yes, Got it"
                    class="text-underline text-body1 text-weight-bold no-hover q-mt-lg q-mt-md-sm q-mb-md q-mb-md-none"
                    padding="0"
                    @click="betaMode = false"
                  />
                </div>
                <div class="col-md-3 q-pa-none"></div>
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
  </q-toolbar>
</template>

<script>
export default {
  name: 'ModeHeader',
  data() {
    return {
      betaMode: false,
    }
  },
  props: {
    mode: {
      type: Number,
    },
  },
  computed: {
    modeDescription() {
      return (
        (this.businessConfigurations &&
          this.businessConfigurations?.modeDescription) ||
        ''
      )
    },
    shortDescription() {
      return this.modeDescription
        ? this.modeDescription.slice(0, 125) + '...'
        : ''
    },
  },
}
</script>
<style lang="scss">
.betaMode {
  min-height: 32px;
  border-bottom: 1px solid #ffffff33;
  @media (max-width: 1023px) {
    position: fixed;
    bottom: 72px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    padding: 10px 15px;
    &__dialog {
      text-align: center;
      .q-dialog__inner {
        right: 15px;
        left: 15px;
        bottom: 5px;
      }
    }
  }
}
</style>
