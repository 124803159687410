import { render, staticRenderFns } from "./QuickProductDetailDialog.vue?vue&type=template&id=74213a5c"
import script from "./QuickProductDetailDialog.vue?vue&type=script&lang=js"
export * from "./QuickProductDetailDialog.vue?vue&type=script&lang=js"
import style0 from "./QuickProductDetailDialog.vue?vue&type=style&index=0&id=74213a5c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QColor from 'quasar/src/components/color/QColor.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QBtn,QScrollArea,QColor});qInstall(component, 'directives', {ClosePopup});
