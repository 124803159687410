<template>
  <div id="q-app">
    <!-- <iframe
      :src="`${OIDC_AUTHORITY}/cookietest.html`"
      class="hidden"
      v-if="isLoggedIn"
    /> -->
    <div class="site-loader" v-show="!appLoaded">
      <div></div>
      <div></div>
    </div>

    <component :is="currentComponentName" v-if="currentComponentName" />
    <router-view v-else />
    <q-no-ssr>
      <DisclaimerDialog />
      <AnnouncementDialog />

      <template v-if="businessConfigurations.isStoreAgeRestricted">
        <PersonalInfoDialog />
        <EAIVSpinner />
      </template>

      <div class="iframe-container">
        <LNSIFrame />
      </div>

      <!-- Install PWA Dialog -->
      <InstallationBanner
        v-if="isBeforeInstallPromptSupported"
        :show="appLoaded && showPWAInstallPopup"
        is-dialog
      />
      <InstallationInstructions
        v-else
        :show="appLoaded && showPWAInstallPopup"
        is-dialog
      />
      <!-- Install PWA Dialog -->
    </q-no-ssr>
  </div>
</template>

<script>
import NoConnection from 'components/NoConnection'
import StoreNotActive from 'components/StoreNotActive'
import BetaModeDialog from 'components/common/modes/BetaModeDialog'
import TestModeDialog from 'components/common/modes/TestModeDialog'
import OfflineModeDialog from 'components/common/modes/OfflineModeDialog'

import { throttle, Platform } from 'quasar'
import { mapGetters } from 'vuex'
import LNSIFrame from 'components/LNS/IFrame'
import { reloadPage, isDateEqual } from 'src/utils'
import {
  setAVSessionID,
  removeAVSessionID,
  tempCacheStorage,
} from 'src/boot/axios'
import EAIVSpinner from 'components/dialog/eaiv/EAIVSpinner'
import HistoryMixin from 'src/utils/HistoryMixin'
import IdentityMixin from 'src/utils/IdentityMixin'
import DisclaimerAnnouncementMixin from 'src/utils/DisclaimerAnnouncementMixin'
// import { iosPWASplash } from 'src/utils'
import { isSsr } from 'src/constants'

var pwaEventObj = null

export default {
  name: 'App',
  // data() {
  //   return {
  //     // OIDC_AUTHORITY: process.env.OIDC_AUTHORITY,
  //   }
  // },
  meta() {
    return {
      title: 'Online Store',
      ...this.commonMeta,
      ...this.newCommonMeta,
    }
  },
  components: {
    NoConnection,
    BetaModeDialog,
    StoreNotActive,
    TestModeDialog,
    OfflineModeDialog,
    PersonalInfoDialog: () =>
      import('components/dialog/eaiv/PersonalInfoDialog.vue'),
    EAIVSpinner,
    LNSIFrame,
    AgeVerification: () => import('src/pages/AgeVerification.vue'),
    DisclaimerDialog: () => import('components/dialog/DisclaimerDialog.vue'),
    AnnouncementDialog: () =>
      import('components/dialog/AnnouncementDialog.vue'),
    InstallationBanner: () =>
      import('src/components/pwa/InstallationBanner.vue'),
    InstallationInstructions: () =>
      import('src/components/pwa/InstallationInstructions.vue'),
  },
  computed: {
    ...mapGetters('common', [
      'appLoaded',
      'networkStatus',
      'storeConfigurationLoaded',
      'tenantFound',
      'storeIsActive',
      'showPWAInstallPopup',
    ]),
    ...mapGetters('persisted', [
      'activeMode',
      'eaivPersistedSessionId',
      'isBetaModeActive',
      'isTestModeActive',
      'isOfflineModeActive',
      'isBetaAccessCodeValidated',
    ]),
    ...mapGetters('persistedLocal', ['isPWAPopupDenied']),
    ...mapGetters('customer', ['customer']),
    eaivSessionId() {
      return this.$route.query?.sessionId
    },
    currentComponentName() {
      let component

      if (!this.networkStatus) component = 'NoConnection'
      else if (this.isBetaModeActive && !this.isBetaAccessCodeValidated)
        component = 'BetaModeDialog'
      else if (this.isTestModeActive && !this.isBetaAccessCodeValidated)
        component = 'TestModeDialog'
      else if (this.isOfflineModeActive && !this.isBetaAccessCodeValidated)
        component = 'OfflineModeDialog'
      else if (
        this.storeConfigurationLoaded &&
        !(this.tenantFound && this.storeIsActive)
      )
        component = 'StoreNotActive'
      else if (
        this.isAgeVerificationRequired &&
        this.$route.name &&
        !this.$route.meta?.skipAgeVerification
      )
        component = 'AgeVerification'

      return component
    },
    allowedBrowsers() {
      let isShow = false
      if (
        Platform?.is?.mobile &&
        (Platform?.is?.android || (Platform?.is?.ios && Platform?.is?.safari))
      )
        isShow = true
      return isShow
    },
  },
  mixins: [HistoryMixin, IdentityMixin, DisclaimerAnnouncementMixin],
  created() {
    const _this = this
    if (this.isCordova) {
      document.addEventListener(
        'deviceready',
        () => {
          this.$store.commit(
            'common/SET_NETWORK_STATUS',
            this.checkConnection()
          )

          document.addEventListener(
            'offline',
            () => {
              this.$store.commit('common/SET_NETWORK_STATUS', 0)
            },
            false
          )

          document.addEventListener(
            'online',
            () => {
              if (this.isCordova) {
                this.$router
                  .go(0)
                  .finlly(() =>
                    this.$store.commit('common/SET_NETWORK_STATUS', 1)
                  )
              } else this.$store.commit('common/SET_NETWORK_STATUS', 1)
            },
            false
          )

          document.addEventListener(
            'touchend',
            throttle(() => {
              if (_this.isLoggedIn) {
                _this.$store.dispatch('auth/getUserInfo')
                _this.$store.dispatch('notification/getAllNotification')
              }
            }, 5000),
            false
          )
        },
        false
      )

      if (typeof window !== 'undefined') {
        window.handleOpenURL = (url) => {
          let splitUrl = url.split('://')
          if (!splitUrl[1]) reloadPage()
          if (splitUrl[1] && /sessionId=/.test(splitUrl[1])) {
            let callbackParams = splitUrl[1].split('?')
            let params = new URLSearchParams(callbackParams[1])
            if (params.get('sessionId')) {
              this.$store.commit('ageVerification/SET_LOADING', true)
              this.fetchSessionDetail(params.get('sessionId'))
            }
          } else {
            this.changeDialogState({
              dialog: 'accountDrawer',
              val: false,
            })

            let splitUrl = url.split('://')

            if (!splitUrl[1]) reloadPage()
            this.$router.push(splitUrl[1])
          }
        }
      }
    }
  },
  beforeMount() {
    if (isSsr && this.$route.name !== 'Callback')
      this.$store.dispatch('common/getConfiguration')
  },
  mounted() {
    const _this = this
    window.onfocus = () => {
      let promises = []
      if (this.isLoggedIn) {
        promises = [
          this.$store.dispatch('auth/getUserInfo'),
          this.$store.dispatch('notification/getAllNotification'),
        ]
      }
      if (this.activeMode == 1)
        promises.push(this.$store.dispatch('common/getConfiguration'))

      Promise.all(promises).finally(() => {})
    }

    // window.addEventListener(
    //   'message',
    //   (evt) => {
    //     if (evt.data === 'FTX:3PCsupported') {
    //       this.$oidc.events.addUserSignedOut(async () => {
    //         //this.$oidc.signoutRedirect() //the reason I removed this is, 3PC is called when session is endned by another app/tab, in that case when we make end session request again it fails as session was ended by another app already.
    //         // reloadPage()

    //         let userInfo = await _this.$store.dispatch('auth/oidcGetUser')
    //         if (userInfo && userInfo.access_token)
    //           _this.$store.dispatch('auth/logout')
    //         else reloadPage()
    //       })
    //     }
    //     // else if (evt.data === 'MM:3PCunsupported') console.log('Not Supported')
    //   },
    //   false
    // )
    document.addEventListener('deviceready', this.onDeviceReady, false)

    document.getElementById('mainPreLoader')?.remove()

    /* Start: PWA installation popup */
    if (_this.isBeforeInstallPromptSupported) {
      _this.$root.$on('handle-pwa-action', _this.handlePWAAction)

      window.addEventListener('beforeinstallprompt', (event) => {
        event.preventDefault()
        pwaEventObj = event

        _this.$store.commit('common/UPDATE_STATE', {
          key: 'showPWAInstallPopupSidebar',
          val: true,
        })

        !_this.isPWAPopupDenied &&
          _this.$store.commit('common/UPDATE_STATE', {
            key: 'showPWAInstallPopup',
            val: true,
          })
      })
    } else if (
      !!_this.isMobile &&
      !_this.isPWA &&
      !_this.isPWAPopupDenied &&
      _this.allowedBrowsers
    ) {
      _this.$store.commit('common/UPDATE_STATE', {
        key: 'showPWAInstallPopup',
        val: true,
      })
    }

    if (!this.isCordova) {
      window.addEventListener(
        'offline',
        () => {
          let networkState = navigator.onLine ? 1 : 0
          this.handleNetworkState(networkState)
        },
        false
      )

      window.addEventListener(
        'online',
        () => {
          let networkState = navigator.onLine ? 1 : 0
          this.handleNetworkState(networkState)
        },
        false
      )
    }

    if (!this.isCordova && !this.instanceLoaded) this.loadIdentityInstance()
    if (!this.isCordova && 'serviceWorker' in navigator) {
      navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
          console.log('Service Worker registered Commerce:', registration)
        })
        .catch((error) => {
          console.error('Service Worker registration failed Commerce:', error)
        })
    }
  },
  watch: {
    eaivSessionId(newVal, oldVal) {
      if (newVal && newVal != oldVal && !this.isCordova) {
        this.$store.commit('ageVerification/SET_LOADING', true)
        this.fetchSessionDetail(this.eaivSessionId)
      }
    },
    // businessConfigurations: {
    //   handler(newVal, oldVal) {
    //     if (newVal != oldVal) {
    //       this.setIosPWASplash()
    //     }
    //   },
    //   deep: true,
    // },
  },
  methods: {
    onDeviceReady() {
      window.open = cordova.InAppBrowser.open
    },
    checkConnection() {
      let networkState = navigator.connection.type

      return networkState == Connection.UNKNOWN ||
        networkState == Connection.NONE
        ? 0
        : 1
    },
    verificationComplete(sessionId, routeName, isSuccess = true) {
      tempCacheStorage.clear()
      this.$store
        .dispatch('ageVerification/verificationComplete', {
          sessionId,
          params: { isInfoMismatched: false },
        })
        .then((response) => {
          if (isSuccess) {
            if (this.isLoggedIn) {
              this.$store.dispatch('auth/getUserInfo')
              this.$store.commit('customer/SET_CUSTOMER', {
                ...this.customer,
                isVerifiedOnline: true,
              })
            }
            if (
              this.showEAIVPage ||
              this.ageRestrictedPages.includes(routeName) === false
            )
              this.onEaivSuccess({
                eaivSessionId: sessionId,
                routeName: routeName,
              })
            else
              this.$root.$emit('eaivVerificationSuccess', {
                eaivSessionId: sessionId,
                routeName: routeName,
              })
            this.$store.commit('persisted/SET_EAIV_VERIFICATION_STATUS', true)

            this.changeDialogState({
              dialog: 'MismatchPersonalInfo',
              val: false,
              properties: {},
            })

            if (
              !this.isLoggedIn &&
              routeName != 'Checkout' &&
              this.businessConfigurations?.isStoreAgeRestricted &&
              this.businessConfigurations?.enableAgeRestrictedBrowsing
            )
              this.changeDialogState({
                dialog: 'AskForSignup',
                val: true,
                properties: {},
              })
          } else {
            if (
              this.showEAIVPage ||
              this.ageRestrictedPages.includes(routeName) === false
            )
              this.onEaivFail({
                eaivSessionId: sessionId,
                routeName: routeName,
              })
            else
              this.$root.$emit('eaivVerificationFailed', {
                eaivSessionId: sessionId,
                routeName: routeName,
              })
          }
        })

      // Remove skip from persisted
      this.$store.commit('ageVerification/SET_FIELD', {
        field: 'skipBrowsingAgeRestrictedProd',
        value: false,
      })
    },
    onCancelVerification(eaivSessionId) {
      tempCacheStorage.clear()
      this.$store
        .dispatch('ageVerification/verificationComplete', {
          sessionId: eaivSessionId,
          params: { isInfoMismatched: true },
        })
        .then((response) => {
          this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
          this.$store.commit('ageVerification/RESET_VERIFICATION_STATUS')
          this.changeDialogState({
            dialog: 'MismatchPersonalInfo',
            val: false,
            properties: {},
          })
        })
    },
    fetchSessionDetail(sessionId = null) {
      if (!sessionId) return
      tempCacheStorage.clear()
      let routeName = this.$route.name,
        mismatchedFieldName = []

      this.$store
        .dispatch('ageVerification/checkStatus', sessionId)
        .then((response) => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          if (
            response.success &&
            response.data?.isVerified === true &&
            this.eaivPersistedSessionId === sessionId
          ) {
            let personalInfo = response.data?.personalInfo,
              preFillInformation = response.data?.preFillInformation

            if (
              (this.isLoggedIn ||
                (!this.isLoggedIn && routeName == 'Checkout')) &&
              personalInfo &&
              preFillInformation &&
              (personalInfo?.firstName != preFillInformation?.firstName ||
                personalInfo?.lastName != preFillInformation?.lastName ||
                isDateEqual(personalInfo?.dob, preFillInformation?.dob) ==
                  false)
            ) {
              if (personalInfo?.firstName != preFillInformation?.firstName)
                mismatchedFieldName.push('firstName')
              if (personalInfo?.lastName != preFillInformation?.lastName)
                mismatchedFieldName.push('lastName')
              if (
                this.isDateEqual(personalInfo?.dob, preFillInformation?.dob) ==
                false
              )
                mismatchedFieldName.push('dob')

              this.changeDialogState({
                dialog: 'MismatchPersonalInfo',
                val: true,
                properties: {
                  personalInfo,
                  preFillInformation,
                  mismatchedFieldName,
                  onContinue: this.verificationComplete,
                  onCancel: this.onCancelVerification,
                },
              })
            } else {
              this.verificationComplete(sessionId, routeName, true)
            }
          } else {
            this.verificationComplete(sessionId, routeName, false)
          }
        })
        .catch(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        })
    },
    removeQueryParams(eaivSessionId, isSuccess) {
      if (isSuccess) setAVSessionID(eaivSessionId)
      else {
        this.$store.commit('persisted/SET_EAIV_SESSION_ID', null)
        removeAVSessionID()
      }

      this.$store.commit('ageVerification/SET_VERIFICATION_STATUS', isSuccess)
      // remove sessionId from URL
      let query = Object.assign({}, this.$route.query)
      let params = Object.assign({}, this.$route.params)
      delete query.sessionId
      this.$router.replace({ query, params }).catch(() => {})
    },
    async onEaivSuccess({ eaivSessionId, routeName }) {
      if (!eaivSessionId || !routeName) return
      this.removeQueryParams(eaivSessionId, true)

      if (!this.showEAIVPage) {
        tempCacheStorage.clear()
        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.showSuccess('Age Verified Successfully.')
        }, 500)
      } else {
        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        }, 500)
      }
    },
    async onEaivFail({ eaivSessionId, routeName }) {
      if (!eaivSessionId || !routeName) return
      this.removeQueryParams(eaivSessionId, false)
      if (!this.showEAIVPage) {
        tempCacheStorage.clear()
        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
          this.changeDialogState({
            dialog: 'VerificationFailDialog',
            val: true,
            properties: {},
          })
        }, 500)
      } else {
        setTimeout(() => {
          this.$store.commit('ageVerification/SET_LOADING', false)
        }, 500)
      }
    },
    handlePWAAction(isAccepted) {
      const _this = this
      if (!pwaEventObj) return
      if (isAccepted && pwaEventObj)
        pwaEventObj.prompt()?.then((pRes) => {
          if (pRes?.accepted === 'accepted') {
            _this.$store.commit('common/UPDATE_STATE', {
              key: 'showPWAInstallPopupSidebar',
              val: false,
            })
          }
        })
      this.$store.commit('common/UPDATE_STATE', {
        key: 'showPWAInstallPopup',
        val: false,
      })

      if (!isAccepted)
        this.$store.commit('persistedLocal/SET_PWA_POPUP_DENIED', true)
    },
    handleNetworkState(networkState) {
      let element = document.getElementsByTagName('html')
      if (element?.length) {
        element[0].style.cssText = !networkState
          ? 'filter: grayscale(1); pointer-events: none;'
          : ''
      }
    },
    // setIosPWASplash() {
    //   this.$nextTick(() => {
    //     let checkMetaExists = document.querySelector(
    //       "meta[property='application-name']"
    //     )

    //     if (checkMetaExists) return
    //     else if (
    //       Platform?.is?.mobile &&
    //       Platform?.is?.ios &&
    //       this.businessConfigurations.pwaImageUrl
    //     )
    //       iosPWASplash(this.businessConfigurations.pwaImageUrl, {
    //         appTitle: this.businessConfigurations.businessName,
    //         splashColor: 'white',
    //       })
    //   })
    // },
  },
}
</script>
