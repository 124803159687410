<template>
  <q-page class="accountPages subscriptionDetailPage">
    <div
      class="container"
      v-if="subscriptionDetail && subscriptionDetail?.subscriptionNumber"
    >
      <div class="page-title hide-in-mobile">
        <div class="row q-col-gutter-x-md items-center">
          <div class="col-12 col-md-12">
            <div class="page-breadcrumb">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
            </div>
            <h2 class="inline-block vertical-middle">
              Subscription ID:
              {{ subscriptionDetail?.subscriptionNumber }}
            </h2>
          </div>
        </div>
      </div>
      <div class="page-content">
        <!-- Alert for Pause Subscription -->
        <div
          class="bg-amber-1 r-5 q-pa-md q-mb-md"
          v-if="
            subscriptionDetail.status == 2 && !subscriptionDetail?.isBlacklisted
          "
        >
          <div class="row q-col-gutter-md items-center">
            <div class="col-auto">
              <q-avatar
                rounded
                size="36px"
                font-size="24px"
                color="amber-10"
                text-color="white"
                icon="warning"
              />
            </div>
            <div class="col">
              <div class="text-13 text-tertiary">
                Your auto-ship subscription for this product is currently
                paused. you can resume it anytime by updating the 'Frequency'
                and 'Scheduled Date' for upcoming orders.
              </div>
            </div>
            <div class="col-12 col-md-3">
              <q-btn
                unelevated
                no-caps
                color="green"
                label="Resume Subscription"
                class="full-width"
                @click="resumeSubscriptionConfirm()"
              />
            </div>
          </div>
        </div>
        <!-- Alert for Pause Subscription -->
        <!-- Alert for Canceled Subscription -->
        <div
          class="bg-red-1 r-5 q-pa-md q-mb-md"
          v-else-if="subscriptionDetail.status == 3"
        >
          <div class="row q-col-gutter-md items-center">
            <div class="col-auto">
              <q-avatar
                rounded
                size="36px"
                font-size="24px"
                color="red-8"
                text-color="white"
                icon="warning"
              />
            </div>
            <div class="col">
              <div class="text-13 text-tertiary">
                Your subscription has been canceled, so the auto-ship order for
                this product has been discontinued.
              </div>
            </div>
          </div>
        </div>
        <!-- Alert for Canceled Subscription -->
        <!-- Alert for Blackisted Address -->
        <div
          class="bg-amber-1 r-5 q-pa-md q-mb-md"
          v-else-if="
            subscriptionDetail?.status != 3 && subscriptionDetail?.isBlacklisted
          "
        >
          <div class="row q-col-gutter-md items-center">
            <div class="col-auto">
              <q-avatar
                rounded
                size="36px"
                font-size="24px"
                color="amber-10"
                text-color="white"
                icon="warning"
              />
            </div>
            <div class="col">
              <div class="text-13 text-tertiary">
                You subscription product is found blacklisted for your selected
                address or region. To resume and continue this subscription
                kindly change your address.
              </div>
            </div>
            <div class="col-12 col-md-3">
              <q-btn
                unelevated
                no-caps
                color="green"
                label="Update Address"
                class="full-width"
                @click="addEditAddressDetails"
              />
            </div>
          </div>
        </div>
        <!-- Alert for Blackisted Address -->

        <div class="row q-col-gutter-md">
          <div class="col-12 col-md-9">
            <div class="row q-col-gutter-y-md">
              <div class="col-12">
                <div class="b-1 b-cc bg-white r-5">
                  <div class="q-px-md q-pt-sm">
                    <div class="row q-col-gutter-md items-center">
                      <div class="col">
                        <div
                          class="text-subtitle1 text-tertiary line-height-normal text-weight-bold"
                        >
                          Subscription
                          <q-chip
                            size="md"
                            :color="getOrderStatus(subscriptionDetail).color"
                            :text-color="
                              getOrderStatus(subscriptionDetail).text
                            "
                            >{{
                              getOrderStatus(subscriptionDetail).name
                            }}</q-chip
                          >
                        </div>
                      </div>
                      <div :class="isMobile ? 'col-12 q-pt-none' : 'col-auto'">
                        <q-btn
                          unelevated
                          no-caps
                          flat
                          color="primary"
                          label="Update frequency & Schedule"
                          padding="0"
                          class="no-hover text-underline"
                          @click="changeFrequencyConfirm"
                          v-if="isSubscriptionActive"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="q-pa-md">
                    <div class="row q-col-gutter-md">
                      <div class="col-12 col-sm-6 col-md-4">
                        <div
                          class="text-caption text-uppercase line-height-normal"
                        >
                          Subscription Started On:
                        </div>
                        <div class="text-subtitle1 text-tertiary">
                          {{
                            subscriptionDetail?.subscriptionStartDate
                              | formatDate
                          }}
                        </div>
                      </div>

                      <div class="col-12 col-sm-6 col-md-4">
                        <div
                          class="text-caption text-uppercase line-height-normal"
                        >
                          Frequency:
                        </div>
                        <div class="text-subtitle1 text-tertiary">
                          Every {{ subscriptionDetail?.frequency }} Days
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-6 col-md-4"
                        v-if="subscriptionDetail?.status != 3"
                      >
                        <div
                          class="text-caption text-uppercase line-height-normal"
                        >
                          Next Order:
                        </div>
                        <div
                          class="text-subtitle1 text-tertiary"
                          v-if="isSubscriptionActive"
                        >
                          {{ subscriptionDetail?.nextOrderDate | formatDate }}
                        </div>
                        <div
                          class="text-subtitle1 text-tertiary text-left"
                          v-else-if="subscriptionDetail.status == 2"
                        >
                          Until I Resume
                        </div>
                      </div>
                      <div v-else>
                        <div
                          class="text-caption text-uppercase line-height-normal"
                        >
                          Subscription Ended:
                        </div>
                        <div class="text-subtitle1 text-tertiary">
                          {{ subscriptionDetail?.nextOrderDate | formatDate }}
                        </div>
                      </div>
                    </div>

                    <div
                      class="bg-light-blue-1 r-5 q-pa-md q-mt-md"
                      v-if="isSubscriptionActive"
                    >
                      <div class="row q-col-gutter-md items-center">
                        <div class="col-auto">
                          <q-avatar
                            rounded
                            size="36px"
                            font-size="24px"
                            color="light-blue-6"
                            text-color="white"
                            icon="warning"
                          />
                        </div>
                        <div class="col">
                          <div class="text-13 text-tertiary">
                            New order will be created automatically on the next
                            cycle everytime. The payment will also be proceeded
                            on that day. So, we advice make your payment method
                            and address details up to date.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="b-1 b-cc bg-white r-5">
                  <div class="q-px-md q-pt-md">
                    <div class="row q-col-gutter-md">
                      <div class="col-12">
                        <div
                          class="text-subtitle1 text-tertiary line-height-normal text-weight-bold"
                        >
                          Item Information
                        </div>
                      </div>
                      <div class="col-12 col-md-7">
                        <q-item class="q-pa-none">
                          <q-item-section avatar top>
                            <div class="flex b-1 q-pa-xs">
                              <!-- changes  -->
                              <q-img
                                :src="
                                  subscriptionDetail.productImage
                                    | defaultProductImage
                                "
                                style="width: 48px; height: 48px"
                              />
                            </div>
                          </q-item-section>
                          <q-item-section>
                            <q-item-label>
                              <!-- Doubt -->
                              <div
                                :to="{
                                  name: 'Home',
                                }"
                              >
                                {{ subscriptionDetail?.productName }}
                              </div>
                            </q-item-label>
                            <q-item-label>
                              <div class="text-subtitle2 q-mr-sm">
                                {{ subscriptionDetail?.qty }} x
                                {{ subscriptionDetail.productPrice | currency }}
                              </div>
                              <UpdateSubscriptionQuantity
                                v-if="isSubscriptionActive"
                                :subscriptionQuantity="subscriptionDetail?.qty"
                                :subscriptionId="
                                  subscriptionDetail?.subscriptionID
                                "
                                :getSubscriptionDetails="
                                  fetchSubscriptionDetail
                                "
                              />
                            </q-item-label>
                            <q-item-label class="subscribe-linked-product">
                              <!--Added linked Proudcts-->
                              <div
                                class="substitution-product--block"
                                v-if="linkedProduct"
                              >
                                <div class="product-image">
                                  <q-avatar square>
                                    <box-image
                                      :imageSrc="linkedProduct.productImage"
                                      width="40px"
                                    />
                                  </q-avatar>
                                  <q-avatar flat>
                                    <SvgIcon icon="linked" />
                                  </q-avatar>
                                </div>
                                <div class="co-product-details">
                                  <div class="product--title">
                                    <h4>
                                      <template>
                                        {{ linkedProduct.productName }}
                                      </template>
                                      <q-tooltip
                                        max-width="180px"
                                        content-class="bg-dark"
                                      >
                                        {{ linkedProduct.productName }}
                                      </q-tooltip>
                                    </h4>
                                  </div>

                                  <div class="flex product-edit--options">
                                    <div class="product-quantity">
                                      <span
                                        v-html="
                                          `${
                                            linkedProduct.qty
                                          } x ${$options.filters.currency(
                                            linkedProduct.productPrice
                                          )}`
                                        "
                                    /></div>
                                  </div>
                                  <div class="price">
                                    <span
                                      v-html="
                                        $options.filters.currency(
                                          linkedProduct.totalPrice
                                        )
                                      "
                                    />
                                  </div>
                                </div>
                              </div>
                              <!--Added linked Proudcts-->
                            </q-item-label>
                          </q-item-section>
                        </q-item>
                      </div>
                      <div class="col-12 col-md-4 offset-md-1">
                        <table
                          class="full-width text-subtitle2 text-weight-bold text-tertiary"
                        >
                          <tr>
                            <td>Total Price</td>
                            <td class="text-right">{{
                              subscriptionDetail?.totalPrice | currency
                            }}</td>
                          </tr>
                          <tr v-if="subscriptionDetail?.subscriptionDiscount">
                            <td>Discount</td>
                            <td class="text-right"
                              >-${{
                                subscriptionDetail.subscriptionDiscount.toFixed(
                                  2
                                )
                              }}</td
                            >
                          </tr>
                          <!-- <tr v-if="subscriptionDetail?.deliveryFee">
                            <td>Delivery Fee</td>
                            <td class="text-right"
                              >+${{
                                subscriptionDetail?.deliveryFee.toFixed(2)
                              }}</td
                            >
                          </tr> -->
                          <tr>
                            <td>Order Total <br /></td>
                            <td class="text-right">{{
                              subscriptionDetail.subTotal | currency
                            }}</td>
                          </tr>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div class="q-pa-md">
                    <div
                      class="bg-light-blue-1 r-5 q-pa-md"
                      v-if="isSubscriptionActive"
                    >
                      <div class="row q-col-gutter-md items-center">
                        <div class="col-auto">
                          <q-avatar
                            rounded
                            size="36px"
                            font-size="24px"
                            color="light-blue-6"
                            text-color="white"
                            icon="warning"
                          />
                        </div>
                        <div class="col">
                          <div class="text-13 text-tertiary">
                            The order total calculation above will apply to your
                            next order. Please note that the total may vary due
                            to changes in discount, delivery fees, and taxes.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <q-table
                  title="Upcoming Orders"
                  :data="upcomingOrderData"
                  :columns="upcomingOrderColumns"
                  row-key="ScheduleDate"
                  :hide-header="subscriptionDetail?.status == 2"
                  :hide-bottom="subscriptionDetail?.status != 2"
                  separator="horizontal"
                  bordered
                  flat
                  class="table-subsctiption"
                  table-header-class="bg-grey-1 text-uppercase"
                  v-show="subscriptionDetail?.status != 3"
                >
                  <template v-slot:body="props">
                    <q-tr :props="props" v-if="isSubscriptionActive">
                      <q-td key="ScheduleDate" :props="props">
                        <span>{{ props?.row.scheduleDate | formatDate }}</span>
                        <span
                          class="q-pl-sm text-strike"
                          v-if="
                            [4, 7].includes(props.row.status) &&
                            props.row.previousScheduleDate
                          "
                          >{{ props.row.previousScheduleDate | formatDate }}
                        </span>
                        <div
                          class="text-caption"
                          v-if="
                            [6, 7].includes(props.row.status) &&
                            props.row.reason
                          "
                          >{{ props.row.reason }}
                        </div>
                      </q-td>
                      <q-td key="status" :props="props">
                        <q-chip
                          size="md"
                          :color="getUpcomingOrderStatus(props?.row).color"
                          :text-color="getUpcomingOrderStatus(props?.row).text"
                          >{{ getUpcomingOrderStatus(props?.row).name }}</q-chip
                        >
                      </q-td>
                      <q-td key="actions" :props="props">
                        <template v-if="props?.row.status == 1">
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="red-6"
                            label="Skip"
                            padding="2px 12px"
                            class="q-mr-sm"
                            :disable="isScheduleDisable(props.row.scheduleDate)"
                            @click="
                              skipResumeOrderConfirm('SKIP_ORDER', props.row)
                            "
                          />
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="deep-purple-6"
                            label="Reschedule"
                            padding="2px 12px"
                            class="q-mr-sm"
                            :disable="isScheduleDisable(props.row.scheduleDate)"
                            @click="rescheduleOrderConfirm(props.row)"
                          />
                        </template>
                        <template v-else-if="props?.row.status == 4">
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="red-6"
                            label="Skip"
                            padding="2px 12px"
                            class="q-mr-sm"
                            @click="
                              skipResumeOrderConfirm('SKIP_ORDER', props.row)
                            "
                          />
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="deep-purple-6"
                            label="Reschedule"
                            padding="2px 12px"
                            class="q-mr-sm"
                            @click="rescheduleOrderConfirm(props.row)"
                          />
                        </template>
                        <template v-else-if="props?.row.status == 5">
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="green-6"
                            label="Resume"
                            padding="2px 12px"
                            class="q-mr-sm"
                            @click="
                              skipResumeOrderConfirm('RESUME_ORDER', props.row)
                            "
                          />
                        </template>
                      </q-td>
                    </q-tr>
                  </template>
                  <template v-slot:no-data="props">
                    <div
                      class="full-width bg-amber-1 r-5 q-pa-md q-ma-md"
                      v-if="subscriptionDetail?.status == 2"
                    >
                      <div class="row q-col-gutter-md items-center">
                        <div class="col-auto">
                          <q-avatar
                            rounded
                            size="36px"
                            font-size="24px"
                            color="amber-10"
                            text-color="white"
                            icon="warning"
                          />
                        </div>
                        <div class="col">
                          <div class="text-13 text-tertiary">
                            Your auto-ship subscription for this product is
                            currently paused.
                          </div>
                        </div>
                      </div>
                    </div>
                    <template v-else>{{ props.message }}</template>
                  </template>
                </q-table>
              </div>
              <div class="col-12">
                <q-table
                  title="Previous Orders"
                  :data="previousOrderData"
                  :columns="previousOrderColumns"
                  @request="fetchPreviousOrders"
                  row-key="orderID"
                  separator="horizontal"
                  bordered
                  flat
                  class="table-subsctiption"
                  :rows-per-page-options="rows"
                  table-header-class="bg-grey-1 text-uppercase"
                  :pagination.sync="pagination"
                  :loading="loading"
                >
                  <template v-slot:body="props">
                    <q-tr :props="props">
                      <q-td key="name" :props="props">
                        {{ props?.row.name | formatDate }}
                      </q-td>
                      <q-td key="actions" :props="props">
                        <template v-if="props.row.status != 25">
                          <q-btn
                            unelevated
                            no-caps
                            outline
                            color="blue-6"
                            label="View Order"
                            padding="2px 12px"
                            class="q-mr-sm"
                            @click="redirect(props.row)"
                          />
                        </template>
                        <template v-else>-</template>
                      </q-td>
                      <q-td key="status" :props="props">
                        <q-chip
                          size="md"
                          :color="getPreviousOrderStatus(props?.row).color"
                          :text-color="getPreviousOrderStatus(props?.row).text"
                          >{{
                            props?.row.errorCount > 1
                              ? ` Payment Failed (${props?.row.errorCount} Times)`
                              : getPreviousOrderStatus(props?.row).name
                          }}</q-chip
                        >
                      </q-td>
                    </q-tr>
                  </template>
                </q-table>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="row q-col-gutter-y-md">
              <div class="col-12">
                <div class="b-1 b-cc bg-grey-1 r-5 q-pa-md">
                  <div class="row q-col-gutter-md">
                    <div class="col-auto">
                      <q-avatar
                        rounded
                        size="36px"
                        font-size="20px"
                        color="tertiary"
                        text-color="white"
                      >
                        <SvgIcon
                          :icon="subscriptionDetail.codeName"
                          size="20px"
                        />
                      </q-avatar>
                    </div>
                    <div class="col">
                      <div
                        class="text-caption text-uppercase line-height-normal"
                      >
                        Fulfillment
                      </div>
                      <div class="text-subtitle2 text-tertiary">
                        {{ subscriptionDetail?.fulfillmentName }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="b-1 b-cc bg-grey-1 r-5 q-pa-md">
                  <div class="row q-col-gutter-md items-center">
                    <div class="col">
                      <div
                        class="text-subtitle1 text-tertiary line-height-normal text-weight-bold"
                      >
                        {{
                          subscriptionDetail.codeName == 'scd'
                            ? 'Delivery'
                            : 'Shipping'
                        }}
                        Address
                      </div>
                    </div>
                    <div class="col-auto">
                      <q-btn
                        v-if="isSubscriptionActive"
                        unelevated
                        no-caps
                        flat
                        color="primary"
                        label="Update"
                        padding="0"
                        class="no-hover text-underline"
                        @click="addEditAddressDetails"
                      />
                    </div>
                    <div class="col-12">
                      <div
                        class="text-caption text-uppercase line-height-normal"
                      >
                        {{
                          subscriptionDetail.codeName == 'scd'
                            ? 'Deliver'
                            : 'Ship'
                        }}
                        to
                      </div>
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal"
                      >
                        {{ subscriptionDetail?.deliverTo }}
                        <p
                          class="line-height-15 q-mb-none"
                          v-html="
                            $options.filters.formatAddress(subscriptionDetail)
                          "
                        ></p>
                      </div>
                      <!-- Alert for Blackisted Address -->
                      <div
                        class="bg-red-1 r-5 q-pa-md q-mt-md"
                        v-if="
                          subscriptionDetail?.status != 3 &&
                          subscriptionDetail?.isBlacklisted
                        "
                      >
                        <div class="row q-col-gutter-md items-center">
                          <div class="col-auto">
                            <q-avatar
                              rounded
                              size="36px"
                              font-size="24px"
                              color="red-8"
                              text-color="white"
                              icon="warning"
                            />
                          </div>
                          <div class="col">
                            <div class="text-13 text-tertiary">
                              Please update the address for resume your
                              subscription.
                            </div>
                          </div>
                          <div class="col-12">
                            <q-btn
                              unelevated
                              no-caps
                              color="tertiary"
                              label="Update Address"
                              class="full-width"
                              @click="addEditAddressDetails"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Alert for Blackisted Address -->
                      <q-item-label
                        v-if="unableToDeliver && subscriptionDetail.status != 3"
                        class="text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"
                      >
                        *Unable to
                        {{
                          subscriptionDetail.codeName == 'scd'
                            ? 'deliver'
                            : 'ship'
                        }}
                        to this location for
                        {{
                          subscriptionDetail.codeName == 'scd'
                            ? 'Delivery'
                            : 'Shipping'
                        }}
                      </q-item-label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="b-1 b-cc bg-grey-1 r-5 q-pa-md">
                  <div class="row q-col-gutter-md items-center">
                    <div class="col">
                      <div
                        class="text-subtitle1 text-tertiary line-height-normal text-weight-bold"
                      >
                        Payment Method
                      </div>
                    </div>
                    <div class="col-auto">
                      <q-btn
                        v-if="isSubscriptionActive"
                        unelevated
                        no-caps
                        flat
                        color="primary"
                        label="Update"
                        padding="0"
                        class="no-hover text-underline"
                        @click="addEditPaymentDetails"
                      />
                    </div>
                    <div class="col-12">
                      <div
                        class="text-caption text-uppercase line-height-normal"
                        >Credit or Debit Card</div
                      >
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal"
                      >
                        <img
                          :src="`images/payment/${
                            getCardType(subscriptionDetail.cardType).logo
                          }`"
                          :title="
                            getCardType(subscriptionDetail.cardType).title
                          "
                          class="q-my-sm q-mr-xs vm"
                        />
                        {{ subscriptionDetail.cardHolderName }}<br />
                      </div>
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal q-mb-sm"
                      >
                        Ending in {{ subscriptionDetail.cardLast4Digits }}
                      </div>
                      <div
                        class="text-subtitle2 text-tertiary line-height-normal"
                      >
                        Expires on {{ subscriptionDetail.cardExpiry }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <q-btn
                  v-if="isSubscriptionActive"
                  unelevated
                  no-caps
                  color="primary"
                  label="Pause Subscription"
                  class="full-width q-mb-sm"
                  @click="pauseSubscriptionConfirm"
                />
                <q-btn
                  v-if="subscriptionDetail?.status != 3"
                  unelevated
                  no-caps
                  flat
                  color="tertiary"
                  label="Cancel Subscription"
                  class="no-hover full-width text-underline"
                  @click="
                    changeDialogState({
                      dialog: 'CancelSubscriptionDialog',
                      val: true,
                      properties: {
                        subscriptionID: subscriptionDetail.subscriptionID,
                        refreshListing: fetchDetail,
                        isMinRecurringCompleted:
                          subscriptionDetail.isMinRecurringCompleted,
                        minOrderRecurring: subscriptionDetail.minOrderRecurring,
                        cardDetails: {
                          cardHolderName: subscriptionDetail.cardHolderName,
                          cardType: subscriptionDetail.cardType,
                          cardExpiry: subscriptionDetail.cardExpiry,
                          cardLast4Digits: subscriptionDetail.cardLast4Digits,
                        },
                      },
                    })
                  "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ChangeFrequencyDialog />
    <SkipScheduleDialog />
    <RescheduleOrderDialog />
    <PauseSubscriptionDialog />
    <CancelSubscriptionDialog />
    <ResumeSubscriptionDialog />
    <UpdateAddressDialog />
    <UpdatePaymentDialog />
    <AddPaymentMethod />
    <AddBillingAddress />
  </q-page>
</template>

<script>
import { getCardType } from 'src/utils'
import { mapGetters } from 'vuex'

export default {
  name: 'subscriptionDetail',
  props: ['subscriptionId'],
  meta() {
    return {
      title: this.title,
      ...this.commonMeta,
    }
  },
  components: {
    UpdateSubscriptionQuantity: () =>
      import('components/subscription/UpdateSubscriptionQuantity.vue'),
    ChangeFrequencyDialog: () =>
      import('components/subscription/ChangeFrequencyDialog'),
    SkipScheduleDialog: () =>
      import('components/subscription/SkipScheduleDialog'),
    RescheduleOrderDialog: () =>
      import('components/subscription/RescheduleOrderDialog'),
    PauseSubscriptionDialog: () =>
      import('components/subscription/PauseSubscriptionDialog'),
    CancelSubscriptionDialog: () =>
      import('components/subscription/CancelSubscriptionDialog'),
    ResumeSubscriptionDialog: () =>
      import('components/subscription/ResumeSubscriptionDialog'),
    UpdateAddressDialog: () =>
      import('components/subscription/UpdateAddressDialog'),
    UpdatePaymentDialog: () =>
      import('components/subscription/UpdatePaymentDialog'),
    AddPaymentMethod: () => import('components/payment/AddPaymentMethod'),
    AddBillingAddress: () => import('components/payment/AddBillingAddress'),
  },
  data() {
    return {
      rows: [1],
      upcomingOrders: [],
      previousOrders: [],
      pagination: {
        page: 1,
        rowsPerPage: 5,
        PageSize: 5,
        rowsNumber: null,
        hasNext: true,
      },
      loading: false,
      upcomingOrderColumns: [
        {
          name: 'ScheduleDate',
          required: true,
          label: 'SCHEDULED ON',
          align: 'left',
          field: 'scheduleDate',
          format: (val) => `${val}`,
        },
        {
          name: 'status',
          label: 'Status',
          align: 'center',
          field: 'status',
          style: 'width: 120px',
        },
        {
          name: 'actions',
          label: 'Actions',
          align: 'left',
          field: 'actions',
          style: 'width: 190px',
        },
      ],
      previousOrderColumns: [
        {
          name: 'name',
          required: true,
          label: 'Order Date',
          align: 'left',
          field: (row) => row.name,
          format: (val) => `${val}`,
        },
        {
          name: 'actions',
          label: 'Order Details',
          align: 'center',
          field: 'actions',
          style: 'width: 190px',
        },
        {
          name: 'status',
          label: 'Status',
          align: 'center',
          field: 'status',
          style: 'width: 120px',
        },
      ],
    }
  },
  computed: {
    ...mapGetters('order', ['subscriptionInfoById']),
    ...mapGetters('address', ['getAddressByAddressID']),
    unableToDeliver() {
      let addressDetail = this.getAddressByAddressID(
        this.subscriptionDetail?.addressId
      )

      if (
        (this.subscriptionDetail.codeName == 'scd' &&
          !addressDetail.isDeliver) ||
        (this.subscriptionDetail.codeName == 'sd' &&
          !addressDetail.isShippingPossible)
      ) {
        return true
      } else {
        return false
      }
    },
    title() {
      return this.subscriptionDetail ? this.subscriptionDetail.productName : ''
    },
    subscriptionDetail() {
      return this.subscriptionInfoById(this.subscriptionId)
    },
    isSubscriptionActive() {
      return this.subscriptionDetail?.status == 1
    },
    upcomingOrderData() {
      let upcomingOrders = this.upcomingOrders.slice()
      return this.isSubscriptionActive ? upcomingOrders : []
    },
    previousOrderData() {
      return this.previousOrders?.map((order) => {
        return {
          orderId: order.orderID,
          name: order.orderActionDate,
          status: order.status,
          errorCount: order.errorCount,
        }
      })
    },
    linkedProduct() {
      return this.subscriptionDetail?.linkedProduct
        ? this.subscriptionDetail.linkedProduct
        : false
    },
  },
  methods: {
    getCardType: getCardType,
    async fetchDetail() {
      this.fetchSubscriptionDetail()
      this.fetchUpcomingOrders()
      this.fetchPreviousOrders({
        pagination: this.pagination,
      })
    },
    async fetchPreviousOrders(props) {
      this.loading = true
      try {
        let params = {
          Page: props?.pagination?.page || this.pagination.page || 1,
          PageSize: this.pagination?.PageSize,
        }
        let subscriptionId = this.subscriptionId
        const response = await this.$store.dispatch('order/getPreviousOrders', {
          subscriptionId,
          params,
        })
        if (response && response.success && response.data?.items) {
          this.previousOrders = response.data.items
          this.pagination = {
            ...this.pagination,
            page: response.data.index + 1,
            rowsPerPage: response.data.size,
            rowsNumber: response.data.count,
            hasNext: response.data.hasNext,
          }
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    getOrderStatus(order) {
      const statusMap = {
        1: {
          name: 'Active',
          color: 'green-1',
          text: 'green-6',
          class: 'bg-light-green-1',
        },
        2: {
          name: 'Paused',
          color: 'amber-1',
          text: 'amber-6',
          class: 'bg-amber-1',
        },
        3: {
          name: 'Canceled',
          color: 'deep-orange-1',
          text: 'deep-orange-6',
          class: 'bg-deep-orange-1',
        },
      }

      const defaultStatus = { name: '', color: '', text: '', class: '' }

      return statusMap[order.status] || defaultStatus
    },
    getUpcomingOrderStatus(order) {
      const statusMap = {
        1: {
          name: 'Scheduled',
          color: 'green-1',
          text: 'green-6',
          class: 'bg-light-green-1',
        },
        3: {
          name: 'Canceled',
          color: 'deep-orange-1',
          text: 'deep-orange-6',
          class: 'bg-deep-orange-1',
        },
        4: {
          name: 'Rescheduled',
          color: 'amber-1',
          text: 'amber-6',
          class: 'bg-amber-1',
        },
        5: {
          name: 'Skipped',
          color: 'red-1',
          text: 'red-6',
          class: 'bg-amber-1',
        },
        6: {
          name: 'Skipped By Store',
          color: 'red-1',
          text: 'red-6',
          class: 'bg-amber-1',
        },
        7: {
          name: 'Rescheduled By Store',
          color: 'amber-1',
          text: 'amber-6',
          class: 'bg-amber-1',
        },
      }

      const defaultStatus = { name: '', color: '', text: '', class: '' }

      return statusMap[order.status] || defaultStatus
    },
    getPreviousOrderStatus(order) {
      const statusMap = {
        7: {
          name: 'Order Skipped by Store',
          color: 'amber-1',
          text: 'amber-6',
        },
        9: {
          name: 'Order Cancel by Store',
          color: 'red-1',
          text: 'red-6',
        },
        12: {
          name: 'Order In Process',
          color: 'green-1',
          text: 'green-6',
        },
        13: {
          name: 'Order Canceled By Customer',
          color: 'red-1',
          text: 'red-6',
        },
        14: { name: 'Prepared', color: 'green-1', text: 'green-6' },
        15: {
          name: 'Ready For Delivery',
          color: 'green-1',
          text: 'green-6',
        },
        16: {
          name: 'Delivery In Progress',
          color: 'green-1',
          text: 'green-6',
        },
        17: { name: 'Delivered', color: 'green-1', text: 'green-6' },
        18: {
          name: 'Unable To Deliver',
          color: 'red-1',
          text: 'red-6',
        },
        19: {
          name: 'Rescheduled',
          color: 'amber-1',
          text: 'amber-6',
        },
        21: {
          name: 'Subscription Canceled by Store',
          color: 'red-1',
          text: 'red-6',
        },
        24: {
          name: 'Order Returned By Store',
          color: 'red-1',
          text: 'red-6',
        },
        25: {
          name: 'Payment Failed',
          color: 'red-1',
          text: 'red-6',
        },
      }

      const defaultStatus = { name: '', color: '', text: '' }

      return statusMap[order.status] || defaultStatus
    },
    redirect(order) {
      if ([13, 17, 18, 9, 24, 25].includes(order.status))
        this.$router.push({
          name: 'OrderDetail',
          params: { orderId: order.orderId },
        })
      else {
        this.$router.push({
          name: 'TrackOrder',
          params: { orderId: order.orderId },
        })
      }
    },
    resumeSubscriptionConfirm() {
      this.changeDialogState({
        dialog: 'ResumeSubscriptionDialog',
        val: true,
        properties: {
          subscriptionId: this.subscriptionDetail?.subscriptionID,
          frequencies: this.subscriptionDetail?.frequencies,
          frequency: this.subscriptionDetail?.frequency,
          refreshListing: this.fetchDetail,
        },
      })
    },
    async getSubscriptionDetails(subscriptionId) {
      this.$store.dispatch('order/viewSubscriptionDetail', {
        subscriptionId: this.subscriptionDetail.subscriptionID,
      })
      this.fetchUpcomingOrders(subscriptionId)
    },
    pauseSubscriptionConfirm() {
      this.changeDialogState({
        dialog: 'PauseSubscriptionDialog',
        val: true,
        properties: {
          subscriptionID: this.subscriptionDetail.subscriptionID,
          refreshListing: this.fetchDetail,
        },
      })
    },
    skipResumeOrderConfirm(actionType, order) {
      this.changeDialogState({
        dialog: 'SkipResumeScheduleDialog',
        val: true,
        properties: {
          actionType,
          order,
          refreshListing: this.fetchUpcomingOrders,
        },
      })
    },
    fetchSubscriptionDetail() {
      this.$store
        .dispatch('order/viewSubscriptionDetail', {
          subscriptionId: this.subscriptionId,
        })
        .catch((error) => console.log(error))
    },
    async fetchUpcomingOrders() {
      this.upcomingOrders = await this.$store.dispatch(
        'order/getUpcomingOrders',
        this.subscriptionId
      )
    },
    rescheduleOrderConfirm(order) {
      this.changeDialogState({
        dialog: 'RescheduleOrderDialog',
        val: true,
        properties: {
          order,
          frequency: this.subscriptionDetail?.frequency,
          refreshListing: this.fetchUpcomingOrders,
        },
      })
    },
    changeFrequencyConfirm() {
      this.changeDialogState({
        dialog: 'ChangeFrequencyDialog',
        val: true,
        properties: {
          actionType: 6,
          subscriptionID: this.subscriptionDetail.subscriptionID,
          frequency: this.subscriptionDetail.frequency,
          frequencies: this.subscriptionDetail.frequencies,
          nextOrderDate: this.isSubscriptionActive
            ? this.subscriptionDetail.nextOrderDate
            : '',
          refreshListing: this.fetchDetail,
        },
      })
    },
    addEditAddressDetails() {
      this.changeDialogState({
        dialog: 'UpdateAddressDialog',
        val: true,
        properties: {
          actionType: 8,
          fulfillmentCode: this.subscriptionDetail.codeName,
          subscriptionID: this.subscriptionDetail.subscriptionID,
          addressId: this.subscriptionDetail.addressId,
          refreshListing: this.fetchSubscriptionDetail,
        },
      })
    },
    addEditPaymentDetails() {
      this.changeDialogState({
        dialog: 'UpdatePaymentDialog',
        val: true,
        properties: {
          actionType: 9,
          subscriptionID: this.subscriptionDetail.subscriptionID,
          paymentMethodId: this.subscriptionDetail.paymentMethodID,
          refreshListing: this.fetchSubscriptionDetail,
        },
      })
    },
    isScheduleDisable(scheduleDate) {
      return !!(
        scheduleDate &&
        new Date(scheduleDate).setHours(0, 0, 0, 0) ===
          new Date().setHours(0, 0, 0, 0)
      )
    },
  },
  mounted() {
    this.fetchDetail()
  },
}
</script>
<style lang="scss">
.subscriptionDetailPage .product-cart {
  margin-bottom: 8px;
  .btn-update {
    font-family: 'Lato', sans-serif;
  }
  .error {
    top: 45px;
  }
}
.subscribe-linked-product {
  padding-top: 8px;
  .substitution-product--block {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
  }
  .product-image {
    width: 60px;
    height: 60px;
    border: 1px solid #2a9dcc;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0;
    .q-avatar:not(.q-avatar--square) {
      position: absolute;
      left: -12px;
      width: 24px;
      height: 24px;
      border: 1px solid #2a9dcc;
      font-size: 23px;
      line-height: 24px;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background: #fff;
    }
  }
  .co-product-details {
    padding-left: 16px;
    flex: 1 0 0;
    width: 100%;
    max-width: 100%;
    min-width: 0;
    min-height: 60px;
    justify-content: space-around;
    font-size: 0.875rem;
    letter-spacing: 0.00714em;
    h4 {
      font-size: 15px;
      line-height: 22px;
      font-weight: normal;
      color: #1f1b1c;
      margin: 0;
    }
  }
}
</style>
