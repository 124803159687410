<template>
  <div v-if="!redirectLink" @click="checkShowLogin">
    <slot />
  </div>
  <router-link
    v-else-if="isCordova || !slide.imageOpenInNewTab"
    :to="redirectLink"
  >
    <slot />
  </router-link>
  <a
    v-else
    :target="slide.imageOpenInNewTab ? '_blank' : '_self'"
    :href="redirectLink"
  >
    <slot />
  </a>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SlideLink',
  props: {
    slide: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  computed: {
    ...mapGetters('common', ['storeHasLnsSubscription']),
    redirectLink() {
      if (
        this.slide.imageLinkType?.toUpperCase() === 'LNS' &&
        this.storeHasLnsSubscription
      ) {
        if (this.isLoggedIn) return '/loyalty-program#/'

        return null
      }

      if (this.slide.imageLinkType && this.slide.imageSeoName)
        return `/${this.slide.imageLinkType}/${this.slide.imageSeoName}`

      return null
    },
  },
  methods: {
    checkShowLogin() {
      if (!this.isLoggedIn && this.slide.imageLinkType.toUpperCase() === 'LNS')
        this.changeDialogState({
          dialog: 'accountDrawer',
          val: true,
        })
    },
  },
}
</script>
