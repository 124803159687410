<template>
  <q-page class="accountPages">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <div class="page-breadcrumb">
          <a
            href="javascript:void(0)"
            @click="applyBack()"
            class="no-underline"
          >
            <SvgIcon icon="back-arrow-line" />Back
          </a>
        </div>
        <h2>Payment Method</h2>
        <p>
          Select or enter your preferred method of payment for easier checkout.
        </p>
      </div>
      <div class="page-content">
        <div class="managePaymentItems">
          <!-- Loader -->
          <span v-show="loading">
            <div class="paymentItem" v-for="n in 3" :key="n">
              <div class="paymentInner">
                <div class="paymentMiddle">
                  <h3><q-skeleton type="rect" width="140px" /></h3>
                  <p>
                    <q-skeleton type="text" width="100%" />
                    <q-skeleton type="text" width="30%" />
                  </p>
                  <h3
                    ><q-skeleton type="text" width="140px" height="20px"
                  /></h3>
                  <p>
                    <q-skeleton type="text" width="100%" />
                    <q-skeleton type="text" width="30%" />
                  </p>
                  <p>
                    <q-skeleton type="rect" width="50%" class="q-mt-lg" />
                  </p>
                </div>
                <div class="paymentBottom">
                  <a href="javascript:void(0);">
                    <q-skeleton type="rect" width="100%" />
                  </a>
                </div>
              </div>
            </div>
          </span>
          <!-- Loader -->
          <transition-group
            v-show="!loading"
            appear
            leave-active-class="animated fadeOutUp"
            enter-active-class="animated fadeIn"
          >
            <div
              v-for="(paymentMethod, key, index) in paymentMethodsList"
              :key="`${key}-${index}`"
              :class="[
                'paymentItem',
                key == 'add-payment' ? 'addPaymentItem' : '',
              ]"
            >
              <div class="paymentInner" v-if="key == 'add-payment'">
                <a
                  href="javascript:void('0');"
                  class="paymentMiddle"
                  @click="addPaymentMethod"
                >
                  <SvgIcon size="16px" icon="plus" class="q-mr-sm" /> Add New
                  Credit/Debit Card
                </a>
              </div>
              <div
                class="paymentInner"
                :class="[{ invalidcard: paymentMethod.isInvalid }]"
                v-else
              >
                <span
                  v-if="isMobile && paymentMethod.isInvalid"
                  class="text-body2 text-negative q-px-md q-mt-sm"
                  >This card is invalid as the retailer has changed the payment
                  method.</span
                >
                <q-btn
                  v-else-if="paymentMethod.isInvalid"
                  round
                  dense
                  color="negative"
                  icon="info"
                  flat
                  padding="0"
                  class="absolute-top-right q-mt-sm q-mr-sm invalidcard-icon"
                >
                  <q-tooltip
                    content-class="bg-negative text-body2"
                    anchor="center left"
                    self="center right"
                    :offset="[10, 10]"
                  >
                    This card is invalid as <br />the retailer has changed<br />
                    the payment method.
                  </q-tooltip>
                </q-btn>
                <div class="paymentMiddle">
                  <!-- <a
                    @click="itemActivate(index)"
                    href="javascript:void(0)"
                    class="quickAction"
                    :class="{ active: active_el[index] }"
                  >
                    <SvgIcon icon="arrow-down" />
                  </a> -->
                  <span :class="`cc`">
                    <img
                      :src="`images/payment/${
                        getCardType(paymentMethod.cardType).logo
                      }`"
                      :title="getCardType(paymentMethod.cardType).title"
                    />
                  </span>
                  <h3>
                    {{ paymentMethod.cardHolderName }}
                    <transition
                      appear
                      enter-active-class="animated fadeIn"
                      leave-active-class="animated fadeOut"
                    >
                      <span v-if="paymentMethod.isDefault">Default</span>
                    </transition>
                  </h3>
                  <p>
                    Ending in
                    <strong> {{ paymentMethod.cardLast4Digits }} </strong>
                  </p>
                  <p>
                    Expires <strong> {{ paymentMethod.cardExpiry }} </strong>
                  </p>
                  <!-- :class="{ hidden: !active_el[index] }" -->
                  <q-separator class="q-mt-md q-mb-sm" />
                  <div class="relative-position">
                    <h5>Billing Address</h5>
                    <p class="ellipsis text-weight-bold">
                      {{ paymentMethod?.address?.firstName }}
                      {{ paymentMethod?.address?.lastName }}
                    </p>
                    <p
                      v-html="
                        $options.filters.formatAddress(paymentMethod.address)
                      "
                    />
                    <!-- <transition
                      appear
                      enter-active-class="animated fadeIn"
                      leave-active-class="animated fadeOut"
                      class="addressSwitch"
                      v-if="!paymentMethod.isDefault"
                    >
                      <q-toggle
                        size="xs"
                        :value="paymentMethod.isDefault"
                        @input="setAsDefault(paymentMethod)"
                        label="Set as Default payment method"
                        class="toggle"
                      />
                    </transition> -->
                    <q-btn
                      unelevated
                      round
                      dense
                      color="grey-6"
                      text-color="white"
                      :class="{
                        disable: paymentMethod.isInvalid,
                      }"
                      padding="6px"
                      class="absolute-top-right q-mr-sm"
                      @click="editBillingInfo(paymentMethod)"
                      ><q-icon name="edit" size="1rem"
                    /></q-btn>
                  </div>
                  <!-- <Alert
                    v-if="paymentMethod.isInvalid"
                    :show="true"
                    :variant="'danger'"
                    ><strong>Invalid :</strong> This card is invalid as the
                    retailer has changed the payment method.</Alert
                  > -->
                </div>

                <div class="paymentBottom">
                  <q-btn
                    @click="setAsDefault(paymentMethod)"
                    dense
                    no-caps
                    unelevated
                    rounded
                    color="primary"
                    flat
                    :class="{
                      disable:
                        paymentMethod.isDefault || paymentMethod.isInvalid,
                    }"
                  >
                    <q-icon
                      name="credit_score"
                      size="18px"
                      class="q-mr-xs"
                    ></q-icon>
                    Set Default</q-btn
                  >
                  <q-separator vertical />
                  <q-btn
                    flat
                    class="delete"
                    color="negative"
                    :class="{
                      disable: paymentMethod.isDefault,
                    }"
                    :loading="showLoading(paymentMethod.paymentMethodId)"
                    @click="deletePaymentMethod(paymentMethod)"
                  >
                    <SvgIcon size="11px" icon="trash" class="q-mr-sm" />
                    Delete
                  </q-btn>
                </div>
              </div>
            </div>
          </transition-group>
        </div>
      </div>
    </div>
    <!-- Add Payment Dialog -->
    <AddPaymentMethod />
    <AddBillingAddress />
    <!-- Add Payment Dialog -->
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import { mergeObjects, getClassForCardType, getCardType } from 'src/utils'
import AddPaymentMethod from 'components/payment/AddPaymentMethod'
import AddBillingAddress from 'components/payment/AddBillingAddress'
import { testModePaymentMessage } from 'src/constants'
export default {
  name: 'PagePayment',
  components: { AddPaymentMethod, AddBillingAddress },
  meta() {
    return {
      title: 'Payment Methods',
      ...this.commonMeta,
    }
  },
  computed: {
    ...mapGetters('payment', ['loading', 'paymentMethods']),
    paymentMethodsList() {
      if (this.loading) return {}

      return mergeObjects({ 'add-payment': {} }, this.paymentMethods)
    },
  },
  destroyed() {
    this.$store.dispatch('payment/getAvailableMethods')
  },
  methods: {
    getClassForCardType: getClassForCardType,
    getCardType: getCardType,
    showLoading(paymentMethodId) {
      return this.trashLoading[paymentMethodId] || false
    },
    addPaymentMethod() {
      if (
        this.businessConfigurations.mode === 2 &&
        this.businessConfigurations.currentPaymentGateway == null
      ) {
        this.notify(testModePaymentMessage)
      } else {
        this.changeDialogState({
          dialog: 'AddPaymentMethod',
          val: true,
        })
      }
    },
    editBillingInfo(paymentMethod) {
      this.changeDialogState({
        dialog: 'AddPaymentMethod',
        val: true,
        properties: { paymentMethod, action: 'ChooseBillingAddress' },
      })
    },
    deletePaymentMethod(paymentMethod) {
      if (paymentMethod.islinkedSubscription) {
        this.ftxConfirmCoupon(
          '',
          'This payment method is linked to an active subscription. If you still wish to delete it, please update or remove it from your subscription details first.',
          false,
          'Ok',
          true,
          false,
          'info-filled',
          '3em',
          true,
          false,
          false,
          true
        )
      } else {
        this.ftxConfirm('Are you sure you want to delete Payment Method?').onOk(
          async () => {
            this.$set(this.trashLoading, paymentMethod.paymentMethodId, true)

            this.$store
              .dispatch('payment/deletePaymentMethod', paymentMethod)
              .then((response) => {
                if (response && response.success) {
                  this.showSuccess('Your Payment method Removed successfully.')
                } else if (response?.errors.length) {
                  if (
                    response.errors[0].errorCode == 'ACTIVE_SUBSCRIPTION_FOUND'
                  ) {
                    this.ftxConfirmCoupon(
                      '',
                      'This payment method is linked to an active subscription. If you still wish to delete it, please update or remove it from your subscription details first.',
                      false,
                      'Ok',
                      true,
                      false,
                      'info-filled',
                      '3em',
                      true,
                      false,
                      false,
                      true
                    )
                  } else {
                    this.showError(response.message)
                  }
                } else {
                  this.showError(response.message)
                }
              })
              .finally(() => {
                this.$set(
                  this.trashLoading,
                  paymentMethod.paymentMethodId,
                  false
                )
              })
          }
        )
      }
    },
    setAsDefault(paymentMethod) {
      if (!paymentMethod.isDefault) {
        this.$store
          .dispatch('payment/UpdateDefaultPaymentMethod', paymentMethod)
          .then((response) => {
            // this.showSuccess('Default payment method modified successfully.')
          })
      }
    },
    itemActivate(index) {
      this.active_el = mergeObjects(this.active_el, {
        [index]: !this.active_el[index],
      })
    },
    notify(message) {
      this.showSuccess(message)
    },
  },
  data() {
    return {
      trashLoading: {},
      active_el: {},
    }
  },
}
</script>
<style lang="scss">
.managePaymentItems {
  > span {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    margin-left: -10px;
    margin-right: -10px;
  }
  .paymentItem {
    padding: 10px;
    flex: 0 0 100%;
    @media (min-width: 600px) {
      flex: 0 0 50%;
    }
    @media (min-width: 1024px) {
      flex: 0 0 33.33%;
    }
    .paymentInner {
      display: flex;
      flex-direction: column;
      position: relative;
      border: 1px solid #cccccc;
      height: 100%;
      min-height: 173px;
      -webkit-border-radius: 5px;
      border-radius: 5px;
      &.invalidcard {
        background: rgba(193, 0, 1, 0.05);
        border-color: rgba(193, 0, 1, 0.2);
        .paymentBottom {
          border-top-color: rgba(193, 0, 1, 0.2);
        }
        .q-separator {
          background: rgba(193, 0, 1, 0.2);
        }
        .invalidcard-icon {
          z-index: 1;
        }
      }
    }
    .paymentMiddle {
      padding: 20px;
      padding-left: 65px;
      flex: 1 0 0;
      position: relative;
      .cc {
        position: absolute;
        display: block;
        width: 40px;
        left: 15px;
        top: 20px;
      }
      h3 {
        margin: 0;
        padding: 0;
        font-size: 16px;
        font-weight: 600;
        line-height: normal;
        color: #314154;
        min-height: 21px;
        span {
          display: inline-block;
          vertical-align: middle;
          background: #91bf5b;
          margin-left: 5px;
          padding: 3px 15px;
          font-size: 10px;
          color: #fff;
          border-radius: 10px;
        }
        @media (min-width: 1681px) {
          font-size: 20px;
        }
      }
      h5 {
        font-size: 13px;
        font-weight: 600;
        color: #314154;
        margin: 5px 0 0;
        padding: 0;
      }
      p {
        margin: 5px 0 0;
        padding: 0;
        font-size: 13px;
        line-height: 1.5;
        color: #888888;
        @media (min-width: 1681px) {
          font-size: 16px;
        }
      }
      .toggle {
        margin-top: 15px;
        .q-toggle__label {
          font-size: 12px;
          color: #0f141a;
        }
      }
    }
    .quickAction {
      display: block;
      width: 24px;
      height: 24px;
      text-align: center;
      line-height: 20px;
      color: #888888;
      background: #eeeeee;
      position: absolute;
      right: 15px;
      top: 20px;
      border-radius: 50%;
      svg {
        width: 10px;
        height: 20px;
        transition: transform 0.3s ease-in-out;
      }
      &.active {
        svg {
          transform: rotate(-180deg);
        }
      }
    }
    .paymentSwitch {
      margin-top: 10px;
      min-height: 15px;
      font-size: 13px;
      color: #0f141a;
    }
    .paymentBottom {
      border-top: 1px solid #cccccc;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      a,
      button {
        flex: 10000 0 0;
        border-radius: 0;
      }
      .editPayment {
        flex: 0 0 auto;
        border-radius: 0;
        padding: 0 8px;
      }
      button {
        border-left: none !important;
      }
      a {
        padding: 10px;
        font-size: 14px;
        text-align: center;
        text-decoration: none;
        border-left: 1px solid #ccc;
        @media (min-width: 1681px) {
          padding: 15px;
        }
        &:first-child {
          border-left: none;
        }
        svg {
          width: 20px;
          height: 20px;
        }
        &.edit {
          color: $primary;
          color: var(--q-color-primary) !important;
        }

        &.disable {
          color: #888888;
          pointer-events: none;
        }
      }
    }
    &.addPaymentItem {
      .paymentInner {
        text-align: center;
        background: #f2fbff;
        background: var(--q-color-light-primary);
        border-color: #99c2d2;
        border-color: var(--q-color-primary);
        @media (max-width: 767px) {
          min-height: 60px;
        }
      }
      .paymentMiddle {
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $tertiary;
        font-weight: bold;
        text-transform: uppercase;
        text-decoration: none;
      }
    }
  }
}
</style>
