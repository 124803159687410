import { Platform } from 'quasar'

import { convertArrayToObject } from 'src/utils'
import { NotifyMethods } from 'src/boot/notify'
import { reloadPage } from 'src/utils'
import { isSsr } from 'src/constants'

export async function getFooterData({ commit }) {
  try {
    let { data } = await this.$axiosInstance.get('/FooterDetails', {
      params: {
        global: true,
      },
    })

    if (data?.data) commit('SET_FOOTER_DATA', data.data)
  } catch (error) {}
}

export async function getConfiguration({ commit, rootGetters }) {
  const isServer = process.env.SERVER,
    loadSsrConf = isSsr && isServer

  try {
    let response, url

    if (loadSsrConf) url = '/v2/Configuration'
    else url = '/v1/Configuration'

    // if (loadSsrConf && false) {
    //   response = await new Promise((res) => {
    //     return res({
    //       data: {
    //         data: {
    //           businessConfigurationID: '41a686e2-92b0-4847-8b88-4abfb84715a3',
    //           businessName: 'Smokers Choice USA',
    //           isActive: true,
    //           mode: 0,
    //           modeDescription: '',
    //           isStoreAgeRestricted: false,
    //           enableAgeRestrictedBrowsing: false,
    //           enableAgeRestrictedCheckout: false,
    //           fulfillments: [
    //             {
    //               fulfillmentID: '81c89b19-8f64-4cb9-b1c3-8ad12b0135e4',
    //               businessID: '41a686e2-92b0-4847-8b88-4abfb84715a3',
    //               fulfillmentName: 'Store Pickup',
    //               codeName: 'sp',
    //               isActive: true,
    //               infoForCustomer:
    //                 '<div style="text-align: left;"><b style="text-align: center; font-size: 14px; letter-spacing: 0.25004px;">Description For Users Added By Admin</b></div>',
    //               pickupInfo:
    //                 '<div style="text-align: center;"><b style="font-size: 14px; letter-spacing: 0.25004px;">Store Pickup Instruction Added By Admin</b></div>',
    //               minOrderAmount: 100,
    //               fixedFee: 10,
    //               slotClosingDays: 0,
    //               slotClosingHours: 0,
    //               slotClosingMinutes: 0,
    //               slotClosingTimeInMin: 0,
    //               isSlotBookingRequired: true,
    //               isComingSoon: false,
    //               enableRescheduleOrder: true,
    //               deliveryMethod: 0,
    //               deliveryCoverageBy: 0,
    //               isTipEnabled: true,
    //               tipOption1: 1,
    //               tipOption2: 2,
    //               tipOption3: 3,
    //               tipOption4: 4,
    //               isSignatureRequired: false,
    //             },
    //             {
    //               fulfillmentID: '88c78724-6158-4b63-9019-3fc16f5e6c4a',
    //               businessID: '41a686e2-92b0-4847-8b88-4abfb84715a3',
    //               fulfillmentName: 'Delivery',
    //               codeName: 'scd',
    //               isActive: true,
    //               infoForCustomer:
    //                 'Delivery&nbsp;<span style="font-size: 14px; letter-spacing: 0.25004px;">Description for Users 222</span>',
    //               pickupInfo: null,
    //               minOrderAmount: 0,
    //               fixedFee: 0,
    //               slotClosingDays: 0,
    //               slotClosingHours: 0,
    //               slotClosingMinutes: 0,
    //               slotClosingTimeInMin: 0,
    //               isSlotBookingRequired: true,
    //               isComingSoon: false,
    //               enableRescheduleOrder: false,
    //               deliveryMethod: 1,
    //               deliveryCoverageBy: 0,
    //               isTipEnabled: true,
    //               tipOption1: 10,
    //               tipOption2: 25,
    //               tipOption3: 35,
    //               tipOption4: 40,
    //               isSignatureRequired: true,
    //             },
    //             {
    //               fulfillmentID: '1cb3c275-93e7-45c6-9410-b8b07c99003f',
    //               businessID: '41a686e2-92b0-4847-8b88-4abfb84715a3',
    //               fulfillmentName: 'Standard Shipping',
    //               codeName: 'sd',
    //               isActive: true,
    //               infoForCustomer:
    //                 '<span style="font-size: 14px; letter-spacing: 0.25004px;">Standard Shipping Description for Users333</span>',
    //               pickupInfo: '',
    //               minOrderAmount: 0,
    //               fixedFee: 0,
    //               slotClosingDays: 0,
    //               slotClosingHours: 0,
    //               slotClosingMinutes: 0,
    //               slotClosingTimeInMin: 0,
    //               isSlotBookingRequired: false,
    //               isComingSoon: false,
    //               enableRescheduleOrder: false,
    //               deliveryMethod: 0,
    //               deliveryCoverageBy: 0,
    //               isTipEnabled: false,
    //               tipOption1: 0,
    //               tipOption2: 0,
    //               tipOption3: 0,
    //               tipOption4: 0,
    //               isSignatureRequired: false,
    //             },
    //           ],
    //           tenantAcr: 'ftx.dev.ftxcommerce.com',
    //           accountID: '5acdfce1-c19d-4ebf-9b26-6de6cb440be7',
    //           logoURL:
    //             'https://ftx-online-ordering-images.s3.amazonaws.com/OnlineOrder/backend-images/1d4b115c-8505-43bd-a369-94b42d604b75_202404150825166159.png',
    //           defaultProductImageURL: null,
    //           betaImageUrl:
    //             'https://ftx-online-ordering-images.s3.amazonaws.com/OnlineOrder/backend-images/1d4b115c-8505-43bd-a369-94b42d604b75_202404150825166159.png',
    //         },
    //         success: true,
    //         message: 'success',
    //         instance: null,
    //         developerMessage: null,
    //         errors: null,
    //       },
    //       status: 200,
    //       statusText: '',
    //     })
    //   })
    // } else {
    response = await this.$axiosInstance.get(url, {
      params: {
        global: true,
      },
      useCache: false,
    })
    // }

    if (response?.data) {
      let { data } = response,
        {
          businessConfigurations,
          fulfillments,
          socialPlatforms,
          // shippingConfigurations,
          tenantAcr,
          accountID,
        } = data.data

      if (loadSsrConf) {
        businessConfigurations = data.data

        let themeData = {
          logoURL: businessConfigurations.logoURL,
          defaultProductImageURL: businessConfigurations.defaultProductImageURL,
          betaImageUrl: businessConfigurations.betaImageUrl,
        }
        commit('theme/SET_THEME', themeData, { root: true })
      }

      if (!tenantAcr) tenantAcr = process.env.Tenant_ACR.replace('tenant:', '')
      // businessFulfillments = []

      // for (let fulfillment of fulfillments) {
      //   businessFulfillments.push({ ...fulfillment, model: true })
      // }

      // if (tenantAcr) this.$oidc._settings._acr_values = tenantAcr

      commit('SET_BUSINESS_CONFIGURATIONS', {
        ...businessConfigurations,
        tenantAcr,
        accountID,
      })
      // commit('SET_SHIPPING_CONFIGURATIONS', shippingConfigurations)

      // Set Mode Details
      if ([0, 1, 2, 3].includes(businessConfigurations?.mode)) {
        let activeMode = rootGetters['persisted/activeMode']
        if (activeMode !== businessConfigurations.mode)
          await commit('persisted/VALIDATE_ACCESS_CODE', false, {
            root: true,
          })

        commit('persisted/SET_ACTIVE_MODE', businessConfigurations.mode, {
          root: true,
        })
        if (activeMode == 1 && activeMode != businessConfigurations.mode)
          reloadPage()
      }

      commit(
        'SET_BUSINESS_FULFILLMENTS',
        convertArrayToObject(
          fulfillments.filter((fulfillment) => fulfillment.isActive),
          'codeName'
        )
      )
      commit(
        'SET_ALL_FULFILLMENT',
        convertArrayToObject(fulfillments, 'codeName')
      )

      if (!loadSsrConf) {
        commit('SET_SOCIAL_PLATFORMS', socialPlatforms)
      }

      commit('SET_TENANT_FOUND', true)

      commit(
        'SET_STORE_IS_ACTIVE',
        businessConfigurations && businessConfigurations.isActive
      )

      commit('SET_STATE', {
        key: 'storeConfigurationLoaded',
        value: true,
      })

      if (!loadSsrConf) {
        commit('SET_STATE', {
          key: 'clientStoreConfigurationLoaded',
          value: true,
        })
      }
    }

    return response
  } catch (error) {
    commit('SET_STATE', {
      key: 'storeConfigurationLoaded',
      value: true,
    })

    if (!loadSsrConf) {
      commit('SET_STATE', {
        key: 'clientStoreConfigurationLoaded',
        value: true,
      })
    }
  }
}

export async function getMobileAppData({ commit }) {
  try {
    let { data } = await this.$axiosInstance.get('/MobileApp', {
      params: {
        global: true,
      },
    })
    if (data && data.data && data.data.url) {
      commit('SET_MOBILE_APP_DATA', data.data)
      commit('persistedLocal/SHOW_APP_DOWNLOAD_NOTIFICATION', false, {
        root: true,
      })

      NotifyMethods.showAppDownloadNotify(() => {
        if (Platform?.is?.mobile) window.open(data.data.url, '_blank').focus()
        else
          commit(
            'showcase/updateDialogState',
            {
              dialog: 'AppBarcode',
              val: true,
            },
            { root: true }
          )
      })
    }
  } catch (error) {}
}

export async function validateAccessCode({ commit }, { mode, code }) {
  try {
    let response
    if (mode == 2)
      response = await this.$axiosInstance.post('/Mode/Test', {
        accessCode: code,
      })
    else if (mode == 3)
      response = await this.$axiosInstance.post('/Mode/Beta', {
        accessCode: code,
      })

    if (response.data) return response.data
  } catch (error) {
    return error
    // if (error.errors[0].errorCode == 'CONFIGRATION_NOT_FOUND')
    //   return error.errors[0]
    // else return false
  }
}

export async function checkDeliveryAddressForLocations({ commit }) {
  try {
    let response = await this.$axiosInstance.get('/Configuration/Locations', {
      params: {
        global: true,
      },
    })
    if (response?.data && response?.data?.success)
      commit(
        'SET_ASK_ADDRESS_FOR_DELIVERY',
        response?.data?.data?.askFullAddressForDelivery || false
      )
  } catch (error) {
    return false
  }
}

export async function getDisclaimer({ commit, rootGetters }) {
  try {
    let disclaimerUpdate = rootGetters['persistedLocal/disclaimerUpdate']

    let { data } = await this.$axiosInstance.get('/Disclaimer', {
      params: {
        disablePaging: true,
      },
    })

    let disclaimers = data?.data?.items || []

    if (disclaimers?.length) {
      commit('SET_STATE', {
        key: 'disclaimers',
        value: disclaimers,
      })

      let hasUnReadDisclaimers = false
      // if (!Object.keys(disclaimerUpdate).length) {
      //   hasUnReadDisclaimers = true
      // } else
      disclaimers.every((disclaimer) => {
        if (
          disclaimer.isRequiredAcceptance &&
          (!disclaimerUpdate?.hasOwnProperty(disclaimer.id) ||
            (disclaimerUpdate?.hasOwnProperty(disclaimer.id) &&
              disclaimerUpdate[disclaimer.id] != disclaimer.updatedAt))
        ) {
          hasUnReadDisclaimers = true
          return false
        }

        return true
      })

      if (hasUnReadDisclaimers) {
        let parentDialog = rootGetters['showcase/getDialogState'](
          'AnnouncementDialog'
        )
          ? 'AnnouncementDialog'
          : null
        commit(
          'showcase/updateDialogState',
          {
            dialog: 'DisclaimerDialog',
            val: true,
            parentDialog,
          },
          { root: true }
        )
      }
    }
    return data
  } catch (error) {
    return error
  }
}

export async function getAnnouncement({ commit, rootGetters }) {
  try {
    let announcementUpdate = rootGetters['persistedLocal/announcementUpdate']

    let { data } = await this.$axiosInstance.get('/Disclaimer/Announcement', {
      params: {
        disablePaging: true,
      },
    })

    let announcements = data?.data?.items
    if (announcements?.length) {
      commit('SET_STATE', {
        key: 'announcements',
        value: announcements,
      })

      if (rootGetters['persisted/currentLocationId']) {
        let hasUnReadAnnouncement = false
        if (!Object.keys(announcementUpdate).length) {
          hasUnReadAnnouncement = true
        } else
          announcements.every((announcement) => {
            if (
              !announcementUpdate?.hasOwnProperty(announcement.id) ||
              (announcementUpdate?.hasOwnProperty(announcement.id) &&
                announcementUpdate[announcement.id] != announcement.updatedAt)
            ) {
              hasUnReadAnnouncement = true
              return false
            }

            return true
          })

        if (hasUnReadAnnouncement) {
          let parentDialog = rootGetters['showcase/getDialogState'](
            'DisclaimerDialog'
          )
            ? 'DisclaimerDialog'
            : null
          commit(
            'showcase/updateDialogState',
            {
              dialog: 'AnnouncementDialog',
              val: true,
              parentDialog,
            },
            { root: true }
          )

          commit(
            'showcase/SET_DIALOG_PROPERTIES',
            {
              dialog: 'AnnouncementDialog',
              properties: {
                showUnread: true,
              },
              refresh: true,
            },
            { root: true }
          )
        }
      }
    }
    return data
  } catch (error) {
    return error
  }
}

export async function getPaymentServiceLogos({ commit }) {
  try {
    let { data } = await this.$axiosInstance.get('/FooterDetails/PaymentLogos')
    if (data?.data) {
      commit('SET_PAYMENT_SERVICES_LOGO', data.data)
    }
  } catch (error) {}
}


export async function requestOtp({ commit }, otpRequestData) {
  try {
    const response = await this.$axiosInstance.post('v1/Customer/CustomerInquiry/Otp', otpRequestData)
    return response.data
  } catch (error) {
    if (error?.errors && error?.errors[0]?.errorCode === "CUST_0006") {
      NotifyMethods.showError('An error occurred while requesting OTP. Please try again later.')
    }
    else {
      NotifyMethods.showError('An error occurred. Please try again later.');
    }
    return false
  }
}

export async function submitCustomerInquiry({ commit }, inquiryData) {
  try {
    const response = await this.$axiosInstance.post('v1/Customer/CustomerInquiry', inquiryData);
    return response.data;
  } catch (error) {
    let errorMessage = 'An error occurred. Please try again later.';

    if (error?.errors) {
      const otpError = error.errors.find(err => 
        err.errorCode === "OTP_0002" || 
        err.errorCode === "OTP_0003" ||
        err.errorCode === "OTP_ATTEMPTS_EXCEEDED"
      );

      if (otpError) {
        if (otpError.errorCode === "OTP_0002") {
          return { success: false, otpClear: true };
        }
        else if (otpError.errorCode === "OTP_ATTEMPTS_EXCEEDED") {
          return { success: false, otpAttemptsExceeded: true };
        }
        errorMessage = otpError.descreption;
      }
    }

    NotifyMethods.showError(errorMessage);
    return false;
  }
}
