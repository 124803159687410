<template>
  <q-dialog v-model="dialogState" position="bottom" maximized>
    <q-card class="mobileCardDialogue">
      <div class="mobileDialogTitle">
        <h5 class="text-h5">Note</h5>
        <q-icon class="close cursor-pointer" name="close" v-close-popup />
      </div>
      <div class="mobileDialogContent">
        <p class="q-pa-md text-body2">{{ properties.tooltip }}</p>
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
export default {
  name: 'label-tooltip',
  data() {
    return {
      dialog: 'tooltipDialog',
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
}
</script>
