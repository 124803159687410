export default function () {
  return {
    orders: {},
    recentOrderNumber: null,
    detail: {},
    trackOrderDetail: {},
    editDetail: {},
    subscriptionDetail: {},

    // update order
    categories: [],
    recentItems: {},
    recentUpdatedOrder: null,
    alertData: {},
    updateTimeSlot: {},

    parkingSpots: null,
    slotInfo: {},
    loadingSlot: false,
    filterSearchBy: '',
  }
}
