var render = function render(){var _vm=this,_c=_vm._self._c;return _c('q-input',{ref:"searchBar",staticClass:"col h-search-input",attrs:{"borderless":"","dense":"","bg-color":"white","color":"dark","bottom-slots":"","placeholder":"What are you looking for?","autocomplete":"off","maxlength":"40"},on:{"click":() => {
      if (this.isMobile) {
        this.$refs.searchBar.blur()
        this.changeDialogState({
          dialog: 'MobileSearchDialog',
          val: true,
        })
      } else {
        this.focus = true
      }
    },"blur":() => {
      this.focus = false
      this.searchingProduct = false
    },"keyup":_vm.initiateSearch,"focus":_vm.initiateSearch},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText && _vm.searchText.trim()),expression:"searchText && searchText.trim()"}],attrs:{"dense":"","flat":"","ripple":false,"icon":"close","size":".5em"},on:{"click":_vm.clearInput}}),_c('q-btn',{attrs:{"dense":"","flat":"","ripple":false},on:{"click":_vm.searchClicked}},[_c('SvgIcon',{attrs:{"className":"q-ml-sm q-mr-sm","color":"grey","size":"1rem","icon":"simple-search"}})],1)]},proxy:true},(_vm.isMobile)?{key:"prepend",fn:function(){return [_c('q-btn',{attrs:{"dense":"","flat":"","ripple":false}},[_c('SvgIcon',{attrs:{"color":"grey","size":"1rem","icon":"back-arrow-line"}})],1)]},proxy:true}:null],null,true),model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}},[(!_vm.isMobile)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.focus),expression:"focus"}],staticClass:"h-search-dropdown"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText && _vm.searchText.trim().length < 3),expression:"searchText && searchText.trim().length < 3"}],staticClass:"h-search-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.trendingProducts).length),expression:"Object.keys(trendingProducts).length"}],staticClass:"label"},[_vm._v("\n        Trending\n      ")]),(Object.keys(_vm.trendingProducts).length)?_c('q-scroll-area',{style:({
          height:
            Object.keys(_vm.trendingProducts).length * 54 > 162
              ? '162px'
              : `${Object.keys(_vm.trendingProducts).length * 54}px`,
        }),attrs:{"thumb-style":{ ..._vm.scrollAreaThumbStyle, zIndex: 1000 },"bar-style":{ ..._vm.scrollAreaBarStyle, zIndex: 1000 },"visible":_vm.scrollAreaVisible}},[_c('q-list',{directives:[{name:"show",rawName:"v-show",value:(Object.keys(_vm.trendingProducts).length),expression:"Object.keys(trendingProducts).length"}],attrs:{"padding":""}},_vm._l((_vm.trendingProducts),function(product,productId){return _c('q-item',{key:productId,attrs:{"clickable":""},on:{"click":function($event){_vm.focus = false}}},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-avatar',{attrs:{"rounded":""}},[_c('q-img',{attrs:{"src":_vm._f("defaultProductImage")(product.image)}})],1)],1),_c('q-item-section',[(product.seoName)?_c('router-link',{attrs:{"to":{
                  name: 'Detail',
                  params: { seoName: product.seoName },
                }}},[_vm._v("\n                "+_vm._s(product.productName)+"\n              ")]):_vm._e(),_vm._v("\n              in\n              "),_c('span',{staticClass:"text-primary"},_vm._l((product.categories),function(category,index){return _c('router-link',{key:`${category.categoryID}-${index}`,attrs:{"to":{
                    name: 'Category',
                    params: { seoName: category.categorySeoName },
                  }}},[_vm._v("\n                  "+_vm._s(_vm.$options.filters.titleCase(
                      category.categoryName +
                        (product.categories.length - 1 != index ? ', ' : '')
                    ))+"\n                ")])}),1)],1)],1)}),1)],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.frequentSearches.length),expression:"frequentSearches.length"}],staticClass:"label"},[_vm._v("\n        Frequent Searches\n      ")]),(_vm.frequentSearches.length)?_c('q-scroll-area',{style:({
          height:
            _vm.frequentSearches.length * 42 > 126
              ? '126px'
              : `${_vm.frequentSearches.length * 42}px`,
        }),attrs:{"thumb-style":{ ..._vm.scrollAreaThumbStyle, zIndex: 1000 },"bar-style":{ ..._vm.scrollAreaBarStyle, zIndex: 1000 },"visible":_vm.scrollAreaVisible}},[_c('q-list',{attrs:{"padding":""}},_vm._l((_vm.frequentSearches),function(item,index){return _c('router-link',{key:`${item}-${index}`,staticClass:"no-underline",attrs:{"to":{
              name: 'Search',
              query: { q: item },
            }},nativeOn:{"click":function($event){_vm.focus = false}}},[_c('q-item',{staticClass:"items-center"},[_c('q-item-section',{attrs:{"avatar":""}},[_c('q-icon',{attrs:{"color":"dark","name":"search"}})],1),_c('q-item-section',[_vm._v(_vm._s(item))])],1)],1)}),1)],1):_vm._e()],1),(
        _vm.productsByKeyword &&
        _vm.productsByKeyword.items &&
        _vm.productsByKeyword.items.length
      )?_c('div',{directives:[{name:"show",rawName:"v-show",value:(
        _vm.searchText && _vm.searchText.trim().length >= 3 && !_vm.searchingProduct
      ),expression:"\n        searchText && searchText.trim().length >= 3 && !searchingProduct\n      "}],staticClass:"h-search-2",class:{
        'one-record': _vm.productsByKeyword.items.length == 1,
        'two-record': _vm.productsByKeyword.items.length == 2,
      }},[_c('q-scroll-area',{style:({
          height:
            _vm.productsByKeyword &&
            _vm.productsByKeyword.items &&
            _vm.productsByKeyword.items.length * 55 > 275
              ? '290px'
              : `${_vm.productsByKeyword.items.length * 55 + 15}px`,
        }),attrs:{"thumb-style":{ ..._vm.scrollAreaThumbStyle, zIndex: 1000 },"bar-style":{ ..._vm.scrollAreaBarStyle, zIndex: 1000 },"visible":_vm.scrollAreaVisible}},[_c('q-list',{attrs:{"padding":""}},_vm._l((_vm.productsByKeyword.items),function(product){return _c('q-item',{key:product.productID},[_c('q-item-section',{attrs:{"avatar":""}},[_c('router-link',{staticClass:"no-underline",staticStyle:{"width":"50px","padding":"5px"},attrs:{"to":{
                  name: 'Detail',
                  params: { seoName: product.seoName },
                }}},[_c('q-avatar',{attrs:{"rounded":""}},[_c('q-img',{staticStyle:{"width":"40px","height":"40px"},attrs:{"src":product && product.images && product.images.length
                        ? product.images[0].imageUrl
                        : _vm.$options.filters.defaultProductImage(''),"contain":""}})],1)],1)],1),_c('q-item-section',[_c('q-item-label',[_c('router-link',{staticClass:"no-underline ellipsis",attrs:{"to":{
                    name: 'Detail',
                    params: { seoName: product.seoName },
                  }},nativeOn:{"click":function($event){_vm.focus = false}}},[_vm._v("\n                  "+_vm._s(product.title)+"\n                  "),(!_vm.isMobile)?_c('q-tooltip',{attrs:{"max-width":"180px","content-class":"bg-dark"}},[_vm._v("\n                    "+_vm._s(product.title)+"\n                  ")]):_vm._e()],1)],1),_c('q-item-label',{attrs:{"caption":"","lines":"2"}},[_vm._v("\n                1 x\n                "),_c('strong',{staticClass:"text-primaryOnBody q-mr-sm"},[_vm._v("\n                  "+_vm._s(_vm._f("currency")(product.price))+"\n                ")]),(false)?_c('span',{staticClass:"text-strike"},[_vm._v("\n                  "+_vm._s(_vm._f("currency")(product.compareAtPrice))+"\n                ")]):_vm._e()])],1),(!_vm.isMobile)?_c('q-item-section',{staticClass:"h-search-cart",attrs:{"side":"","top":""}},[_c('AddToCartBtn',{attrs:{"productFulFillments":product.fulFillments
                    ? product.fulFillments
                    : product.productFulfillments,"productId":product.productID,"onDeliveryModeChnaged":_vm.onDeliveryModeChnaged,"isinstock":product.isinstock}})],1):_vm._e()],1)}),1)],1),_c('router-link',{staticClass:"more-products",attrs:{"to":{
          name: 'Search',
          query: { q: _vm.searchText },
        }},nativeOn:{"click":function($event){_vm.focus = false}}},[_vm._v("\n        See All Results\n      ")])],1):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchText && _vm.searchText.trim().length >= 3),expression:"searchText && searchText.trim().length >= 3"}],staticClass:"h-search-2"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.searchingProduct),expression:"searchingProduct"}]},_vm._l((3),function(n){return _c('q-item',{key:n},[_c('q-item-section',{attrs:{"avatar":"","top":""}},[_c('q-skeleton',{staticClass:"q-mx-auto",attrs:{"width":"50px","height":"50px"}})],1),_c('q-item-section',{attrs:{"top":""}},[_c('q-skeleton',{staticClass:"q-mb-sm",attrs:{"type":"rect","width":"70%"}}),_c('q-skeleton',{staticClass:"q-mb-sm",attrs:{"type":"text","width":"20%"}})],1),(!_vm.isMobile)?_c('q-item-section',{attrs:{"side":"","top":""}},[_c('q-skeleton',{staticClass:"q-mb-sm",attrs:{"type":"rect","width":"184px","height":"38px"}})],1):_vm._e()],1)}),1),_c('EmptyComponent',{directives:[{name:"show",rawName:"v-show",value:(
          !_vm.searchingProduct &&
          _vm.productsByKeyword &&
          _vm.productsByKeyword.items &&
          _vm.productsByKeyword.items.length == 0
        ),expression:"\n          !searchingProduct &&\n          productsByKeyword &&\n          productsByKeyword.items &&\n          productsByKeyword.items.length == 0\n        "}],attrs:{"imgWidth":160,"image":"images/empty-search.png"}},[(_vm.currentLocationId)?_c('p',[_vm._v("\n          No product available matching  \n          "),_c('strong',[_vm._v("\n            "+_vm._s(_vm.searchText)+"\n          ")])]):_c('p',[_vm._v("\n          Please select the location before searching the product\n        ")])])],1)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }