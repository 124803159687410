<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'right'"
    maximized
    className="dialog-xs"
    :before-show="onShow"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <h4
            class="text-subtitle1 q-ma-none q-pa-md text-weight-bold text-black"
          >
            Update Payment Method
          </h4>
        </div>

        <q-card-section class="q-pa-none">
          <q-scroll-area
            :thumb-style="scrollAreaThumbStyle"
            :bar-style="scrollAreaBarStyle"
            :visible="scrollAreaVisible"
            style="height: calc(100dvh - 80px)"
          >
            <div class="addressList">
              <q-list>
                <template>
                  <q-item
                    tag="label"
                    v-for="(paymentMethod, key, index) in paymentMethodsList"
                    :key="`${key}-${index}`"
                    :class="
                      currentPaymentMethodId == paymentMethod.paymentMethodId
                        ? 'active'
                        : ''
                    "
                  >
                    <q-item-section
                      class="addAddress"
                      @click="addPaymentMethod"
                      v-if="key == 'add-payment'"
                    >
                      <q-item-label>
                        <span>+</span>
                        Add new credit/debit card
                      </q-item-label>
                    </q-item-section>
                    <q-item-section v-else>
                      <q-radio
                        size="sm"
                        v-model="currentPaymentMethodId"
                        :val="paymentMethod.paymentMethodId"
                        :label="
                          currentPaymentMethodId ==
                          paymentMethod.paymentMethodId
                            ? 'Selected'
                            : 'Select'
                        "
                        @input="
                          updatePaymentMethod(paymentMethod.paymentMethodId)
                        "
                      />
                      <q-item-label>
                        <div class="row items-center">
                          <!-- <div class="col text-weight-bold">Name</div> -->
                          <strong>{{ paymentMethod.cardHolderName }}</strong>
                          <span v-if="paymentMethod.isDefault" class="default">
                            Default
                          </span>
                        </div>
                      </q-item-label>
                      <q-item-label>
                        Ending in
                        <strong>{{ paymentMethod.cardLast4Digits }}</strong>
                      </q-item-label>
                      <q-item-label>
                        Expires <strong>{{ paymentMethod.cardExpiry }}</strong>
                        <span :class="`paymentIcon`">
                          <img
                            :src="`images/payment/${
                              getCardType(paymentMethod.cardType).logo
                            }`"
                            :title="getCardType(paymentMethod.cardType).title"
                          />
                        </span>
                      </q-item-label>
                      <q-separator class="q-mx-sm q-my-md" />
                      <q-item-label class="address q-mb-sm relative-position">
                        <strong>Billing Address</strong><br />
                        <p class="ellipsis q-pt-md q-mb-none">
                          {{ paymentMethod?.address?.firstName }}
                          {{ paymentMethod?.address?.lastName }}
                        </p>
                        <p
                          v-html="
                            $options.filters.formatAddress(
                              paymentMethod?.address
                            )
                          "
                          class="address"
                        >
                        </p>

                        <q-btn
                          unelevated
                          round
                          dense
                          color="grey-6"
                          text-color="white"
                          padding="6px"
                          :class="{
                            disable: paymentMethod.isInvalid,
                          }"
                          class="absolute-top-right q-mr-sm"
                          @click="editBillingInfo(paymentMethod)"
                        >
                          <q-icon name="edit" size="1rem" />
                        </q-btn>
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </template>
              </q-list>
            </div>
          </q-scroll-area>
        </q-card-section>
        <q-inner-loading
          :showing="loading"
          label="Please wait..."
          label-class="text-teal"
          label-style="font-size: 1.1em"
        />
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
import { mapGetters } from 'vuex'
import { mergeObjects, getCardType } from 'src/utils'

export default {
  name: 'UpdatePaymentDialog',
  data() {
    return {
      dialog: 'UpdatePaymentDialog',
      currentPaymentMethodId: null,
    }
  },
  computed: {
    ...mapGetters('payment', ['loading', 'paymentMethods']),
    paymentMethodsList() {
      if (this.loading) return {}

      return mergeObjects({ 'add-payment': {} }, this.paymentMethods)
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    getCardType: getCardType,
    async onShow() {
      this.$root.$on('payment-method-added', (data) => {
        if (data.newPaymentMethodId) {
          this.currentPaymentMethodId = data.newPaymentMethodId
          this.updatePaymentMethod(data.newPaymentMethodId)
        }
      })

      if (!Object.keys(this.paymentMethods).length)
        await this.$store.dispatch('payment/getAvailableMethods')
      this.currentPaymentMethodId = this.properties.paymentMethodId
    },
    addPaymentMethod() {
      this.changeDialogState({
        dialog: 'AddPaymentMethod',
        val: true,
        parentDialog: 'UpdatePaymentDialog',
      })
    },
    editBillingInfo(paymentMethod) {
      let properties = { paymentMethod, action: 'ChooseBillingAddress' }
      this.changeDialogState({
        dialog: 'AddPaymentMethod',
        val: true,
        properties,
      })
    },
    updatePaymentMethod(paymentMethodId) {
      let reqObj = {
        id: this.properties.subscriptionID,
        params: {
          actionType: this.properties.actionType,
          paymentMethodId,
        },
      }
      this.$store.commit('payment/SET_LOADING', true)
      this.$store
        .dispatch('order/updateSubscriptionAddressPayment', reqObj)
        .then(async (response) => {
          if (
            this.properties?.refreshListing &&
            response?.data?.subscriptionId
          ) {
            this.properties.refreshListing()
            this.changeDialogState({
              dialog: this.dialog,
              val: false,
            })
          }
        })
        .catch((error) => {
          console.log(error)
        })
        .finally(() => this.$store.commit('payment/SET_LOADING', false))
    },
  },
}
</script>

<style lange="scss" scoped>
.paymentIcon {
  margin-right: 0px !important;
}
</style>
