<template>
  <q-card flat class="lastOrderedInfo">
    <q-card-section>
      <div class="row items-center">
        <div class="col-12 col-md-12 text-center">
          <div class="text-caption q-mb-sm"
            >You last purchased this product</div
          >
          <div class="text-body2 text-weight-medium text-dark q-mb-sm"
            >Last Purchased:
            {{ lastPurchasedDetail?.lastPurchased?.lastPurchase | formatDate }}
            <br />
            {{ setVariant }}
          </div>
        </div>
        <div class="col-12 col-md-12 text-center">
          <q-btn
            unelevated
            no-caps
            color="tertiary"
            class="q-mt-sm"
            size="13px"
            :to="{
              name: 'OrderDetail',
              params: { orderId: lastPurchasedDetail?.lastPurchased?.orderID },
            }"
          >
            View Order
          </q-btn>
        </div>
      </div>
    </q-card-section>
  </q-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'LastOrderedInfo',
  props: {
    lastPurchasedDetail: {
      type: Object,
      default: () => {
        return new Object({})
      },
    },
  },
  computed: {
    ...mapGetters('product', ['getCurrentSelectedProductVariant']),
    setVariant() {
      const obj = this.getCurrentSelectedProductVariant

      const arr = Object.entries(obj)
        .map(
          ([name, value]) =>
            `${name.charAt(0).toUpperCase() + name.slice(1)}: ${value}`
        )
        .join(', ')

      return arr
    },
  },
}
</script>
<style lang="scss">
.lastOrderedInfo {
  border: 1px solid #ffbf87;
  background: #ffefe1;
  -webkit-border-radius: 7px;
  border-radius: 7px;
  margin-bottom: 20px;
}
</style>
