<template>
  <ftx-dialog
    :dialog="dialog"
    :position="isMobile ? 'bottom' : 'standard'"
    :maximized="isMobile ? true : false"
    :persistent="true"
    className="dialog-xl"
    :onShow="onShow"
  >
    <template v-slot:DialogContent>
      <q-card
        class="simpleDialog q-pa-none"
        :class="isMobile ? 'mobileCardDialogue' : ''"
      >
        <div class="dialogHeader">
          <q-btn
            class="close absolute-top-right"
            padding="md"
            flat
            v-close-popup
          >
            <SvgIcon icon="close" size="14px" />
          </q-btn>
          <div class="row">
            <div
              class="col-12 text-center"
              :class="isMobile ? 'q-px-md' : 'q-px-xl'"
            >
              <h4 class="text-h6 text-weight-bold text-black">
                We found the same product that you already subscribed
              </h4>
              <div class="text-subtitle1 line-height-normal text-black"
                >You're about to
                {{
                  $route.name == 'OrderEdit' ? 'add ' : 'place the order for '
                }}a product that you have already subscribed to. Selecting the
                <strong>'Subscription'</strong> option again will create a new
                subscription, while your existing subscription will remain
                unchanged.<br /><br />However, if you wish to update the
                subscription details such as quantity, order frequency, etc.,
                You can do so individually <br />in the
                <a
                  href="javascript:void(0);"
                  @click="navigateToSubscription"
                  class="text-underline text-primary pointer"
                  >Manage My Subscription</a
                >
                section.</div
              >
            </div>
          </div>
        </div>

        <q-card-section
          class="text-center"
          :class="isMobile ? 'q-px-md' : 'q-px-xl'"
        >
          <div class="row q-col-gutter-md">
            <div class="col-12">
              <div class="b-1 b-e4 q-pa-md">
                <q-scroll-area
                  class="already-subscribed-products"
                  :thumb-style="scrollAreaThumbStyle"
                  :bar-style="scrollAreaBarStyle"
                  :visible="scrollAreaVisible"
                  :style="[{ maxWidth: '100%', height: `${contentHeight}px` }]"
                  ref="alreadySubscribedProducts"
                >
                  <div class="row q-col-gutter-md">
                    <div
                      class="col-12 col-md-6"
                      v-for="(
                        product, index
                      ) of properties.alreadySubscribedProducts"
                      :key="index"
                    >
                      <q-item class="q-pa-none text-left">
                        <q-item-section avatar>
                          <div class="flex b-1 q-pa-xs">
                            <q-img
                              :src="product.image | defaultProductImage"
                              style="width: 48px; height: 48px"
                            />
                          </div>
                        </q-item-section>
                        <q-item-section>
                          <q-item-label
                            class="text-tertiary text-subtitle2 line-height-normal no-underline"
                          >
                            {{ product.title || product.productName }}
                          </q-item-label>
                          <q-item-label
                            class="text-tertiary text-subtitle2 line-height-normal no-underline"
                          >
                            <q-item-label
                              class="text-tertiary text-subtitle2 line-height-normal no-underline"
                            >
                              Subscription Id:<b>{{
                                product?.subscriptionNumber
                              }}</b>
                            </q-item-label>
                          </q-item-label>
                        </q-item-section>
                      </q-item>
                    </div>
                  </div>
                </q-scroll-area>
              </div>
            </div>

            <div class="col-12">
              <div class="text-18 text-weight-bold text-black"
                >Are you sure you want to place new order?</div
              >
              <div class="q-py-md">
                <q-btn
                  unelevated
                  no-caps
                  outline
                  color="tertiary"
                  label="No"
                  size="md"
                  padding="sm lg"
                  class="q-mx-sm"
                  v-close-popup
                /><q-btn
                  unelevated
                  no-caps
                  color="primary"
                  label="Yes"
                  size="md"
                  padding="sm lg"
                  class="q-mx-sm"
                  @click="buyAlreadySubscribedProducts"
                />
              </div>
            </div>
          </div>
        </q-card-section>
      </q-card>
    </template>
  </ftx-dialog>
</template>
<script>
export default {
  name: 'AlreadySubscribedProducts',
  data() {
    return {
      dialog: 'AlreadySubscribedProducts',
      contentHeight: 0,
    }
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    navigateToSubscription() {
      this.$router.push({
        name: 'ManageSubscription',
      })
      this.changeDialogState({
        dialog: this.dialog,
        val: false,
      })
    },
    buyAlreadySubscribedProducts() {
      if (this.$route.name == 'OrderEdit') {
        if (this.properties?.updateOrder) this.properties.updateOrder(false)
        this.changeDialogState({
          dialog: 'AlreadySubscribedProducts',
          val: false,
        })
      } else {
        this.$store.commit('cart/SET_CONFIRM_SUBSCRIPTION', true)
        this.properties?.proceedCheckout?.()
      }
    },
    onShow() {
      let productCount = Object.keys(
        this.properties.alreadySubscribedProducts
      ).length
      let productsInRow = this.isMobile ? 1 : 2
      let totalRowCount = Math.ceil(productCount / productsInRow)
      // Executes when total rows of product more than 2
      if (totalRowCount > 2) {
        this.contentHeight = this.isMobile ? 250 : 300
      } else {
        this.contentHeight = 150
      }
    },
  },
}
</script>
