var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ftx-dialog',{attrs:{"dialog":_vm.dialog,"position":_vm.isMobile ? 'bottom' : 'right',"maximized":"","className":"dialog-xs","before-show":_vm.onShow},scopedSlots:_vm._u([{key:"DialogContent",fn:function(){return [_c('q-card',{staticClass:"simpleDialog q-pa-none",class:_vm.isMobile ? 'mobileCardDialogue' : ''},[_c('div',{staticClass:"dialogHeader"},[_c('q-btn',{directives:[{name:"close-popup",rawName:"v-close-popup"}],staticClass:"close absolute-top-right",attrs:{"padding":"md","flat":""}},[_c('SvgIcon',{attrs:{"icon":"close","size":"14px"}})],1),_c('h4',{staticClass:"text-subtitle1 q-ma-none q-pa-md text-weight-bold text-black"},[_vm._v("\n          Update Address\n        ")])],1),_c('q-card-section',{staticClass:"q-pa-none"},[_c('q-scroll-area',{staticStyle:{"height":"calc(100dvh - 80px)"},attrs:{"thumb-style":_vm.scrollAreaThumbStyle,"bar-style":_vm.scrollAreaBarStyle,"visible":_vm.scrollAreaVisible}},[_c('div',{staticClass:"addressList"},[_c('q-list',_vm._l((_vm.addressList),function(address,key){return _c('q-item',{key:`${key}`,class:address.addressId == _vm.addressId ? 'active' : '',attrs:{"tag":"label"}},[(key == 'add-address')?_c('q-item-section',{staticClass:"addAddress",on:{"click":_vm.editAddress}},[_c('q-item-label',[_c('span',[_vm._v("+")]),_vm._v("\n                    Add new address\n                  ")])],1):_c('q-item-section',[_c('div',{style:(!_vm.isAddressDisable(address) && key !== 'add-address'
                        ? 'opacity: 0.3; cursor: not-allowed !important;'
                        : '')},[_c('q-radio',{attrs:{"size":"sm","value":_vm.addressId,"val":address.addressId,"label":_vm.addressId == address.addressId ? 'Selected' : 'Select',"disable":!_vm.isAddressDisable(address)},on:{"input":function($event){return _vm.updateAddress(address)}}}),_c('q-item-label',[_c('div',{staticClass:"row items-center"},[_c('div',{staticClass:"col text-weight-bold"},[_vm._v("\n                          "+_vm._s(address.firstName)+" "+_vm._s(address.lastName)+"\n                          "),(address.isDefault)?_c('span',{staticClass:"default"},[_vm._v("\n                            Default\n                          ")]):_vm._e()])])]),_c('q-item-label',{staticClass:"address q-mb-sm",domProps:{"innerHTML":_vm._s(_vm.$options.filters.formatAddress(address))}}),(
                        _vm.properties.fulfillmentCode == 'scd' &&
                        address.isDeliver === false
                      )?_c('q-item-label',{staticClass:"text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"},[_vm._v("\n                      *Unable to deliver to this location for Delivery\n                    ")]):(
                        _vm.properties.fulfillmentCode == 'sd' &&
                        address.isShippingPossible === false
                      )?_c('q-item-label',{staticClass:"text-red bg-red-1 dotted-border-red q-mt-sm text-body2 q-pa-sm rounded-borders"},[_vm._v("\n                      *Unable to ship to this location for Standard Shipping\n                    ")]):_vm._e(),_c('q-btn',{staticClass:"editAddress q-mr-sm q-mt-sm",attrs:{"disable":!_vm.isAddressDisable(address),"unelevated":"","flat":""},on:{"click":function($event){return _vm.editAddress(address)}}},[_c('q-icon',{attrs:{"name":"edit","size":"1rem"}})],1)],1)])],1)}),1)],1)])],1),_c('q-inner-loading',{attrs:{"showing":_vm.loading,"label":"Please wait...","label-class":"text-teal","label-style":"font-size: 1.1em"}})],1)]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }