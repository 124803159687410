import { convertArrayToObject } from 'src/utils'
import { Platform } from 'quasar'
import { NotifyMethods } from 'src/boot/notify'

export async function getCategories(
  { commit, rootGetters },
  currentLocationId
) {
  try {
    commit('SET_LOADING_CATEGORIES', true)

    let { data } = await this.$axiosInstance.get('v1/Category', {
      params: {
        includeChild: -1,
        DisablePaging: true,
        LocationID: currentLocationId.currentLocationId,
        global: true,
        fulfillmentCodeName:
          rootGetters['persisted/selectedFulfillmentCodeName'],
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      },
    })

    commit('SET_CATEGORIES', data.data.items)
  } catch (err) {
  } finally {
    commit('SET_LOADING_CATEGORIES', false)
  }
}

export async function getCategory(
  { commit, getters, rootGetters },
  { seoName, locationId }
) {
  try {
    commit('SET_LOADING_CATEGORY', true)

    let category = getters['getCategoryById'](null, seoName)

    if (category) {
      commit('SET_CATEGORY', category)

      if (Platform?.is?.mobile) {
        commit(
          'common/SET_HEADER_TITLE',
          { title: category.categoryName },
          { root: true }
        )
      }

      commit('SET_LOADING_CATEGORY', false)

      return category
    }

    let { data } = await this.$axiosInstance.get('v1/Category', {
      params: {
        includeChild: -1,
        CategorySeoName: seoName,
        LocationID: locationId,
        fulfillmentCodeName:
          rootGetters['persisted/selectedFulfillmentCodeName'],
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      },
    })

    if (data?.data?.items?.length > 0) {
      commit('SET_CATEGORY', data.data.items[0])

      if (Platform?.is?.mobile) {
        commit(
          'common/SET_HEADER_TITLE',
          { title: data.data.items[0].categoryName },
          { root: true }
        )
      }

      commit('SET_LOADING_CATEGORY', false)

      return data.data.items[0]
    } else commit('SET_CATEGORY')

    commit('SET_LOADING_CATEGORY', false)
    return null
  } catch (err) {
    commit('SET_CATEGORY')
    commit('SET_LOADING_CATEGORY', false)
  }
}

// export async function getCategoryFilters({ commit, getters }, params) {
//   try {
//     let categoryId = params.categoryIds

// if (categoryId && !getters['getFilterByCategoryId'](categoryId)) {
//   commit('SET_LOADING_Filters', true)

//   let { data } = await this.$axiosInstance.get('v1/Category/Filters', {
//     params: {
//       ...params,
//       global: true,
//     },
//   })
//   let filters = data.data.items
//   let objFilters = {}

//       if (filters.length) {
//         objFilters = convertArrayToObject(filters, 'filterCode')
//       }

//       commit('ADD_FILTER', { [params.categoryIds]: objFilters })

//       commit('SET_LOADING_Filters', false)
//     }
//   } catch (err) {}
// }

export async function getFilters({ commit, rootGetters }, params) {
  try {
    commit('SET_LOADING_Filters', true)

    params = {
      ...params,
      global: true,
    }

    if (
      rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
      ((!rootGetters['auth/isLoggedIn'] &&
        !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
        (rootGetters['auth/isLoggedIn'] &&
          !rootGetters['customer/isVerifiedOnline']))
    ) {
      params['skipBrowsingAgeRestrictedProd'] = true
    }

    let { data } = await this.$axiosInstance.get('v1/Category/Filters', {
      params,
    })
    commit('SET_LOADING_Filters', false)
    return convertArrayToObject(data.data.items, 'filterCode')
  } catch (err) {
    this.$router.push({ name: 'Home' }).catch(() => {})
    NotifyMethods.showError(
      `<strong>Error!</strong>${err.errors[0].descreption}.`
    )
  }
}

export async function getTrendingCategory({ commit, rootGetters }, locationId) {
  try {
    commit('SET_LOADING_TRENDING_CATEGORY', true)
    let selectedFulfillmentCodeName =
      rootGetters['persisted/selectedFulfillmentCodeName']
    let selectedZipCode = rootGetters['persisted/selectedZipCode']
    if (locationId) {
      let { data } = await this.$axiosInstance.get(`/Category/Trending`, {
        params: {
          locationId,
          fulfillmentCodeName:
            rootGetters['persisted/selectedFulfillmentCodeName'],
          zipCode: rootGetters['persisted/locationOrShippingZip'],
        },
      })
      await commit('SET_TRENDING_CATEGORY', data.data.items)
      commit('SET_LOADING_TRENDING_CATEGORY', false)
    }
  } catch (err) {}
}

export const getProducts = async ({ commit, rootGetters }, params) => {
  try {
    let rParams = {
      locationID: rootGetters['persisted/currentLocationId'],
      categoryID: rootGetters['category/currentCategory'].categoryID,
      zipCode: rootGetters['persisted/locationOrShippingZip'],
      ...params,
      zipCode: rootGetters['persisted/selectedZipCode'],
    }

    const isLoggedIn = rootGetters['auth/isLoggedIn']
    if (
      rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
      ((!isLoggedIn &&
        !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
        (isLoggedIn && !rootGetters['customer/isVerifiedOnline']))
    ) {
      rParams.skipBrowsingAgeRestrictedProd = true
    }

    let { data } = await this.$axiosInstance.get('/Product', {
        params: rParams,
      }),
      products = convertArrayToObject(data.data.items, 'productID')

    commit('SET_TOTAL_COUNT', data.data.count)

    if (params.Page == 1) {
      commit('SET_PRODUCTS', products)
    } else {
      commit('ADD_PRODUCTS', products)
    }

    return data.data
  } catch (error) {}
}

export async function getOrderCategory(
  { commit, rootGetters },
  { orderId, locationId }
) {
  try {
    let { data } = await this.$axiosInstance.get('/Category', {
      params: {
        includeChild: -1,
        OrderId: orderId,
        LocationID: locationId,
        fulfillmentCodeName:
          rootGetters['persisted/selectedFulfillmentCodeName'],
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      },
    })

    if (data?.success && data.data.count)
      commit('order/SET_ORDER_CATEGORIES', data.data.items, { root: true })
  } catch (err) {}
}
