<template>
  <q-page class="accountPages orderPage">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <div class="row q-col-gutter-x-md items-center">
          <div class="col-12 col-md-12">
            <div class="page-breadcrumb">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
            </div>
          </div>
          <div class="col-12">
            <h2>Manage My Subscription</h2>
            <p>
              Manage your subscribed products. To ensure smooth processing of
              your auto-scheduled orders, please keep your payment information
              and address details up to date to avoid any failures or errors.
            </p>
          </div>
          <div class="col-12 q-mt-md">
            <OrderSearch
              @filterModified="filterModified"
              :filterKey="filterKey"
              searchInputPlaceholder="Enter Product name or Subscription ID here"
            />
          </div>
        </div>
      </div>
      <div class="page-content">
        <OrderSearch
          @filterModified="filterModified"
          :filterKey="filterKey"
          searchInputPlaceholder="Enter Product name or Subscription ID here"
          v-if="isMobile"
        />

        <q-infinite-scroll
          @load="fetchNext"
          ref="infiniteScroll"
          :class="['accoutOrderItems', { 'q-mt-md': isMobile }]"
          :offset="1000"
        >
          <template v-if="!showSkeletonLoader">
            <div
              v-for="subscription of subscriptions"
              :key="subscription.autoShipSubscriptionID"
              class="orderItem"
            >
              <SubscriptionOrderItem :subscription="subscription" />
            </div>
          </template>
          <OrderSkeleton v-else />
          <template v-slot:loading>
            <OrderSkeleton v-show="showSkeletonLoader" />
            <LoadMoreSpinner v-show="!showSkeletonLoader" />
          </template>
        </q-infinite-scroll>
        <EmptyComponent
          v-if="!loading && Object.keys(subscriptions).length == 0"
          :image="`images/empty-product.png`"
          btnText="Continue Shopping"
          to="/"
        >
          <p>No data found</p>
        </EmptyComponent>
      </div>
    </div>
  </q-page>
</template>

<script>
import { mapGetters } from 'vuex'
import SubscriptionOrderItem from 'components/order/SubscriptionOrderItem.vue'
import OrderSkeleton from 'components/order/OrderSkeleton'
import { convertArrayToObject } from 'src/utils'
import formatDate from 'src/filters/formatDate'

export default {
  name: 'ManageSubscription',
  meta() {
    return {
      title: 'Manage My Subscription',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      filterKey: 'auto-ship-order',
      subscriptionFilterRef: {},
      subscriptions: {},
      showSkeletonLoader: false,
      loading: false,
      startDate: '',
      endDate: '',
    }
  },
  components: {
    SubscriptionOrderItem,
    OrderSkeleton,
    OrderSearch: () => import('components/order/OrderSearch'),
  },
  computed: {
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    ...mapGetters('order', ['filterSearchBy']),
    autoShipFilters() {
      return this.getFilterByPage(this.filterKey)
    },
  },
  methods: {
    refreshListing() {
      this.$refs.infiniteScroll.stop()
      this.$refs.infiniteScroll.reset()
      this.$refs.infiniteScroll.resume()
      this.$refs.infiniteScroll.trigger()
    },
    filterModified(filters) {
      this.subscriptionFilterRef = filters
      this.refreshListing()
    },
    requestFilterQuery() {
      const { filterByStatus, StartDate, EndDate } = this.subscriptionFilterRef
      const { startDate, endDate, filterSearchBy, autoShipFilters } = this

      const hasDateRange =
        (StartDate && EndDate) ||
        (startDate && endDate && autoShipFilters.filterByUpcoming !== '')

      const filters = []

      // Search by SKU, Title, or SubscriptionNumber
      if (filterSearchBy) {
        filters.push(`(SKU|Title|SubscriptionNumber)@=${filterSearchBy}`)
      }

      // Filter by status
      if (filterByStatus) {
        filters.push(`Status==${filterByStatus}`)
      }

      // Date range filtering
      if (hasDateRange) {
        const start = formatDate(StartDate || startDate, 'YYYY-MM-DD')
        const end = formatDate(EndDate || endDate, 'YYYY-MM-DD')
        filters.push(`NextOrderDate>=${start},NextOrderDate<=${end}`)
      }

      return filters.join(',')
    },
    fetchNext(index, done) {
      let filterQuery = this.requestFilterQuery()
      let params = {
        Page: index,
        pageSize: 10,
        ...{
          Filters: filterQuery,
        },
      }
      this.getSubscriptions(params, done)
    },
    getSubscriptions(params, done) {
      if (!done) {
        this.$refs.infiniteScroll.reset()
      }
      if (params.Page == 1) {
        this.showSkeletonLoader = true
        this.subscriptions = {}
      }

      this.loading = true
      this.$store
        .dispatch('order/getSubscriptions', params)
        .then((res) => {
          if (res && res.success) {
            this.subscriptions = Object.assign(
              {},
              this.subscriptions,
              convertArrayToObject(res.data.items, 'autoShipSubscriptionID')
            )
            this.showSkeletonLoader = false
            if (done) {
              done(!res.data.hasNext)
            } else {
              this.$refs.infiniteScroll.resume()
              this.$refs.infiniteScroll.setIndex(1)
            }
          } else {
            if (done) {
              done(true)
            }
            if (res && !res.success) this.showSkeletonLoader = false
          }
        })
        .finally(() => {
          // this.showSkeletonLoader = false
          this.loading = false
        })
    },
    calculateOrderDate() {
      const currentDate = new Date()
      let startDate, endDate
      const upcomingOrderFilter = this.autoShipFilters.filterByUpcoming

      const formatDate = (date) =>
        date.toLocaleDateString('en-US', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })

      switch (upcomingOrderFilter) {
        case '':
          // No upcoming filter applied
          break

        case 1:
          // Next Monday and the end of that week (Sunday)
          const today = currentDate.getDay()
          const diffToMon = today === 0 ? 1 : 8 - today // Get difference to Monday
          startDate = new Date(
            currentDate.setDate(currentDate.getDate() + diffToMon)
          )
          endDate = new Date(currentDate.setDate(currentDate.getDate() + 6))
          startDate = formatDate(startDate)
          endDate = formatDate(endDate)
          break

        case 2:
          // First and last day of the next month
          startDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 1,
            1
          )
          endDate = new Date(
            currentDate.getFullYear(),
            currentDate.getMonth() + 2,
            0
          )
          startDate = formatDate(startDate)
          endDate = formatDate(endDate)
          break

        case 3:
          // Custom Start and End Date from filters
          if (this.autoShipFilters?.StartDate) {
            startDate = formatDate(new Date(this.autoShipFilters.StartDate))
          }
          if (this.autoShipFilters?.EndDate) {
            endDate = formatDate(new Date(this.autoShipFilters.EndDate))
          }
          break
      }

      // Set calculated dates to component's properties
      // this.startDate = startDate
      // this.endDate = endDate

      // Set persisted filterByStatus to local variable
      this.subscriptionFilterRef = {
        ...this.subscriptionFilterRef,
        StartDate: startDate || '',
        EndDate: endDate || '',
        filterByStatus: this.autoShipFilters?.filterByStatus || '',
      }
    },
  },
  created() {
    this.calculateOrderDate()
    if (this.$route.query?.sku) {
      this.$store.commit('order/SET_FILTER_SEARCH', this.$route.query.sku)
    }
  },
  destroyed() {
    this.$store.commit('order/SET_FILTER_SEARCH', '')
  },
}
</script>

<style lang="scss">
.order-alert {
  margin-bottom: 0;
}
.accountTabs {
  .q-tabs__content {
    border: 1px solid #cccccc;
    border-radius: 5px;
    .q-tab {
      text-transform: capitalize;
      @media (max-width: 767px) {
        padding: 0 6px;
      }
      &.q-tab--active {
        .q-tab__label {
          color: $primary;
          color: var(--q-color-primaryOnBody) !important;
          font-weight: bold;
        }
      }
      @media (min-width: 1681px) {
        min-height: 60px;
      }
    }
    .q-tab__label {
      font-size: 12px;
      color: #888888;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
      @media (max-width: 599px) {
        font-size: 11px;
      }
    }
    .q-tab__indicator {
      height: 4px;
    }
  }
  .q-tab-panel {
    padding-left: 0;
    padding-right: 0;
  }
}
.accoutOrderItems {
  .orderItem {
    margin-top: 15px;
    background: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 5px;
    &:first-child {
      margin-top: 0;
    }
    .orderItemTop {
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      .actions {
        text-align: right;
      }
    }
    .orderItemMiddle {
      border-top: 1px solid #cccccc;
      padding: 15px;
      .actions {
        text-align: right;
        .q-btn {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        }
        .q-btn__wrapper {
          padding: 9px 18px;
        }
        @media (max-width: 767px) {
          text-align: center;
          .q-btn {
            margin: 5px;
            /*width: 100%;
            max-width: 300px;*/
          }
        }
      }
      .q-list {
        @media (max-width: 767px) {
          padding-bottom: 15px;
        }
      }
      .q-item {
        padding-left: 5px;
        padding-right: 5px;
      }
      .q-list:not(.singleItem) {
        display: flex;
        flex-wrap: wrap;
        .q-item__section--avatar {
          min-width: 50px;
          padding-right: 0;
        }
      }
      .disable,
      .disabled {
        img {
          filter: grayscale(100%);
          opacity: 0.7;
        }
      }
      .q-item__section--avatar {
        min-width: 70px;
      }
      .q-avatar {
        height: 1.3em;
        width: 1.3em;
        a {
          padding: 5px;
          border: 1px solid #cccccc;
          width: 50px;
          text-align: center;
          text-decoration: none;
        }
      }
      .last {
        .q-avatar a {
          height: 50px;
          background: $primary;
          background: var(--q-color-primaryOnBody) !important;
          border-color: $primary;
          border-color: var(--q-color-primaryOnBody) !important;
          color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            strong {
              font-size: 18px;
            }
          }
        }
      }
      .q-item__label a {
        color: #314154;
        font-size: 14px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .orderItemInfo {
      margin: 0 -10px;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      font-size: 14px;
      font-weight: bold;
      color: #0f141a;

      li {
        padding: 0 10px 10px;
        flex: 1 0 0;
        @media (max-width: 767px) {
          padding-bottom: 10px;
          flex: 0 0 50%;
        }
        > span {
          padding-bottom: 5px;
          display: block;
          font-size: 11px;
          font-weight: normal;
          color: #888888;
          text-transform: uppercase;
        }
      }
    }
    .btn-cancel {
      font-size: 14px;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: bottom;
        margin-right: 8px;
        background: #f9d7d7;
        color: #ec7a7a;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-substitution {
      display: inline-block;
      font-size: 13px;
      color: $primary;
      color: var(--q-color-primary) !important;
      text-decoration: none;
      padding-left: 18px;
      margin-right: 10px;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        background: $primary;
        background: var(--q-color-primary) !important;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .btn-cancelled {
      font-size: 14px;
      font-weight: bold;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #ec7a7a;
        color: #fff;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-completed,
    .btn-refunded {
      font-size: 14px;
      font-weight: bold;
      color: #008a03;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #008a03;
        color: #fff;
        svg {
          width: 8px;
          height: 7px;
        }
      }
    }
    .btn-returned {
      font-size: 14px;
      font-weight: bold;
      color: #e54d08;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
.q-panel-parent,
.q-tab-panel {
  overflow: inherit;
}
/* Mobile View */
@media (max-width: 767px) {
  .accountPages {
    &.orderPage {
      padding-top: 0;
    }
    .q-tabs {
      margin-left: -15px;
      margin-right: -15px;
      .q-tabs__content {
        border: none;
        border-bottom: 1px solid #cccccc;
        border-radius: 0;
      }
    }
    .accoutOrderItems {
      .q-tabs {
        position: sticky;
        top: 58px;
        background: #fff;
        z-index: 99;
      }
      .orderItemToggle {
        position: absolute;
        right: 4px;
        top: 4px;
      }
      .orderItemTop {
        position: relative;
        padding: 10px;
      }
      .orderItemMiddle {
        padding: 10px;
        .q-list {
          padding-bottom: 5px;
        }
        .actions {
          .q-btn {
            margin: 3px;
          }
          .q-btn__wrapper {
            padding: 8px 5px;
          }
        }
      }
    }
    .orderItemInfo {
      font-size: 14px !important;
      li {
        flex: 0 0 50% !important;
      }
      .oItemIcon {
        order: 1;
        flex: 0 0 45px !important;
      }
      .oItemNo {
        order: 2;
      }
      .oItemStore {
        order: 3;
      }
      .oItemDate {
        order: 3;
        flex: 0 0 46% !important;
      }
      .oItemAddress {
        order: 4;
        flex: 0 0 27% !important;
      }
      .oItemTotal {
        order: 5;
        flex: 0 0 27% !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
.accoutOrderItems {
  .q-expansion-item {
    .q-card {
      background: none;
      .orderItemMiddle {
        background: #fff;
      }
    }
    .q-item__section--avatar {
      padding-right: 0;
      min-width: 42px;
    }
    /*+ .orderItemTop {
      display: none;
      + .orderItemMiddle {
        display: none;
      }
    }*/
    .orderItemInfo {
      border-bottom: 1px dashed #c5c5c5;
      margin-bottom: 12px;
    }
    .q-card__actions {
      .q-btn {
        border-top: 1px solid #c5c5c5;
        @include border-radius(0);
        .q-btn__content {
          justify-content: space-between;
        }
        i {
          float: right;
        }
      }
    }
  }
}
/* Mobile View */
</style>
