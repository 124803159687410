import {
  convertArrayToObject,
  hideShowFullSpinner,
  cartHasFulfillment,
} from 'src/utils'
import { Platform } from 'quasar'
import { NotifyMethods } from 'src/boot/notify'
export async function getAlreadySubscribeProducts({}, params = {}) {
  try {
    let response = await this.$axiosInstance.get(
      'v1/Cart/GetAlreadySubscribeProducts',
      { params }
    )
    return response.data
  } catch (error) {
    console.log('err', error)
  }
}
export async function getCartDetail(
  { commit, getters, rootGetters, dispatch },
  { showFullLoader = false, isCheckoutPage = false } = {}
) {
  try {
    commit('SET_LOADING', true)
    if (showFullLoader) hideShowFullSpinner(true)

    let isLoggedIn = rootGetters['auth/isLoggedIn'],
      guestCartNumber = rootGetters['guest/guestCartNumber'],
      verifiedGuestCustomerId = rootGetters['guest/verifiedGuestCustomerId'],
      customerInfo = rootGetters['customer/customer'],
      requestObj = {}

    if (guestCartNumber) {
      if (isLoggedIn) {
        if (
          !verifiedGuestCustomerId ||
          verifiedGuestCustomerId == customerInfo.customerID
        )
          requestObj.orderNumber = guestCartNumber
        dispatch('guest/resetGuestUserInfo', null, { root: true })
      } else requestObj.orderNumber = guestCartNumber
    }

    if (getters['checkoutSelection']?.rateID && isCheckoutPage)
      requestObj['shippingRateID'] = getters['checkoutSelection'].rateID

    let { data } = await this.$axiosInstance.get('v1/Cart/GetOrderNumber', {
      params: requestObj,
    })

    if (data?.data?.orderNumber) {
      commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data ? data.data : {},
      })
      commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
    } else if (data.data == null) {
      /* remove guest cart number for guest checkout */
      if (!isLoggedIn && guestCartNumber) {
        dispatch('guest/resetGuestUserInfo', null, { root: true })
      }

      commit('SET_CURRENT_CART_NUMBER', null)
      /* remove guest cart number for guest checkout */
    }
    return data
  } catch (error) {
  } finally {
    hideShowFullSpinner(false)
    commit('SET_LOADING', false)
  }
}

export async function addUpdateCartDetail(
  { commit, getters, rootGetters, dispatch },
  params
) {
  try {
    let locationID = rootGetters['persisted/currentLocationId'],
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      guestCartNumber = rootGetters['guest/guestCartNumber'],
      requestObj = {
        ...params,
        locationID: locationID,
        zipCode: rootGetters['persisted/locationOrShippingZip'],
      }

    /* Guest checkout: Pass orderNumber and slotBookingID parameter */
    if (!isLoggedIn) {
      if (guestCartNumber) requestObj.orderNumber = guestCartNumber
      let selectedFulfillmentCodeName =
          rootGetters['persisted/selectedFulfillmentCodeName'],
        guestSlotBookingId = rootGetters['guest/slotBookingId'](
          selectedFulfillmentCodeName
        ),
        isSlotBookingRequired = rootGetters['common/isSlotBookingRequired']
      if (guestSlotBookingId && isSlotBookingRequired)
        requestObj.slotBookingID = guestSlotBookingId
    }
    /* Guest checkout: Pass orderNumber and slotBookingID parameter */

    delete requestObj.apiVersion

    const response = await this.$axiosInstance.post(
      `${params.apiVersion == 'v1' ? 'v1' : 'v2'}/Cart`,
      requestObj
    )

    if (response.data?.data?.orderNumber) {
      if (
        getters['currentCartNumber'] &&
        getters['currentCartNumber'] === response.data?.data?.orderNumber
      ) {
        commit('ADD_UPDATE_CART_DETAIL', {
          cartId: response.data.data.orderNumber,
          cartDetail: response.data.data,
        })
      } else {
        commit('PUSH_CART_DETAIL', {
          cartId: response.data.data.orderNumber,
          cartDetail: response.data.data,
        })
      }

      commit('SET_CURRENT_CART_NUMBER', response.data.data.orderNumber)

      dispatch('cart/updatePayAtStoreOption', null, { root: true })

      /* Store guest cart number for guest checkout */
      if (!isLoggedIn && guestCartNumber != response.data.data.orderNumber) {
        dispatch('guest/resetGuestUserInfo', null, { root: true })
        commit('guest/SET_GUEST_CART_NUMBER', response.data.data.orderNumber, {
          root: true,
        })
      }
      /* Store guest cart number for guest checkout */

      if (
        this.$router.currentRoute.name == 'Cart' &&
        params.apiVersion !== 'v1'
      )
        dispatch('getCartDetail', false)

      return response.data
      // return response.data.data
    }
  } catch (error) {
    if (error.errors && error.errors.length) {
      error.errors.forEach((error) => {
        if (error.errorCode !== 'CRT_400' && error.errorCode !== 'CRT_0114') {
          NotifyMethods.showError(error.descreption)
        }
      })
      // if (error.errors[0].errorCode !== 'CRT_400')
    }
    return error
    // return false
  }
}

export async function deleteCartItem(
  { commit, getters, dispatch, rootGetters },
  { orderItemId, removeOutOfStockProduct = false }
) {
  try {
    let cartNumber = getters['currentCartNumber'],
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      guestCartNumber = rootGetters['guest/guestCartNumber'],
      fulfillmentId =
        rootGetters['persisted/selectedFulfillment']?.fulfillmentId

    let response = await this.$axiosInstance.delete(`v2/Cart/${orderItemId}`)

    if (response?.data) {
      if (!isLoggedIn) cartNumber = guestCartNumber

      let itemLength = Object.keys(
        getters.currentCartDetails?.fulfillments?.[fulfillmentId]
          ?.shoppingCartItems || {}
      ).length

      if (itemLength > 1) {
        commit('DELETE_CART_ITEM', { orderItemId, cartNumber, fulfillmentId })
        // Remove cart info from local storage
        let cartPersistedInfo =
          rootGetters['persistedLocal/getCartPersistedInfo'](cartNumber)
        if (
          cartPersistedInfo?.subscriptionDetails &&
          cartPersistedInfo?.subscriptionDetails[orderItemId]
        ) {
          commit(
            'persistedLocal/REMOVE_ITEM_SUBSCRIPTION',
            { cartNumber, orderItemId },
            {
              root: true,
            }
          )
        }
      } else {
        commit('PUSH_CART_DETAIL', {
          cartId: cartNumber,
          cartDetail: {},
        })

        commit('SET_CURRENT_CART_NUMBER', null)
        commit('persistedLocal/SET_CONTACTLESS_DELIVERY', false, {
          root: true,
        })

        if (!isLoggedIn && guestCartNumber)
          dispatch('guest/resetGuestUserInfo', null, { root: true })

        // Remove cart info from local storage
        commit(
          'persistedLocal/SET_CART_PERSISTED_INFO',
          {
            cartNumber: cartNumber,
          },
          { root: true }
        )
        if (getters['isConfirmSubscription'])
          commit('SET_CONFIRM_SUBSCRIPTION', false)
      }

      if (removeOutOfStockProduct)
        commit('REMOVE_OUT_OF_STOCK_PRODUCT', {
          cartId: cartNumber,
          orderItemId,
        })
      commit('REMOVE_RECENT_SUBSTITUTE', orderItemId)
      dispatch('cart/updatePayAtStoreOption', null, { root: true })
    }

    if (this.$router.currentRoute.name == 'Cart')
      dispatch('getCartDetail', false)
  } catch (error) {
    if (
      error?.errors &&
      Array.isArray(error.errors) &&
      error.errors.length &&
      error.errors[0].errorCode == 'CRT_0108'
    ) {
      dispatch('getCartDetail', false)
      // reloadPage()

      // let cartNumber = getters['currentCartNumber'],
      //   isLoggedIn = rootGetters['auth/isLoggedIn'],
      //   guestCartNumber = rootGetters['guest/guestCartNumber']
      // if (cartNumber) {
      //   let requestObj = {}
      //   if (!isLoggedIn && guestCartNumber)
      //     requestObj.orderNumber = guestCartNumber
      //   let { data } = await CartAgent.getCustomerCart(requestObj)
      //   commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
      //   commit('PUSH_CART_DETAIL', {
      //     cartId: data.data.orderNumber,
      //     cartDetail: data.data ? data.data : {},
      //   })
      // }
    }
  }
}

export async function emptyCart({ commit, rootGetters, getters, dispatch }) {
  let cartNumber = getters['currentCartNumber'],
    isLoggedIn = rootGetters['auth/isLoggedIn']
  if (!cartNumber) return

  try {
    await this.$axiosInstance.delete(`v1/Cart/Empty/${cartNumber}`, {
      params: {
        locationID: rootGetters['persisted/currentLocationId'],
      },
    })

    commit('PUSH_CART_DETAIL', {
      cartId: cartNumber,
      cartDetail: {},
      refresh: true,
    })

    commit('SET_CURRENT_CART_NUMBER', null)
    /* remove guest cart number for guest checkout */
    if (!isLoggedIn) {
      dispatch('guest/resetGuestUserInfo', null, { root: true })
    }
    /* remove guest cart number for guest checkout */
    commit('persistedLocal/SET_CONTACTLESS_DELIVERY', false, {
      root: true,
    })
    // Remove cart info from local storage
    commit(
      'persistedLocal/SET_CART_PERSISTED_INFO',
      {
        cartNumber: cartNumber,
      },
      { root: true }
    )
    if (getters['isConfirmSubscription'])
      commit('SET_CONFIRM_SUBSCRIPTION', false)
  } catch (error) {}
}

export async function updateSubstitution(
  { commit, rootGetters },
  params,
  options = {}
) {
  try {
    let cartNumber = rootGetters['cart/currentCartNumber'],
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      queryParams = {}
    if (!isLoggedIn) queryParams.orderNumber = cartNumber
    let { data } = await this.$axiosInstance.put(
      'v1/Cart/AllowSubstitute',
      params,
      { queryParams }
    )

    if (data?.data?.length) {
      let substitutions = convertArrayToObject(data.data, 'orderLineItemID')

      commit('SET_SUBSTITUTION', substitutions)

      if (options.resetSubstitution) commit('RESET_RECENT_SUBSTITUTE')

      return true
    }

    return false
  } catch (error) {}
}

export async function updatePickupInfo({ commit }, params) {
  try {
    let { data } = await this.$axiosInstance.put('v1/Cart/PickupInfo', params)

    if (data.data) {
      commit('SET_PICKUP_INFO', {
        fulfillmentMethodID: params.fulfillmentMethodID,
        pickupPerson: {
          primaryUserFirstName: params.primaryUserFirstName,
          primaryUserLastName: params.primaryUserLastName,
          pickUpByPhone1: params.pickUpByPhone1,
          pickUpByEmail1: params.pickUpByEmail1,
          pickupByDOB1: params.pickupByDOB1,
          alternateUserFirstName: params.alternateUserFirstName,
          alternateUserLastName: params.alternateUserLastName,
          pickUpByPhone2: params.pickUpByPhone2,
          pickUpByEmail2: params.pickUpByEmail2,
          pickupByDOB2: params.pickupByDOB2,
        },
      })
    }

    return data.data
  } catch (error) {}
}

export async function updateCartAddress({ commit, getters }, params) {
  try {
    if (getters['checkoutSelection']?.rateID)
      params['shippingRateID'] = getters['checkoutSelection'].rateID

    let { data } = await this.$axiosInstance.put('v1/Cart/OrderAddress', params)

    if (data.success) {
      if (data.data && data.data.orderNumber) {
        commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)

        commit('PUSH_CART_DETAIL', {
          cartId: data.data.orderNumber,
          cartDetail: data.data,
          refresh: true,
        })
      }

      commit('SET_ORDER_ADDRESS', params)
      return data
    }
    return data
  } catch (error) {
    return error
  }
}

export async function updateCartPaymentMethod({ commit }, params) {
  try {
    let { data } = await this.$axiosInstance.put(
      'v1/Cart/OrderPayments',
      params
    )

    if (data.success) {
      commit('SET_CART_PAYMENT_METHOD', params.paymentMethodId)
      return true
    }
    return false
  } catch (error) {
    return false
  }
}

export async function placeOrder({ commit }, params) {
  try {
    let { data } = await this.$axiosInstance.post('v1/Cart/PlaceOrder', params)

    if (data.success && data.data.orderNumber) {
      await commit(
        'order/SET_ORDER_DETAIL',
        {
          [data.data.orderNumber]: data.data,
        },
        { root: true }
      )
      await commit('order/SET_RECENT_ORDER', data.data.orderNumber, {
        root: true,
      })
    }

    return data.data
  } catch (error) {
    return error
  }
}

export async function getOrderThankYou({ commit }, orderNumber) {
  try {
    let { data } = await this.$axiosInstance.get('v1/ThankYou', {
      params: {
        OrderNumber: orderNumber,
      },
    })

    if (data.success && data.data.orderNumber) {
      await commit(
        'order/SET_ORDER_DETAIL',
        {
          [data.data.orderNumber]: data.data,
        },
        { root: true }
      )
      await commit('order/SET_RECENT_ORDER', data.data.orderNumber, {
        root: true,
      })
    }
  } catch (error) {}
}

export async function resetCart(
  { commit, getters, rootGetters, dispatch },
  params
) {
  const isLoggedIn = rootGetters['auth/isLoggedIn'],
    cartNumber = isLoggedIn
      ? getters['currentCartNumber']
      : rootGetters['guest/guestCartNumber']
  commit('PUSH_CART_DETAIL', {
    cartId: cartNumber,
    cartDetail: {},
    refresh: true,
  })

  commit('SET_CURRENT_CART_NUMBER', null)
  /* remove guest cart number for guest checkout */
  if (!isLoggedIn && cartNumber) {
    dispatch('guest/resetGuestUserInfo', null, { root: true })
  }
  /* remove guest cart number for guest checkout */
  commit('persistedLocal/SET_CONTACTLESS_DELIVERY', false, {
    root: true,
  })

  // Remove cart info from local storage
  commit(
    'persistedLocal/SET_CART_PERSISTED_INFO',
    { cartNumber },
    { root: true }
  )
  if (getters['isConfirmSubscription'])
    commit('SET_CONFIRM_SUBSCRIPTION', false)
}

export function toggleSubstitution(
  { commit, getters },
  substituteProductData = {}
) {
  let isSubstitutionAllowed = getters['isSubstitutionAllowed'],
    isAllowSubstitute = true,
    objSubstitute = {}

  if (isSubstitutionAllowed) {
    isAllowSubstitute = false
  }

  if (getters.currentCartDetails?.fulfillments) {
    for (let fId in getters.currentCartDetails.fulfillments) {
      const fulfillment = getters.currentCartDetails.fulfillments[fId]
      if (fulfillment.codeName !== 'sd') {
        for (let olId in fulfillment.shoppingCartItems) {
          const item = fulfillment.shoppingCartItems[olId]
          let params = {
            substituteProducts:
              isAllowSubstitute && substituteProductData[item.orderLineItemID]
                ? substituteProductData[item.orderLineItemID][
                    'substituteProducts'
                  ]
                : [],
            selectedSubstituteProduct: isAllowSubstitute
              ? getters.selectedChildSubstituteProduct(item.orderLineItemID)
              : null,
          }
          objSubstitute[item.orderLineItemID] = {
            orderLineItemID: item.orderLineItemID,
            allowSubstitute: isAllowSubstitute,
            ...params,
          }
        }
      }
    }
  }
  commit('SET_RECENT_SUBSTITUTE', objSubstitute)
}

export function toggleSubstitute(
  { commit, getters },
  { orderLineItemId, allowSubstitute, substituteProducts = [] }
) {
  if (orderLineItemId)
    commit('SET_RECENT_SUBSTITUTE', {
      [orderLineItemId]: {
        orderLineItemID: orderLineItemId,
        allowSubstitute: !getters.substitute(orderLineItemId, allowSubstitute),
        substituteProducts: substituteProducts,
        selectedSubstituteProduct: null,
      },
    })
}

export async function showAlert(
  { commit, dispatch, getters, rootGetters },
  errors = []
) {
  errors.every((element) => {
    if (element.errorCode === 'CRT_0002') {
      let cartNumber = getters['currentCartNumber'],
        isLoggedIn = rootGetters['auth/isLoggedIn'],
        guestCartNumber = rootGetters['guest/guestCartNumber']

      commit('PUSH_CART_DETAIL', {
        cartId: cartNumber,
        cartDetail: {},
        refresh: true,
      })
      commit('SET_CURRENT_CART_NUMBER', null)
      /* remove guest cart number for guest checkout */
      if (!isLoggedIn && guestCartNumber) {
        dispatch('guest/resetGuestUserInfo', null, { root: true })
      }
      /* remove guest cart number for guest checkout */
      commit('persistedLocal/SET_CONTACTLESS_DELIVERY', false, {
        root: true,
      })

      // let message = `You have completed Order ${cartNumber} on another device or session. Refreshing the cart to begin a new order`
      let message =
        'Your previous cart has been abandoned. Please refresh your page and you can begin creating a new order!'
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(message)
      } else {
        commit('SET_ALERT', {
          alertKey: 'cartAlert',
          alertData: {
            variant: 'danger',
            message,
          },
        })
      }
      // return false
    } else if (
      element.errorCode === 'CRT_0018' ||
      element.errorCode === 'CRT_0014'
    ) {
      this.$router.push({ name: 'Cart' }).catch(() => {})
      dispatch('reserveSlot/resetSlots', null, { root: true })

      if (Platform?.is?.mobile) {
        NotifyMethods.showError(element.descreption)
      } else {
        commit('SHOW_ALERT_ERROR', { ...element, showCountDown: 10 })
      }
    } else {
      if (Platform?.is?.mobile) {
        NotifyMethods.showError(element.descreption)
      } else {
        commit('SET_ALERT', {
          alertKey: 'cartAlert',
          alertData: {
            variant: 'danger',
            message: element.descreption,
          },
        })
      }
    }
    return true
  })
}

export async function applyLnsPoints({ commit }, body = { dollars: 0 }) {
  try {
    let { data } = await this.$axiosInstance.post(
      'v1/Cart/ApplyLnsPoints',
      body
    )

    if (data.success && data.data)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data,
        refresh: true,
      })

    return data
  } catch (error) {
    return error
  }
}

export async function applyCoupons({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.post('v1/Cart/Coupons', body)

    if (data.success && data.data)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data,
        refresh: true,
      })

    return data
  } catch (error) {
    return error
  }
}
export async function applyTip({ commit, getters }, body) {
  try {
    hideShowFullSpinner(true)
    if (getters['checkoutSelection']?.rateID)
      body['shippingRateID'] = getters['checkoutSelection'].rateID

    let { data } = await this.$axiosInstance.post('v1/Cart/tip', body)

    if (data?.data?.orderNumber) {
      commit(
        'cart/PUSH_CART_DETAIL',
        {
          cartId: data.data.orderNumber,
          cartDetail: data.data ? data.data : {},
          refresh: true,
        },
        {
          root: true,
        }
      )
    }

    return data
  } catch (error) {
    return error
  } finally {
    hideShowFullSpinner(false)
  }
}

export async function removeCoupons({ commit }, couponID) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `v1/Cart/Coupons/${couponID}`
    )

    if (data.success && data.data)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data,
        refresh: true,
      })

    return data
  } catch (error) {
    return error
  }
}

export async function getOutOfStockProducts({ commit }, params) {
  try {
    let response = await this.$axiosInstance.get(
      `v1/Cart/OutOfStock/Products`,
      {
        params,
      }
    )

    if (response?.data?.success) {
      let products = response.data.data.map((product) => {
        return { ...product, selectedQty: product.inStockQty }
      })
      commit('SET_OUT_OF_STOCK_PRODUCTS', {
        cartId: params.orderNumber,
        products: products,
      })
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function updateAllOutOfStockProducts(
  { commit, rootGetters },
  objRequest
) {
  try {
    let cartNumber = rootGetters['cart/currentCartNumber'],
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      guestCartNumber = rootGetters['guest/guestCartNumber']
    let response = await this.$axiosInstance.put(
      'v1/Cart/OutOfStock/Update',
      objRequest
    )

    if (cartNumber && response?.data?.success && response.data?.data === true) {
      commit('RESET_OUT_OF_STOCK_PRODUCTS', {
        cartId: cartNumber,
      })
      let requestObj = {}
      if (!isLoggedIn && guestCartNumber)
        requestObj.orderNumber = guestCartNumber
      let { data } = await this.$axiosInstance.get('v1/Cart/GetOrderNumber', {
        params: requestObj,
      })

      if (data?.data?.orderNumber) {
        commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
        commit('PUSH_CART_DETAIL', {
          cartId: data.data.orderNumber,
          cartDetail: data.data ? data.data : {},
          refresh: true,
        })
      }
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function removeAllOutOfStockProducts(
  { commit, rootGetters, dispatch },
  { orderLineItemIDs }
) {
  try {
    let cartHasAllOutOfStockProducts =
      rootGetters['cart/cartHasAllOutOfStockProducts']
    let response = await this.$axiosInstance.delete('v1/Cart/Remove', {
      data: orderLineItemIDs,
    })
    let cartNumber = rootGetters['cart/currentCartNumber'],
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      guestCartNumber = rootGetters['guest/guestCartNumber']
    if (
      cartNumber &&
      response &&
      response.data &&
      response.data.success &&
      response.data.data === true
    ) {
      commit('RESET_OUT_OF_STOCK_PRODUCTS', {
        cartId: cartNumber,
      })
      let outOfStockProducts = rootGetters['cart/outOfStockProducts']
      if (cartHasAllOutOfStockProducts && !outOfStockProducts.length) {
        await dispatch('emptyCart')
        return response.data
      }
      if (!cartHasAllOutOfStockProducts) {
        let requestObj = {}
        if (!isLoggedIn && guestCartNumber)
          requestObj.guestCartNumber = guestCartNumber
        let { data } = await this.$axiosInstance.get(
          '/v1/Cart/GetOrderNumber',
          {
            params: requestObj,
          }
        )

        if (data?.data?.orderNumber) {
          commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
          commit('PUSH_CART_DETAIL', {
            cartId: data.data.orderNumber,
            cartDetail: data.data ? data.data : {},
            refresh: true,
          })
        }
      }
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function getAllSubstituteItemsForProduct(
  { commit, getters, rootGetters, dispatch },
  params
) {
  try {
    let locationID = rootGetters['persisted/currentLocationId']
    let response = await this.$axiosInstance.post(
      `v1/Cart/AllSubstituteItemsForProduct?locationID=${locationID}`,
      params
    )

    return response.data
  } catch (error) {
    return error
  }
}

export function toggleSubstituteChildProduct(
  { commit, getters },
  {
    orderLineItemID,
    substituteProductID,
    allowSubstitute,
    originalLineItemID = null,
  }
) {
  if (orderLineItemID)
    commit('SET_RECENT_SUBSTITUTE', {
      [orderLineItemID]: {
        orderLineItemID: orderLineItemID,
        allowSubstitute: getters.substitute(orderLineItemID, allowSubstitute),
        substituteProducts: getters.substituteChildProductDetails(
          orderLineItemID,
          allowSubstitute
        ),
        selectedSubstituteProduct: substituteProductID,
        previousOrderLineItemID: originalLineItemID,
      },
    })
}

export function updatePayAtStoreOption({ commit, getters }) {
  let isPayAtStoreAvailable = {
    sp: false,
    scd: false,
    sd: false,
  }

  if (
    cartHasFulfillment(getters.currentCartDetails, 'sp') &&
    getters.isPayAtStoreAvailable('sp')
  )
    isPayAtStoreAvailable = Object.assign({}, isPayAtStoreAvailable, {
      sp: true,
    })
  commit('SET_PAY_AT_STORE', isPayAtStoreAvailable)
}

export async function getShippingRates(
  { commit, getters, rootGetters },
  showFullLoader
) {
  try {
    if (showFullLoader) {
      hideShowFullSpinner(true)
    }

    commit('SET_LOADING', true)

    /* Start: Guest Checkout Changes */
    let params = {},
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      cartNumber = getters['currentCartNumber']
    if (!isLoggedIn && cartNumber) params.orderNumber = cartNumber
    /* End: Guest Checkout Changes */

    let { data } = await this.$axiosInstance.get('v1/Cart/Shipping/Rates', {
      params,
    })

    if (data?.data) {
      const rateObjData = convertArrayToObject(data.data, 'rateID')
      commit('SET_SHIPPING_RATES', rateObjData)
      if (rootGetters['cart/currentCartNumber']) {
        const cartPersistedInfo = rootGetters[
          'persistedLocal/getCartPersistedInfo'
        ](rootGetters['cart/currentCartNumber'])
        const currentRateId = cartPersistedInfo?.rateID || ''

        /* if (currentRateId && !rateObjData[currentRateId]) {
          commit(
            'persistedLocal/SET_CART_PERSISTED_INFO',
            {
              cartNumber: rootGetters['cart/currentCartNumber'],
            },
            { root: true }
          )
          currentRateId = ''
        } */

        if (!currentRateId || (currentRateId && !rateObjData[currentRateId])) {
          if (data.data[0]) {
            commit(
              'persistedLocal/SET_CART_PERSISTED_INFO',
              {
                cartNumber: rootGetters['cart/currentCartNumber'],
                data: {
                  ...(cartPersistedInfo || {}),
                  rateID: data.data[0].rateID,
                  deliveryInstruction: '',
                },
              },
              { root: true }
            )
          }
          // else {
          //   commit('persistedLocal/SET_CART_PERSISTED_INFO', {
          //     cartNumber: rootGetters['cart/currentCartNumber'],
          //   })
          // }
        }

        // !rootGetters['persisted/getCartPersistedInfo'](
        //   rootGetters['cart/currentCartNumber']
        // )
      }
    }
  } catch (error) {
  } finally {
    hideShowFullSpinner(false)
    commit('SET_LOADING', false)
  }
}

export async function getDeliveryRates({
  commit,
  dispatch,
  getters,
  rootGetters,
}) {
  try {
    commit('SET_LOADING', true)

    /* Start: Guest Checkout Changes */
    let params = {},
      isLoggedIn = rootGetters['auth/isLoggedIn'],
      cartNumber = getters['currentCartNumber']
    if (!isLoggedIn && cartNumber) params.orderNumber = cartNumber
    /* End: Guest Checkout Changes */

    let { data } = await this.$axiosInstance.get('v1/Cart/Delivery/Rate', {
      params,
    })

    if (data?.data?.orderNumber) {
      commit('SET_CURRENT_CART_NUMBER', data.data.orderNumber)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data ? data.data : {},
        refresh: true,
      })
    }
  } catch (error) {
    return error
  } finally {
    commit('SET_LOADING', false)
  }
}

export async function requestForGuestOtp({ commit }, body) {
  try {
    let response = await this.$axiosInstance.post('v1/Cart/Guest/Otp', body)
    return response.data
  } catch (error) {
    return error
  }
}

export async function verifyGuestOtp({ dispatch }, body) {
  try {
    let response = await this.$axiosInstance.post(
      'v1/Cart/Guest/Otp/Verify',
      body
    )

    if (response.data?.success && response.data?.data?.isVerified) {
      dispatch(
        'guest/setGuestUser',
        {
          guestUser: {
            firstName: body.firstName,
            lastName: body.lastName,
            email: body.email,
            customerID: response.data.data.customerID,
          },
          setCustomerID: true,
        },
        { root: true }
      )
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function fetchGuestUserInfo({ commit }, customerID) {
  try {
    let response = await this.$axiosInstance.get(
      `v1/Cart/Guest/Customer/${customerID}`
    )

    if (response.data?.success && response.data?.data) {
      commit('guest/SET_GUEST_USER', response.data.data, { root: true })
    }
    return response.data
  } catch (error) {
    return error
  }
}

export async function verifyGuestAge({ commit, rootGetters }) {
  try {
    let guestCartNumber = rootGetters['guest/guestCartNumber']
    let response = await this.$axiosInstance.post(
      `v1/Cart/Guest/Age/Verify?orderNumber=${guestCartNumber}`,
      {}
    )

    return response.data
  } catch (error) {
    return error
  }
}

export async function applyDiscountCoupons({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.post(
      'v1/Cart/DiscountCoupons',
      body
    )

    if (data.success && data.data)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data,
        refresh: true,
      })

    return data
  } catch (error) {
    return error
  }
}

export async function removeDiscountCoupons({ commit }, body) {
  try {
    let { data } = await this.$axiosInstance.delete(
      `v1/Cart/DiscountCoupons/${body.couponID}/${body.orderNumber}`
    )

    if (data.success && data.data)
      commit('PUSH_CART_DETAIL', {
        cartId: data.data.orderNumber,
        cartDetail: data.data,
        refresh: true,
      })

    return data
  } catch (error) {
    return error
  }
}
