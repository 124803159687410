<template>
  <q-page class="accountPages orderPage">
    <div class="container">
      <div class="page-title hide-in-mobile">
        <div class="row q-col-gutter-x-md items-center">
          <div class="col-12 col-md-12">
            <div class="page-breadcrumb">
              <a
                href="javascript:void(0)"
                @click="applyBack()"
                class="no-underline"
              >
                <SvgIcon icon="back-arrow-line" />Back
              </a>
            </div>
          </div>
          <div class="col-12 col-md-6">
            <h2>Orders</h2>
            <p>
              Place, track, and review the status of past and present orders,
              all in one place.
            </p>
          </div>
          <div class="col-12 col-md-6">
            <!-- <div class="row q-col-gutter-sm">
              <div class="col-4">
                <div class="form-field">
                  <q-select
                      dense
                      options-dense
                      outlined
                      label="Filter By"
                      :value="orderFilters.selectedFilter"
                      :options="orderFilters.filterOptions"
                      @input="applyFilters"
                  />
                </div>
              </div>
              <div class="col-8">
                <div class="form-field">
                  <q-input
                    outlined
                    v-model="orderfilterInput"
                    color="grey"
                    label="Enter Product name or Order number here"
                  >
                    <template v-slot:append>
                      <q-btn
                        unelevated
                        no-caps
                        color="secondaryOnBody"
                        @click="inputFilters"
                        label="Search"
                        dense
                        class="q-px-sm r-4"
                      />
                    </template>
                  </q-input>

                </div>
              </div>
            </div> -->
            <OrderSearch
              @filterModified="filterModified"
              :filterKey="filterKey"
            />
          </div>
          <div class="col-12">
            <div class="row" v-if="!isMobile">
              <Alert
                v-if="orderAlert"
                className="col-12 col-md-8 q-mt-sm order-alert"
                :variant="orderAlert.variant"
                :show="10"
              >
                <span v-html="orderAlert.message" />
              </Alert>
            </div>
          </div>
        </div>
      </div>
      <div class="page-content">
        <!-- Order Tabs -->
        <div class="accountTabs">
          <q-infinite-scroll
            @load="fetchNext"
            ref="infiniteScroll"
            class="accoutOrderItems"
            :offset="1000"
          >
            <q-tabs
              v-model="tab"
              class="text-primaryOnBody"
              active-color="primaryOnBody"
              indicator-color="primaryOnBody"
              align="justify"
              @input="refreshListing"
            >
              <q-tab
                v-for="objTab in orderTabs"
                :key="`tab_${objTab.type}`"
                :name="objTab.type"
                :label="objTab.title"
              />
            </q-tabs>
            <!-- <div class="flex items-center justify-end" v-if="isMobile">
              <span class="q-mr-sm q-pb-xs"> Show :</span>
              <q-select
                dense
                options-dense
                borderless
                :value="orderFilters.selectedFilter"
                :options="orderFilters.filterOptions"
                @input="applyFilters"
            /></div> -->
            <OrderSearch
              @filterModified="filterModified"
              :filterKey="filterKey"
              v-if="isMobile"
            />
            <q-tab-panels v-model="tab" animated>
              <q-tab-panel
                v-for="objTab in orderTabs"
                :key="'tab_panel_' + objTab.type"
                :name="objTab.type"
                style="padding-top: 20px"
              >
                <CustomerOrders
                  @order-canceled="orderCanceled"
                  :orderType="objTab.type"
                  :orders="orders"
                  v-if="!showSkeletonLoader"
                />
                <OrderSkeleton v-else />
              </q-tab-panel>
            </q-tab-panels>
            <template v-slot:loading>
              <OrderSkeleton v-show="showSkeletonLoader" />
              <LoadMoreSpinner v-show="!showSkeletonLoader" />
            </template>
          </q-infinite-scroll>
          <EmptyComponent
            v-if="!loading && Object.keys(orders).length == 0"
            :image="`images/empty-product.png`"
            btnText="Continue Shopping"
            to="/"
          >
            <p>No data found</p>
          </EmptyComponent>
        </div>
        <!-- Order Tabs -->
      </div>
    </div>
  </q-page>
</template>

<script>
import { convertArrayToObject } from 'src/utils'
import CustomerOrders from 'components/order/CustomerOrders'
import OrderSkeleton from 'components/order/OrderSkeleton'
import { mapGetters } from 'vuex'

export default {
  name: 'PageOrders',
  components: {
    CustomerOrders,
    OrderSkeleton,
    OrderSearch: () => import('components/order/OrderSearch'),
  },
  meta() {
    return {
      title: 'Orders',
      ...this.commonMeta,
    }
  },
  data() {
    return {
      tab: 'TO',
      orderTabs: {
        TO: {
          title: 'Orders in-Process',
          type: 'TO',
        },
        OH: {
          title: 'completed Orders',
          type: 'OH',
        },
        CO: {
          title: 'Canceled Orders',
          type: 'CO',
        },
      },
      pageSize: 10,
      showSkeletonLoader: false,
      loading: false,
      orders: {},
      orderfilter: 'Last 3 Months',
      orderfilterInput: '',
      startDate: '',
      endDate: '',
      orderFilterRef: {},
      filterKey: 'order',
    }
  },
  computed: {
    ...mapGetters('order', ['alertData', 'filterSearchBy']),
    ...mapGetters('persistedLocal', ['getFilterByPage']),
    orderFilters() {
      return this.getFilterByPage(this.filterKey)
    },
    objRequest() {
      return {
        OrderType: this.tab,
        PageSize: this.pageSize,
      }
    },
    orderAlert() {
      return this.alertData('orderAlert')
    },
    filterOptions() {
      let options = [
        'All Orders',
        'Last 30 Days',
        'Last 3 Months',
        'Last 6 Months',
      ]
      let currentYear = new Date().getFullYear()
      for (let year = currentYear; year > currentYear - 5; year--) {
        options.push(`${year}`)
      }
      return options
    },
  },
  methods: {
    filterModified(filters) {
      this.orderFilterRef = filters

      // this.$store.commit(
      //   'order/SET_FILTER_SEARCH',
      //   this.orderFilterRef.searchby
      // )
      // let params = {
      //   ...this.objRequest,
      //   ...filters,
      // }
      this.refreshListing()
      // this.getOrders(params, null)
    },
    requestFilterQuery() {
      let isDate =
        (this.orderFilterRef.StartDate && this.orderFilterRef.EndDate) ||
        (this.startDate && this.endDate)

      let newFilters = ''
      if (this.filterSearchBy) {
        newFilters +=
          `(OrderNumberForFilter|ProductTittle)@=` + `${this.filterSearchBy}`
      }

      if (this.filterSearchBy && isDate) {
        newFilters += ','
      }
      if (isDate) {
        newFilters +=
          `OrderDeliveredDateTime>=` +
          `${this.orderFilterRef.StartDate || this.startDate}` +
          `,` +
          `OrderDeliveredDateTime<=` +
          `${this.orderFilterRef.EndDate || this.endDate} 23:59:59`
      }
      return newFilters
    },
    fetchNext(index, done) {
      let filterQuery = this.requestFilterQuery()
      let params = {
        Page: index,
        ...this.objRequest,
        ...{
          Filters: filterQuery,
        },
      }
      this.getOrders(params, done)
    },
    refreshListing(value) {
      if (value)
        this.$router.replace({ query: { tab: value } }).catch((err) => {})
      this.$refs.infiniteScroll.stop()
      this.$refs.infiniteScroll.reset()
      this.$refs.infiniteScroll.resume()
      this.$refs.infiniteScroll.trigger()
    },
    orderCanceled(orderId) {
      this.$delete(this.orders, orderId)
    },
    calculateOrderDate() {
      let currentDate = new Date()
      let startDate, endDate
      let orderfilter = this.orderFilters.selectedFilter
      switch (orderfilter) {
        case 'All Orders':
          break
        case 'Last 30 Days':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 30)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        case 'Last 3 Months':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 90)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        case 'Last 6 Months':
          startDate = new Date(
            new Date().setDate(currentDate.getDate() - 180)
          ).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = currentDate.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
        default:
          startDate = new Date(orderfilter, 0, 1).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          endDate = new Date(orderfilter, 12, 0).toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
          })
          break
      }
      this.startDate = startDate
      this.endDate = endDate
    },
    getOrders(params, done) {
      if (!done) {
        this.$refs.infiniteScroll.reset()
      }
      if (params.Page == 1) {
        this.showSkeletonLoader = true
        this.orders = {}
      }

      this.loading = true
      this.$store
        .dispatch('order/getOrders', params)
        .then((res) => {
          if (res && res.success) {
            this.orders = Object.assign(
              {},
              this.orders,
              convertArrayToObject(res.data.items, 'orderId')
            )
            this.showSkeletonLoader = false
            if (done) {
              done(!res.data.hasNext)
            } else {
              this.$refs.infiniteScroll.resume()
              this.$refs.infiniteScroll.setIndex(1)
            }
          } else {
            if (done) {
              done(true)
            }
            if (res && !res.success) this.showSkeletonLoader = false
          }
        })
        .finally(() => {
          // this.showSkeletonLoader = false
          this.loading = false
        })
    },
  },
  beforeCreate() {
    let options = [
      'All Orders',
      'Last 30 Days',
      'Last 3 Months',
      'Last 6 Months',
    ]
    let currentYear = new Date().getFullYear()
    for (let year = currentYear; year > currentYear - 5; year--) {
      options.push(`${year}`)
    }
    this.$store.commit('persistedLocal/SET_ORDER_FILTER_OPTIONS', options)
  },
  created() {
    if (
      this.$route.query &&
      this.$route.query.tab &&
      this.orderTabs[this.$route.query.tab]
    ) {
      this.tab = this.$route.query.tab
      // this.orderfilter = this.tab == 'TO' ? 'All Orders' : 'Last 30 Days'
    }
    this.calculateOrderDate()
  },
  destroyed() {
    this.$store.commit('order/SET_FILTER_SEARCH', '')

    if (this.orderAlert)
      this.$store.commit('order/SET_ORDER_ALERT', {
        alertKey: 'orderAlert',
        alertData: null,
      })
  },
}
</script>
<style lang="scss">
.order-alert {
  margin-bottom: 0;
}
.accountTabs {
  .q-tabs__content {
    border: 1px solid #cccccc;
    border-radius: 5px;
    .q-tab {
      text-transform: capitalize;
      @media (max-width: 767px) {
        padding: 0 6px;
      }
      &.q-tab--active {
        .q-tab__label {
          color: $primary;
          color: var(--q-color-primaryOnBody) !important;
          font-weight: bold;
        }
      }
      @media (min-width: 1681px) {
        min-height: 60px;
      }
    }
    .q-tab__label {
      font-size: 12px;
      color: #888888;
      @media (min-width: 1681px) {
        font-size: 18px;
      }
      @media (max-width: 599px) {
        font-size: 11px;
      }
    }
    .q-tab__indicator {
      height: 4px;
    }
  }
  .q-tab-panel {
    padding-left: 0;
    padding-right: 0;
  }
}
.accoutOrderItems {
  .orderItem {
    margin-top: 15px;
    background: #fafafa;
    border: 1px solid #cccccc;
    border-radius: 5px;
    &:first-child {
      margin-top: 0;
    }
    .orderItemTop {
      background: #fff;
      padding: 15px;
      border-radius: 5px;
      .actions {
        text-align: right;
      }
    }
    .orderItemMiddle {
      border-top: 1px solid #cccccc;
      padding: 15px;
      .actions {
        text-align: right;
        .q-btn {
          box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2);
        }
        .q-btn__wrapper {
          padding: 9px 18px;
        }
        @media (max-width: 767px) {
          text-align: center;
          .q-btn {
            margin: 5px;
            /*width: 100%;
            max-width: 300px;*/
          }
        }
      }
      .q-list {
        @media (max-width: 767px) {
          padding-bottom: 15px;
        }
      }
      .q-item {
        padding-left: 5px;
        padding-right: 5px;
      }
      .q-list:not(.singleItem) {
        display: flex;
        flex-wrap: wrap;
        .q-item__section--avatar {
          min-width: 50px;
          padding-right: 0;
        }
      }
      .disable,
      .disabled {
        img {
          filter: grayscale(100%);
          opacity: 0.7;
        }
      }
      .q-item__section--avatar {
        min-width: 70px;
      }
      .q-avatar {
        height: 1.3em;
        width: 1.3em;
        a {
          padding: 5px;
          border: 1px solid #cccccc;
          width: 50px;
          text-align: center;
          text-decoration: none;
        }
      }
      .last {
        .q-avatar a {
          height: 50px;
          background: $primary;
          background: var(--q-color-primaryOnBody) !important;
          border-color: $primary;
          border-color: var(--q-color-primaryOnBody) !important;
          color: $white;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          span {
            font-size: 14px;
            font-weight: bold;
            line-height: 16px;
            strong {
              font-size: 18px;
            }
          }
        }
      }
      .q-item__label a {
        color: #314154;
        font-size: 14px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .orderItemInfo {
      margin: 0 -10px;
      padding: 0;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      list-style: none;
      font-size: 14px;
      font-weight: bold;
      color: #0f141a;

      li {
        padding: 0 10px 10px;
        flex: 1 0 0;
        @media (max-width: 767px) {
          padding-bottom: 10px;
          flex: 0 0 50%;
        }
        > span {
          padding-bottom: 5px;
          display: block;
          font-size: 11px;
          font-weight: normal;
          color: #888888;
          text-transform: uppercase;
        }
      }
    }
    .btn-cancel {
      font-size: 14px;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 3px;
        vertical-align: bottom;
        margin-right: 8px;
        background: #f9d7d7;
        color: #ec7a7a;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-substitution {
      display: inline-block;
      font-size: 13px;
      color: $primary;
      color: var(--q-color-primary) !important;
      text-decoration: none;
      padding-left: 18px;
      margin-right: 10px;
      position: relative;
      &:before {
        content: '';
        width: 10px;
        height: 10px;
        display: block;
        border-radius: 50%;
        background: $primary;
        background: var(--q-color-primary) !important;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .btn-cancelled {
      font-size: 14px;
      font-weight: bold;
      color: #ec7a7a;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #ec7a7a;
        color: #fff;
        svg {
          width: 7px;
          height: 7px;
        }
      }
    }
    .btn-completed,
    .btn-refunded {
      font-size: 14px;
      font-weight: bold;
      color: #008a03;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        background: #008a03;
        color: #fff;
        svg {
          width: 8px;
          height: 7px;
        }
      }
    }
    .btn-returned {
      font-size: 14px;
      font-weight: bold;
      color: #e54d08;
      text-transform: uppercase;
      text-decoration: none;
      i {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        vertical-align: bottom;
        margin-right: 8px;
        svg {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
.q-panel-parent,
.q-tab-panel {
  overflow: inherit;
}
/* Mobile View */
@media (max-width: 767px) {
  .accountPages {
    &.orderPage {
      padding-top: 0;
    }
    .q-tabs {
      margin-left: -15px;
      margin-right: -15px;
      .q-tabs__content {
        border: none;
        border-bottom: 1px solid #cccccc;
        border-radius: 0;
      }
    }
    .accoutOrderItems {
      .q-tabs {
        position: sticky;
        top: 58px;
        background: #fff;
        z-index: 99;
      }
      .orderItemToggle {
        position: absolute;
        right: 4px;
        top: 4px;
      }
      .orderItemTop {
        position: relative;
        padding: 10px;
      }
      .orderItemMiddle {
        padding: 10px;
        .q-list {
          padding-bottom: 5px;
        }
        .actions {
          .q-btn {
            margin: 3px;
          }
          .q-btn__wrapper {
            padding: 8px 5px;
          }
        }
      }
    }
    .orderItemInfo {
      font-size: 14px !important;
      li {
        flex: 0 0 50% !important;
      }
      .oItemIcon {
        order: 1;
        flex: 0 0 45px !important;
      }
      .oItemNo {
        order: 2;
      }
      .oItemStore {
        order: 3;
      }
      .oItemDate {
        order: 3;
        flex: 0 0 46% !important;
      }
      .oItemAddress {
        order: 4;
        flex: 0 0 27% !important;
      }
      .oItemTotal {
        order: 5;
        flex: 0 0 27% !important;
        padding-bottom: 0 !important;
      }
    }
  }
}
.accoutOrderItems {
  .q-expansion-item {
    .q-card {
      background: none;
      .orderItemMiddle {
        background: #fff;
      }
    }
    .q-item__section--avatar {
      padding-right: 0;
      min-width: 42px;
    }
    /*+ .orderItemTop {
      display: none;
      + .orderItemMiddle {
        display: none;
      }
    }*/
    .orderItemInfo {
      border-bottom: 1px dashed #c5c5c5;
      margin-bottom: 12px;
    }
    .q-card__actions {
      .q-btn {
        border-top: 1px solid #c5c5c5;
        @include border-radius(0);
        .q-btn__content {
          justify-content: space-between;
        }
        i {
          float: right;
        }
      }
    }
  }
}
/* Mobile View */
</style>
