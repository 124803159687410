import { Platform } from 'quasar'

export async function getDiscounts(
  { commit, rootGetters, getters },
  params = {}
) {
  try {
    if (
      (params?.hasOwnProperty('loadMore') && getters.hasMoreDiscount) ||
      (!params?.hasOwnProperty('loadMore') && getters.page == 1)
    ) {
      getters.page == 1 && commit('SET_LOADING', true)
      getters.page !== 1 && commit('SET_LOADING_MORE', true)

      let rParams = {
        locationID: rootGetters['persisted/currentLocationId'],
        page: getters.page,
        pageSize: getters.pageSize,
        ...rootGetters['common/currentDeliveryModes'],
      }

      const isLoggedIn = rootGetters['auth/isLoggedIn']
      if (
        rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
        ((!isLoggedIn &&
          !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
          (isLoggedIn && !rootGetters['customer/isVerifiedOnline']))
      ) {
        rParams.skipBrowsingAgeRestrictedProd = true
      }

      let { data } = await this.$axiosInstance.get('/discount', {
        params: rParams,
      })

      if (data?.success) commit('SET_DISCOUNTS', data.data || [])
    }
  } catch (err) {
  } finally {
    commit('SET_LOADING', false)
    commit('SET_LOADING_MORE', false)
  }
}

export async function getDiscountDetail(
  { commit, rootGetters, getters },
  seoName
) {
  if (!seoName) return false
  let discount = getters.discounts[seoName]

  if (discount) {
    commit('SET_DISCOUNT', discount)

    if (Platform?.is?.mobile)
      commit(
        'common/SET_HEADER_TITLE',
        { title: discount.discountName },
        { root: true }
      )

    return true
  }

  commit(
    'SET_LOADING_CURRENT_DISCOUNT',
    true
    // !getters.discounts?.hasOwnProperty(seoName)
  )

  try {
    let response = await this.$axiosInstance.get(`/discount/${seoName}`, {
      params: {
        locationID: rootGetters['persisted/currentLocationId'],
        ...rootGetters['common/currentDeliveryModes'],
      },
    })

    discount = response?.data?.data
    if (!discount) return

    commit('SET_DISCOUNT', discount)

    if (Platform?.is?.mobile)
      commit(
        'common/SET_HEADER_TITLE',
        { title: discount.discountName },
        { root: true }
      )
    return true
  } catch (e) {
    return false
  } finally {
    commit('SET_LOADING_CURRENT_DISCOUNT', false)
  }
}

export function getTriggerProductList({ rootGetters }, { discountId, params }) {
  const isLoggedIn = rootGetters['auth/isLoggedIn']
  const shouldSkipAgeRestricted =
    rootGetters['ageVerification/skipBrowsingAgeRestrictedProd'] &&
    ((!isLoggedIn &&
      !rootGetters['persisted/eaivPersistedVerificationSuccess']) ||
      (isLoggedIn && !rootGetters['customer/isVerifiedOnline']))

  return this.$axiosInstance.get(`/discount/${discountId}/triggerproduct`, {
    params: {
      ...params,
      ...(shouldSkipAgeRestricted && { skipBrowsingAgeRestrictedProd: true }),
    },
  })
}
