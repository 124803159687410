<template>
  <div>
    <div
      class="row items-start q-category-slider"
      v-show="!showLoading && getCategories?.length"
    >
      <div class="col-12 relative-position">
        <div class="flex items-center justify-center">
          <swiper class="swiper" :options="swiperOption">
            <swiper-slide
              v-for="(category, index) in getCategories"
              :key="category.categoryID + '-' + index"
            >
              <router-link
                :to="{
                  name: 'Category',
                  params: { seoName: category.seoName },
                }"
              >
                {{ $options.filters.titleCase(category.categoryName) }}
              </router-link>
            </swiper-slide>

          </swiper>
        </div>
        <div class="swiper-button-prev" slot="button-prev" />
        <div class="swiper-button-next" slot="button-next" />
      </div>
    </div>
    <div
      v-show="showLoading"
      class="row q-col-gutter-md q-category-slider q-category-slider-loader"
    >
      <div class="col" v-for="n in 9" :key="n">
        <q-skeleton type="QChip" width="100%" height="30px" />
      </div>
    </div>
  </div>
</template>
<script>
import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import { mapGetters } from 'vuex';

export default {
  components: { Swiper, SwiperSlide },
  data() {
    return {
      swiperOption: {
        slidesPerView: 'auto',
        // slidesPerGroup: 5,
        // loop: true,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
    }
  },
  computed: {
    ...mapGetters('category', [
      'trendingCategories',
      'loadingTrendingCategories',
      'loadingCategories',
      'categories',
    ]),
    ...mapGetters('common', ['showTrendingCategory']),
    getCategories() {
      if (this.businessConfigurations?.showTrendingCategory)
        return this.trendingCategories
      else return this.categories
    },
    showLoading() {
      if (this.businessConfigurations?.showTrendingCategory)
        return this.loadingTrendingCategories
      else return this.loadingCategories
    },
  },
}
</script>
<style lang="scss">
.q-category-slider {
  padding: 20px 40px 10px;
  @media (max-width: 599px) {
    padding: 10px 15px;
  }
  .swiper-container {
    padding: 0 15px;
    &:after,
    &:before {
      content: '';
      width: 15px;
      position: absolute;
      top: 0;
      height: 100%;
      z-index: 2;
    }
    &:after {
      left: 0;
      background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 1) 0%,
        rgba(255, 255, 255, 0) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=1 );
    }
    &:before {
      right: 0;
      background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
    }
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 16px;
    height: 16px;
    line-height: 1;
    background: $secondary;
    background: var(--q-color-secondary) !important;
    color: #fff;
    color: var(--q-color-secondaryText) !important;
    border-radius: 50%;
    right: 0;
    margin-top: -8px;
    &:after {
      width: 3px;
      // height: 7px;
      font-size: 6px;
      // margin-top: 2px;
    }
  }
  .swiper-button-prev {
    left: 0;
  }
  .swiper-slide {
    width: auto;
    a {
      padding: 8px 16px;
      display: block;
      font-size: 12px;
      font-weight: bold;
      min-height: auto;
      background: #fafafa;
      color: #393939;
      text-decoration: none;
      text-transform: none;
      border: 1px solid #eeeeee;
      border-radius: 5px;
    }
    a:hover {
      background: $secondary;
      background: var(--q-color-secondary) !important;
      border-color: $secondary;
      border-color: var(--q-color-secondaryOnBody) !important;
      color: #fff;
      color: var(--q-color-secondaryText) !important;
    }
  }
}
</style>
